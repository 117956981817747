import React, { useState } from 'react';
import $ from 'jquery'
import './../../static/css/driver.css'
import history from './../../services/history';

const Driver = () => {

    const [modal, setModal] = useState(false)

    const AddDriver = () => {
        setModal(!modal)
    }

    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
        }
    }

    const navigateAndShow = () => {
        history.push('/dashboard/driver/details')
    }
    return (
        <>
            <div className="contentpanel">
                <h1 className="title">Driver</h1>
                <hr />

                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Drivers List</div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="truck_no" id="searchType">
                                        <option value="License_no">License No.</option>
                                        <option value="transporter_name">Driver Name</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                <option value="all">All Type</option>
                                <option value="owned">Owned Vehicle</option>
                                <option value="hired">Hired Vehicle</option>
                            </select>
                        </div>
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userType") !== 'userType2' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all">Transporter's Name</option>
                                        <option value="ajay">Ajay</option>
                                        <option value="nitin">Nitin</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary' onClick={() => AddDriver()}>Add Driver</button>
                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item'>
                                    {localStorage.getItem("userType")==='userType2'?"":<div className='ttname'>Transporter : <span className='transportrName'>Ajay(ID : XXXX)</span></div>}
                                    <div className='sno_txt'>1.</div>
                                    <div className='data_item_content'>
                                        <div>
                                            <span>Driver Name</span>
                                            <span>(OTM/Driver API)</span>
                                        </div>
                                        <div>
                                            <span>Driver Number</span>
                                            <span>(OTM/Driver API)</span>
                                        </div>
                                        <div>
                                            <span>License Number</span>
                                            <span>(OTM/Lynkit)</span>
                                        </div>
                                        <div>
                                            <span>Aadhar No.</span>
                                            <span>(OTM/Lynkit)</span>
                                        </div>
                                        <div>
                                            <span>Date of Birth</span>
                                            <span>(Driver API)</span>
                                        </div>
                                    </div>
                                    <div className='data_item_content set_height clps'>
                                        <div >
                                            <span>Validity from</span>
                                            <span>(Driver API)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_15"}>
                                            <span>Validity to</span>
                                            <span>(Driver API)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_15"}>
                                            <span>State</span>
                                            <span>(Driver API)</span>
                                        </div>
                                        <div>
                                            <span>City</span>
                                            <span>(Driver API)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_15"}>
                                            <span>Pin Code</span>
                                            <span>(Driver API)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_15" : "wd_15"}>
                                            <span>Vaccination status</span>
                                            <span>Full Vacinated on 12-09-2021<i className="fa fa-file" id="vaccination_document" aria-hidden="true"></i></span>
                                        </div>

                                        {localStorage.getItem("userType") === 'userType2' ?
                                            <div className='wd_15' >
                                                <span>Black Listed status</span>
                                                <span>(Lynkit)</span>
                                            </div>
                                            : ""}
                                        <div className='btn_conte'>
                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1'>View Documents</button>
                                            {localStorage.getItem("userType") !== 'userType2' ?
                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>Update</button>
                                                : ""}
                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Delete</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1' onClick={navigateAndShow}>View Detail</button>
                                            {localStorage.getItem("userType") !== 'userType2' ?
                                                <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1'>Blacklist</button>
                                                : ""}
                                        </div>
                                    </div>
                                    <div className='collps_icon' onClick={() => { toogleItemView(0) }}>
                                        <i className='fa fa-chevron-down'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modal && (
                <div className='modal'>
                    <div className='overlay' >
                        <div className='modal_content'>
                            <h3>Add Driver</h3>
                            <span className="ct-close" onClick={AddDriver}></span>

                            <div className='driver_details'>
                                <div className="cust-row_driver">
                                    <div className='cust-col-10'>
                                        <div className='table-responsive_driver'>
                                            <div className='add-bordered_table'>
                                                <div className='addDriver-detail1'>

                                                    <div className='addDriver-Left'>
                                                        <span>DL No</span>
                                                    </div>

                                                    <div className='addDriver-right'>
                                                        <input type='text' value="HHJY41F12" />
                                                    </div>
                                                </div>
                                                <div className='addDriver-detail2'>
                                                    <div className='addDriver-Left'>
                                                        <span>Date of Birth </span>
                                                    </div>

                                                    <div className='addDriver-right'>
                                                        <input type='text' value="18-03-1996" />
                                                    </div>
                                                </div>
                                                <div className='addDriver-detail3'>
                                                    <div className='addDriver-Left'>
                                                        <span>Mobile Number</span>
                                                    </div>

                                                    <div className='addDriver-right'>
                                                        <input type='text' value="9917668262" />
                                                    </div>
                                                </div>

                                                <div className='addDriver-detail4'>
                                                    <div className='addDriver-Left'>
                                                        <span>Aadhar Number</span>
                                                    </div>

                                                    <div className='addDriver-right'>
                                                        <input type='text' value="9464-7772-2221" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="add_div">
                                    <button className="action_btn">Add Driver</button>
                                </div>
                            </div>







                        </div>

                    </div>
                </div>
            )}






        </>

    )

}

export default Driver;