import types from './types'
import axios from 'axios'
import JointActionDispatcher from '../../../services/jointActionDispatcher'

// const domain = 'http://d8gnu1rryevl2.cloudfront.net'
// const domain = 'https://attendance.lynkit.io'
// const domain = process.env.REACT_APP_HOSTNAME
// const domain = "https://beta.attendance.lynkit.io";
const domain = process.env.REACT_APP_APIHOST || "";
// get department
export const getDepartmentsFetch = () => {
    return {
        type: types.GET_DEPARTMENTS_FETCH
    }
}
export const getDepartmentsSucc = (data) => {
    return {
        type: types.GET_DEPARTMENTS_SUCC,
        payload: data
    }
}

export const getDepartmentsErr = (error) => {
    return {
        type: types.GET_DEPARTMENTS_ERR,
        payload: error
    }
}

export const callGetDepartmentsApi = (body) => {
    return (dispatch) => {
        dispatch(getDepartmentsFetch());
        axios.post(domain + '/api/user/get_departments', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(getDepartmentsSucc(response.data));
        }).catch((error) => {
            dispatch(getDepartmentsErr(error.message))
        })
    }

}

// add  department

export const addDepartmentFetch = () => {
    return {
        type: types.ADD_DEPARTMENT_FETCH
    }
}

export const addDepartmentSucc = (data) => {
    return {
        type: types.ADD_DEPARTMENT_SUCC,
        payload: data
    }
}

export const addDepartmentErr = (error) => {
    return {
        type: types.ADD_DEPARTMENT_ERR,
        payload: error
    }
}
export const callAddDepartmentApi = (body) => {
    return (dispatch) => {
        dispatch(addDepartmentFetch());
        axios.post(domain + '/api/user/add_department', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            dispatch(addDepartmentSucc(response.data));
            dispatch(callGetDepartmentsApi({}));
        }).catch((error) => {
            dispatch(addDepartmentErr(error.message))
        })
    }
}


export const getDepartments = {
    url: `/api/user/get_departments`,
    action: types.GET_DEPARTMENTS,
    __proto__: JointActionDispatcher
}

export const addDepartment = {
    url: `/api/user/add_department`,
    action: types.ADD_DEPARTMENT,
    __proto__: JointActionDispatcher
}

export const updateDepartment = {
    url: `/api/user/update_department`,
    action: types.UPDATE_DEPARTMENT,
    __proto__: JointActionDispatcher
}

export const deleteDepartment = {
    url: `/api/user/delete_department`,
    action: types.DELETE_DEPARTMENT,
    __proto__: JointActionDispatcher
}
