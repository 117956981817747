import React, {useState} from 'react';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import {useForm} from 'react-hook-form';


export default function AddHoliday({closeModal, addHoliday}) {

    const { register, handleSubmit, getValues, reset, formState:{errors} } = useForm();



    const handleAddHoliday = (data) => {
        data.holidayType="day"
        console.log(data,"data for holiday")
        addHoliday(data);
        reset();
        closeModal();
    }

    return (
        <form onSubmit={handleSubmit(handleAddHoliday)}>
            <section id="addHolidayModal" className="custom-modal custom-modal-small">
                <div className="custom-modal-header">
                <h1>Add Holiday</h1>
                <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'70vh'}}>
                    <div className="cust-row">
                        <div className='cust-col-10'>
                            <div className='table-responsive'>
                                <table className='bordered_table'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Date <span className="red_color">*</span></h2>
                                            </th>
                                            <th>
                                                <input type="date" className="form-control" {...register('date', { required : true })} />
                                                {errors.date && <p className='error red_color'>Date is required</p>}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Holiday Name <span className="red_color">*</span></h2>
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" {...register('name', { required : true })}/>
                                                {errors.name && <p className='error red_color'>Holiday Name is required</p>}
                                            </th>
                                        </tr>
                                        
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                   
                    <div className="cust-row">
                        <div className="cust-col-3">
                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Add</div>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </section>

        </form>
        
    )
}
