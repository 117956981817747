import React, { useEffect, useRef, useState } from 'react';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import $ from 'jquery'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { expenseTypesSelector, monthSalarySelector, paymentMethodsSelector,allBankSelector } from '../../../state/redux/workforceDetail/selectors';
import { getExpenseType, getPaymentMethod, getSalary, getFile, addPaymentMethod,getAllBanks } from '../../../state/redux/workforceDetail/actions';
import { userDetailSelector } from '../../../state/redux/workforce/selectors';
import moment from 'moment';


export default function UpdateSalary({ closeModal, expenseObj, updateExpense, userObj }) {

    // console.log({up});
    const dispatch = useDispatch();

    const { banks } = useSelector(userDetailSelector);
    const allBanks = useSelector(allBankSelector);
    const selectedSalary = useSelector(monthSalarySelector);
    const paymentMethods = useSelector(paymentMethodsSelector);
    const [imageUrl, setImageUrl] = useState([])
    const [keyArray, setKeyArray] = useState([])


    const { register, handleSubmit, watch, reset, setValue,getValues, formState: { errors } } = useForm();
    const selectedPaymentMode = watch('payment_mode', "");
    const month = watch('month', "")

    const [includeBalance, setIncludeBalance] = useState(false);
    const [includeBonus, setIncludeBonus] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([])
    const [bonus, setBonus] = useState(0);
    const [balanceNew, setBalanceNew] = useState(0)
    const [monthSalary, setMonthSalary] = useState({})

    const [showTid, setShowTid] = useState(false);
    useEffect(() => {
        let s = false;
        for (let i = 0; i < paymentMethods.length; i++) {
            if (selectedPaymentMode == paymentMethods[i].payment_method) {
                s = paymentMethods[i].transaction_id
                break;
            }
        }
        setShowTid(s);
    }, [selectedPaymentMode])


    const handleFilesUpload = async (files) => {
        let imageURL = URL.createObjectURL(files[0])
        // console.log(URL.createObjectURL(files[0]),"files this is uploaded")


        const promises = Array.from(files).map(async file => {
            // console.log(file.name);
            const base64File = await base64Convert(file);
            return base64File
            // {
            //     base64File: base64File,
            //     filename: file.name
            // }
        })
        const base64Files = await Promise.all(promises);
        // console.log(base64Files, "files ti check")
        setKeyArray((prev) => [...prev, ...base64Files])
        setImageUrl((prev) => [...prev, ...base64Files])
        setUploadFiles(base64Files);
    }

    const handleUpdateExpense = (data) => {
        // console.log(data,keyArray,"what you need to send")
        const bankDetails = data.bank.split(";")
        data.bank_details = {
            bankName: bankDetails[0],
            accountNo: bankDetails[1],
            branchName: bankDetails[2],
        }
        if(data.trans_bank){
            let obj=allBanks.find(el=>el._id==data.trans_bank)
            delete data.trans_bank
            data['from_bank']=obj
        }
        data.amount_paid = getTotal();
        data.transaction_type = "credit"
        data.expense_type = "user"
        data.expense_name = "salary"
        // data.driverId = userId;
        data.transaction_details = {
            deductions: includeBalance ? balanceNew : "0",
            bonus: includeBonus ? bonus : "0",
            salary: monthSalary.salary,
            month: getValues().month
        }
        data.use_wallet_balance = includeBalance
        data.adjusted_wallet_balance = includeBalance ? balanceNew : "0"
        data.image = keyArray;
        // console.log(data,"final to submit ")
        updateExpense(expenseObj._id, data);
        reset();
        exitModal();

    }
    const handleRemoveDocument = (i) => {
        let oldKey = [...keyArray]
        let oldBase = [...uploadFiles]
        let oldDocuments = [...imageUrl];
        oldKey.splice(i, 1);
        oldDocuments.splice(i, 1);
        oldBase.splice(i, 1);
        setKeyArray(oldKey)
        setImageUrl(oldDocuments);
        setUploadFiles(oldBase)
    }
    const getTotal = () => {

        let total = monthSalary.salary;
        if (includeBalance) {
            total = total - balanceNew
        }
        if (includeBonus && bonus > 0) {
            total = total + parseFloat(bonus)
        }
        return total
    }
    useEffect(() => {
        setMonthSalary(selectedSalary)

    }, [selectedSalary])

    // const handleAddSalary = (data) => {
    //     const bankDetails = data.bank.split(";")
    //     data.bank_details = {
    //         bankName: bankDetails[0],
    //         accountNo: bankDetails[1],
    //         branchName: bankDetails[2],
    //     }
    //     data.amount_paid = getTotal();
    //     data.transaction_type = "credit"
    //     data.expense_type = "user"
    //     data.expense_name = "salary"
    //     data.driverId = userId;
    //     data.transaction_details = {
    //         deductions: balanceNew,
    //         bonus: bonus,
    //         salary: monthSalary.salary
    //     }
    //     data.use_wallet_balance = includeBalance
    //     data.adjusted_wallet_balance = balanceNew
    //     console.log(data);
    //     addSalary(data)
    //     reset();
    //     setUploadFiles([]);
    //     closeModal();
    // }
    const changeBalance = (val) => {
        let num = val.target.value;

        let t = num;
        num = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        setBalanceNew(num)
    }
    const changeBonus = (val) => {
        let num = val;

        let t = num;
        num = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;

        setBonus(num)
    }
    const exitModal = () => {
        closeModal()
        setImageUrl([])
        setUploadFiles([])
        setKeyArray([])
        setMonthSalary({})
        setBalanceNew(0)
        setBonus(0)
        setIncludeBalance(false)
        setIncludeBonus(false)
    }
    const toggleIncludeBalance = () => {
        setIncludeBalance(prev => !prev)
        setBalanceNew(0)
        wallet_balance = calculateWallet(Number(userObj.balance), Number(expenseObj?.transaction_details?.deductions || 0), includeBalance, Number(balanceNew || 0))

    }
    const toggleIncludeBonus = () => {
        setBonus(0)
        setIncludeBonus(bonus => !bonus)
    }

    useEffect(() => {
        dispatch(getPaymentMethod.call({}))
        dispatch(getAllBanks.call({}))
    }, [])
    useEffect(() => {

        if (expenseObj) {
            dispatch(getSalary.call({
                userId: userObj._id,
                month: expenseObj?.transaction_details?.month || new Date(expenseObj.expense_date).getMonth() + 1
            }))

            if (expenseObj?.transaction_details?.bonus != 0) {

                setIncludeBonus(true)
                setBonus(expenseObj?.transaction_details?.bonus)
            }
            if (expenseObj?.transaction_details?.deductions != 0) {

                setBalanceNew(expenseObj?.transaction_details?.deductions)
                setIncludeBalance(true)
            }
            dispatch(getPaymentMethod.call({}, () => {
                setValue('month', expenseObj?.transaction_details?.month || new Date(expenseObj?.expense_date).getMonth() + 1);
                setValue('expense_id', expenseObj._id);
                setValue('expense_type', "user");
                setValue('transaction_type', expenseObj.transaction_type);
                setValue('expense_name', expenseObj.expense_name);
                setValue('amount_paid', expenseObj.amount_paid);
                setValue('expense_date', moment(expenseObj.expense_date).format("YYYY-MM-DD"));
                setValue('payment_mode', expenseObj.payment_mode);
                if(expenseObj.transaction_bank && expenseObj.transaction_bank._id){
                    setValue('trans_bank', expenseObj.transaction_bank._id);
                }
                else{
                    setValue('trans_bank', "")
                }
                setValue('notes', expenseObj.notes);
                let s = false;
                for (let i = 0; i < paymentMethods.length; i++) {
                    if (expenseObj.payment_mode == paymentMethods[i].payment_method) {
                        s = paymentMethods[i].transaction_id
                        break;
                    }
                }
                // console.log("Setting show id ");
                // console.log(s);
                setShowTid(s);
            }))
        }
        if (expenseObj?.image.length > 0) {
            setKeyArray(expenseObj.image)
            expenseObj.image.map((img) => {
                return (

                    dispatch(getFile.call({
                        key: img
                    }
                        , (image_data) => {
                            if (image_data) {
                                setImageUrl((prev) => [...prev, image_data.data]);
                            }

                        }
                    ))
                )
            })
        }

    }, [expenseObj])

    useEffect(() => {
        if (month != '') {
            dispatch(getSalary.call({
                userId: userObj._id,
                month: month
            }))
        }

    }, [month])



    let wallet_balance = calculateWallet(Number(userObj.balance), Number(expenseObj?.transaction_details?.deductions || 0), includeBalance, Number(balanceNew || 0))


    function calculateWallet(oldBalance, oldDeduction, shouldDeduct, newDeduction) {
        let result = Number(oldBalance ? oldBalance.toFixed(2) : 0)
        if (shouldDeduct) {
            result = result + oldDeduction - newDeduction
        }
        else {
            result = result + oldDeduction
        }
        return result;
    }

    // console.log({monthSalary})
    return (

        <section id="updateSalaryModal" className="custom-modal custom-modal-small">
            <div className="custom-modal-header">
                <h1>Update Salary</h1>
                <span className="ct-close" onClick={exitModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="cust-row mb-2">
                    <div className='cust-col-5'>
                        <label className="black_color font-bold text-left wdt100">User</label>
                        <input className='cust-input' placeholder='Username' value={userObj.name} />
                    </div>
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">Month<span className="red_color">*</span></label>
                        <select className="cust-input select2-single" {...register('month', { required: true })}>
                            <option value="">Select Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </div>
                </div>
                <h6>Salary Details</h6>
                <div className='cust-row'>
                    <div className='cust-col-1'></div>
                    <div className='cust-col-8'>
                        <div className=''>
                            <table className='bordered_table'>
                                <tbody>
                                    <tr>
                                        <th>
                                            <h2 className='tht txtCent'>Monthly Salary</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{month != "" ? userObj.salary : 0}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <h2 className='tht txtCent'>Present days</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.present}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht txtCent'>Absent days</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.absent}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht txtCent'>Total Leaves</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.leaves}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht txtCent'>Paid Leaves</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.leaves_paid || 0}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht txtCent'>Unpaid Leaves</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.leaves_unpaid || 0}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht txtCent'>Holidays</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.holidays}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht txtCent'>Payable days</h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht txtCent'>{monthSalary.payableDays}</h2>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht'>
                                                <input type="checkbox" value={includeBonus} onClick={() => toggleIncludeBonus()} checked={includeBonus} />
                                                Add bonus
                                            </h2>
                                        </th>
                                        <th>
                                            <input type="number" className="cust-input" value={bonus} onChange={(e) => changeBonus(e.target.value)} disabled={!includeBonus} />
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <h2 className='tht'>
                                                <input type="checkbox" value={includeBalance} onClick={() => toggleIncludeBalance()} checked={includeBalance} />
                                                Wallet balance
                                            </h2>
                                        </th>
                                        <th >
                                            <strong className='text-danger'>
                                                {wallet_balance}
                                            </strong>
                                            {<input className='w-50' type="number" min="0" value={balanceNew} onChange={(e) => changeBalance(e)} disabled={!includeBalance} />}
                                        </th>
                                    </tr>

                                    <tr>
                                        <th >
                                            <h2 className='tht'>
                                                <strong>Total</strong>
                                            </h2>
                                        </th>
                                        <th className='text-end'>
                                            <h2 className='tht'>
                                                <strong className='text-success'>
                                                    {
                                                        isNaN(Number(getTotal())) ? 0 : getTotal().toFixed(2)
                                                    }
                                                </strong>
                                            </h2>
                                        </th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(handleUpdateExpense)}>
                    <div className='cust-row'>
                        <div className='cust-col-5'>
                            <label className="black_color font-bold text-left wdt100">Bank</label>
                            <select className='cust-input select2-single' {...register('bank')}>
                                <option value="">Select Bank</option>
                                {
                                    banks.map((bank, i) => {
                                        return <option key={i} value={bank.bankName + ";" + bank.accountNo + ";" + bank.branchName}>{bank.bankName} - {bank.accountNo}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='cust-col-5'>
                            <label className="black_color font-bold text-left wdt100">Payment Date<span className="red_color">*</span></label>
                            <input className="cust-input" type="date" max={moment().format("YYYY-MM-DD")} placeholder="Payment Date" {...register('expense_date', { required: true })} />
                            {errors.expense_date && <p className='error red_color'>Payment Date is required</p>}
                        </div>

                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Payment Mode<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" type="password" {...register('payment_mode', { required: true })}>
                                <option value="">Select Mode</option>
                                {
                                    paymentMethods.map((method, i) => {
                                        return (
                                            <option value={method.payment_method} key={i}>{method.payment_method}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.payment_mode && <p className='error red_color'>Payment Mode is required</p>}


                        </div>
                        {
                            showTid &&
                            (
                                <div className="cust-col-5">
                                    <label className="black_color font-bold text-left wdt100">Transaction ID<span className="red_color">*</span></label>
                                    <input className="cust-input" type="text" placeholder="Transaction Id" {...register('transaction_id', { required: true })} />
                                </div>
                            )
                        }
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Remarks(Optional)</label>
                            <input className="cust-input" type="text" placeholder="Add Remarks" {...register('notes')} />
                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Select Transaction Bank<span>(optional)</span></label>
                            <select data-testid="bank_dropdown_salary_update" className="cust-input select2-single" {...register('trans_bank', { required: false })}>
                                <option value="">Select Bank</option>
                                {
                                    allBanks.map((bank, i) => {
                                        return (
                                            <option key={i} value={bank._id}>{bank.bankName +" - "+bank.accountNumber}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.trans_bank && <p className='error red_color'>Transaction Bank is required</p>}
                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <label className="black_color font-bold text-left wdt100">Add Image(Optional)</label>
                            <div className='doc_container'>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={handleFilesUpload} modalId="update-salary-documents" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        imageUrl.length > 0 &&
                        <>
                            <br />
                            <h6>Selected Files</h6>
                            <hr />
                            <div className='row'>
                                {
                                    imageUrl.map((file, i) => {
                                        return (
                                            <div className='col-md-6 col-sm-4 col-lg-3' key={i}>
                                                <div className='alert alert-info alert-holiday alert-dismissible fade show p-3' style={{ width: "115px", height: "110px" }}>
                                                    {/* {<img src={file} style={{ width: "100%", height: "100%" }} />} <button type="button" className="btn-close" onClick={() => handleRemoveDocument(i)}></button> */}
                                                    {file?.includes("image") ?
                                                    <>
                                                        <img style={{ width: "100%", height: "100%" }}
                                                            src={file}
                                                            alt='' />
                                                        <button type="button" className="btn-close" onClick={() => handleRemoveDocument(i)}></button>
                                                    </>
                                                    :
                                                    <>
                                                        <object
                                                            data={file}
                                                            type="application/pdf"
                                                            width="100%"
                                                            height='90%'
                                                        ></object>
                                                       <button type="button" className="btn-close" onClick={() => handleRemoveDocument(i)}></button>
                                                    </>
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <hr />
                        </>
                    }
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'> Pay </div>
                            </button>
                        </div>
                    </div>
                </form>




            </div>
        </section>


    )
}


