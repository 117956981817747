import React from "react";
import './../../static/css/contracts.css'
import TotalPOD from './../../static/images/icon/contract/TotalPOD.svg'
import TotalRevenue from './../../static/images/icon/contract/TotalRevenue.svg'
import TotalTonnage from './../../static/images/icon/contract/TotalTonnage.svg'
import TotalBooking from './../../static/images/icon/contract/TotalBooking.svg'
import Accepted from './../../static/images/icon/contract/Accepted.svg'
import Rejected from './../../static/images/icon/contract/Rejected.svg'
import Overspeed from './../../static/images/icon/contract/Overspeed.svg'
import OverStoppage from './../../static/images/icon/contract/OverStoppage.svg'
import LateIndenting from './../../static/images/icon/contract/LateIndenting.svg'
import Latedelivery from './../../static/images/icon/contract/Latedelivery.svg'
import history from './../../services/history'

const Contracts = () => {

    const _viewDetail = () =>{
        history.push("/dashboard/Contracts/details")
    }

    return ( 
        <>
            <div className="contractscontentpanel">
            <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title font-bold font-xl">
                            Contract
                        </h1>
                    </div>
                    {
                        localStorage.getItem("userType")!=='userType2'?
                        <div className='dynamicDropdown'>
                            <select name="userName"  className="ws"  id="transDropdown">
                                <option value="all">Select Transporter</option>
                                <option value="ajay">Ajay</option>
                                <option value="nitin">Nitin</option>
                            </select>
                        </div>:""
                    }
                </div>
                <hr className="bgg"/>
                <div className='cust-row'>
                    {
                        localStorage.getItem("userType")!=='userType2'?
                        <div className='cust-col-3'>
                            <h3 className='font-lg orange_color'><span className='font-bold grey_color'>Transporter : </span> XYZ Logistics Pvt. Ltd.</h3>
                        </div>
                        :""
                    }
                </div>
                <div className="contract-container">
                    <div className="contract-header1">

                        <div className="contractHeader1-one">
                            <div className="contractNum">
                                <button>1</button>
                            </div>
                            <div className="Contractone">
                                <div className="contractone-1">
                                    <span className="contractspan1">Contract Name</span>
                                    <span className="contractspan2">Renukoot Contract</span>
                                </div>
                                <div className="contractone-2">
                                    <span className="contractspan1">Contract ID</span>
                                    <span className="contractspan2">HIL/DAH/001/DH </span>
                                </div>
                                <div className="contractone-3">
                                    <span className="contractspan1">Start Date</span>
                                    <span className="contractspan2">18-03-1996 </span>
                                </div>
                            </div>
                            <div className="Contracttwo">
                                <div className="contractTwo-1">
                                    <span className="contractspan1">Rate</span>
                                    <span className="contractspan2">Rs. 1.60 per Km</span>
                                    <span className="contractspan3">as per 01-12-21</span>
                                </div>
                                <div className="contractTwo-2">
                                    <span className="contractspan1">Diesel Rate</span>
                                    <span className="contractspan2">Rs. 90/L</span>
                                    <span className="contractspan3">as per 01-12-21</span>
                                </div>
                                <div className="contractTwo-3">
                                    <span className="contractspan1">End Date</span>
                                    <span className="contractspan2">18-03-2022</span>
                                </div>
                            </div>
                            <div className="Contractthree">
                                <button onClick={()=>{_viewDetail()}}>View</button>
                            </div>
                        </div>

                        <div className="contractHeader1-two">
                            <div className="contractNum">
                                <button>2</button>
                            </div>
                            <div className="Contractone">
                                <div className="contractone-1">
                                    <span className="contractspan1">Contract Name</span>
                                    <span className="contractspan2">Trilok Contract</span>
                                </div>
                                <div className="contractone-2">
                                    <span className="contractspan1">Contract ID</span>
                                    <span className="contractspan2">HIL/DAH/002/DH </span>
                                </div>
                                <div className="contractone-3">
                                    <span className="contractspan1">Start Date</span>
                                    <span className="contractspan2">28-07-1996 </span>
                                </div>
                            </div>
                            <div className="Contracttwo">
                                <div className="contractTwo-1">
                                    <span className="contractspan1">Rate</span>
                                    <span className="contractspan2">Rs. 1.70 per Km</span>
                                    <span className="contractspan3">as per 10-12-21</span>
                                </div>
                                <div className="contractTwo-2">
                                    <span className="contractspan1">Diesel Rate</span>
                                    <span className="contractspan2">Rs. 92/L</span>
                                    <span className="contractspan3">as per 11-12-21</span>
                                </div>
                                <div className="contractTwo-3">
                                    <span className="contractspan1">End Date</span>
                                    <span className="contractspan2">28-03-2022</span>
                                </div>
                            </div>
                            <div className="Contractthree">
                                <button onClick={()=>{_viewDetail()}}>View</button>
                            </div>
                        </div>

                        <div className="contractHeader1-three">
                            <div className="contactAdd"></div>
                        </div>
                    </div>

                    <div className="contract-header2">
                        <div className="Contract-h2-div-1">

                            <div className="contract-h2-r1">
                                <span>Contract Performance</span>
                            </div>

                            <div className="contract-h2-r2">
                                <div className="Contract-div-1-c1">
                                
                                    <div className="contract-c1-r1">
                                        <img src={TotalPOD} alt="logo"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">245</span>
                                        <span className="c1-r2-t2">Total POD</span>
                                    </div>
                                </div>

                                <div className="Contract-div-1-c2">
                                    <div className="contract-c1-r1">
                                        <img src={TotalRevenue} alt="logo"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">5000</span>
                                        <span className="c1-r2-t2">Total revenue</span>
                                    </div>
                                </div>

                                <div className="Contract-div-1-c3">
                                    <div className="contract-c1-r1">
                                        <img src={TotalTonnage} alt="logo"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">40</span>
                                        <span className="c1-r2-t2">Total tonnage</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Contract-h2-div-2">
                            <div className="contract-h2-r1">
                                <span>Total Bookings </span>
                            </div>

                            <div className="contract-h2-r2">
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={TotalBooking} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">75</span>
                                        <span className="c1-r2-t2">Total Booking</span>
                                    </div>
                                </div>

                                <div className="Contract-div-1-c2">
                                    <div className="contract-c1-r1">
                                        <img src={Accepted} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">50</span>
                                        <span className="c1-r2-t2">Accepted</span>
                                    </div>
                                </div>

                                <div className="Contract-div-1-c3">
                                    <div className="contract-c1-r1">
                                        <img src={Rejected} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">24</span>
                                        <span className="c1-r2-t2">Rejected</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Contract-h2-div-3">
                            <div className="contract-h2-r1">
                                <span>Total Penalties </span>
                            </div>

                            <div className="contract-h2-r3">
                                <div className="Contract-div-r3-c1">
                                    <div className="contract-c1-r1">
                                        <img src={Overspeed} alt="none"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">5</span>
                                        <span className="c1-r2-t2">Overspeed</span>
                                    </div>
                                </div>

                                <div className="Contract-div-r3-c2">
                                    <div className="contract-c1-r1">
                                        <img src={OverStoppage} alt="none"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">12</span>
                                        <span className="c1-r2-t2">Over stoppage</span>
                                    </div>
                                </div>

                                <div className="Contract-div-r3-c3">
                                    <div className="contract-c1-r1">
                                        <img src={LateIndenting} alt="none"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">16</span>
                                        <span className="c1-r2-t2">Late intending</span>
                                    </div>
                                </div>

                                <div className="Contract-div-r3-c4">
                                    <div className="contract-c1-r1">
                                        <img src={Latedelivery} alt="none"/>
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">4</span>
                                        <span className="c1-r2-t2">Late delivery</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contracts;