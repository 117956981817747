import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import '../../../static/css/reactCalender.css'
import GeneralCalendar from '../../Components/GeneralCalendar';
import moment, { months } from 'moment';
import { getLeaveType } from '../../../state/redux/category/actions';
import { getLeaveBalance } from '../../../state/redux/workforceDetail/actions';
import { useDispatch, useSelector } from 'react-redux';
import { leaveBalanceSelector } from '../../../state/redux/workforceDetail/selectors';

export default function MarkAttendance({ user, date, status, closeModal, markAttendance }) {

    // console.log({user, status, date})
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, watch, setValue, formState: { errors }, } = useForm();
    const dayDuration = watch('leaveDetails.dayDuration', "");
    const leaveStatus = watch('status', "")
    const selectedLeaveType = watch("leaveDetails.leaveType")

    useEffect(() => {
        setValue('leaveDetails.leaveLeft', leaveBalance[selectedLeaveType])
    }, [selectedLeaveType])
    const [today, setToday] = useState(new Date())
    const [calenderData, setCalendarData] = useState([]);

    const leaveBalance = useSelector(leaveBalanceSelector);
    const leaveCategories = useSelector(
		(state) => state.category.categoryList.category
	);
    // console.log({leaveCategories})
// console.log({errors, selectedLeaveType, watchLeaveLeft: leaveBalance[selectedLeaveType], leaveBalance})
    const handleAdd = (data) => {
        // console.log({data})
        data['date'] = moment(data['date']).format("MM-DD-YYYY");

        const selectedLeave = leaveCategories.find(_=> _.leaveType == data?.leaveDetails?.leaveType);
        if (data.status == "Absent") {
            data.reason = data.leaveDetails.reason
        }
        data = {...data, leaveDetails: {...data.leaveDetails, leaveCategory: selectedLeave?.leaveCategory}}

        // console.log(data)

        markAttendance(data);
        reset();
        closeModal();
    }

    useEffect(() => {

        if (user && date && status) {

            setValue('userId', user._id);
            setValue('date', date.clone().format('YYYY-MM-DD'))
            setValue('status', status)
            let monthStart = date.clone().startOf('month')
            const monthEnd = date.clone().endOf('month');
            let calendarMatrix = [
                Array(7).fill({ text: " " }),
                Array(7).fill({ text: " " }),
                Array(7).fill({ text: " " }),
                Array(7).fill({ text: " " }),
                Array(7).fill({ text: " " }),
                Array(7).fill({ text: " " })
            ]
            let row = 0;
            while (monthEnd.diff(monthStart) >= 0) {
                const dayOfMonth = (+monthStart.clone().format("DD"));
                const dayOfWeek = monthStart.clone().isoWeekday();
                // const row = Math.floor(dayOfMonth / 7);
                const col = dayOfWeek % 7;


                let status = '';
                for (let i = user.attendance.length - 1; i >= 0; i--) {
                    let temp = moment(moment(user.attendance[i].date).format("YYYY-MM-DD"));
                    let diff = monthStart.clone().diff(temp, 'days')
                    if (diff == 0) {
                        status = user.attendance[i].status.toLowerCase().replace(/\s/g, '') + "-cell";
                        break;
                    }
                }

                try {
                    calendarMatrix[row][col] = {
                        text: monthStart.clone().format("DD"),
                        className: status
                    }
                }
                catch (e) {
                    console.log(row, col, calendarMatrix, "error case");
                }

                if (col == 6) {
                    row++;
                }
                monthStart = monthStart.add(1, 'days');
            }
            setCalendarData(calendarMatrix);
            dispatch(getLeaveType.call({}, (res) => {
                if (res) {
                    // console.log({res})
                    for (let i = 0; i < res.data.length; i++) {
                        const leaveType = res.data[i].leaveType;
                        dispatch(getLeaveBalance.call({
                            userId: user._id,
                            leaveType: leaveType
                        }))

                    }
                }
            }))

            const currAttd= user?.attendance?.find(_=> moment(_?.date)?.format('YYYY-MM-DD')== date.clone().format('YYYY-MM-DD'));
            // console.log(user,currAttd, currAttd?.leaveDetails?.leaveType, currAttd?.leaveDetails?.reason)
            setValue('leaveDetails.leaveType', currAttd?.leaveDetails?.leaveType)
            if(currAttd?.status == 'Absent'){
                setValue('leaveDetails.reason', currAttd?.reason)

            }else{

                setValue('leaveDetails.reason', currAttd?.leaveDetails?.reason)
            }

        }
    }, [user, date])

    // console.log({leaveBalance})
    return (
        <>
            {
                user &&
                <form onSubmit={handleSubmit(handleAdd)}>
                    <section id="markAttendanceModal" className="custom-modal custom-modal-small">
                        <div className="custom-modal-header">
                            <h1>{user.name}</h1>
                            <span className="ct-close" onClick={closeModal}></span>
                        </div>
                        <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                            <div className="cust-row">
                                <div className='cust-col-2'></div>
                                <div className='cust-col-6'>
                                    <GeneralCalendar data={calenderData} />
                                </div>
                            </div>
                            <div className='cust-row'>
                                <div className="cust-col-5">
                                    <label className="black_color font-bold text-left wdt100">Date <span className='red_color'>*</span></label>
                                    <input className="cust-input" type="text" disabled  {...register('date', { required: true })} />
                                </div>
                                <div className="cust-col-5">
                                    <label className="black_color font-bold text-left wdt100">Status <span className='red_color'>*</span></label>
                                    <select className='cust-input' {...register('status', { required: true })}>
                                        <option value="Present">Present</option>
                                        <option value="Absent">Absent</option>
                                        <option value="Holiday">Holiday</option>
                                        <option value="On Leave">On Leave</option>
                                    </select>
                                </div>
                            </div>


                            {
                                leaveStatus == 'On Leave' &&
                                <>
                                    <div className='cust-row'>
                                        <div className="cust-col-5">
                                            <label className="black_color font-bold text-left wdt100">Leave Type <span className='red_color'>*</span></label>
                                            <select className='cust-input' {...register('leaveDetails.leaveType', { required: true })}>
                                                <option value="" hidden>Choose leave type</option>
                                                {
                                                    Object.keys(leaveBalance).map((lt, i) => {
                                                        return (<option value={lt} key={i}>{lt}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {
                                            leaveBalance && leaveBalance[selectedLeaveType] ?
                                            <div className="cust-col-5">
                                            <label className="black_color font-bold text-left wdt100">Leaves Left <span className='red_color'>*</span></label>
                                            <input type="text" className='cust-input' disabled {...register('leaveDetails.leaveLeft', { required: true })} />
                                        </div>: null
                                        }
                                        
                                    </div>
                                    <div className='cust-row'>
                                        <div className="cust-col-5">
                                            <label className="black_color font-bold text-left wdt100">Day <span className='red_color'>*</span></label>
                                            <select className='cust-input' {...register('leaveDetails.dayDuration', { required: true })}>
                                                <option value="full">Full</option>
                                                <option value="half">Half</option>
                                            </select>
                                        </div>
                                        {
                                            dayDuration == 'half' &&
                                            <div className="cust-col-5">
                                                <label className="black_color font-bold text-left wdt100">Half <span className='red_color'>*</span></label>
                                                <select className='cust-input' {...register('leaveDetails.daySlot', { required: true })}>
                                                    <option value="1">1st</option>
                                                    <option value="2">2nd</option>
                                                </select>
                                            </div>

                                        }

                                    </div>
                                </>
                            }

                            {
                                leaveStatus != "Present" &&
                                <div className='cust-row'>
                                    <div className='cust-col-10'>
                                        <label className="black_color font-bold text-left wdt100">Reason <span className='red_color'>*</span></label>
                                        <input type="text" className="cust-input" {...register('leaveDetails.reason', { required: true })} />
                                    </div>
                                </div>
                            }

                            <div className="cust-row">
                                <div className="cust-col-3">
                                </div>
                                <div className="cust-col-4">
                                    <button className='button bti bg-grey-out m-0'>
                                        <div className='btn_icon'>
                                            <i className='fa fa-plus'></i>
                                        </div>
                                        <div className='btn_txt font-lg'>Add</div>
                                    </button>
                                </div>
                            </div>


                        </div>
                    </section>

                </form>
            }
        </>

    )
}
