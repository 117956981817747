import React, { useEffect, useState } from 'react';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { expenseTypesSelector, paymentMethodsSelector,allBankSelector } from '../../../state/redux/workforceDetail/selectors';
import { getExpenseType, getPaymentMethod, getFile,getAllBanks } from '../../../state/redux/workforceDetail/actions';
import moment from 'moment';


export default function UpdateExpense({ expenseObj, closeModal, updateExpense }) {

    const dispatch = useDispatch();
    const expenseTypes = useSelector(expenseTypesSelector);
    const paymentMethods = useSelector(paymentMethodsSelector);
    const allBanks = useSelector(allBankSelector);
    let expenseTypes_extended =[...expenseTypes];
    let paymentMethods_extended =[...paymentMethods];
    const [uploadFiles, setUploadFiles] = useState([])
    const [imageUrl, setImageUrl] = useState([])
    const [keyArray, setKeyArray] = useState([])
    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    const selectedPaymentMode = watch('payment_mode', "");

    const [showTid, setShowTid] = useState(false);
    useEffect(() => {
        let s = false;
        for (let i = 0; i < paymentMethods.length; i++) {
            if (selectedPaymentMode == paymentMethods[i].payment_method) {
                s = paymentMethods[i].transaction_id
                break;
            }
        }
        setShowTid(s);
    }, [selectedPaymentMode])
    useEffect(() => {
        
        if (expenseObj) {
            
            setValue('transaction_id', expenseObj.transaction_id);
        }

    }, [showTid])

    useEffect(()=>{
        dispatch(getAllBanks.call({}))
    },[])

    if(expenseObj){
        const extraExpenseName= expenseTypes_extended.find((expType)=>{
           return( expType.expense_name===expenseObj.expense_name);
        })
        if(!extraExpenseName){
            expenseTypes_extended=[...expenseTypes_extended,expenseObj]
        }
        
    }

    if(expenseObj){
        const extraPaymentName= paymentMethods_extended.find((payType)=>{
           return( payType.payment_mode===expenseObj.payment_mode);
        })
        if(!extraPaymentName){
            expenseObj.payment_method = expenseObj.payment_mode
            paymentMethods_extended=[...paymentMethods_extended,expenseObj]
        }
    
    }
    

    const handleFilesUpload = async (files) => {
        let imageURL = URL.createObjectURL(files[0])
        


        const promises = Array.from(files).map(async file => {
            // console.log(file.name);
            const base64File = await base64Convert(file);
            return base64File
            // {
            //     base64File: base64File,
            //     filename: file.name
            // }
        })
        const base64Files = await Promise.all(promises);
        // console.log(base64Files, "files ti check")
        setKeyArray((prev) => [...prev, ...base64Files])
        setImageUrl((prev) => [...prev, ...base64Files])
        setUploadFiles(base64Files);
    }
    {/* let imageURL=URL.createObjectURL(files[0])
        console.log(URL.createObjectURL(files[0]),"files this is uploaded")
       setImagesUrl((prev)=>[...prev,imageURL])
        
        const promises = Array.from(files).map(async file => {
            console.log(file.name);
            const base64File = await base64Convert(file);
            console.log(base64File,"the file uploaded base64")
            return base64File
            
        })
        // console.log(allImages,"promises the image base 64")
        const base64Files = await Promise.all(promises);
        console.log(...base64Files)
        setUploadFiles([...uploadFiles, ...base64Files]);
        console.log(uploadFiles)*/}


    const handleUpdateExpense = (data) => {
        // console.log(data,keyArray,"what you need to send")
        if(data.trans_bank){
            let obj=allBanks.find(el=>el._id==data.trans_bank)
            delete data.trans_bank
            data['from_bank']=obj
        }
        data.image = keyArray;
        updateExpense(expenseObj._id, data);
        reset();
        closeModal();
        setImageUrl([])
        setUploadFiles([])
        setKeyArray([])
    }
    
    const handleRemoveDocument = (i) => {
        let oldKey = [...keyArray]
        let oldBase = [...uploadFiles]
        let oldDocuments = [...imageUrl];
        oldKey.splice(i, 1);
        oldDocuments.splice(i, 1);
        oldBase.splice(i, 1);
        setKeyArray(oldKey)
        setImageUrl(oldDocuments);
        setUploadFiles(oldBase)
    }
    
    useEffect(() => {
    
        if (expenseObj) {
            dispatch(getExpenseType.call({
                expense_type: "user"
            }))
            dispatch(getPaymentMethod.call({}, () => {
                setValue('expense_id', expenseObj._id);
                setValue('expense_type', "user");
                setValue('transaction_type', expenseObj.transaction_type);
                setValue('expense_name', expenseObj.expense_name);
                setValue('amount_paid', expenseObj.amount_paid);
                setValue('expense_date', moment(expenseObj.expense_date).format("YYYY-MM-DD"));
                setValue('payment_mode', expenseObj.payment_mode);
                setValue('notes', expenseObj.notes);
                if(expenseObj.transaction_bank && expenseObj.transaction_bank._id){
                    setValue('trans_bank', expenseObj.transaction_bank._id);
                }
                else{
                    setValue('trans_bank', "")
                }
                let s = false;
                for (let i = 0; i < paymentMethods.length; i++) {
                    if (expenseObj.payment_mode == paymentMethods[i].payment_method) {
                        s = paymentMethods[i].transaction_id
                        break;
                    }
                }
                // console.log("Setting show id ");
                // console.log(s);
                setShowTid(s);
            }))
        }
        if (expenseObj?.image.length > 0) {
            setKeyArray(expenseObj.image)
            expenseObj.image.map((img) => {
                return (

                    dispatch(getFile.call({
                        key: img
                    }
                        , (image_data) => {
                            if (image_data) {
                                setImageUrl((prev) => [...prev, image_data.data]);
                            }

                        }
                    ))
                )
            })
        }

    }, [expenseObj])



    return (
        <form onSubmit={handleSubmit(handleUpdateExpense)}>
            <section id="updateExpenseModal" className="custom-modal">
                <div className="custom-modal-header">
                    <h1>Update</h1>
                    <span className="ct-close" onClick={() => {
                        setShowTid(false);
                        setImageUrl([])
                        setUploadFiles([])
                        setKeyArray([])
                        closeModal();
                    }}></span>
                </div>
                <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                    <div className="cust-row mb-2">
                        {/* <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Type<span className="red_color">*</span></label>
                            <input className='cust-input' type="text" {...register('transaction_type', { required : true })} disabled/>
                        </div> */}
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Expense Type<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" {...register('expense_name', { required: true })}
                                defaultValue={expenseObj ? expenseObj.expense_name : ''}
                            >

                                <option disabled value="">Select Expense</option>
                                {/* <option disabled value={expenseObj.expense_name}>{expenseObj.expense_name}</option> */}
                                {
                                    expenseTypes_extended.map((exp, i) => {
                                        return (
                                            <option key={i} value={exp.expense_name}>{exp.expense_name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.expense_name && <p className='error red_color'>Expense Type is required</p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Amount Paid<span className="red_color">*</span></label>
                            <input className="cust-input" type="number"  step=".01"  placeholder="Amount" {...register('amount_paid', { required: true })} />
                            {errors.amount_paid && <p className='error red_color'>Amount Paid is required</p>}
                        </div>

                    </div>
                    <div className="cust-row mb-2">

                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Payment Date<span className="red_color">*</span></label>
                            <input className="cust-input" type="date" max={moment().format("YYYY-MM-DD")} placeholder="Bank Name" {...register('expense_date', { required: true })} />
                            {errors.expense_date && <p className='error red_color'>Expense Date is required</p>}
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Payment Mode<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" {...register('payment_mode', { required: true })}
                            defaultValue={expenseObj ? expenseObj.payment_mode : ''}>
                                <option disabled value="">Select Mode</option>
                                {
                                    paymentMethods_extended.map((method, i) => {
                                        return (
                                            <option value={method.payment_method} key={i}>{method.payment_method}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.payment_mode && <p className='error red_color'>Payment Mode is required</p>}
                        </div>
                    </div>
                    <div className="cust-row mb-2">

                        {
                            showTid &&
                            (
                                <div className="cust-col-5">
                                    <label className="black_color font-bold text-left wdt100">Transaction ID<span className="red_color">*</span></label>
                                    <input className="cust-input" type="text" placeholder="Transaction Id" {...register('transaction_id', { required: true })} />
                                </div>
                            )

                        }

                        {expenseObj?.transaction_type=='debit'?<div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Select Transaction Bank<span>(optional)</span></label>
                            <select data-testid="bank_dropdown_update" className="cust-input select2-single" {...register('trans_bank', { required: false })}>
                                <option value="">Select Bank</option>
                                {
                                    allBanks.map((bank, i) => {
                                        return (
                                            <option key={i} value={bank._id}>{bank.bankName +" - "+bank.accountNumber}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.trans_bank && <p className='error red_color'>Transaction Bank is required</p>}
                        </div>:""}
                    </div>
                    <div className='cust-row mb-2'>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Remarks(Optional)</label>
                            <input className="cust-input" type="text" placeholder="Add Remarks" {...register('notes')} />
                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <label className="black_color font-bold text-left wdt100">Add Image(Optional)</label>
                            <div className='doc_container'>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={handleFilesUpload} modalId="update-expense-documents" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        imageUrl.length > 0 &&
                        <>
                            <br />
                            <h6>Selected Files</h6>
                            <hr />
                            <div className='row'>
                                {
                                    imageUrl.map((file, i) => {
                                        return (
                                            <div className='col-md-6 col-sm-4 col-lg-3' key={i}>
                                                <div className='alert alert-info alert-holiday alert-dismissible fade show p-3' style={{ width: "115px", height: "110px" }}>
                                                {file?.includes("image") ?
                                                    <>
                                                        <img style={{ width: "100%", height: "100%" }}
                                                            src={file}
                                                            alt='' />
                                                        <button type="button" className="btn-close" onClick={() => handleRemoveDocument(i)}></button>
                                                    </>
                                                    :
                                                    <>
                                                        <object
                                                            data={file}
                                                            type="application/pdf"
                                                            width="100%"
                                                            height='90%'
                                                        ></object>
                                                       <button type="button" className="btn-close" onClick={() => handleRemoveDocument(i)}></button>
                                                    </>
                                                }
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <hr />
                        </>
                    }
                    <div className="cust-row">
                        <div className="cust-col-4">

                        </div>
                        <div className="cust-col-2">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'> Submit </div>
                            </button>
                        </div>
                    </div>

                </div>
            </section>

        </form>

    )
}


