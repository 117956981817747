import JointActionDispatcher from "../../../services/jointActionDispatcher";
import types from './types'


export const getLoginInfo = {
    url: `/api/user/get_user_detail`,
    action: types.LOGIN_INFO,
    __proto__: JointActionDispatcher
}

export const getExpense = {
    url: `/api/expense/get_expense`,
    action: types.GET_EXPENSE,
    __proto__: JointActionDispatcher
}

export const getAllBanks = {
    url: `/api/settings/get_banks`,
    action: types.GET_ALL_BANK,
    __proto__: JointActionDispatcher
}

export const addExpense = {
    url: `/api/expense/add_expense`,
    action: types.ADD_EXPENSE,
    __proto__: JointActionDispatcher
}

export const deleteExpense = {
    url: `/api/expense/delete_expense`,
    action: types.DELETE_EXPENSE,
    __proto__: JointActionDispatcher
}

export const updateExpense = {
    url: `/api/expense/update_expense`,
    action: types.UPDATE_EXPENSE,
    __proto__: JointActionDispatcher
}

export const getExpenseType = {
    url: `/api/expense/get_expense_type`,
    action: types.GET_EXPENSE_TYPE,
    __proto__: JointActionDispatcher
}

export const addExpenseType = {
    url: `/api/expense/add_expense_type`,
    action: types.ADD_EXPENSE_TYPE,
    __proto__: JointActionDispatcher
}

export const getPaymentMethod = {
    url: `/api/expense/get_payment_method`,
    action: types.GET_PAYMENT_METHOD,
    __proto__: JointActionDispatcher
}

export const addPaymentMethod = {
    url: `/api/expense/add_payment_method`,
    action: types.ADD_PAYMENT_METHOD,
    __proto__: JointActionDispatcher
}

export const getSalary = {
    url: `/api/attendance/get_salary`,
    action: types.GET_SALARY,
    __proto__: JointActionDispatcher
}

export const getLeaveBalance = {
    url: `/api/user/get_leave_balance`,
    action: types.GET_LEAVE_BALANCE,
    __proto__: JointActionDispatcher
}

export const getFile = {
    url: `/api/user/get_file`,
    action: types.GET_FILE,
    __proto__: JointActionDispatcher
}

export const updateDocuments = {
    url: `/api/user/update_document`,
    action: types.UPDATE_DOCUMENT,
    __proto__: JointActionDispatcher
}

export const deleteDocuments = {
    url: `/api/user/delete_document`,
    action: types.DELETE_DOCUMENT,
    __proto__: JointActionDispatcher
}

export const updateBank = {
    url: `/api/user/update_bank_details`,
    action: types.UPDATE_BANK,
    __proto__: JointActionDispatcher
}

export const deleteBank = {
    url: `/api/user/delete_bank_details`,
    action: types.DELETE_BANK,
    __proto__: JointActionDispatcher
}
