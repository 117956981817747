import React from 'react'
import history from '../../services/history'
import ConfirmBookingModal from './../Dashboard/Modal/ConfirmBooking'
import $ from 'jquery'

const BookingDetail = () =>{

    const _goBack = () =>{
        history.push("/dashboard/booking")
    }

    const confirmBooking = () => {
        // window.$.fn.show_notification({ message: "confirmation is required for acceptance of booking", title: 'Are you sure!!', type: 'confirm', autohide: false},(result) => {
        //     if (result.type==="confirm") {
        //         this._closeModal()
        //     }
        // })
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const cancelBooking = () => {
        // window.$.fn.show_notification({ message: "confirmation is required to cancel booking request", title: 'Are you sure!!', type: 'confirm', autohide: false,confirmVal:"Yes, Proceed",showInput:true,inputPlaceholder:"Reason for cancellation?"},(result)=>{
        //     if(result.type==='confirm'){
                
        //     }
        // })
    }
    
    const _closeModal = () => { 
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    return(
        <div className="contentpanel">            
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Booking Detail
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                        </button>
                    </div>
                </div>
                <hr className='bgg'/>
                <div className="cust-row">
                    <div className='cust-col-10'>
                        <div className="vd_cont">
                            <div className="vd_cont_tbl brtd">
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Booking Number
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                        <span className='font-weight-bold'>(Ekayan)</span>
                                        <span className='grey_color font-md'>(Date:Ekayan)</span>
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        From
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Goods Type
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Goods Weight(Kg)
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                    Vehicle Type
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                    Requested On
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                    Confirmed On
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                    Contract Name
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                    Contract Rate
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                    Driver Mobile
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Lynkit)
                                    </div>
                                </div>
                            </div>
                            <div className="vd_cont_tbl no_btr">
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Status
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                        <span className='font-weight-bold'>(Ekayan)</span>
                                        <span className='grey_color font-md'>(Date:Ekayan)</span>
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        To
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Goods Description
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        No. of Goods
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Shipment Type
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (To be Decided)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">
                                        Requested By
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">                                        
                                        Need Vehicle by Date
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">                                        
                                    Desired Date of Delivery
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Ekayan)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">                                        
                                    Vehicle Number
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Lynkit)
                                    </div>
                                </div>
                                <div className="vd_cont_tbl_sec">
                                    <div className="vd_cont_tbl_sec_1">                                        
                                    DL Number
                                    </div>
                                    <div className="vd_cont_tbl_sec_2">
                                    (Lynkit)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-3'></div>
                    {
                    localStorage.getItem("userType")==='userType2'? 
                    <div className='cust-col-2'>
                        <button className='button bti bg-success-out m-0' onClick={()=>{_handleClick('confirmBookingModal')}}>
                            <div className='btn_icon'>
                                <i className='fa fa-check'></i>
                            </div>
                            <div className='btn_txt font-lg'>Allocate Vehicle</div>
                        </button>
                    </div>:""}
                    <div className='cust-col-2'>
                        <button className='button bti bg-danger-out m-0' onClick={()=>{cancelBooking()}}>
                            <div className='btn_icon'>
                                <i className='fa fa-close'></i>
                            </div>
                            <div className='btn_txt font-lg'>Cancel Booking</div>
                        </button>
                    </div>
                </div>
            </div>
            <ConfirmBookingModal closeModal={_closeModal} confirmBooking={confirmBooking}/>
        </div>
    )
}

export default BookingDetail