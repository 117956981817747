let namespace = "category";
// GET CATEGORY
const GET_CATEGORY_FETCH = `${namespace}/GET_CATEGORY_FETCH`;
const GET_CATEGORY_SUCC = `${namespace}/GET_CATEGORY_SUCC`;
const GET_CATEGORY_ERR = `${namespace}/GET_CATEGORY_ERR`;

// ADD CATEGORY
const ADD_CATEGORY_FETCH = `${namespace}/ADD_CATEGORY_FETCH`;
const ADD_CATEGORY_SUCC = `${namespace}/ADD_CATEGORY_SUCC`;
const ADD_CATEGORY_ERR = `${namespace}/ADD_CATEGORY_ERR`;

const GET_LEAVE_TYPE = `${namespace}/GET_LEAVE_TYPE`;
const GET_LEAVE_TYPE_FETCH = `${namespace}/GET_LEAVE_TYPE_FETCH`;
const GET_LEAVE_TYPE_SUCC = `${namespace}/GET_LEAVE_TYPE_SUCC`;
const GET_LEAVE_TYPE_ERR = `${namespace}/GET_LEAVE_TYPE_ERR`;


const ADD_LEAVE_TYPE = `${namespace}/ADD_LEAVE_TYPE`;
const ADD_LEAVE_TYPE_FETCH = `${namespace}/ADD_LEAVE_TYPE_FETCH`;
const ADD_LEAVE_TYPE_SUCC = `${namespace}/ADD_LEAVE_TYPE_SUCC`;
const ADD_LEAVE_TYPE_ERR = `${namespace}/ADD_LEAVE_TYPE_ERR`;



const UPDATE_LEAVE_TYPE = `${namespace}/UPDATE_LEAVE_TYPE`;

const DELETE_LEAVE_TYPE = `${namespace}/DELETE_LEAVE_TYPE`;

export default{
    // get category
    GET_CATEGORY_FETCH,
    GET_CATEGORY_SUCC,
    GET_CATEGORY_ERR,

    // add category
    ADD_CATEGORY_FETCH,
    ADD_CATEGORY_SUCC,
    ADD_CATEGORY_ERR,

    GET_LEAVE_TYPE,
    GET_LEAVE_TYPE_FETCH,
    GET_LEAVE_TYPE_SUCC,
    GET_LEAVE_TYPE_ERR,

    ADD_LEAVE_TYPE,
    
    UPDATE_LEAVE_TYPE,
    
    DELETE_LEAVE_TYPE
}
