import React, { useState } from "react";
import GeneralTable from "./GeneralTable";
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import Pagination from "./Pagination";

const GeneralTab = ({ userType, filterUsers, headers, data, total_users, page, setPage, pageLimit, setPageLimit }) => {

    const { register, handleSubmit, getValues, reset } = useForm();

    const handlePageChange = (newPage) => {
        filterUsers({
            ...getValues(),
            limit: pageLimit,
            page_no: newPage
        })
        setPage(newPage);
    };

    const handleSearch = (data) => {
        setPage(1);
        filterUsers({
            ...data,
            limit: pageLimit,
            page_no: 1
        })
    }

    return (
        <>
            <div className='cust-row'>
                <div className='cust-col-1 flex-algn-cent'>
                    <div className='tt2'>Filters <i className="fa fa-filter"></i></div>
                </div>
                <div className='cust-col-4'>
                    <form onSubmit={handleSubmit(handleSearch)}>
                        <div className="input-group">
                            <select className="form-select" {...register('searchBy')} >
                                {/* <option value="">Search By</option> */}
                                <option value="name">Name</option>
                                <option value="mobile">Mobile</option>
                                <option value="empId">Employee Id</option>

                            </select>
                            <input type="text" className="form-control" placeholder="Search" {...register('search')} />
                            <button className="btn btn-orange">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div className="cust-col-2">
                    <div className="input-group">
                        <label className="input-group-text">
                            Show Entries
                        </label>
                        <select className="form-select" onChange={(e) => setPageLimit(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </select>
                    </div>

                </div>

                <div className="cust-col-1"></div>
                <div className='cust-col-2 flex-jc-end'>
                    <Link to="/dashboard/workforce/user-add" className='btn btn-shadow btn-secondary'>Add {userType} <i className="fa fa-user"></i></Link>
                </div>
            </div>

            <div className='cust-row pt-3'>
                <div className="cust-col-10">
                    <GeneralTable headers={headers} rows={data} />
                </div>
            </div>
            <div className="cust-row">
                <div className="cust-col-5"></div>
                <div className="cust-col-5">
                    {/* 
                        <ul className="pagination justify-content-end">
                            {
                                Array(Math.ceil(total_users / pageLimit)).fill(0).map((_, i) => {
                                    return <li key={i} className={"page-item " + (i + 1 == page ? "active" : "")} onClick={() => handlePage(i + 1)}><a className="page-link">{i + 1}</a></li>
                                })
                            }
                        </ul>
                    </nav> */}
                  
                    <Pagination onPageChange={handlePageChange} totalCount={total_users} currentPage={page} pageSize={pageLimit} />


                </div>
            </div>
        </>
    )

}

export default GeneralTab;


