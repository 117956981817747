import moment from 'moment';
import React, {useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';


export default function MarkAll({closeModal, markAll}) {

    
    const { register, handleSubmit, getValues, reset,watch, formState: {errors} } = useForm();
    const selectedStatus = watch('status', "")

    const handleAddDocuments = (data) => {
        data.date = moment(data.date).format("MM-DD-YYYY");
        markAll(data);
    }

    return (
        <form onSubmit={handleSubmit(handleAddDocuments)}>
            <section id="markAllModal" className="custom-modal custom-modal-small">
                <div className="custom-modal-header">
                    <h1>Mark All Users</h1>
                    <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'75vh'}}>
                    <div className="cust-row mb-2">
                        <div className='cust-col-5'>
                            <label className="black_color font-bold text-left wdt100">Select Date<span className="red_color">*</span></label>
                            <input type="date" className='cust-input' {...register('date', {required: true})}/>
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Select Status<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" type="password" {...register('status', { required : true })}>
                                <option disabled value="">Select Account</option>
                                <option value="Present">Present</option>
                                <option value="Absent">Absent</option>
                                <option value="Holiday">Holiday</option>
                            </select>
                            <div className='error text-start mt-1'>
                                { errors.accountType && <p className='error red_color'>Status Type is required</p>}
                            </div>
                            


                        </div>
                    </div>
                    {
                        (selectedStatus == 'Absent' || selectedStatus == 'Holiday') && 
                        <div className="cust-row mb-2">
                            <div className='cust-col-2'></div>
                            <div className="cust-col-6">
                                <label className="black_color font-bold text-left wdt100">Reason<span className="red_color">*</span></label>
                                <input className="cust-input" type="text" placeholder="Enter Reason" {...register('reason', { required : true , minLength:3  })} />
                                <div className='error text-start mt-1'>
                                    { errors.accountNo && <p className='error red_color'>Reason cannot be empty</p>}
                                </div>
                                
                            </div>
                            
                        </div>
                    }
                    
                    
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Submit</div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </form>
        
    )
}
