import moment from 'moment';
import { useEffect,useState } from 'react';
import {useForm} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callGetUserTypesApi } from '../../../state/redux/workforce/actions';
import { userTypesSelector } from '../../../state/redux/workforce/selectors';
import { base64Convert } from '../../../services/functions';
import { getFile } from '../../../state/redux/workforceDetail/actions';
import { getDepartments } from '../../../state/redux/department/actions';
import { departmentListSelector } from '../../../state/redux/department/selectors';

const UpdateUser = ({closeModal, updateUser, updateUserObj}) => {
    const dispatch = useDispatch();
    const userTypes = useSelector(userTypesSelector)
    const {department} = useSelector(departmentListSelector);

    const [profileImgBase64, setProfileImageBase64] = useState(null);

    const { register, handleSubmit, reset, watch, formState:{errors}, setValue } = useForm();
    const selectedDob = watch('dob');
    // const watchPic = watch('profile_pic');
    // console.log({watchPic})


    const handleSubmitForm = (data) => {
        // console.log({data, profileImgBase64})
        updateUser(updateUserObj._id, data)
        reset();
        closeModal();
    }

    const handleProfileImageChange = async (e) => {
        // console.log("change")
        if(e.target.files.length > 0){
            const base64ProfileImage = await base64Convert(e.target.files[0]);
            setProfileImageBase64(base64ProfileImage);
            // console.log({base64ProfileImage})
            setValue('profile_pic', base64ProfileImage)
        }
        else{
            setProfileImageBase64(null)
            // console.log("null")
        }
    }

    

    const isBefore = (date) => {
        return moment(date).isBefore(moment());
    }

    const isAfter = (date) => {
        return moment(selectedDob).isBefore(date)
    }

    useEffect(()=>{
        dispatch(callGetUserTypesApi({}))
        dispatch(getDepartments.call({}))
    }, [])

    useEffect(()=>{
        // console.log({updateUserObj})
        if(updateUserObj){
            dispatch(getFile.call({
                key: updateUserObj.profile_pic
            }, (image_data) => {
                if(image_data){
                    setProfileImageBase64(image_data.data);
                }
                
            }))
            setValue('profile_pic', updateUserObj.profile_pic)
            setValue('name', updateUserObj.name)
            setValue('email', updateUserObj.email)
            setValue('mobile', updateUserObj.mobile)
            setValue('dob', moment(updateUserObj.dob).format('yyyy-MM-DD'))
            setValue('gender', updateUserObj.gender)
            setValue('doj', moment(updateUserObj.doj).format('yyyy-MM-DD'))
            setValue('address', updateUserObj.address)
            setValue('salary', updateUserObj.salary)
            setValue('department', updateUserObj.department)
            setValue('role_type', updateUserObj.role_type)
            setValue('user_type', updateUserObj.user_type)
            // console.log(updateUserObj.profile_pic);
            // setProfileImageBase64(updateUserObj.profile_pic)
        }
    }, [updateUserObj])
    return(
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <section id="updateUserModal" className="custom-modal" style={{width:"60%"}}>
                <div className="custom-modal-header">
                    <h1>Update User</h1>
                    <span className="ct-close" onClick={closeModal}></span>
                </div>
                {
                    updateUserObj &&
                    <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'75vh'}}>
                        <div className='cust-row mb-2'>
                            <div className='cust-col-3'></div>
                            <div className="cust-col-4 p-3 text-center">
                                {
                                    profileImgBase64
                                    ?
                                        <img src={profileImgBase64} className="img-thumbnail p-2" />
                                    :
                                        <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" className="img-thumbnail p-2" />
                                }
                                <input type="file" className="mt-2" onChange= {handleProfileImageChange} accept="image/png, image/jpeg"/>
                            </div>
                        </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Enter Name" {...register('name', { required : true , minLength:2})}/>
                            {errors.name && <p className='error red_color'>Minimum length of name is 2 </p>}
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Email Id</label>
                            <input className="cust-input" type="text" placeholder="Enter Email" defaultValue={updateUserObj.email} {...register('email' , {  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}/>
                            {errors.email && <p className='error red_color'>Email should be valid</p>}
                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Mobile<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder=" Enter Mobile " {...register('mobile', { required : true , minLength:10 , maxLength:10})}/>
                            {errors.mobile && <p className='error red_color'>Mobile Number should be of 10 digits</p>}
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Date of Birth<span className="red_color">*</span></label>
                            <input className="cust-input" type="date" placeholder="" {...register('dob', { required : true, validate:isBefore })}/>
                            {errors.dob && <p className='error red_color'>DOB should be valid</p>}
                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Gender</label>
                            <select className="cust-input select2-single" type="text" {...register('gender')}>
                                <option disabled value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Date of Joining<span className="red_color">*</span></label>
                            <input className="cust-input" type="date" placeholder="Enter DOJ" {...register('doj', { required : true, validate:isAfter })}/>
                            {errors.doj && <p className='error red_color'>DOJ should be greater than DOB</p>}
                        </div>
                    </div>
                    
                    <div className="cust-row mb-2">
                        
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Address</label>
                            <input className="cust-input" type="text" placeholder="Enter Address" {...register('address')} />
                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Salary</label>
                            <input className="cust-input" type="number" step='.01' placeholder="Enter Salary" {...register('salary')}/>
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Department</label>
                            <select className='cust-input select2-single' {...register('department')}>
                                <option disabled value="">Select Department</option>
                                {
                                    department.map((dt, i) => {
                                        return <option value={dt.department} key={i}>{dt.department}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Role Type<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Enter Role" {...register('role_type', { required : true })} />
                            {errors.role_type && <p className='error red_color'>Role Type is required </p>}
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">User Type</label>
                            <select className="cust-input select2-single" type="text" {...register('user_type')}>
                                <option disabled value="">Select Type</option>
                                {
                                    userTypes.map((ut, i) => {
                                        return <option value={ut.user_type} key={i}>{ut.user_type}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Confirm Update</div>
                            </button>
                        </div>
                    </div>
                </div>
                }
            </section>
        </form>
    )
}

export default UpdateUser