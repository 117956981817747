import React, { useState } from 'react';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import { useForm } from 'react-hook-form';  


export default function AddDocuments({ closeModal, addDocuments, setDocumenstArray, documentsArray  }) {


    const [uploadFiles, setUploadFiles] = useState([]) 
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();

    let path = window.location.pathname 

    const handleFilesUpload = async (files) => {
        const promises = Array.from(files).map(async file => {
            const base64File = await base64Convert(file); 
            return  base64File
        })
        const base64Files = await Promise.all(promises);
        setUploadFiles([...uploadFiles, ...base64Files]);
    }

    const handleRemoveDocument = (i) => {
        let oldDocuments = [...uploadFiles];
        oldDocuments.splice(i, 1);
        setUploadFiles(oldDocuments);
    }

    const handleAddDocuments = (data) => {
        let body = data;
        body['documents'] = uploadFiles

        let final = {...body, documents:uploadFiles}

        if (body.documents.length > 0) {
            addDocuments(body);
            reset();
            setUploadFiles([]);
            closeModal();
        }
        else {
            console.log("Form Errors");
        }

        if(path === '/dashboard/workforce/user-add'){
            let temp = [...documentsArray]
            temp.push( uploadFiles)
            setDocumenstArray(temp)

        }
      



    }
    const PreviewFiles = ({ files, isEditable = true, onRemove = (index) => { } }) => {
        return (
            <div className="img_preview_container custom-scrollbar">
                {files?.map((file, index) => {
                    return (
                        <div className="img_preview_wrap" key={index} >
                            <div className="img_preview_item">
                                {isEditable ? (
                                    <span className="clsbt" onClick={() => onRemove(index)}>
                                        <i className="fa fa-times"></i>
                                    </span>
                                ) : null}
                                <PreviewFile data={file} />
                            </div>

                            {/* <div className="img_preview_text mt-1">
                                <span>
                                    {file.filename}
                                </span>
                            </div> */}

                        </div>
                    );
                })}
            </div>
        );
    };

    const PreviewFile = ({ data, height = "" }) => {
        return (
            <>
                {data?.includes("image") ? (
                    <img src={data} alt="image" width="100%" height="100%" />
                ) : (
                    <object
                        data={data}
                        type="application/pdf"
                        width="100%"
                        height='100%'
                    ></object>
                )}
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit(handleAddDocuments)}>
            <section id="addDocumentsModal" className="custom-modal">
                <div className="custom-modal-header">
                    <h1>Add Documents</h1>
                    <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                    <div className="cust-row">
                        <div className='cust-col-10'>
                            <div className='table-responsive'>
                                <table className='bordered_table'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Document Name<span className="red_color">*</span></h2>
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" {...register('document_name', { required: true })} />
                                                {errors.document_name && <p className='error red_color'>Document Name is required</p>}


                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Document Number <span className="red_color">*</span></h2>
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" {...register('document_no', { required: true })} />
                                                {errors.document_no && <p className='error red_color'>Document Number is required</p>}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Document Expiry <span className="red_color">*</span></h2>
                                            </th>
                                            <th>
                                                <input type="date" className="form-control" {...register('document_expiry', { required: true })} />
                                                {errors.document_expiry && <p className='error red_color'>Document expiry is required</p>}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Notify Before(in days) <span className='red_color'>*</span></h2>
                                            </th>
                                            <th>
                                                <input type="number" className="form-control" {...register('notify_before', { required: true, pattern: /^[+]?\d+(\.\d+)?$/ })} />
                                                {errors.notify_before && <p className='error red_color'>Notify Before is required</p>}


                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Notify Email</h2>
                                            </th>
                                            <th>
                                                <input type="email" className="form-control" {...register('notify_email', { pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                                {errors.email && <p className='error red_color'>Email should be valid </p>}



                                            </th>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='doc_container'>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={handleFilesUpload} modalId="adds-documents" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        uploadFiles.length > 0 &&
                        <>
                            <br />
                            <h6>Selected Files</h6>
                            <hr />
                            <div className='row rowHeight' style={{ width: '98%' }}>
                                {
                                    <div className='col-md-4 col-sm-6 pClass'>
                                        <PreviewFiles files={uploadFiles} isEditable={true} onRemove={handleRemoveDocument} />
                                    </div>
                                }
                            </div>
                            <hr />
                        </>
                    }
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Add Document</div>
                            </button>
                        </div>
                    </div>

                </div>
            </section>

        </form>

    )
}
