import history from '../../services/history'
import TransLogo from './../../static/images/credlynk_logo.png'
import $ from 'jquery'

const CreateReceipt = () =>{

    const _goBack = () =>{
        history.push("/dashboard/goods-receipt")
    }

    const toogleHubView = (id,check=true) => {
        if($("#"+id).hasClass('open') && check===true){
          $("#"+id).animate({height:30},300,()=>{
            $("#"+id).removeClass('open')
          })
        }
        else{
          let height=Number($("#"+id).children('.customBdy').height())
          $("#"+id).addClass('open')
          $("#"+id).animate({height},500,()=>{
            $("#"+id).css('height','auto')
          })
        }
    }

    return(
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Create Receipt
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <p className='txtr'><span>*</span> Indicates the required field</p>
                    </div>
                </div>
                <hr className='bgg'/>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='form-container'>
                            <div class="custom-collapse autoheight">
                                <div class="customBdy pdm5">
                                    <div className='tbl_div'>
                                        <div className='logo_sec_1'>
                                            <img src={TransLogo} alt=""/>
                                        </div>
                                        <div className='content_sec_1'>
                                            <p className='font-bold font-xl'>XYZ Logitics Pvt. Ltd.</p>
                                            <p className='font-lg'>D-34, ABD Gali No. 4, New South Extension, phase - 4, New Delhi, Delhi - 220011</p>
                                            <p><span className='font-bold'>PAN - </span>QWASW4325G <span className='font-bold'>GSTIN - </span>09QWASW4325G1Z3</p>
                                            <p>Goods Receipt</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Basic Details
                                    {/* <div class="collapse_icon"></div> */}
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Consignment Note No.</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Consignment Note No.'/>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Contract Type</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Full Truck Load</option>
                                                <option>Part Truck Load</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Payment Term</span>
                                        </div>
                                        <div className='wdt15'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Full Truck Load</option>
                                                <option>Part Truck Load</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Consignment Note Date</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Consignment Note Date'/>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Delivery Type</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Door Delivery</option>
                                                <option>Godown</option>
                                                <option>Station Delivery</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Billed To</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Full Truck Load</option>
                                                <option>Part Truck Load</option>
                                            </select>
                                        </div>
                                        <div className='wdt5'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Movement Type</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Import</option>
                                                <option>Export</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Bill of Entry Date'/>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Carrier Risk</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Transportation</span>
                                        </div>
                                        <div className='wdt15'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Road</option>
                                                <option>Rail</option>
                                                <option>Air</option>
                                            </select>
                                        </div>
                                    </div>                                    
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt20 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Bill of Entry Number'/>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Bill of Lading Number'/>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Managing Hub</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Est. Delivery Date</span>
                                        </div>
                                        <div className='wdt15'>
                                            <input className='wdt100 brd_none nfc' placeholder='Est. Delivery Date'/>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Remark</span>
                                        </div>
                                        <div className='wdt90'>
                                            <input className='wdt100 brd_none nfc' placeholder='Remark'/>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Consignor</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Consignor</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <div className="cust_dd">
                                                <div className="check_cust"></div>
                                                <label>Quick Add</label>
                                            </div>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Pickup<span className='required'>*</span></span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Select Pickup</option>
                                                <option>Consignee</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>State</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>City</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Address'/>
                                        </div>
                                        <div className='wdt10'>
                                            <input className='wdt100 brd_none nfc' placeholder='Pincode'/>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Consignee</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Consignee</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <div className="cust_dd">
                                                <div className="check_cust"></div>
                                                <label>Quick Add</label>
                                            </div>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Dropoff<span className='required'>*</span></span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Select Dropoff</option>
                                                <option>Consignor</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>State</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>City</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Address'/>
                                        </div>
                                        <div className='wdt10'>
                                            <input className='wdt100 brd_none nfc' placeholder='Pincode'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Goods Details
                                    {/* <div class="collapse_icon"></div> */}
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex'>
                                        <div className='wdt55 brd_btm'>
                                            <div className='dv_flex brd_rht brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Eway Bill Number</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Eway Bill Number'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Valid From Date'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Valid Upto Date'/>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-upload font-lg orange_color crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex brd_rht brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Eway Bill Number</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Eway Bill Number'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Valid From Date'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Valid Upto Date'/>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-upload font-lg orange_color crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex brd_rht pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Eway Bill Number</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Eway Bill Number'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Valid From Date'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Valid Upto Date'/>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-upload font-lg orange_color crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt45 brd_btm'>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Goods Description</span>
                                                </div>
                                                <div className='wdt25 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Detail'/>
                                                </div>
                                                <div className='wdt25 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='HSN Code'/>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Goods Description</span>
                                                </div>
                                                <div className='wdt25 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Detail'/>
                                                </div>
                                                <div className='wdt25 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='HSN Code'/>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt50 brd_rht'>
                                            <span className='font-bold black_color'>Require OTP to Confirm Delivery for my Organization?</span>
                                        </div>
                                        <div className='wdt50'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='dv_flex'>
                                        <div className='wdt30 brd_btm brd_rht'>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Actual Weight</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Actual Weight'/>
                                                </div>
                                                <div className='wdt15 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>KG</option>
                                                    </select>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt15'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Actual Weight</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Actual Weight'/>
                                                </div>
                                                <div className='wdt15 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>KG</option>
                                                    </select>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt15'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt30 brd_btm brd_rht'>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Weight Charge</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Weight Charged'/>
                                                </div>
                                                <div className='wdt15 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>KG</option>
                                                    </select>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt15'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Weight Charge</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Weight Charged'/>
                                                </div>
                                                <div className='wdt15 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>KG</option>
                                                    </select>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt15'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Weight Charge</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Weight Charged'/>
                                                </div>
                                                <div className='wdt15 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>KG</option>
                                                    </select>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt15'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt40 brd_btm'>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt25 brd_rht'>
                                                    <span className='font-bold black_color'>Package Detail</span>
                                                </div>
                                                <div className='wdt25 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Package Type'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='No. of Pkgs'/>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-plus orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-upload orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt25 brd_rht'>
                                                    <span className='font-bold black_color'>Package Detail</span>
                                                </div>
                                                <div className='wdt25 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='Package Type'/>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <input className='wdt100 brd_none nfc' placeholder='No. of Pkgs'/>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-plus orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt5'>
                                                    <i className='fa fa-upload orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Invoice Detail</span>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>Number</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Invoice Number'/>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>Date</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Invoice Date'/>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>Value</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Invoice Amount'/>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-upload orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='text-center wdt10'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Invoice Detail</span>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>Number</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Invoice Number'/>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>Date</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Invoice Date'/>
                                        </div>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>Value</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Invoice Amount'/>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-upload orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='text-center wdt10'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Payment Details
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Weight Charges</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Per KG</option>
                                                <option>Lumpsum</option>
                                                <option>Not Required</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Rate'/>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Insurance Charges</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>% of Invoice Value</option>
                                                <option>Fixed Charge</option>
                                                <option>Not Required</option>
                                            </select>
                                        </div>
                                        <div className='wdt10'>
                                            <input className='wdt100 brd_none nfc' placeholder='Rate'/>
                                        </div>
                                    </div>
                                    <div className='dv_flex'>
                                        <div className='wdt50 brd_rht'>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt100 text-center' style={{minHeight:"29.14px"}}>
                                                    <span className='font-bold black_color'>Freight Charges</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt40 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>Select Type</option>
                                                        <option>Freight</option>
                                                        <option>Loading</option>
                                                        <option>Weight Charges</option>
                                                        <option>Insurance Charges</option>
                                                        <option>Shipping Charge</option>
                                                        <option>Delivery Charges</option>
                                                        <option>Risk Charges</option>
                                                    </select>
                                                </div>
                                                <div className='wdt40 brd_rht'>
                                                    <input className='wdt100 brd_none nfc'/>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5'>
                                                <div className='wdt40 brd_rht'>
                                                    <select className='wdt100 brd_none nfc'>
                                                        <option>Select Type</option>
                                                        <option>Freight</option>
                                                        <option>Loading</option>
                                                        <option>Weight Charges</option>
                                                        <option>Insurance Charges</option>
                                                        <option>Shipping Charge</option>
                                                        <option>Delivery Charges</option>
                                                        <option>Risk Charges</option>
                                                    </select>
                                                </div>
                                                <div className='wdt40 brd_rht'>
                                                    <input className='wdt100 brd_none nfc'/>
                                                </div>
                                                <div className='brd_rht text-center wdt10'>
                                                    <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                                </div>
                                                <div className='text-center wdt10'>
                                                    <span className='add_btn'>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt50'>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Advance Freight</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <input className='wdt100 brd_none nfc'/>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Freight Due After Delivery</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <input className='wdt100 brd_none nfc'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse" id="transSection">
                                <div class="customHead">
                                    Transportation Details
                                    <div class="collapse_icon" onClick={()=>{toogleHubView('transSection')}}></div>
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Vehicle Type</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option>Select Vehicle Type</option>
                                                <option>Open Body Container</option>
                                            </select>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Vehicle Number</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Vehicle Number'/>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Driver Name</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Driver Name'/>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Driver Mobile</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Driver Mobile'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse" id="additionalDoc">
                                <div class="customHead">
                                    Additional Documents
                                    <div class="collapse_icon" onClick={()=>{toogleHubView('additionalDoc')}}></div>
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>1.</span>
                                        </div>
                                        <div className='wdt40 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Document Type'/>
                                        </div>
                                        <div className='wdt40 brd_rht'>
                                            <span className='font-bold grey_color'>No Document Selected</span>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-upload orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='text-center wdt5'>
                                            <i className='fa fa-plus orange_color font-lg crsr-ptr'></i>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5'>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>2.</span>
                                        </div>
                                        <div className='wdt40 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Document Type'/>
                                        </div>
                                        <div className='wdt40 brd_rht'>
                                            <span className='font-bold grey_color'>No Document Selected</span>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-upload orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='text-center wdt5'>
                                            <i className='fa fa-plus orange_color font-lg crsr-ptr'></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse" id="policySection">
                                <div class="customHead">
                                    Policy Details
                                    <div class="collapse_icon" onClick={()=>{toogleHubView('policySection')}}></div>
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Policy Number</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc'/>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Name Of Issuer</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc'/>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Issue Date</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc'/>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Expiry Date</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <input className='wdt100 brd_none nfc'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse" id="additionalContact">
                                <div class="customHead">
                                    Add additional contacts to send Consignment notifications to?
                                    <div class="collapse_icon" onClick={()=>{toogleHubView('additionalContact')}}></div>
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>1.</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option value="NA">Notification for?</option>
                                                <option value="transporter">Transporter</option>
                                                <option value="consignee">Consignee</option>
                                                <option value="consignor">Consignor</option>
                                            </select>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Email ID'/>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Mobile Number'/>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='text-center wdt10'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5'>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>2.</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <select className='wdt100 brd_none nfc'>
                                                <option value="NA">Notification for?</option>
                                                <option value="transporter">Transporter</option>
                                                <option value="consignee">Consignee</option>
                                                <option value="consignor">Consignor</option>
                                            </select>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Email ID'/>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <input className='wdt100 brd_none nfc' placeholder='Mobile Number'/>
                                        </div>
                                        <div className='brd_rht text-center wdt5'>
                                            <i className='fa fa-close orange_color font-lg crsr-ptr'></i>
                                        </div>
                                        <div className='text-center wdt10'>
                                            <span className='add_btn'>Add</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-2'></div>
                    <div className='cust-col-3'>
                        <button className='button bti bg-orange-out m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-plus font-xl'></i>
                            </div>
                            <div className='btn_txt font-lg'>Create Goods Receipt</div>
                        </button>
                    </div>
                    <div className='cust-col-3'>
                        <button className='button bti bg-grey-out m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-save font-xl'></i>
                            </div>
                            <div className='btn_txt font-lg'>Save Goods Receipt for Later</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateReceipt