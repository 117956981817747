import React, {useEffect, useState} from "react";
import './../../../static/css/workforce.css';
import { selectInit,formatLineChartData,make_pie_chart } from './../../../services/functions'
import { useDispatch, useSelector } from "react-redux";
import { defaultHolidaysSelector, holidaysSelector, statsSelector } from "../../../state/redux/attendance/selectors";
import { getAttendanceStats, getDefaultHolidays, getHolidays } from "../../../state/redux/attendance/action";
import moment from "moment";
import DonutChart from "react-donut-chart";

const HomePage = ()=>{
    const dispatch = useDispatch();
    const default_holidays = useSelector(defaultHolidaysSelector);
    const holidays = useSelector(holidaysSelector);
    const stats = useSelector(statsSelector);
    const [statsData, setStatsData] = useState([]);
    console.log(stats);
    const [month, setMonth] = useState(new Date().getMonth());

    useEffect(()=>{
        dispatch(getDefaultHolidays.call({}))
    },[])

    useEffect(() => {

        const newStatsData = [
            {
                value: stats.filter(obj => obj._id == 'Present')[0] ? stats.filter(obj => obj._id == 'Present')[0].count: 0,
                label:"Present"
            },
            {
                value: stats.filter(obj => obj._id == 'On Leave')[0] ? stats.filter(obj => obj._id == 'On Leave')[0].count: 0,
                label:"On Leave"
            },
            {
                value: stats.filter(obj => obj._id == 'Absent')[0] ? stats.filter(obj => obj._id == 'Absent')[0].count: 0,
                label:"Absent"
            },
            {
                value: stats.filter(obj => obj._id == 'Holiday')[0] ? stats.filter(obj => obj._id == 'Holiday')[0].count: 0,
                label:"Holiday"
            }  
            
        ]
        setStatsData(newStatsData)

    }, [stats])

    const data = [
        {
          label: "Active",
          value: 23
        },
        {
          label: "Recovered",
          value: 12
        },
        {
          label: "Deaths",
          value: 32
        }
      ];

    useEffect(()=>{
        const monthStart = moment().month(month).startOf('month').format("MM-DD-YYYY");
        const monthEnd = moment().month(month).endOf('month').format("MM-DD-YYYY");
        dispatch(getAttendanceStats.call({
            firstDay: monthStart,
            lastDay: monthEnd
        }))

    }, [month])

    useEffect(()=>{
        dispatch(getHolidays.call({holidayType: 'day'}));
    }, [])

    return(
        <>
        <div className='contentpanel'>
            <div className ='cust-row'>
                <div className='cust-col-6'>
                    <div className="card">
                        <div className="card-header">
                            <div className="cust-row">
                                <div className="cust-col-4 pt-2">
                                    <h6>Users Data</h6>
                                </div>
                                <div className="cust-col-6">
                                    <div className="input-group">
                                        {/* <select className="form-select">
                                            <option>User Name</option>
                                        </select>
                                        <select className="form-select">
                                            <option>User Type</option>
                                        </select> */}
                                        <select className="form-select" value={month} onChange={(e) => setMonth(e.target.value)}>
                                            <option disabled value="">Select Month</option>
                                            <option value="0">January</option>
                                            <option value="1">February</option>
                                            <option value="2">March</option>
                                            <option value="3">April</option>
                                            <option value="4">May</option>
                                            <option value="5">June</option>
                                            <option value="6">July</option>
                                            <option value="7">August</option>
                                            <option value="8">September</option>
                                            <option value="9">October</option>
                                            <option value="10">November</option>
                                            <option value="11">December</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="card-body">

                            <div className="cust-row">
                                <div className="cust-col-1"></div>
                                <div className="cust-col-8">
                                    <DonutChart colors={["green", "yellow", "red", "gray"]} data={statsData}/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className='cust-col-6'>
                    <div className="card">
                        <div className="card-header text-center p-3">
                            <h6>Upcoming Holidays</h6>
                        </div>
                        <ul className="list-group list-group-flush">
                            {
                                holidays.filter(h => h.hasOwnProperty("date")).map((holiday, i) => {
                                    return (
                                        <li className="list-group-item p-2" key={i}>
                                            <div className="cust-row">
                                                <div className="cust-col-1">
                                                    <i className="fa fa-calendar text-orange fs-6"></i>
                                                </div>
                                                <div className="cust-col-4">
                                                    <span className="fs-6">{moment(holiday.date).format("DD-MM-YYYY")}</span>
                                                </div>
                                                <div className="cust-col-5">
                                                    <span className="fs-6">{holiday.holidayName}</span>
                                                </div>
                                            </div>   
                                        </li>   
                                    )
                                })
                            }
                        </ul>
                    </div>

                </div>

            </div>

        </div>
        </>
    )

}

export default HomePage;