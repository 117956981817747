import { React, useEffect, useRef, useState } from "react";
import history from '../../services/history';
import moment from 'moment'
import './../../static/css/workforce.css';
import { useForm } from 'react-hook-form';
import $ from 'jquery';
import { Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { addUserAction, callAddUserApi, callAddUserTypesApi, callGetUserTypesApi, getUsersAction } from "../../state/redux/workforce/actions";
import { base64Convert } from "../../services/functions";
import AddDocuments from "./Modal/AddDocuments";
import AddBankDetail from "./Modal/AddBankDetail";
import ViewDocument from './Modal/ViewDocument'
import ViewBankDocument from './Modal/ViewBankDocument'

import AddUserType from "./Modal/AddUserType";
import { userTypesSelector } from "../../state/redux/workforce/selectors";
import { departmentListSelector } from "../../state/redux/department/selectors";
import UpdateLocalDocuments from "./Modal/UpdateLocalDocuments";
import UpdateLocalBankDetail from "./Modal/UpdateLocalBank";
import { getDepartments } from "../../state/redux/department/actions";

const User = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm();
    const selectedDob = watch('dob');
    const [profileImg, setProfileImage] = useState("");
    const [profileImgBase64, setProfileImageBase64] = useState(null);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [addedBanks, setAddedBanks] = useState([]);
    const [imageArrays, setImageArrays] = useState([])
    const [updateDocumentInd, setUpdateDocumentInd] = useState(null);
    const [updateDocumentObj, setUpdateDocumentObj] = useState(null);
    const [updateBankInd, setUpdateBankInd] = useState(null);
    const [updateBankObj, setUpdateBankObj] = useState(null);
    const [documentsArray, setDocumenstArray] = useState([])
    const [action, setAction] = useState('view')

    const [bankAction, setBankAction] = useState('view')
    const [bankdocumentsArray, setBankDocumenstArray] = useState([])
    const [ind, setInd] = useState(null)
    const [bankIndex, setBankInd] = useState(null)

    const userTypes = useSelector(userTypesSelector);
    const { department } = useSelector(departmentListSelector)

    const addDocuments = (documentObj) => {
        setUploadedDocuments([...uploadedDocuments, documentObj]);
    }

    const addBank = (bankObj) => {
        setAddedBanks([...addedBanks, bankObj]);
    }

    const addUserType = (userTypeObj) => {
        dispatch(callAddUserTypesApi(userTypeObj))
    }

    const removeBank = (i) => {
        let newBanks = [...addedBanks]
        newBanks.splice(i, 1)
        setAddedBanks(newBanks);
    }

    const handleUpdateBank = (i) => {
        setUpdateBankObj(addedBanks[i])
        setUpdateBankInd(i);
        _handleClick('updateLocalBankDetailModal');
    }

    const updateBank = (data, i) => {
        let newBanks = [...addedBanks];
        newBanks[i] = data;

        let temp = [...bankdocumentsArray]
        temp[i] = newBanks[i].documents
        setBankDocumenstArray(temp)
        setAddedBanks(newBanks);
        setUpdateBankInd(null);
        setUpdateBankObj(null);
    }

    const updateDocuments = (data, i) => {
        let newDocs = [...uploadedDocuments]
        let temp = [...documentsArray]
        newDocs[i] = data;
        temp[i] = newDocs[i].documents
        setDocumenstArray(temp)
        setUploadedDocuments(newDocs);
        setUpdateDocumentInd(null)
        setUpdateDocumentObj(null);
    }

    const removeDocument = (i) => {
        let newDocs = [...uploadedDocuments]
        newDocs.splice(i, 1)
        setUploadedDocuments(newDocs);
    }

    const handleUpdateDocument = (i) => {
        setUpdateDocumentObj(uploadedDocuments[i]);
        setUpdateDocumentInd(i);
        _handleClick('updateLocalDocumentsModal')
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    const navigateAndShow = () => {
        history.push('/dashboard/user');
    }

    const handleProfileImageChange = async (e) => {
        if (e.target.files.length > 0) {
            const base64ProfileImage = await base64Convert(e.target.files[0]);
            setProfileImageBase64(base64ProfileImage);
        }
        else {
            setProfileImageBase64(null)
        }
    }

    const documentFunction = (key, i) => {
        if (key === 'document') {
            let files = documentsArray
            setImageArrays(files)
            setAction('view')
            setInd(i)
        }
        else if(key === 'bankdocument'){
            let files = bankdocumentsArray
            setImageArrays(files)
            setBankAction('view')
            setBankInd(i)
        }
    }


    const handleFormSubmit = () => {
        let body = getValues();

        body['user_role'] = 'user'
        body['driver_ref_id'] = ""
        body['profile_pic'] = profileImgBase64

        if (uploadedDocuments.length > 0) {
            body['userDocs'] = uploadedDocuments.map(doc => {
                return {
                    ...doc,
                    documents: doc.documents.map(file => file)
                }
            })
        }
        if (addedBanks.length > 0) {
            body['bankDetails'] = addedBanks.map(doc => {
                return {
                    ...doc,
                    documents: doc.documents.map(file => file)
                }
            })
        }
        body.toast = true;
        dispatch(addUserAction.call(body, () => {
            history.push("/dashboard/workforce")
            dispatch(getUsersAction.call({
                limit: 5,
                page_no: 1,
            }))
        }))
        // dispatch(callAddUserApi(body))


    }

    const isBefore = (date) => {
        return moment(date).isBefore(moment());
    }

    const isAfter = (date) => {
        return moment(selectedDob).isBefore(date)
    }

    useEffect(() => {
        dispatch(callGetUserTypesApi({}));
        dispatch(getDepartments.call({}));
    }, [])

    useEffect(()=>{
        console.log(('documentsArraydocumentsArraydocumentsArray-----',documentsArray));
    },[documentsArray])


    return (
        <>
            <div className='contentpanel'>
                <h1 className="title">Add User</h1>
                <hr />
                <div className="cust-container">
                    <div className="cust-row">
                        <div className="cust-col-9">
                            <div className="cust-row">
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Name</strong><span className="red_color">*</span></label>
                                    <input type="text" className='form-control' placeholder='Enter Name'  {...register('name', { required: true, minLength: 2 })} />
                                    <div className="mt-1">
                                        {errors.name && <p className='error red_color'>Mininum length for Name is 2</p>}
                                    </div>


                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Date of Birth</strong><span className="red_color">*</span></label>
                                    <input type="date" placeholder='enter date of birth' className='form-control' {...register('dob', { required: true, validate: isBefore })} />
                                    <div className="mt-1">
                                        {errors.dob && <p className='error red_color'>DOB should be valid</p>}
                                    </div>
                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Date of Joining</strong><span className="red_color">*</span></label>
                                    <input type="date" placeholder='enter date of joining' className='form-control' {...register('doj', { required: true, validate: isAfter })} />
                                    <div className="mt-1">
                                        {errors.doj && <p className='error red_color'>DOJ should be greater than DOB</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="cust-row mt-2">
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Email Id</strong></label>
                                    <input type="email" className='form-control' placeholder='Enter Email'  {...register('email', { pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                    <div className="mt-1">
                                        {errors.email && <p className='error red_color'>Email should be valid</p>}
                                    </div>
                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Address</strong></label>
                                    <input type="text" className='form-control' placeholder='Enter Address'  {...register('address')} />

                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Salary</strong></label>
                                    <input type="number" className='form-control' placeholder='Enter Salary'  {...register('salary', { pattern: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/ })} />
                                    {errors.salary && <p className='error red_color'>Allow only 2 numbers after decimal.</p>}
                                </div>
                            </div>
                            <div className="cust-row mt-2">
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Department</strong></label>
                                    {/* <input type="text" className='form-control' placeholder='Enter Department'  {...register('department', { required: true })} /> */}
                                    <select className='form-select' {...register('department')}>
                                        <option value="" hidden>Select Department</option>
                                        {
                                            department.map((dt, i) => {
                                                return <option value={dt.department} key={i + 1}>{dt.department}</option>
                                            })
                                        }
                                    </select>

                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Role Type</strong><span className="red_color">*</span></label>
                                    <input type="text" className='form-control' placeholder='Enter Role' name="roleType"  {...register('role_type', { required: true })} />
                                    <div className="mt-1">
                                        {errors.role_type && <p className='error red_color'>Role is required</p>}
                                    </div>
                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Gender</strong></label>
                                    <select className='form-select select2-single' {...register('gender')} >
                                        <option value="NA">Select Gender</option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                    </select>

                                </div>
                            </div>

                            <div className="cust-row mt-2">
                                <AddUserType closeModal={_closeModal} addUserType={addUserType} />
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>User Type</strong></label>
                                    <div className="input-group">
                                        <select className='form-select select2-single'{...register('user_type')} >
                                            <option value="" hidden>Select Type </option>
                                            {
                                                userTypes.map((userType, i) => <option key={i} value={userType.user_type}>{userType.user_type}</option>)
                                            }
                                        </select>
                                        <button className="btn btn-sm btn-orange" onClick={() => _handleClick('addUserTypeModal')}>
                                            <i className="fa fa-plus"></i> Add
                                        </button>
                                    </div>



                                </div>
                                <div className="cust-col-3 form-group">
                                    <label className="form-label"><strong>Mobile Number</strong><span className="red_color">*</span></label>
                                    <input type="text" placeholder="Enter Mobile Number" className='form-control' {...register('mobile', { required: true, maxLength: 10, minLength: 10, pattern: /^[+]?\d+$/ })} />
                                    <div className="mt-1">
                                        {errors.mobile && <p className='error red_color'>Mobile should be 10 digits</p>}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="cust-col-3 p-3 text-center">
                            {
                                profileImgBase64
                                    ?
                                    <img src={profileImgBase64} className="img-thumbnail p-2" />
                                    :
                                    <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" className="img-thumbnail p-2" />
                            }
                            <input type="file" className="mt-2" {...register('profile_pic', { onChange: (e) => { handleProfileImageChange(e) } })} accept="image/png, image/jpeg" />
                        </div>
                    </div>
                    <br />


                    <div className="cust-row">
                        <div className="cust-col-10 accordion-orange">
                            <Accordion defaultActiveKey="0">

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Add Documents</Accordion.Header>
                                    <Accordion.Body>
                                        <AddDocuments setDocumenstArray={setDocumenstArray} documentsArray={documentsArray} closeModal={_closeModal} addDocuments={addDocuments} />
                                        <ViewDocument ind={ind} documentsArray={documentsArray} setAction={setAction} action={action} imageArrays={imageArrays} closeModal={_closeModal} />
                                        <UpdateLocalDocuments documentsArray={documentsArray} setDocumenstArray={setDocumenstArray} closeModal={_closeModal} updateDocuments={updateDocuments} updateObj={updateDocumentObj} updateInd={updateDocumentInd} />
                                        <div className='text-end'>
                                            <button className='btn btn-sm btn-orange' onClick={() => _handleClick('addDocumentsModal')}>Add Document <i className="fa fa-plus"></i></button>
                                        </div>

                                        <div className='cust-row '>
                                            {
                                                uploadedDocuments.map((document, i) => {
                                                    return (
                                                        <div className='cust-col-3 p-1 bank-card ms-1' key={i}>
                                                            <table className='table'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Documents Name</strong></td>
                                                                        <td>{document?.document_name}</td>
                                                                    </tr>
                                                                    <tr style={{ position: 'relative' }}>
                                                                        <td><strong>Number of Filesss</strong></td>
                                                                        <td>{document?.documents?.length}</td>
                                                                        {document?.documents?.length !== 0 ?
                                                                            <i onClick={() => { _handleClick('ViewDocument'); documentFunction('document', i) }} style={{ position: 'absolute', right: '10px', top: '6px', cursor: 'pointer' }} className="fa fa-info-circle" aria-hidden="true"></i>
                                                                            : ''}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/* <button className='btn btn-sm btn-danger' onClick={()=> removeDocument(i)}>Delete</button> */}
                                                            <button className='btn btn-sm btn-success float-end' onClick={() => handleUpdateDocument(i)}>Update</button>

                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Add Bank</Accordion.Header>
                                    <Accordion.Body>
                                        <AddBankDetail setBankDocumenstArray={setBankDocumenstArray} bankdocumentsArray={bankdocumentsArray} closeModal={_closeModal} addBank={addBank} />
                                        <ViewBankDocument bankIndex={bankIndex} bankdocumentsArray={bankdocumentsArray} setBankAction={setBankAction} bankAction={bankAction} imageArrays={imageArrays} closeModal={_closeModal} />
                                        <UpdateLocalBankDetail bankdocumentsArray={bankdocumentsArray} closeModal={_closeModal} updateBank={updateBank} bankObj={updateBankObj} bankInd={updateBankInd} />
                                        <div className='text-end'>
                                            <button className='btn btn-sm btn-orange' onClick={() => _handleClick('addBankDetailModal')}>Add Bank <i className="fa fa-plus"></i></button>
                                        </div>
                                        <div className='cust-row '>
                                            {
                                                addedBanks.map((bank, i) => {
                                                    return (
                                                        <div className='cust-col-3 p-2 bank-card ms-1' key={i}>
                                                            <table className='table'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <p><strong>Benificiary Name : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank.beneficiaryName}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p><strong>Bank Name : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank.bankName}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p><strong>Account Number : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank.accountNo}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p><strong>Account Type : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank.accountType}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p><strong>Branch Name : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank.branchName}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p><strong>IFSC Code : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank.ifscCode}
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{ position: 'relative' }}>
                                                                        <td>
                                                                            <p><strong>Number of files : </strong></p>
                                                                        </td>
                                                                        <td>
                                                                            {bank?.documents?.length}
                                                                        </td>
                                                                        {bank?.documents?.length !== 0 ?
                                                                        <i onClick={() => { _handleClick('ViewBankDocument'); documentFunction('bankdocument', i) }} style={{ position: 'absolute', right: '10px', top: '6px', cursor: 'pointer' }} className="fa fa-info-circle" aria-hidden="true"></i>
                                                                        : ''}
                                                                    </tr>
                                                                   
                                                                </tbody>
                                                            </table>
                                                            <button className='btn btn-sm btn-danger' onClick={() => removeBank(i)}>Delete</button>
                                                            <button className='btn btn-sm btn-success float-end' onClick={() => handleUpdateBank(i)}>Update</button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                    <br />
                    <div className="text-center">
                        <button className='btn btn-shadow btn-secondary ps-5 pe-5' onClick={handleSubmit(handleFormSubmit)}>Add User</button>
                    </div>

                    <br />
                </div>
            </div>
        </>
    )
}

// validations
// used validate in doj dob ---> not working

export default User;