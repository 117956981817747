import { useEffect } from 'react';
import {useForm} from 'react-hook-form';

const UpdateDepartment = ({closeModal, updateDepartment, departmentObj}) => {
    const { register, handleSubmit, getValues, setValue, reset, formState:{ errors} } = useForm();
    console.log(departmentObj);
    
    const handleSubmitForm = (data) => {
        console.log(data);
        updateDepartment(data)
        reset();
        closeModal();
    }
    useEffect(()=>{
        if(departmentObj){
            setValue('department_id', departmentObj._id)
            setValue('department', departmentObj.department)
            setValue('departmentCode', departmentObj.departmentCode);
            setValue('description', departmentObj.description);
        }
    }, [departmentObj])

    return(
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <section id="updateDepartmentModal" className="custom-modal custom-modal-small" style={{width:"60%"}}>
                <div className="custom-modal-header">
                <h1>Update Department</h1>
                <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'75vh'}}>
                    <div className="cust-row">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Department Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Department Name" {...register('department', { required : true , minLength:2 })} />
                            { errors.department && <p className='error red_color'>Length of Department should be more than 2</p>}
                        </div>
                        
                    </div>
                    <div className="cust-row mb-2">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Department Code</label>
                            <input className="cust-input" type="text" placeholder="Department Code" {...register('departmentCode')} />
                            


                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Description</label>
                            <input className="cust-input" type="text" placeholder="Enter description" {...register('description')} />
                            


                        </div>
                    </div>
                    
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Update</div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UpdateDepartment