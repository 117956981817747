import react, { useEffect, useState } from "react";
import './../../../static/css/workforce.css';
import $ from 'jquery'
import { useDispatch,useSelector } from "react-redux";
import { attendanceListSelector } from "../../../state/redux/attendance/selectors";
import { callGetUserLeavesApi } from "../../../state/redux/attendance/action";
import { useForm } from 'react-hook-form';
import { categoryListSelector } from "../../../state/redux/category/selectors";
import { callGetCategoryApi,callAddCategoryApi, getLeaveType, addLeaveType, deleteLeaveType, updateLeaveType } from "../../../state/redux/category/actions";
import UpdateLeaveType from "../Modal/UpdateLeaveType";
import Confirm from "../Modal/Confirm";
import {toast} from 'react-toastify'
import { capitalize, show_notif } from "../../../services/functions";

const Categories = ()=>{
    const dispatch = useDispatch();
    const {leaves} = useSelector(attendanceListSelector);
    
    const {category, count} = useSelector(categoryListSelector)
    const { register, watch, handleSubmit, getValues, reset, formState: {errors} } = useForm();
    const watchLeaveCategory = watch('leaveCategory');
    const [deleteLeaveId, setDeleteLeaveId] = useState(null);
    const [updateLeaveObj, setUpdateLeaveObj] = useState(null);

    /* Functions for Modal Open & Close */
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setUpdateLeaveObj(null);
    }

    const handleDeleteLeave = (id, modalId) => {
        setDeleteLeaveId(id);
        _handleClick(modalId);
    }

    const confirmDeleteLeave = () => {
        dispatch(deleteLeaveType.call({leave_type_id: deleteLeaveId,toast: true}, (res_data) => {
            dispatch(getLeaveType.call({}, ()=>{
                setDeleteLeaveId(null);
            }))
        }))
    }

    const handleDeleteCategoryToast = (id) => {
        show_notif(
            {
                message: "",
                title: " Are you sure you want to delete this category?",
                type: "confirm",
                autohide: false,
            },
            (result) => {
                if (result.type === "confirm") {
                    dispatch(deleteLeaveType.call({leave_type_id: id, toast: true}, (res_data) => {
                        dispatch(getLeaveType.call({}, ()=>{
                            setDeleteLeaveId(null);
                        }))
                    }))
                }
            }
        );
     
    };

    const handleUpdateLeave = (obj) => {
        setUpdateLeaveObj(obj)
        _handleClick('updateLeaveTypeModal')
    }

    
    const _updateLeaveType = (data) => {
        
        data.toast = true;
        dispatch(updateLeaveType.call(data, (res)=>{
            dispatch(getLeaveType.call({}))          
        }))
    }
    useEffect(()=>{
        dispatch(getLeaveType.call({}));

    },[])

    const handleAddCategory = (data)=>{
        // console.log(data);
        data.toast = true;
        dispatch(addLeaveType.call(data, ()=>{
            reset();
            dispatch(getLeaveType.call({}));
        }));
    }
    return(
        <>
        <div className='contentpanel'>
            <div className='cust-row'>
                <div className="cust-col-5">
                    <div className="card">
                        <div className="card-header">
                           <h5>Leave Categories</h5>
                        </div>
                        <div className="card-body">
                            <UpdateLeaveType closeModal={_closeModal} updateLeaveType={_updateLeaveType} leaveObj={updateLeaveObj}/>
                            <Confirm id='deleteLeaveConfirmModal' closeModal={_closeModal} confirm={confirmDeleteLeave} />
                            
                            <table className='workforce-table'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Leave Category</th>
                                        <th>Leave Type</th>
                                        <th>Leaves(In Year)</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        category.map((c,i)=>{
                                            return(
                                                <tr key={i}>
                                                    <td>{i+1}</td>
                                                    <td>{c.leaveType || "-"}</td>
                                                    <td>{capitalize(c.leaveCategory) || "Paid"}</td>
                                                    <td>{c.leaveCount || "0"}</td>
                                                    <td>
                                                        <>
                                                            <button className='btn-outline-orange' onClick={()=>handleDeleteCategoryToast(c._id)}>
                                                                <i className='fa fa-trash'></i>
                                                            </button>
                                                            <button className='btn-outline-orange ms-2' onClick={()=>handleUpdateLeave(c)}>
                                                                <i className='fa fa-edit'></i>
                                                            </button>
                                                        </>
                                                        
                                                    </td>
                                                </tr>
                                            )

                                        })
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="cust-col-5">
                    <div className="card">
                        <div className="card-header">
                           <h5>Create Leave Category</h5>
                        </div>
                        <form onSubmit={handleSubmit(handleAddCategory)}>
                            <div className="card-body">
                                <div className='cust-row'>
                                    <div className="cust-col-1"></div>
                                    <div className='cust-col-4'>
                                        <label className="mt-2">Leave Category Name<span className="error red_color">*</span></label>
                                    </div>
                                    <div className='cust-col-4'>
                                        <input type='text' className='cust-input' placeholder='Enter Leave Name' {...register('leaveType', { required : true})}/>
                                        {errors.leaveType && <p className="error red_color">Leave Name is required</p>}
                                    </div>
                                </div>
                                <div className='cust-row'>
                                    <div className="cust-col-1"></div>
                                    <div className='cust-col-4'>
                                        <label className="mt-2">Leave Type<span className="error red_color">*</span></label>
                                    </div>
                                    <div className='cust-col-4'>
                                    <select	className="cust-input select2-single" type="text" {...register("leaveCategory", {required: true})} defaultValue={""}>
								        <option disabled value="">Leave Type</option>
								        <option  value="paid">Paid</option>
								        <option  value="unpaid">Unpaid</option>
                                    </select>
                                    {errors.leaveCategory && <p className="error red_color">Leave Type is required</p>}
                                    </div>
                                </div>
                                {watchLeaveCategory == 'paid' ?
                                    <div className='cust-row'>
                                        <div className="cust-col-1"></div>
                                        <div className='cust-col-4'>
                                                <label className="mt-2">Number of Leaves<span className="error red_color">*</span></label>
                                        </div>
                                        <div className='cust-col-4'>
                                            <input type='number' className='cust-input' placeholder='Enter Leave Number'{...register('leaveCount', { required : true , validate: val => val > 0 })} />
                                            {errors.leaveCount && <p className="error red_color">Leave number should be greater than 0</p>}
                                        </div>
                                    </div>
                                : null}
                                <div className="text-center mt-3">
                                    <button className="btn btn-orange btn-sm">Add Category</button>
                                </div>
                                    
                            </div>
                        </form>
                    </div>
                </div>
                

            </div>

        </div>
        </>

    )
}

export default Categories;