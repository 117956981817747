import React,{ Component } from 'react';
import FileUpload from './../FileUpload'

class AddVehicle extends Component{

    constructor(props){
        super(props)
        this._handleFiles=this._handleFiles.bind(this)
    }

    _handleFiles(files){
        console.log(files)
    } 

    render(){
        return(
            <section id="addVehicleModal" className="custom-modal">
                <div className="custom-modal-header">
                <h1>Add Vehicle</h1>
                <span className="ct-close" onClick={this.props.closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'70vh'}}>
                    <div className="cust-row">
                        <div className='cust-col-2'></div>
                        <div className='cust-col-6'>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Enter Vehicle Number..."/>
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                Fetch
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="cust-row">
                        <div className='cust-col-10'>
                            <div className='table-responsive'>
                                <table className='bordered_table'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span className='thd'>Owner Name</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                            <th>
                                                <span className='thd'>Son/Daughter/Wife/Husband Name</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span className='thd'>Engine Number</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                            <th>
                                                <span className='thd'>Chasis Number</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span className='thd'>Truck Model</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                            <th>
                                                <span className='thd'>Truck Type</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span className='thd'>Issue Date</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                            <th>
                                                <span className='thd'>Valid Upto</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span className='thd'>Insurance Validity Upto</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                            <th>
                                                <span className='thd'>PUC Validity Upto</span>
                                                <h2 className='tht'>(From E-Vahan)</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span className='thd'>Truck Serial Number</span>
                                                <h2 className='tht'>(From EKAYAN (HIL))</h2>
                                            </th>
                                            <th>
                                                <span className='thd'>GPS Number</span>
                                                <h2 className='tht'>(From Lynktrac/other vendor GPS system)</h2>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                    <div className="cust-row">
                        <div className='cust-col-10'>
                            <div className='collapse-view'>
                                <div className='collapsable_icon' onClick={()=>{this.props.toogleView(0)}}></div>
                                <div className='doc_container'>
                                    <div className='doc_container_head'>
                                        Upload Vehicle Documents
                                    </div>
                                    <div className='doc_container_bdy'>
                                        <FileUpload handleFiles={this._handleFiles}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div className="row-div">
                        <div className="share-div">
                            <button className="action_btn">Add Vehicle</button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default AddVehicle;