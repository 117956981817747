import JointActionDispatcher from "../../../services/jointActionDispatcher";
import types from "./types";

export const getFieldMapping = {
	url: `/api/ledger/field_mapping_list`,
	action: types.GET_FEILD_MAPPING,
	__proto__: JointActionDispatcher,
};
export const getReport = {
	url: `/api/ledger/download`,
	action: types.GET_REPORT,
	__proto__: JointActionDispatcher,
};
