import React, { useState, useEffect } from 'react';
import FileUpload from './../FileUpload'
import $ from 'jquery';
import moment from 'moment';
import { base64Convert } from '../../../services/functions';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { expenseTypesSelector, paymentMethodsSelector } from '../../../state/redux/workforceDetail/selectors';
import { addExpenseType, addPaymentMethod, getExpenseType, getPaymentMethod } from '../../../state/redux/workforceDetail/actions';
import AddExpenseType from './AddExpenseType';
import AddPaymentMode from './AddPaymentMode';


export default function AddDebit({ closeModal, addDebit }) {

    const dispatch = useDispatch();
    const expenseTypes = useSelector(expenseTypesSelector);
    const paymentMethods = useSelector(paymentMethodsSelector);

    const [uploadFiles, setUploadFiles] = useState([])
    const [imagesUrl, setImagesUrl] = useState([])
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const selectedPaymentMode = watch('payment_mode', "");

    const [showTid, setShowTid] = useState(false);
    useEffect(() => {
        let s = false;
        for (let i = 0; i < paymentMethods.length; i++) {
            if (selectedPaymentMode == paymentMethods[i].payment_method) {
                s = paymentMethods[i].transaction_id
                break;
            }
        }
        setShowTid(s);
    }, [selectedPaymentMode])

    const handleFilesUpload = async (files) => {
        console.log(files, "files ofhhhh")
        for (const file of files) {
            //  let imageURL=URL.createObjectURL(file)
            setImagesUrl((prev) => [...prev, URL.createObjectURL(file)])
        }
        //     files.map((file)=>{
        //         return(
        //         //  imageURL=URL.createObjectURL(file)
        //    setImagesUrl((prev)=>[...prev,URL.createObjectURL(file)])
        //         )
        // })
        //     let imageURL=URL.createObjectURL(files[0])
        //     console.log(URL.createObjectURL(files[0]),"files this is uploaded")
        //    setImagesUrl((prev)=>[...prev,imageURL])

        const promises = Array.from(files).map(async file => {

            const base64File = await base64Convert(file);

            return base64File

        })

        const base64Files = await Promise.all(promises);

        setUploadFiles([...uploadFiles, ...base64Files]);

    }
    useEffect(() => {
        console.log(imagesUrl, "images url when upload")

    }, [imagesUrl])

    const handleRemoveDocument = (i) => {
        let oldBase = [...uploadFiles]
        let oldDocuments = [...imagesUrl];
        oldDocuments.splice(i, 1);
        oldBase.splice(i, 1);
        setImagesUrl(oldDocuments);
        setUploadFiles(oldBase)
    }
    const emptyFields = () => {
        setUploadFiles([]);
        setImagesUrl([])
        reset();
    }

    const handleAddDebit = (data) => {
        data.image = uploadFiles
        data.closeModal = closeModal;
        data.emptyFields = emptyFields;
        console.log({ data }, uploadFiles, "dataaaaaaa")
        addDebit(data);
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = (id) => {
        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(`#addExpenseTypeModalDebit`).removeClass("custom-modal-is-visible")
        // setUpdateDocumentObj(null);
    }

    const _closeModal2 = () => {
        $(`#addPaymentModeModalDebit`).removeClass("custom-modal-is-visible")
    }
    const _addExpenseType = (data) => {
        data.toast = true;
        dispatch(addExpenseType.call(data, () => {
            dispatch(getExpenseType.call({ expense_type: "user" }))
        }))
    }

    const _addPaymentMode = (data) => {
        data.toast = true;
        dispatch(addPaymentMethod.call(data, () => {
            dispatch(getPaymentMethod.call({}))
        }))
    }
    const exitModal = () => {
        closeModal()
        setUploadFiles([])
        setImagesUrl([])
    }

    return (
        <>

            <section id="addDebitModal" className="custom-modal">
                <div className="custom-modal-header">
                    <h1>Add Debit</h1>
                    <span className="ct-close" onClick={exitModal}></span>
                </div>
                <AddExpenseType id="Debit" closeModal={_closeModal} addExpenseType={_addExpenseType} />
                <AddPaymentMode id="Debit" closeModal={_closeModal2} addPaymentMode={_addPaymentMode} />
                <form onSubmit={handleSubmit(handleAddDebit)}>
                    <div className="custom-modal-body" style={{ maxHeight: '70vh', overflowY: "auto", overflowX: "hidden" }}>

                        <div className="cust-row mb-2">
                            <div className="cust-col-5">
                                <label className="black_color font-bold text-left wdt100">Expense Type<span className="red_color">*</span></label>
                                <select className="cust-input select2-single" type="password" {...register('expense_name', { required: true })}>

                                    <option value="">Select</option>
                                    {
                                        expenseTypes.map((exp, i) => {
                                            return (
                                                <option key={i} value={exp.expense_name}>{exp.expense_name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors.expense_name && <p className='error red_color'>Expense Name is required</p>}
                                <div className='text-start mt-1'>
                                    <a className='btn btn-orange btn-sm' onClick={() => _handleClick('addExpenseTypeModalDebit')}>Add +</a>
                                </div>

                            </div>
                            <div className="cust-col-5">
                                <label className="black_color font-bold text-left wdt100">Amount Paid<span className="red_color">*</span></label>
                                <input className="cust-input" type="number" step=".01" placeholder="Enter Amount" {...register('amount_paid', { required: true })} />
                                {errors.amount_paid && <p className='error red_color'>Amount Paid is required</p>}
                            </div>
                            <div className="cust-col-5">
                                <label className="black_color font-bold text-left wdt100">Payment Date<span className="red_color">*</span></label>
                                <input className="cust-input" type="date" max={moment().format("YYYY-MM-DD")} placeholder="Payment Date" {...register('expense_date', { required: true })} />
                                {errors.expense_date && <p className='error red_color'>Payment Date is required</p>}
                            </div>
                        </div>

                        <div className="cust-row mb-2">
                            <div className="cust-col-5">
                                <label className="black_color font-bold text-left wdt100">Payment Mode<span className="red_color">*</span></label>
                                <select className="cust-input select2-single" type="password" {...register('payment_mode', { required: true })}>
                                    <option value="">Select Mode</option>
                                    {
                                        paymentMethods.map((method, i) => {
                                            return (
                                                <option value={method.payment_method} key={i}>{method.payment_method}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors.payment_mode && <p className='error red_color'>Payment Mode is required</p>}
                                <div className='text-start mt-1'>
                                    <a className='btn btn-orange btn-sm' onClick={() => _handleClick('addPaymentModeModalDebit')}>Add +</a>
                                </div>


                            </div>
                            {
                                showTid &&
                                (
                                    <div className="cust-col-5">
                                        <label className="black_color font-bold text-left wdt100">Transaction ID<span className="red_color">*</span></label>
                                        <input className="cust-input" type="text" placeholder="Transaction Id" {...register('transaction_id', { required: true })} />
                                    </div>
                                )

                            }
                            <div className="cust-col-5">
                                <label className="black_color font-bold text-left wdt100">Remarks(Optional)</label>
                                <input className="cust-input" type="text" placeholder="Add Remarks" {...register('notes')} />
                            </div>
                        </div>

                        <div className='cust-row'>
                            <div className='cust-col-10'>
                                <label className="black_color font-bold text-left wdt100">Add Image(Optional)</label>
                                <div className='doc_container'>
                                    <div className='doc_container_bdy'>
                                        <FileUpload handleFiles={handleFilesUpload} modalId="add-debit-documents" />
                                        {/* <input type='file'/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            imagesUrl.length > 0 &&
                            <>
                                <br />
                                <h6>Selected Files</h6>
                                <hr />
                                <div className='row'>
                                    {
                                        imagesUrl.map((file, i) => {
                                            return (
                                                <div className='col-md-6 col-sm-4 col-lg-3' key={i}>
                                                    <div className='alert alert-info alert-holiday alert-dismissible fade show p-3' style={{ width: "115px", height: "110px" }}>
                                                        {file?.includes("image") ? (
                                                            <>
                                                                <img src={file} style={{ width: "100%", height: "100%" }} /> <button type="button" className="btn-close" style={{ padding: "0px" }} onClick={() => handleRemoveDocument(i)}></button>
                                                            </>
                                                        )
                                                            :
                                                            (
                                                                <>
                                                                    <object
                                                                        data={file}
                                                                        type="application/pdf"
                                                                        width="100%"
                                                                        height='100%'
                                                                    ></object>
                                                                    <button type="button" className="btn-close" style={{ padding: "0px" }} onClick={() => handleRemoveDocument(i)}></button>
                                                                </>
                                                            )}
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <hr />
                            </>
                        }
                        <div className="cust-row">
                            <div className="cust-col-4">

                            </div>
                            <div className="cust-col-2">
                                <button className='button bti bg-grey-out m-0'>
                                    <div className='btn_icon'>
                                        <i className='fa fa-plus'></i>
                                    </div>
                                    <div className='btn_txt font-lg'> Submit </div>
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </section>


        </>


    )
}


