import { combineReducers } from "redux";
import attendanceReducer from "./redux/attendance/reducers";
import departmentReducer from "./redux/department/reducers";
import workforceReducer from "./redux/workforce/";
import workforceDetailReducer from "./redux/workforceDetail";
import categoryReducer from "./redux/category/reducers";
import reportReducer from "./redux/report/reducer";

const rootReducer = combineReducers({
	workforce: workforceReducer,
	workforceDetail: workforceDetailReducer,
	attendance: attendanceReducer,
	department: departmentReducer,
	category: categoryReducer,
	report: reportReducer,
});

export default rootReducer;
