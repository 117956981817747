import axios from "axios"
import { showToast } from "./functions"

const domain = process.env.REACT_APP_APIHOST || ""
// const domain = 'http://d8gnu1rryevl2.cloudfront.net'
// const domain = "";
// const domain = 'https://beta.attendance.lynkit.io'


const JointActionDispatcher = {
    fetch() {
        return {
            type: `${this.action}_FETCH`
        }
    },
    success(data) {
        return {
            type: `${this.action}_SUCC`,
            payload: data
        }
    },
    error(error) {
        return {
            type: `${this.action}_ERR`,
            payload: error
        }
    },
    call(body, callback = null) {
        return (dispatch) => {
            dispatch(this.fetch());
            axios.post(`${domain}${this.url}`, body, {
                headers: {
                    'accesskey': localStorage.getItem('secretkey')
                }
            }).then((response) => {

                if (body.toast) {
                    if (response.data.error) {
                        showToast(response.data.message, true)
                    } else {
                        if (!body.onlyError) {
                            showToast(response.data.message, response.data.error)
                        }
                    }

                }
                if (body?.closeModal) {
                    if (response.data.error) {
                        console.log(response.data.error);
                    }
                    else {
                        body.closeModal();
                        body.emptyFields();
                    }
                }

                if (response.data.error) {
                    dispatch(this.error(response.data.message))
                    if (callback) {
                        callback(response.data);
                    }
                } else {
                    dispatch(this.success(response.data))
                    if (callback) {
                        callback(response.data);
                    }
                }
                // return response
            }).catch((error) => {
                dispatch(this.error(error.message))
                if (callback) {
                    callback(null);
                }
            })
        }
    }
}

export default JointActionDispatcher


