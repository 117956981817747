import React,{ useEffect } from 'react'
import $ from 'jquery'
import { selectInit } from './../../services/functions'
import AnalysisModal from './../Dashboard/Modal/Analysis'
import TrackingModal from './../Dashboard/Modal/Tracking'
import ViewDocumentModal from './../Dashboard/ViewDocuments'
import history from '../../services/history';

const Delivery = () =>{

    useEffect(()=>{
        setTimeout(() => {
            selectInit(_onChange)
        }, 100);
    },[])

    const toogleItemView = (index) =>{
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        } 
        else {
            $(".data-cont-item").eq(index).addClass("open")
        }
    }

    const _onChange = (id,value) => {
        
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _goto=()=>{
        history.push("/dashboard/delivery/detail")
    }

    return(
        <div className="contentpanel">
            <h1 className="title">Proof of Delivery</h1>
            <hr/>
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-2 flex-algn-cent'>
                        <div className='tt2'>PoD List</div>
                    </div>
                    <div className='cust-col-4'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="select2-single" defaultValue="truck_no" id="searchType">
                                    <option value="truck_no">Receipt No.</option>
                                </select>
                            </div>
                            <input type="text" className="form-control" placeholder="Search here..."/>
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-2'>
                        <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                            <option value="all">All Type</option>
                            <option value="owned">Owned Vehicle</option>
                            <option value="hired">Hired Vehicle</option>
                        </select>
                    </div>
                    <div className='cust-col-2 flex-jc-end'>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            <div className='data-cont-item'>
                                {localStorage.getItem("userType")==='userType2'?"":<div className='ttname'>Transporter : <span className='transportrName'>Ajay(ID : XXXX)</span></div>}
                                <div className='sno_txt'>1.</div>
                                <div className='data_item_content set_height'>
                                    <div className='wd_15'>
                                        <span>Receipt No.</span>
                                        <span className='orange_color font-lg'>12345</span>
                                        <span>(Oct 12th 2021 11:35 AM)</span>
                                    </div>
                                    <div>
                                        <span>From</span>
                                        <span className='flex_box_2'>Coke, w-101 ,industrial area , Ahmedabad, Gujarat - 343454</span>
                                    </div>
                                    <div>
                                        <span>To</span>
                                        <span className='flex_box_2'>New Delhi Office, g-89 , govind puri, New Delhi, Delhi - 110099</span>
                                    </div>
                                    {
                                        localStorage.getItem("userType")==='userType2'?
                                        <div>
                                            <span>Pkgs Sent/Received</span>
                                            <span>
                                                15/15 <i className='fa fa-info-circle'></i>
                                            </span>
                                        </div>
                                        :
                                        <div>
                                            <span>Total Distance Covered</span>
                                            <span>
                                                1800 KM
                                            </span>
                                        </div>
                                    }                                    
                                    <div className='wd_15'>
                                        <span>Deliverd On</span>
                                        <span>Oct 12th 2021 11:35 AM</span>
                                    </div> 
                                    <div className='wd_10'>
                                        <span>Delivery Date(Est.)</span>
                                        <span>Oct 12th 2021</span>
                                    </div>  
                                                                     
                                </div>
                                <div className='data_item_content set_height clps'>
                                    <div>
                                        <span>Consignor</span>
                                        <span className='flex_box_2'>Dettol, vishnu garden , Ahmedabad, Gujarat - 110020(GSTIN-24AZSXK7676L1Z6)</span>
                                    </div>
                                    <div>
                                        <span>Consignee</span>
                                        <span className='flex_box_2'>RISHABH CONSIGNOR, Test Address of consignor, Bhagalpur, Bihar - 110092</span>
                                    </div>
                                    <div className='wd_15'>
                                        <span>Goods Description</span>
                                        <span>Boxes</span>
                                    </div>  
                                    <div className='wd_15'>
                                        <span>Arrived On</span>
                                        <span>Oct 12th 2021 10:35 AM</span>
                                    </div>                                 
                                    <div className='wd_15'>
                                         <span>Total Freight</span>
                                        <span>100000</span>
                                    </div>
                                    <div className='wd_15'>
                                        <span>Tracking ID</span>
                                        <span>9988776655 <i className='fa fa-clone copyIcon'></i></span>
                                    </div>
                                    <div className='btn_conte'>
                                        <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1' onClick={()=>{_handleClick('viewDocumentsModal')}}>View Documents</button>
                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>Update</button> 
                                        <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Delete</button>
                                        <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Cancel</button>
                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={()=>{_handleClick('trackingModal')}}>Tracking</button>
                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={()=>{_handleClick('analysisModal')}}>Analytics</button>
                                        <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1' onClick={()=>_goto()}>View Detail</button>
                                    </div>
                                </div>
                                <div className='collps_icon' onClick={()=>{toogleItemView(0)}}>
                                    <i className='fa fa-chevron-down'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AnalysisModal closeModal={_closeModal}/>
            <TrackingModal closeModal={_closeModal}/>
            <ViewDocumentModal closeModal={_closeModal}/>
        </div>
    )
}

export default Delivery