import { useEffect, useState } from 'react';
import {useForm} from 'react-hook-form';
import { base64Convert } from '../../../services/functions';
import { getFile } from '../../../state/redux/workforceDetail/actions';
import { useDispatch } from 'react-redux';
import FileUpload from './../FileUpload'

const UpdateBankDetail = ({closeModal, updateBank, bankObj}) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, getValues, setValue, reset, formState:{ errors} } = useForm();

    const [uploadFiles, setUploadFiles] = useState([])
    const [newObj,setNewObj] = useState([])

    const handleSubmitForm = (data) => {   
        let body = data;
        body['documents'] = newObj.map(file => file ? file : file);
        updateBank(body)
        reset();
        closeModal();
    }
    const handleFilesUpload = async (files) => {
        const promises = Array.from(files).map(async file => {
            const base64File = await base64Convert(file);
            return base64File
        })
        const base64Files = await Promise.all(promises);
        setUploadFiles([...uploadFiles, ...base64Files]);
        setNewObj([...newObj, ...base64Files])
    }
    const handleRemoveDocument = (i) => {
        let oldDocuments = [...uploadFiles];
        oldDocuments.splice(i, 1);
        setUploadFiles(oldDocuments);

        let temp = [...newObj];
        temp.splice(i, 1);
        setNewObj(temp);

    }
    const PreviewFiles = ({ files, isEditable = true, onRemove = (index) => { } }) => {

        return (
            <div className="img_preview_container custom-scrollbar">
                {files?.map((file, index) => {
                    return (
                        <div className="img_preview_wrap" key={index} >
                            <div className="img_preview_item">
                                {isEditable ? (
                                    <span className="clsbt" onClick={() => onRemove(index)}>
                                        <i className="fa fa-times"></i>
                                    </span>
                                ) : null}
                                <PreviewFile data={file} />
                            </div>                       
                        </div>
                    );
                })}
            </div>
        );
    };

    const PreviewFile = ({ data }) => {
        return (
            <>
                {data?.includes("image") ? (
                    <img src={data} alt="image" width="100%" height="100%" />
                ) : (
                    <object
                        data={data}
                        type="application/pdf"
                        width="100%"
                        height='100%'
                    ></object>
                )}
            </>
        );
    };

    useEffect(()=>{
        if(bankObj){ 
            setUploadFiles([])
            setNewObj([])
            for(let i=0; i < bankObj.documents.length; i++){
                dispatch(getFile.call({key: bankObj && bankObj.documents[i]}, (image_data) => {
                    if (image_data) {
                        setUploadFiles(old => [...old, image_data?.data || ''])
                        setNewObj(old => [...old, bankObj && bankObj.documents[i] ])
                    }
                }))
            }
            setValue('bank_details_id', bankObj._id);
            setValue('accountNo', bankObj.accountNo);
            setValue('bankName', bankObj.bankName);
            setValue('beneficiaryName', bankObj.beneficiaryName);
            setValue('branchName', bankObj.branchName);
            setValue('ifscCode', bankObj.ifscCode);
        }
    }, [bankObj])  

    



    return(
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <section id="updateBankDetailModal" className="custom-modal" style={{width:"60%"}}>
                <div className="custom-modal-header">
                <h1>Update Bank Detailssssss</h1>
                <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'75vh'}}>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Account Number<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Account" {...register('accountNo', { required : true , minLength:8 , maxLength:17 })} />
                            { errors.accountNo && <p className='error red_color'>Account Number should be Valid</p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Account Type<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" type="password" {...register('accountType', { required : true })}>
                                <option disabled value="">Select Account</option>
                                <option value="savings">Saving Account</option>
                                <option value="current">Current Account</option>
                            </select>
                            { errors.accountType && <p className='error red_color'>Account Type is required</p>}


                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Bank Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Bank Name" {...register('bankName', { required : true , minLength:2  })}/>
                            { errors.bankName && <p className='error red_color'>Mininum length for bank Name is 2 and only letters are allowed</p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Beneficiary Name <span className='red_color'>*</span></label>
                            <input className="cust-input" type="text" placeholder="Beneficiary Name" {...register('beneficiaryName',{required:true})}/>
                            {errors.beneficiaryName && <p>Beneficiary Name is required</p>}

                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Branch Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Branch Name" {...register('branchName', { required : true })}/>
                            { errors.branchName && <p className='error red_color'>Branch Name is required</p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">IFSC Code <span className='red_color'>*</span></label>
                            <input className="cust-input" type="text" placeholder="IFSC" {...register('ifscCode', {required:true})}/>
                            {errors.ifscCode && <p className='red_color'>IFSC Code is required</p>}

                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='doc_container'>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={handleFilesUpload} modalId="update-documents" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        bankObj && bankObj.documents.length > 0 &&
                        <>
                            <br />
                            <h6>Uploaded Files</h6>
                            <hr />
                            <div className='row rowHeight'>
                                {
                                    <div className='col-md-4 col-sm-6 pClass'>
                                        <PreviewFiles files={uploadFiles} isEditable={true} onRemove={handleRemoveDocument}/>
                                    </div>
                                }
                            </div>
                            <hr />
                        </>
                    }
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Update</div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UpdateBankDetail