import React, { Component } from 'react';
import { selectInit } from './../../services/functions'
import $ from 'jquery'
import AddVehicle from './Modal/AddVehicle'
import history from '../../services/history';

class Vehicle extends Component {

    constructor(props) {
        super(props)
        this._onChange = this._onChange.bind(this)
        this._handleClick = this._handleClick.bind(this)
        this._closeModal = this._closeModal.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            selectInit(this._onChange)
        }, 100);
    }

    toogleView(index, check = true) {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }

    toogleItemView(index) {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        }
        else {
            // let height = Number($(".data-cont-item").eq(index).children(".data_item_content.clps").height()) + 2;
            // $(".data-cont-item")
            //     .eq(index)
            //     .addClass("open")
            //     .animate({ height }, 500, () => {
            $(".data-cont-item").eq(index).addClass("open")
            // });
        }
    }

    _onChange(id, value) {
        
    }

    _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    _closeModal() {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    _goto(type) {
        
        if (type === 'detail') {
            history.push("/dashboard/vehicle/details")
        }
    }

    render() {
        return (
            <div className="contentpanel">
                <h1 className="title">Vehicle</h1>
                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Vehicle List</div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="truck_no" id="searchType">
                                        <option value="truck_no">Vehicle No.</option>
                                        <option value="transporter_name">Transporter Name</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                <option value="all">All Type</option>
                                <option value="owned">Owned Vehicle</option>
                                <option value="hired">Hired Vehicle</option>
                            </select>
                        </div>
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userType") !== 'userType2' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all">Transporter's Name</option>
                                        <option value="ajay">Ajay</option>
                                        <option value="nitin">Nitin</option>
                                    </select>
                                    : ""
                            }
                        </div>

                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary' onClick={() => { this._handleClick('addVehicleModal') }}>Add Vehicle</button>
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item'>
                                {localStorage.getItem("userType")==='userType2'?"":<div className='ttname'>Transporter : <span className='transportrName'>Ajay(ID : XXXX)</span></div>}
                                    <div className='sno_txt'>1.</div>
                                    <div className='data_item_content'>
                                        <div>
                                            <span>Vehicle No.</span>
                                            <span>(Lynkit/OTM)</span>
                                        </div>
                                        <div>
                                            <span>Vehicle Model</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div>
                                            <span>Vehicle Type</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div>
                                            <span>Ownership</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div>
                                            <span>Issue Date</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                    </div>
                                    <div className='data_item_content clps'>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_10"}>
                                            <span>RC Valid Upto</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_10"}>
                                            <span>Insurance Valid Upto</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_15"}>
                                            <span>PUC Valid Upto</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_15"}>
                                            <span>Engine Number</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div>
                                            <span>Chasis Number</span>
                                            <span>(e-Vahan)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_10" : "wd_15"}>
                                            <span>Tare Weight</span>
                                            <span>(Lynkit/HIL)</span>
                                        </div>
                                        <div className={localStorage.getItem("userType") === 'userType2' ? "wd_15" : "wd_15"}>
                                            <span>Max Loadable Capacity</span>
                                            <span>(Lynkit/HIL)</span>
                                        </div>
                                        {localStorage.getItem("userType") === 'userType2' ?
                                            <div className='wd_15' >
                                                <span>Black Listed status</span>
                                                <span>(Lynkit)</span>
                                            </div>
                                            : ""}


                                        <div className='btn_conte'>
                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1'>View Documents</button>
                                            {localStorage.getItem("userType") !== 'userType2' ?
                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>Update</button>
                                                : ""}
                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Delete</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1' onClick={() => { this._goto('detail') }}>View Detail</button>
                                            {localStorage.getItem("userType") !== 'userType2' ?
                                                <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1'>Blacklist</button>
                                                : ""}
                                        </div>
                                    </div>
                                    <div className='collps_icon' onClick={() => { this.toogleItemView(0) }}>
                                        <i className='fa fa-chevron-down'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddVehicle closeModal={this._closeModal} toogleView={this.toogleView} />
            </div>
        )
    }
}

export default Vehicle;