import { useForm } from 'react-hook-form';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import { useEffect, useState } from 'react';

const AddBankDetail = ({ closeModal, addBank, setBankDocumenstArray, bankdocumentsArray }) => {
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const [uploadFiles, setUploadFiles] = useState([])
    let path = window.location.pathname

    const handleFilesUpload = async (files) => {
        const promises = Array.from(files).map(async file => {
            const base64File = await base64Convert(file);
            return base64File
        })
        const base64Files = await Promise.all(promises);
        setUploadFiles([...uploadFiles, ...base64Files]);

    }
    const handleRemoveDocument = (i) => {
        let oldDocuments = [...uploadFiles];
        oldDocuments.splice(i, 1);
        setUploadFiles(oldDocuments);
    }
    const handleSubmitForm = (data) => {
        let body = data;
        body['documents'] = uploadFiles

        let final = { ...body, documents: uploadFiles }

        if (body.documents.length > 0) {
            addBank(body);
            reset();
            setUploadFiles([]);
            closeModal();
        }
        else {
            console.log("Form Errors");
        } 
        if (path === '/dashboard/workforce/user-add') {
            let temp = [...bankdocumentsArray]
            temp.push(uploadFiles)
            setBankDocumenstArray(temp)
        }

    }

    const PreviewFiles = ({ files, isEditable = true, onRemove = (index) => { } }) => {
        return (
            <div className="img_preview_container custom-scrollbar">
                {files?.map((file, index) => {
                    return (
                        <div className="img_preview_wrap" key={index} >
                            <div className="img_preview_item">
                                {isEditable ? (
                                    <span className="clsbt" onClick={() => onRemove(index)}>
                                        <i className="fa fa-times"></i>
                                    </span>
                                ) : null}
                                <PreviewFile data={file} />
                            </div>

                            {/* <div className="img_preview_text mt-1">
                                <span>
                                    {file}
                                </span>
                            </div> */}

                        </div>
                    );
                })}
            </div>
        );
    };

    const PreviewFile = ({ data, height = "" }) => {
        return (
            <>
                {data?.includes("image") ? (
                    <img src={data} alt="image" width="100%" height="100%" />
                ) : (
                    <object
                        data={data}
                        type="application/pdf"
                        width="100%"
                        height='100%'
                    ></object>
                )}
            </>
        );
    };




    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <section id="addBankDetailModal" className="custom-modal" style={{ width: "60%" }}>
                <div className="custom-modal-header">
                    <h1>Add Bank Detail</h1>
                    <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{ overflowY: 'scroll', overflowX: 'none', maxHeight: '75vh' }}>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Account Number<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Account" {...register('accountNo', { required: true, minLength: 8, maxLength: 17 })} />
                            {errors.accountNo && <p className='error red_color'>Account Number Should Be Valid</p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Account Type<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" type="password" {...register('accountType', { required: true })}>
                                <option disabled value="">Select Account</option>
                                <option value="savings">Saving Account</option>
                                <option value="current">Current Account</option>
                            </select>
                            {errors.accountType && <p className='error red_color'>Account Type is required</p>}


                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Bank Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Bank Name" {...register('bankName', { required: true, minLength: 2 })} />
                            {errors.bankName && <p className='error red_color'>Mininum length for bank Name is 2 </p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Beneficiary Name <span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Beneficiary Name" {...register('beneficiaryName', { required: true })} />
                            {errors.beneficiaryName && <p className='error red_color'>Beneficiary Name is required</p>}

                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Branch Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder="Branch Name" {...register('branchName', { required: true })} />
                            {errors.branchName && <p className='error red_color'>Branch Name is required</p>}

                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">IFSC Code <span className='red_color'>*</span></label>
                            <input className="cust-input" type="text" placeholder="IFSC" {...register('ifscCode', { required: true })} />
                            {errors.ifscCode && <p className='red_color'>IFSC Code is required</p>}

                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='doc_container'>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={handleFilesUpload} modalId="add-documents" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        uploadFiles.length > 0 &&
                        <>
                            <br />
                            <h6>Selected Files</h6>
                            <hr />
                            <div className='row rowHeight' style={{ width: '98%' }}>
                                {
                                    <div className='col-md-4 col-sm-6 pClass'>
                                        <PreviewFiles files={uploadFiles} isEditable={true} onRemove={handleRemoveDocument} />
                                    </div>
                                }
                            </div>
                            <hr />
                        </>
                    }















                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Add Bank Detail</div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default AddBankDetail