import React, {Component} from 'react';
import Footer from "../../views/Landing/Footer"
import Navbar from "../../views/Landing/Navbar"
import Login from "../../views/Landing/Login"
import './../../static/css/style.css'
import logo from './../../static/images/logo.png'
import queryString from 'query-string';

import history from '../../services/history'

class App extends Component {
  constructor(props) {
    super(props)
    this._onLogin=this._onLogin.bind(this)
  }

  _onLogin(){
    const urlParams = queryString.parse(this.props.location.search);
    localStorage.setItem('secretkey', "7bxt3i6524");
    if(urlParams.redirect){
      history.push(urlParams.redirect);
    }
    else{
      history.push('/dashboard')
    }
    
  }

  render() {
    return (
      <div>
        <Navbar logo={logo}/>
        <Login _onLogin={this._onLogin} />
        
        <Footer/>
      </div>
    )
  }
}

export default App;