import { toast } from "react-toastify";

export function confimToast({message, callback, }) {
	const Confirm = ({ closeToast, toastProps }) => {
		return (
			<div className="confirmation-toast">
				{message}
				<button
					className="confirmation-toast-btn toast-confirm"
					onClick={() => {
						callback()
						closeToast();
					}}

				>
					Yes.
				</button>
				<button
					onClick={closeToast}
					className="confirmation-toast-btn toast-cancel"
				>
					No, go back.
				</button>
			</div>
		);
	};
	toast.warn(Confirm);
}
export function warnToast({message}) {
	const Confirm = ({ closeToast, toastProps }) => {
		return (
			<div className="confirmation-toast">
				{message}
				<button
					className="confirmation-toast-btn toast-confirm"
					onClick={() => {
						closeToast();
					}}

				>
					Okay
				</button>
				{/* <button
					onClick={closeToast}
					className="confirmation-toast-btn toast-cancel"
				>
					No, go back.
				</button> */}
			</div>
		);
	};
	toast.warn(Confirm);
}

