import types from "./types";
const initialState = {
	report: {
		data: "",
		error: null,
		loading: false,
	},
	fieldMapping: {
		data: [],
		error: null,
		loading: false,
	},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case `${types.GET_REPORT}_FETCH`:
			return {
				...state,
				report: {
					data: "",
					error: null,
					loading: true,
				},
			};
		case `${types.GET_REPORT}_SUCC`:
			return {
				...state,
				report: {
					data: action.payload.data,
					error: null,
					loading: false,
				},
			};
		case `${types.GET_REPORT}_ERR`:
			return {
				...state,
				report: {
					data: "",
					error: action.payload,
					loading: false,
				},
			};
		case `${types.GET_REPORT}_RESET`:
			return {
				...state,
				report: {
					data: "",
					error: null,
					loading: false,
				},
			};
		case `${types.GET_FEILD_MAPPING}_FETCH`:
			return {
				...state,
				fieldMapping: {
					data: [],
					error: null,
					loading: true,
				},
			};
		case `${types.GET_FEILD_MAPPING}_SUCC`:
			return {
				...state,
				fieldMapping: {
					data: action.payload.data,
					error: null,
					loading: false,
				},
			};
		case `${types.GET_FEILD_MAPPING}_ERR`:
			return {
				...state,
				fieldMapping: {
					data: [],
					error: action.payload,
					loading: false,
				},
			};
		default:
			return state;
	}
};

export default reducer;
