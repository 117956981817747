import GenericSelect from "./GenericSelect";
import "./util.css";

function Checkbox({
	id,
	name,
	readOnly = false,
	disabled = false,
	checked,
	value = "",
	onChange,
	label = "",
}) {
	return (
		<div className="custom_checkbox">
			<input
				type="checkbox"
				id={id}
				name={name}
				readOnly={readOnly}
				disabled={disabled}
				onChange={onChange}
				checked={checked}
				value={value}
			/>
			{label ? <label htmlFor={id}>{label}</label> : null}
		</div>
	);
}
function Dropdown({
	id,
	name,
	label = "",
	onChange,
	options,
	value,
	defaultValue = null,
	required = false,
	readOnly = false,
	disabled = false,
	isMulti = false,
	config = { module: "" },
}) {
	return (
		<div className="custom-input">
			{label ? (
				<label htmlFor={id} className={required ? "star" : ""}>
					{label}
				</label>
			) : null}
			<div className="select_wrapper">
				<GenericSelect
					id={id}
					options={options}
					onChange={onChange}
					value={value}
					defaultValue={defaultValue}
					readOnly={readOnly}
					isDisabled={disabled}
					name={name}
					aria-label={label}
					isMulti={isMulti}
					extraStyles={{ height: isMulti ? "auto" : "" }}
				/>
			</div>
		</div>
	);
}

function Button({
	onClick,
	title,
	backgroundColor = "#ff7200",
	color = "#fff",
	borderColor = "#ff7200",
	borderRadius = "6px",
	className = "",
	disabled = false,
}) {
	return (
		<button
			className={`custom-button ${className}`}
			onClick={onClick}
			style={{
				border: `1px solid ${borderColor}`,
				color,
				backgroundColor,
				borderRadius,
				padding: `0.4rem 0.5rem`,
			}}
			disabled={disabled}
		>
			{title}
		</button>
	);
}

function Input({
	id,
	name,
	type = "text",
	placeholder = "",
	readOnly = false,
	disabled = false,
	label = "",
	value = "",
	checked = false,
	onChange = (e) => {},
	group = [],
	required = false,
	selectedOption = "",
	min = "",
	max = "",
	step = "",
}) {
	return (
		<div className="custom-input">
			{label ? (
				<label htmlFor={id} className={required ? "star" : ""}>
					{label}
				</label>
			) : null}
			{group && group.length ? (
				<div className="input_group_wrapper">
					{group.map(({ label, value: radioValue }, index) => {
						// const isChecked =
						// radioValue === "true" ? value === "true" : value === "false";
						const isChecked = value === radioValue;
						// console.log({label, value, radioValue, isChecked, check})
						return (
							<div key={index} className="input_group">
								<input
									className="form-control"
									id={id + label}
									type={type}
									name={name}
									// placeholder={placeholder}
									readOnly={readOnly}
									disabled={disabled}
									value={radioValue}
									onChange={onChange}
									checked={isChecked}
									min={min}
									max={max}
									step={step}
								/>
								<label htmlFor={id + label}>{label}</label>
							</div>
						);
					})}
				</div>
			) : (
				<>
					<input
						className="form-control"
						id={id}
						type={type}
						name={name}
						placeholder={placeholder}
						readOnly={readOnly}
						disabled={disabled}
						value={value}
						onChange={onChange}
					/>
				</>
			)}
		</div>
	);
}

export { Checkbox, Dropdown, Button, Input };
