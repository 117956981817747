import $ from 'jquery'

const Hubs  = () => {

    const toogleItemView = (index) =>{
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        } 
        else {
            $(".data-cont-item").eq(index).addClass("open")
        }
    }

    return(
        <div className="contentpanel">
            <h1 className="title">Hubs</h1>
            <hr/>
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            <div className='data-cont-item'>
                                <div className='sno_txt'>1.</div>
                                <div className='data_item_content set_height'>
                                    <div>
                                        <span>Hub Code</span>
                                        <span className='orange_color font-lg'>12345</span>
                                    </div>
                                    <div>
                                        <span>Hub Name</span>
                                        <span>New Delhi Hub</span>
                                    </div>
                                    <div className='wd_30'>
                                        <span>Address</span>
                                        <span className='flex_box_2'>New Delhi Office, g-89 , govind puri, New Delhi, Delhi - 110099</span>
                                    </div>
                                    <div className='wd_15'>
                                        <span>PAN</span>
                                        <span>LMKNJ9090Q</span>
                                    </div>                                                                        
                                    <div className='wd_15'>
                                        <span>GSTIN</span>
                                        <span>07LMKNJ9090Q1Z7</span>
                                    </div>                                                                        
                                </div>
                                <div className='data_item_content set_height clps'>
                                    <div>
                                        <span>Manager Name</span>
                                        <span>Test</span>
                                    </div>
                                    <div>
                                        <span>Manager Mobile</span>
                                        <span>XXXXXXXXXX</span>
                                    </div>
                                    <div>
                                        <span>Manager Email</span>
                                        <span>XXXXXXXXXX@xxx.xxx</span>
                                    </div>  
                                    <div>
                                        <span>Added On</span>
                                        <span>Oct 12th 2021 10:35 AM</span>
                                    </div>                                 
                                    <div>
                                        <span>Total Users</span>
                                        <span>-</span>
                                    </div>
                                    <div className='btn_conte'>
                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>View User's</button> 
                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>Update</button> 
                                        <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Delete</button>
                                        <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1'>View Detail</button>
                                    </div>
                                </div>
                                <div className='collps_icon' onClick={()=>{toogleItemView(0)}}>
                                    <i className='fa fa-chevron-down'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hubs;