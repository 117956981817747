import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings, updateSettings } from '../../../state/redux/attendance/action';
import { settingsSelector } from '../../../state/redux/attendance/selectors';
import { getUserTypes } from '../../../state/redux/workforce/actions';
import { userTypesSelector } from '../../../state/redux/workforce/selectors';
import './../../../static/css/workforce.css';

const SettingPanel = ()=>{
    const dispatch = useDispatch();
    const {register, setValue, handleSubmit, watch, getValues, control, formState: {errors}} = useForm();
    const { fields, append, remove } = useFieldArray({name: "holidaySlots", control})
    const selectedUserType = watch('user_type');
    const inOutTime = watch('inOutTime');
    const inTime = watch('inTime')
    const workingHours = watch('workingHours')
    // const settings = useSelector(settingsSelector)
    const userTypes = useSelector(userTypesSelector);
    const globalSettings = useSelector(settingsSelector);
    const [slots, setSlots] = useState(0);
   
    const handleUpdateSettings = (data) => {
        // Convert holidaySlots values into array
        data.holidaySlots = data.holidaySlots.map((slot, i)=>{
            return {
                user_type: slot.user_type,
                days: [parseInt(slot.days)]
            }
        })
        data.toast = true;
        dispatch(updateSettings.call(data, ()=>{
            dispatch(getSettings.call({}, (res_data) =>{
                remove();
                
                let settings = res_data.data[0];
                setValue('empIdPrefix', settings.empIdPrefix);
                setValue('workingHoursNum', settings.workingHoursNum);
                setValue('inOutTime', settings.inOutTime);
                setValue('inTime', settings.inTime);
                setValue('outTime', settings.outTime);
                
                setValue('workingHours', settings.workingHours)
                let holidaySlots = settings.holidaySlots;
                setSlots(holidaySlots.length);
                for(let i=0;i<holidaySlots.length;i++){
                    append({ user_type: holidaySlots[i].user_type, days: holidaySlots[i].day });
                }
            }))
        }))
    }
    

    const handleAddSlot = () => {
        append({ user_type: getValues().user_type, days: 0 });
        setSlots(slots+1);
    }

    const handleDeleteSlot = (i) => {
        
        remove(i);
        setSlots(slots-1);
    }

    const outAfter = (val) => {
        const [inHour, inMin] = inTime.split(":");
        const [outHour, outMin] = val.split(":");
        if(outHour > inHour){
            return true;
        }
        return false;
    }

    useEffect(()=>{
        dispatch(getSettings.call({}, (res_data) =>{
            remove();
            
            let settings = res_data.data[0];
            setValue('empIdPrefix', settings.empIdPrefix);
            setValue('workingHoursNum', settings.workingHoursNum);
            setValue('inOutTime', settings.inOutTime);
            setValue('inTime', settings.inTime);
            setValue('outTime', settings.outTime);
            
            setValue('workingHours', settings.workingHours)
            let holidaySlots = settings.holidaySlots;
            setSlots(holidaySlots.length);
            for(let i=0;i<holidaySlots.length;i++){
                append({ user_type: holidaySlots[i].user_type, days: holidaySlots[i].day });
            }
        }))
        dispatch(getUserTypes.call({}, (res_data)=>{
            
            setValue('user_type', res_data.data[0].user_type)
        }))
    }, [])
    return(
        <>
            <div className='contentpanel'>
                <div className='cust-row'>
                    <div className='cust-col-2'></div>
                    <div className='cust-col-6'>
                        <div className="card">
                            <div className="card-header">
                                <h5>Attendance Settings</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(handleUpdateSettings)}>
                                    <div className='table-responsive'>
                                        <table className='bordered_table'>
                                            <tbody>
                                                <tr>
                                                    <th colSpan={1}>
                                                        <h2 className='tht'>Employee's Holiday Slot</h2>
                                                    </th>
                                                    <th colSpan={3}>
                                                        
                                                        <div className='input-group'>
                                                            <select className='form-select' {...register('user_type')}>
                                                                {
                                                                    userTypes.map((type, i) => {
                                                                        return <option value={type.user_type} key={i}>{type.user_type}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            <a className='btn btn-success btn-sm' onClick={()=> handleAddSlot()}>Add +</a>
                                                        </div>
                                                    </th>
                                                </tr>
                                                {
                                                    fields.map((item, i) =>{
                                                        
                                                        const row = (
                                                            <div className='input-group mb-1'>
                                                                <select className='form-select' {...register(`holidaySlots.${i}.user_type`, {value: item.user_type})}>
                                                                    {
                                                                        userTypes.map((type, i) => {
                                                                            return <option value={type.user_type} key={i}>{type.user_type}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                <select className='form-select' {...register(`holidaySlots.${i}.days`, {value: item.days})}>
                                                                    <option value={0}>Sunday</option>
                                                                    <option value={1}>Monday</option>
                                                                    <option value={2}>Tuesday</option>
                                                                    <option value={3}>Wednesday</option>
                                                                    <option value={4}>Thursday</option>
                                                                    <option value={5}>Friday</option>
                                                                    <option value={6}>Saturday</option>
                                                                </select>
                                                                <a className='btn btn-danger btn-sm' onClick={()=>handleDeleteSlot(i)}>
                                                                    <i className='fa fa-trash'></i>
                                                                </a>
                                                            </div>
                                                        )
                                                        if(i == 0){
                                                            return (
                                                                <tr key={item.id}>
                                                                    <th rowSpan={slots}>
                                                                        Added Slots
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        {row}
                                                                    </th>
                                                                </tr>
                                                            )
                                                        }else{
                                                            return (
                                                                <tr key={item.id}>
                                                                    <th colSpan={3}>
                                                                        {row}
                                                                    </th>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                                
                                                
                                                <tr>
                                                    <th colSpan={1}>
                                                        <h2 className='tht'>Employee Id Prefix</h2>
                                                    </th>
                                                    <th colSpan={3}>
                                                        <div className='input-group'>
                                                            <input type="text" className="form-control" {...register('empIdPrefix',
                                                            //  { required : true }
                                                             )} />
                                                        </div>
                                                        
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={1}>
                                                        <h2 className='tht'>In & Out Time</h2>
                                                    </th>
                                                    <th colSpan={3}>
                                                        <input type="checkbox" {...register('inOutTime')}/>
                                                    </th>
                                                </tr>
                                                {
                                                    inOutTime && 
                                                    <>
                                                        <tr>
                                                            
                                                            <th colSpan={2}>
                                                                <div className='input-group input-group-sm'>
                                                                    <label className='input-group-text'>In Time</label>
                                                                    <input type="time" className="form-control" {...register('inTime')}/>
                                                                </div>
                                                            </th>
                                                            <th colSpan={2}>
                                                                <div className='input-group input-group-sm'>
                                                                    <label className='input-group-text'>Out Time</label>
                                                                    <input type="time" className="form-control" {...register('outTime', {validate: outAfter})}/>
                                                                </div>
                                                                <div className="mt-1">
                                                                        { errors.outTime && <p className='error red_color'>Out Time should be after In Time</p>}
                                                                    </div>
                                                            </th>
                                                        </tr>
                                                    </>
                                                }
                                                <tr>
                                                    <th colSpan={1}>
                                                        <h2 className='tht'>Working Hours</h2>
                                                    </th>
                                                    <th colSpan={1}>
                                                        <input type="checkbox" {...register('workingHours')}/>
                                                    </th>
                                                    {
                                                        workingHours && 
                                                        <th colSpan={2}>
                                                            <div className='input-group input-group-sm'>
                                                                <input type="number" className="form-control" {...register('workingHoursNum', { required : true })}/>
                                                                <select className='form-select' {...register('workingHoursUnit', {value: 'Hours'})}>
                                                                    <option value="Hours">Hours</option>
                                                                </select>
                                                            </div>
                                                        </th>

                                                    }
                                                    
                                                </tr>
                                                
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='text-center'>
                                        <button className='btn btn-orange'>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

 }

 export default SettingPanel;