import React,{useEffect, useState} from 'react';
import history from '../../services/history'
import client from  './../../static/images/client.png'
import sample from  './../../static/images/sample_icon.png'
import share from  './../../static/images/share_icon.png'

const TaggedImage = (props) =>{   
    const [imgIndex, setImgIndex]=useState(0) 
    const [newarray,setnewarray]=useState([])
    const [imageArray, setImageArray]=useState([
        {
            image:client,
        },
        {
            image:sample,
        
        },
        {
            image:share,
            
        }
        ]);
   
    var arrayLength=imageArray.length
    const [lat, setLat]=useState(28.5470088)
    const [lng,setLng]=useState(77.3560634)
    
    useEffect(()=>{
        setnewarray(imageArray)
    },[])
   
    const getNextImg= ()=>{
        if(imgIndex+1 < arrayLength ){
            var increment=imgIndex + 1
            setImgIndex(increment)
        }
    }
    const getPreImg=()=>{
        if(imgIndex){
            var decrement= imgIndex - 1
            setImgIndex(decrement)
        }
    }
    const toggleImage=(item,index)=>{        
        setImgIndex(index)
    }
    const openMap=()=>{
        history.push({
            pathname:'/dashboard/Map',
            state: {lat, lng, newarray}
        })
    }
    return(
        <>
        <section id="Taggedimage" className="custom-modal" style={{width:"52%"}}>

            <div className="custom-modal-header">
                <h1>Tagged Images</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>

            <div className="Ti-modal-body">
                <div className="cust-row">
                    <div className='TI-outer'>

                        <div className='TI-inner1'>
                            <span><i  className="fa fa-check" aria-hidden="true"></i>Document successfully verified with blockchain on Thursday, January 27, 2022 1:29 PM</span>
                        </div>
                        <div className='TI-inner2'>
                            <div className='left-arrow-div'>
                                <span><i onClick={getPreImg} className="fa fa-angle-double-left" id="leftArrow" aria-hidden="true"></i></span>
                            </div>
                            <div className='taggedimage-div'>
                                <img src={imageArray[imgIndex].image} alt="none" />
                            </div>
                            <div className='right-arrow-div'>
                                <span><i onClick={getNextImg} className="fa fa-angle-double-right" id="rightArrow" aria-hidden="true"></i></span>
                            </div>
                            
                        </div>
                        <div className='TI-inner4'>
                            <div className='latLngdiv'> 
                                <div className='latLng'>
                                    <span>28.5470088,77.3560634(Jan 20th 2022 7:43 PM)</span>
                                </div>
                                <div className='map'>
                                    <button onClick={openMap}>View on Map</button>
                                </div>
                                <div className='details'>
                                    <span>Loaded/ Unloaded Goods (Delivered)</span>
                                </div>
                            </div>
                        </div>
                        <div className='TI-inner3'>
                            <div className='bulletDiv'>
                                {imageArray.map((item,index)=>{return(
                                    <div key={index} onClick={()=>toggleImage(item,index)} className={imgIndex==index?"activepagination": "pagination"}></div>
                                )})}
                            </div>
                        </div>
                       


                    </div>
                </div>
            </div>

        </section>
        </>

    );
};

export default TaggedImage;