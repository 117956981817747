import React from 'react';
import './../../static/css/addressDetail.css'
import history from './../../services/history';

const AddressBookDetails = () => {
 
    const moveToAddBook = () => {
        history.push('/dashboard/address-book')
    }
    return (
        <>
            <div className="AddressDetailcontentpanel">
                <div className='addrDetailHeader'>
                    <div className='addrDetailheader1'>
                        <button onClick={moveToAddBook}><i  id="back-arrow" className="fa fa-arrow-left" aria-hidden="true"></i></button>
                        <h1 className="title">Address Detail</h1>
                    </div>

                    <div className='addrDetailheader2'>
                        <button><i id="edit-addDetailsIcon" className="fa fa-pencil" aria-hidden="true"></i></button>
                        <button><i id="trash-icon" className="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                </div>
                <hr />
                <div className='addressDetailsDiv'>

                    <div className='addrDetailTable'>
                        <div className='addressDetailsInner'>
                             <div className='addDetail2'>
                                <div className='addDetail2_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Company Name</span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>Lynkit Pvt. Ltd</span>
                                </div>
                            </div>

                            <div className='addDetail3'>
                                <div className='addDetail3_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Contact Person</span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>Ajay Kumar</span>
                                </div>
                            </div>

                            <div className='addDetail4'>
                                <div className='addDetail4_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Mobile Number</span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>0987654323</span>
                                </div>
                            </div>

                            <div className='addDetail5'>
                                <div className='addDetail5_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Email ID</span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>ajay@gmail.com</span>
                                </div>
                            </div>

                        </div>
                        <div className='centreAddeDetail'></div>
                        <div className='addressDetailsInner2'>
                        
                            <div className='addDetail6'>
                                <div className='addDetail6_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Registered Address</span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span >246, Block A, Okhla I, Okhla Industrial Estate, New Delhi, Delhi 110020</span>
                                </div>
                            </div>

                            <div className='addDetail7'>
                                <div className='addDetail7_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Pan Number</span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>HFD4326G</span>
                                </div>
                            </div>

                            <div className='addDetail8'>
                                <div className='addDetail8_1'>
                                    <div className='addHeadingdiv'>
                                        <span>GSTTIN Number </span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>HBVJJGGJ5454</span>
                                </div>
                            </div>

                            <div className='addDetail9'>
                                <div className='addDetail9_1'>
                                    <div className='addHeadingdiv'>
                                        <span>Address Type </span>
                                    </div>
                                </div>

                                <div className='addDetailinner2'>
                                    <span>Business Address</span>

                                </div>
                            </div> 
                        </div>
                    </div>




                </div>
            </div>
        </>
    )
}

export default AddressBookDetails;