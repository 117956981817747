/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger'
import rootReducer from './rootReducer';

const getStore = function (initialState){
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunkMiddleware),
  );
}

export default getStore;
