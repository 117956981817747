const Tracking = (props) =>{
    return(
        <section id="trackingModal" className="custom-modal" style={{width:"50%"}}>
            <div className="custom-modal-header">
            <h1>Tracking</h1>
            <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'80vh'}}>
                <div className="cust-row">
                    <div className='cust-col-10'>
                    <div className="contentmo">
                      <p className="tt-title">Tracking Id : <label>7845362511</label></p>
                      <div className="scrollmo">
                        <table className="tt-cust">
                          <tbody>
                            <tr className="bt">
                              <td width="30%">
                                  <div>
                                      <span>13th January 2022<br/>04:44 PM</span> 
                                  </div>
                              </td>
                              <td width="10%">
                                  <div className="midd">
                                      <div className="circle"></div>
                                  </div>
                              </td>
                              <td id="pl" width="60%">
                                  <div>
                                      <strong>Created</strong><br/>
                                      <div>NOISE- site 2 , Ludhiana, Punjab - 233434 To Express Site Hub 1 - Epress logistics, site 1, Tumkur, Karnataka - 098980</div>
                                      <strong>Vehicle No : </strong>DL9CD0002
                                  </div>
                              </td>
                            </tr>
                            <tr className="bt">
                                <td width="30%">
                                    <div>
                                        <span>13th January 2022 <br/>04:49 PM</span>
                                    </div>
                                </td>
                                <td width="10%">
                                    <div className="midd">
                                        <div className="circle"></div>
                                    </div>
                                </td>
                                <td id="pl" width="60%">
                                    <div>
                                        <strong>Out for Pickup</strong><br/>
                                        <div>NOISE- site 2 , Ludhiana, Punjab - 233434</div>
                                        <strong>Vehicle No : </strong>DL9CD0002 
                                    </div>
                                </td>
                            </tr>
                            <tr className="bt">
                                <td width="30%">
                                    <div>
                                        <span>13th January 2022 <br/>04:49 PM</span>
                                    </div>
                                </td>
                                <td width="10%">
                                    <div className="midd">
                                        <div className="circle"></div>
                                    </div>
                                </td>
                                <td id="pl" width="60%">
                                    <div>
                                        <strong>Arrived at Pickup location</strong><br/>
                                        <div>NOISE- site 2 , Ludhiana, Punjab - 233434</div>
                                        <strong>Vehicle No : </strong>DL9CD0002 
                                    </div>
                                </td>
                            </tr>
                            <tr className="bt">
                                <td width="30%">
                                    <div>
                                        <span>13th January 2022 <br/>04:51 PM</span>
                                    </div>
                                </td>
                                <td width="10%">
                                    <div className="midd">
                                        <div className="circle"></div>
                                    </div>
                                </td>
                                <td id="pl" width="60%">
                                    <div>
                                        <strong>Out for Dropoff</strong><br/>
                                        <div>Express Site Hub 1 - Epress logistics, site 1, Tumkur, Karnataka - 098980</div>
                                        <strong>Vehicle No : </strong>DL9CD0002 
                                    </div>
                                </td>
                            </tr>
                            <tr className="bt">
                                <td width="30%">
                                    <div>
                                        <span>13th January 2022 <br/>04:55 PM</span>
                                    </div>
                                </td>
                                <td width="10%">
                                    <div className="midd">
                                        <div className="circle"></div>
                                    </div>
                                </td>
                                <td id="pl" width="60%">
                                    <div>
                                        <strong>Arrived at Dropoff location</strong><br/>
                                        <div>Express Site Hub 1 - Epress logistics, site 1, Tumkur, Karnataka - 098980</div>
                                        <strong>Vehicle No : </strong>DL9CD0002 
                                    </div>
                                </td>
                            </tr>
                            <tr className="bt greenmm">
                                <td width="30%">
                                    <div>
                                        <span>13th January 2022 <br/>05:15 PM</span>
                                    </div>
                                </td>
                                <td width="10%">
                                    <div className="midd">
                                        <div className="circle"></div>
                                    </div>
                                </td>
                                <td id="pl" width="60%">
                                    <div>
                                        <strong>Goods Delivered</strong><br/>
                                        <div>Express Site Hub 1 - Epress logistics, site 1, Tumkur, Karnataka - 098980</div>
                                        <strong>Vehicle No : </strong>DL9CD0002 
                                    </div>
                                </td>
                            </tr>
                          </tbody>       
                        </table>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tracking