import { usePagination, DOTS } from "../../services/functions";
import './../../static/css/pagination.scss'

const Pagination = ({
    onPageChange,
    totalCount,
    sibdivngCount = 1,
    currentPage,
    pageSize,
}) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        sibdivngCount,
        pageSize,
    });


    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };
    const firstPage = () => {
        onPageChange(1);
    }
    const lastPageFunc = () => {
        onPageChange(lastPage);
    }


    let lastPage = paginationRange[paginationRange.length - 1];
    let pItemClass = "";

    return (
        <div className="pagination-container">
            <div className="prev">
                <div className="arrow left" onClick={firstPage} />
                <div className="arrow left" onClick={firstPage} />
            </div>
            <div
                className={`prev ${currentPage === 1 && "disabled"}`}
                onClick={onPrevious}
            >
                Previous
            </div>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return (
                        <div className="pagination-item dots" key={index}>
                            &#8230;
                        </div>
                    );
                }
                pItemClass = pageNumber === currentPage ? "selected" : "";
                return (
                    <div
                        className={`pagination-item ${pItemClass} `}
                        onClick={() => onPageChange(pageNumber)}
                        key={index}
                    >
                        {pageNumber}
                    </div>
                );
            })}
            <div
                className={`next ${currentPage === lastPage && "disabled"}`}
                onClick={onNext}
            >
                Next
            </div>
            <div className="next">
                <div className="arrow right"  onClick={lastPageFunc} />
                <div className="arrow right"  onClick={lastPageFunc} />
            </div>
        </div>
    );
};

export default Pagination;