import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  var signed = false;
  const localToken = localStorage.getItem('secretkey');
  const pathname = rest.location.pathname;
  const urlParams = queryString.parse(rest.location.search);

  if(urlParams.token && urlParams.token.length > 0){
    console.log(urlParams);
    localStorage.setItem('secretkey', urlParams.token);
    signed = true;
  }
  else if(localStorage.getItem('secretkey')){
    signed=true
  }
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return <Redirect to={`/?redirect=${pathname}`} />;
  }
  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (!isPrivate && signed) {
    return <Redirect to="/dashboard" />;
  }
  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return <Route {...rest} component={Component} />;
}
RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
};