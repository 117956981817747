import React, { useEffect, useState } from 'react';
import './../../../static/css/workforce.css';
import { useSelector,useDispatch } from 'react-redux';
import { departmentListSelector } from '../../../state/redux/department/selectors';
import $ from 'jquery'
import { addDepartment, callAddDepartmentApi, callGetDepartmentsApi, getDepartments, deleteDepartment, updateDepartment } from '../../../state/redux/department/actions';
import UpdateDepartment from "../Modal/UpdateDepartment";
import Confirm from "../Modal/Confirm";
import { useForm } from 'react-hook-form';
import {toast} from 'react-toastify'


const DepartmentInfo = ()=>{
    const dispatch = useDispatch();
    const {department} = useSelector(departmentListSelector);
    const { register, handleSubmit, getValues, reset, formState: {errors} } = useForm();

    const [deleteDepartmentId, setDeleteDepartmentId] = useState(null);
    const [updateDepartmentObj, setUpdateDepartmentObj] = useState(null);

    /* Functions for Modal Open & Close */
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setUpdateDepartmentObj(null);
    }

    const handleDeleteDepartment = (id, modalId) => {
        setDeleteDepartmentId(id);
        _handleClick(modalId);
    }

    const confirmDeleteDepartment = () => {
        console.log(deleteDepartmentId);
        dispatch(deleteDepartment.call({department_id: deleteDepartmentId,toast: true}, (res_data) => {
            dispatch(getDepartments.call({}, ()=>{
                setDeleteDepartmentId(null);
            }))
        }))
    }

    const handleDeleteDepartmentToast = (id) => {
        const Confirm = ({ closeToast, toastProps }) => {
          return (
            <div className="confirmation-toast">
              Are you sure you want to delete this department?
              <button
                className="confirmation-toast-btn toast-confirm"
                onClick={() => {
                    dispatch(deleteDepartment.call({department_id: id,toast: true}, (res_data) => {
                        dispatch(getDepartments.call({}, ()=>{
                            setDeleteDepartmentId(null);
                        }))
                    }))
                  closeToast();
                }}
              >
                Yes, delete it.
              </button>
              <button
                onClick={closeToast}
                className="confirmation-toast-btn toast-cancel"
              >
                No, go back.
              </button>
            </div>
          );
        };
        toast.warn(Confirm);
    };

    const handleUpdateDepartment = (obj) => {
        setUpdateDepartmentObj(obj)
        _handleClick('updateDepartmentModal')
    }

    
    const _updateDepartment = (data) => {
        data.toast = true;
        dispatch(updateDepartment.call(data, (res)=>{
            dispatch(getDepartments.call({}));
        }))
    }


    const handleAddDepartment = (data)=>{
        data.toast = true;
        dispatch(addDepartment.call(data, ()=>{
            dispatch(getDepartments.call({}));
            reset();
        }));
    }

    useEffect(()=>{
        dispatch(getDepartments.call({}));
    },[])

    return(
        <>
            <div className='contentpanel'>
                <div className='cust-row'>
                    <div className='cust-col-6'>
                        <h5>Departments</h5>
                        <hr/>
                        <UpdateDepartment closeModal={_closeModal} updateDepartment={_updateDepartment} departmentObj={updateDepartmentObj}/>
                        <Confirm id='deleteDepartmentConfirmModal' closeModal={_closeModal} confirm={confirmDeleteDepartment} />
                        <table className='workforce-table'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Department Name</th>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    department.map((d,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{d.department?d.department:"-"}</td>
                                                <td>{d.departmentCode?d.departmentCode:"-"}</td>
                                                <td>{d.description?d.description:"-"}</td>
                                                <td>
                                                    <>
                                                        <button className='btn-outline-orange' onClick={()=>handleDeleteDepartmentToast(d._id)}>
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                        <button className='btn-outline-orange ms-2' onClick={()=>handleUpdateDepartment(d)}>
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                    </>
                                                    
                                                </td>
                                                

                                            </tr>
                                        )

                                    })
                                }
                                
                            </tbody>
                        </table>

                    </div>
                    <div className='cust-col-4'>
                        <div className="card">
                            <div className="card-header">
                                <h5>Add Department</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(handleAddDepartment)}>
                                    <div className='cust-row'>
                                        <div className='cust-col-4'>
                                                <label>Department Name <span className="red_color">*</span></label>
                                        </div>
                                        <div className='cust-col-6'>
                        
                                            <input type="text" className='form-control' placeholder='Enter Department Name'  {...register('department', { required : true})} />
                                            <div className="mt-1">
                                                { errors.department && <p className='error red_color'>Department Name is required</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='cust-row'>
                                        <div className='cust-col-4'>
                                            <label>Department Code</label>
                                        </div>
                                        <div className='cust-col-6'>
                                            <input type='text' className='form-control' placeholder='Enter Department Code' {...register('departmentCode')}/>
                                            
                                        </div>
                                    </div>
                                    <div className='cust-row'>
                                        <div className='cust-col-4'>
                                            <label>Department Description </label>
                                        </div>
                                        <div className='cust-col-6'>
                                            <input type='text' className='form-control' placeholder='Enter Department Description'{...register('description')}/>
                                            
                                        </div>
                                    </div>
                                    <div className='cust-row'>
                                        <div className='cust-col-10 text-center'>
                                            <button className='btn btn-orange btn-sm'>Add</button>
                                        </div>
                                        
                                    </div>
                                </form>
                                    
                                        
                            </div>
                        </div>
                        
                        

                    </div>
                    

                </div>

            </div>
           
        </>
    )

}

export default DepartmentInfo;
