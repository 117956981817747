import React,{Component} from 'react'
import {Link} from 'react-router-dom';
import history from './../../services/history';

//InActive
import DashboardIcon from './../../static/images/icon/sidebar/updated/dashboard.svg'
import BookingIcon from './../../static/images/icon/sidebar/updated/booking.svg'
import VehicleIcon from './../../static/images/icon/sidebar/updated/vehicles.svg'
import ReceiptIcon from './../../static/images/icon/sidebar/updated/goods_receipt.svg'
import AddressIcon from './../../static/images/icon/sidebar/updated/address_book.svg'
import HubIcon from './../../static/images/icon/sidebar/updated/hubs.svg'
import DriverIcon from './../../static/images/icon/sidebar/updated/driver.svg'
import PoDIcon from './../../static/images/icon/sidebar/updated/pod.svg'
import AnalyticIcon from './../../static/images/icon/sidebar/updated/analytics.svg'
import ContractIcon from './../../static/images/icon/sidebar/updated/contracts.svg'

//Active
import DashboardActiveIcon from './../../static/images/icon/sidebar/updated/dashboard_active.svg'
import BookingActiveIcon from './../../static/images/icon/sidebar/updated/booking_active.svg'
import VehicleActiveIcon from './../../static/images/icon/sidebar/updated/vehicles_active.svg'
import ReceiptActiveIcon from './../../static/images/icon/sidebar/updated/goods_receipt_active.svg'
import AddressActiveIcon from './../../static/images/icon/sidebar/updated/address_book_active.svg'
import HubActiveIcon from './../../static/images/icon/sidebar/updated/hubs_active.svg'
import DriverActiveIcon from './../../static/images/icon/sidebar/updated/driver_active.svg'
import PoDActiveIcon from './../../static/images/icon/sidebar/updated/pod_active.svg'
import AnalyticActiveIcon from './../../static/images/icon/sidebar/updated/analytics_active.svg'
import ContractActiveIcon from './../../static/images/icon/sidebar/updated/contracts_active.svg'

import AttendanceActiveIcon from './../../static/images/icon/workforce/Attendance.svg'
import DepartmentActiveIcon from './../../static/images/icon/workforce/Department.svg'
import LeavesActiveIcon from './../../static/images/icon/workforce/Leaves.svg'
import WorkforceActiveIcon from './../../static/images/icon/workforce/Workforce.svg'
import SettingsIcon from './../../static/images/icon/workforce/setting.svg'
import HolidayIcon from './../../static/images/icon/workforce/holiday.svg'
// import {ReactComponent as ReportIcon} from './../../static/images/report.svg'
import ReportIcon from './../../static/images/report.svg'


class Sidebar extends Component{
    constructor(props){
        super(props)
        this.state={
            url:`${props.location.pathname}${props.location.search}${props.location.hash}`
        }
    }

    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            this.setState({
                url:`${location.pathname}${location.search}${location.hash}`
            })
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }  

    render() {
      return (
          <div className="sidebar open">
              <div className="tab-content">
                  <div className="tab-pane" id="mainmenu">
                      <ul className="menu-nav">
                        <li className={this.state.url.includes("/dashboard/workforce")?" active":""}>
                                <Link to="/dashboard/workforce">
                                    <img className='sicon' src={this.state.url.includes("/dashboard/workforce")?WorkforceActiveIcon:WorkforceActiveIcon} alt=''/>
                                    <span className='txt'>Workforce</span>
                                </Link>
                                {/* <ul className="children">
                                    <li className={this.state.url==="/dashboard/workforce"?"active":""}>
                                        <Link to="/dashboard/workforce">All Workforce</Link>
                                    </li>
                                    {/* <li className={this.state.url.includes("/dashboard/workforce/user-add")?"active":""}>
                                        <Link to="/dashboard/workforce/user-add">Add User</Link>
                                    </li> */}
                                {/* </ul> */} 
                        </li>
                        <li className={this.state.url.includes("/dashboard/attendance")?"nav-parent active":"nav-parent"}>
                                <Link to="/dashboard/attendance">
                                    <img className='sicon' src={this.state.url.includes("/dashboard/booking")?AttendanceActiveIcon:AttendanceActiveIcon} alt=''/>
                                    <span className='txt'>Attendance</span>
                                </Link>
                                 <ul className="children">
                                    <li className={this.state.url==="/dashboard/attendance/view"?"active":""}>
                                        <Link to="/dashboard/attendance/view">Dashboard</Link>
                                    </li>
                                {/*    <li className={this.state.url==="/dashboard/attendance/holidays"?"active":""}>
                                        <Link to="/dashboard/attendance/holidays">Holidays</Link>
                                    </li>
                                    <li className={this.state.url==="/dashboard/attendance/addholiday"?"active":""}>
                                        <Link to="/dashboard/attendance/AddHoliday"> add Holidays</Link>
                                    </li>
                                    <li className={this.state.url==="/dashboard/attendance/settings"?"active":""}>
                                        <Link to="/dashboard/attendance/settings">Settings</Link>
                                    </li>*/}
                                </ul> 
                        </li>
                        <li className={this.state.url.includes("/dashboard/leaves")?"nav-parent active":"nav-parent"}>
                            <Link to="/dashboard/leaves/all">
                                <img className='sicon' src={this.state.url.includes("/dashboard/booking")?LeavesActiveIcon:LeavesActiveIcon} alt=''/>
                                <span className='txt'>Leaves</span>
                            </Link>
                            <ul className='children'>
                                <li className={this.state.url==="/dashboard/leaves/all"?"active":""}>
                                    <Link to="/dashboard/leaves/all">All Leaves</Link>
                                </li>
                                <li className={this.state.url==="/dashboard/leaves/categories"?"active":""}>
                                    <Link to="/dashboard/leaves/categories">Categories</Link>
                                </li>
                            </ul>

                        </li>
                        <li className={this.state.url.includes("/dashboard/departments")?"active":""}>
                            <Link to="/dashboard/departments">
                                <img className='sicon' src={this.state.url.includes("/dashboard/booking")?DepartmentActiveIcon:DepartmentActiveIcon} alt=''/>
                                <span className='txt'>Department</span>
                            </Link>

                        </li>
                        <li className={this.state.url.includes("/dashboard/settings")?"active":""}>
                            <Link to="/dashboard/settings">
                            <img className='sicon' src={this.state.url==="/dashboard"?SettingsIcon:SettingsIcon} alt=''/>
                            <span className='txt'>Settings</span>
                            </Link>
                        </li>
                        <li className={this.state.url.includes("/dashboard/holidays")?"active":""}>
                            <Link to="/dashboard/holidays">
                            <img className='sicon' src={this.state.url==="/dashboard"?HolidayIcon:HolidayIcon} alt=''/>
                            <span className='txt'>Holidays</span>
                            </Link>
                        </li>
                        <li className={this.state.url.includes("/dashboard/reports")?"active":""}>
                            <Link to="/dashboard/reports">
                            <img className='sicon' src={this.state.url==="/dashboard"?ReportIcon:ReportIcon} alt=''/>
                            <span className='txt'>Reports</span>
                            </Link>
                        </li>
                    </ul>
                  </div>
              </div>
          </div>
      )
    }
}

export default Sidebar;