import Select from "react-select";

const GenericSelect = ({
	isBorder = true,
	isCenter = false,
	readOnly = false,
	extraStyles = { height: "", borderRadius: "", border: "" },
	field = {},
	...rest
}) => {
	return (
		<Select
			isSearchable={readOnly ? false : true}
			maxMenuHeight={180}
			menuIsOpen={readOnly ? false : undefined}
			styles={genericSelectStyles({
				isBorder,
				isCenter,
				readOnly,
				extraStyles,
			})}
			{...field}
			{...rest}
		/>
	);
};

export default GenericSelect;

function genericSelectStyles({ isBorder, isCenter, readOnly, extraStyles }) {
	const { height, borderRadius, border } = extraStyles;
	// console.log(extraStyles);
	return {
		control: (styles, state) => {
			return {
				...styles,
				borderRadius: borderRadius || "0",
				border: border ? border : isBorder ? "1px solid #888" : "none",
				borderBottom: border ? border : "1px solid #888",
				cursor: "pointer",
				height: height ? height : "40px",
				minHeight: "32px",
				textAlign: isCenter ? "center" : "left",
				// fontSize: "var(--project-dropdown-placeholder-font-size)",
				// below two get applied when we click on the main control
				boxShadow: "none",
				borderColor: "#888",
				backgroundColor: readOnly
					? "rgba(239, 239, 239, 0.3)"
					: "white",
				":selected": {
					...styles[":focus"],
					borderColor: "#888",
					boxShadow: "none",
				},
				":active": {
					...styles[":active"],
					borderColor: "#888",
					boxShadow: "none",
				},
				":hover": {
					...styles[":hover"],
					borderColor: "#888",
					boxShadow: "none",
				},
			};
		},
		// menu: (styles, state)=>{
		//   return {...styles, zIndex: 9999}
		// },
		option: (styles, { isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				zIndex: "99999",
				fontSize: "1rem",
				backgroundColor: isDisabled
					? "#d7d7d7"
					: isSelected
					? "#ff7200"
					: isFocused
					? "#ff7200"
					: "#fff",
				color: isDisabled
					? undefined
					: isSelected
					? "#fff"
					: isFocused
					? "#fff"
					: "#000",
				cursor: isDisabled ? "not-allowed" : "default",
				":hover": {
					...styles[":hover"],
					color: isDisabled ? undefined : "#fff",
				},
				":active": {
					...styles[":active"],
					backgroundColor: !isDisabled
						? isSelected
							? "#ff7200"
							: undefined
						: undefined,
				},
			};
		},
		valueContainer: (provided, state) => ({
			...provided,
			minHeight: "32px",
			height: height ? height : "40px",
			padding: "0 6px",
			// color: "#888",
		}),
		placeholder: (provided, state) => ({
			...provided,
			// fontSize: "var(--project-input-placeholder-font-size)",
		}),
		input: (provided, state) => ({
			...provided,
			margin: "-2px",
			// margin: "0px",
			// color: "#888",
		}),
		// indicatorSeparator: state => ({
		//   // display: 'none',
		// }),
		indicatorsContainer: (provided, state) => ({
			...provided,
			minHeight: "32px",
			height: height ? height : "40px",
		}),
	};
}
