import types from './types'
import axios from 'axios'
import JointActionDispatcher from '../../../services/jointActionDispatcher'

const namespace = "workforce";

// const domain = 'http://d8gnu1rryevl2.cloudfront.net'
// const domain = 'https://attendance.lynkit.io'
// const domain = process.env.REACT_APP_HOSTNAME
// const domain = "https://beta.attendance.lynkit.io";
const domain = process.env.REACT_APP_APIHOST || "";


export const getUsersAction = {
    url: `/api/user/get_users`,
    action: `${namespace}/GET_USERS`,
    __proto__: JointActionDispatcher
}

export const getUserDetail = {
    url: `/api/user/get_user_detail`,
    action: types.GET_USER_DETAIL,
    __proto__: JointActionDispatcher
}

export const getUserTypes = {
    url: `/api/user/get_user_types`,
    action: types.GET_USER_TYPES,
    __proto__: JointActionDispatcher
}

export const addUserAction = {
    url: `/api/user/add_user`,
    action: types.ADD_USER,
    __proto__: JointActionDispatcher
}


// action for get users list
export const getUsersFetch = () => {
    return {
        type: types.GET_USERS_FETCH
    }
}
export const getUsersSucc = (data) => {
    return {
        type: types.GET_USERS_SUCC,
        payload: data
    }
}
export const getUsersErr = (error) => {
    return {
        type: types.GET_USERS_ERR,
        payload: error
    }
}
export const callGetUsersApi = (body) => {
    return (dispatch) => {
        dispatch(getUsersFetch());
        axios.post(domain + '/api/user/get_users', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            if (response.data.error) {
                dispatch(getUsersErr(response.data.message));
            }
            else {
                dispatch(getUsersSucc(response.data));
            }
        }).catch((error) => {
            dispatch(getUsersErr(error.message));
        })
    }
}


// action for add a user


export const addUserFetch = () => {
    return {
        type: types.ADD_USER_FETCH
    }
}
export const addUserSucc = (data) => {
    return {
        type: types.ADD_USER_SUCC,
        payload: data
    }
}
export const addUserErr = (error) => {
    return {
        type: types.ADD_USER_ERR,
        payload: error

    }
}
export const callAddUserApi = (body) => {
    return (dispatch) => {

        dispatch(addUserFetch());
        axios.post(domain + '/api/user/add_user', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {

            dispatch(addUserSucc(response.data));
        }).catch((error) => {

            dispatch(addUserErr(error.message));
        })
    }
}

export const deleteUserFetch = () => {
    return {
        type: types.DELETE_USER_FETCH
    }
}
export const deleteUserSucc = (data) => {
    return {
        type: types.DELETE_USER_SUCC,
        payload: data
    }
}
export const deleteUserErr = (error) => {
    return {
        type: types.DELETE_USER_ERR,
        payload: error

    }
}
export const callDeleteUserApi = (body, cb = null) => {
    return (dispatch) => {
        dispatch(deleteUserFetch());
        axios.post(domain + '/api/user/delete_user', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            dispatch(deleteUserSucc(response.data));
            dispatch(callGetUsersApi({}))
            cb();
        }).catch((error) => {
            dispatch(deleteUserErr(error.message));
        })
    }
}

export const updateUserAction = {
    url: `/api/user/update_user`,
    action: types.UPDATE_USER,
    __proto__: JointActionDispatcher
}

export const deleteUserAction = {
    url: `/api/user/delete_user`,
    action: types.DELETE_USER,
    __proto__: JointActionDispatcher
}

export const updateUserFetch = () => {
    return {
        type: types.UPDATE_USER_FETCH
    }
}
export const updateUserSucc = (data) => {
    return {
        type: types.UPDATE_USER_SUCC,
        payload: data
    }
}
export const updateUserErr = (error) => {
    return {
        type: types.UPDATE_USER_ERR,
        payload: error

    }
}
export const callUpdateUserApi = (body, next = null) => {
    return (dispatch) => {

        dispatch(updateUserFetch());
        axios.post(domain + '/api/user/update_user', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {

            if (response.data.error) {

            } else {
                dispatch(updateUserSucc(response.data));
                dispatch(callGetUsersApi({
                    limit: body.limit,
                    page_no: body.page_no
                }))
                if (next) {
                    dispatch(next({
                        userId: body.userId
                    }));
                }
            }

        }).catch((error) => {
            console.log(error);
            dispatch(updateUserErr(error.message));
        })
    }
}

//update user status
export const updateUserStatus = {
    url: `/api/user/change_user_status`,
    action: types.UPDATE_USER_STATUS,
    __proto__: JointActionDispatcher
}
// export const updateUserStatusSucc = (data) => {
//     return {
//         type: types.UPDATE_USER_STATUS_SUCC,
//         payload: data
//     }
// }

// export const callUpdateUserStatusApi = (body, next = null) => {
//     return (dispatch) => {

//         // dispatch(updateUserFetch());
//         axios.post(domain + '/api/user/update_user_status', body, {
//             headers: {
//                 'accesskey': localStorage.getItem('secretkey')
//             }
//         }).then((response) => {

//             if (response.data.error) {

//             } else {
//                 dispatch(updateUserStatusSucc(response.data));
//             }

//         }).catch((error) => {
//             console.log(error);
//             // dispatch(updateUserErr(error.message));
//         })
//     }
// }

// get user types
export const getUserTypesFetch = () => {
    return {
        type: types.GET_USER_TYPES_FETCH,

    }
}

export const getUserTypesSucc = (data) => {
    return {
        type: types.GET_USER_TYPES_SUCC,
        payload: data
    }

}
export const getUserTypesErr = (error) => {
    return {
        type: types.GET_USER_TYPES_ERR,
        payload: error
    }
}
export const callGetUserTypesApi = (body) => {
    return (dispatch) => {
        dispatch(getUserTypesFetch());
        axios.post(domain + '/api/user/get_user_types', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            dispatch(getUserTypesSucc(response.data));
        }).catch((error) => {
            dispatch(getUserTypesErr(error.message));
        })
    }
}

// add a user type
export const addUserTypesFetch = () => {
    return {
        type: types.ADD_USER_TYPES_FETCH,

    }
}

export const addUserTypesSucc = (data) => {
    return {
        type: types.ADD_USER_TYPES_SUCC,
        payload: data
    }

}
export const addUserTypesErr = (error) => {
    return {
        type: types.ADD_USER_TYPES_ERR,
        payload: error
    }
}
export const callAddUserTypesApi = (body) => {
    return (dispatch) => {
        dispatch(addUserTypesFetch());
        axios.post(domain + '/api/user/add_user_type', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            dispatch(addUserTypesSucc(response.data));
            dispatch(callGetUserTypesApi({}));
        }).catch((error) => {

            dispatch(addUserTypesErr(error.message));
        })
    }
}



// action for user details

export const getUserDetailFetch = () => {
    return {
        type: types.GET_USER_DETAIL_FETCH,

    }
}

export const getUserDetailSucc = (data) => {
    return {
        type: types.GET_USER_DETAIL_SUCC,
        payload: data
    }

}
export const getUserDetailErr = (error) => {
    return {
        type: types.GET_USER_DETAIL_ERR,
        payload: error
    }
}
export const callGetUserDetailApi = (body) => {
    return (dispatch) => {
        dispatch(getUserDetailFetch());
        axios.post(domain + '/api/user/get_user_detail', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            dispatch(getUserDetailSucc(response.data));
        }).catch((error) => {
            dispatch(getUserDetailErr(error));
        })
    }
}
// action for get bank
export const getBankFetch = () => {
    return {
        type: types.GET_BANK_FETCH
    }
}
export const getBankSucc = (data) => {
    return {
        type: types.GET_BANK_SUCC,
        payload: data
    }
}
export const getBankErr = (error) => {
    return {
        type: types.GET_BANK_ERR,
        payload: error

    }
}

export const callGetBankApi = (body) => {
    return (dispatch) => {
        dispatch(getBankFetch());
        axios.post(domain + '/api/user/get_bank_details', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            if (response.data.error) {
                dispatch(getBankErr(response.data.message));
            } else {
                dispatch(getBankSucc(response.data));
            }

        }).catch((error) => {
            dispatch(getBankErr(error.message));
        })
    }
}

//  action for add bank

export const getBankAction = {
    url: `/api/user/get_bank_details`,
    action: types.GET_BANK,
    __proto__: JointActionDispatcher
}


export const addBankFetch = () => {
    return {
        type: types.ADD_BANK_FETCH
    }
}
export const addBankSucc = (data) => {
    return {
        type: types.ADD_BANK_SUCC,
        payload: data
    }
}
export const addBankErr = (error) => {
    return {
        type: types.ADD_BANK_ERR,
        payload: error

    }
}
export const callAddBankApi = (body) => {
    return (dispatch) => {

        dispatch(addBankFetch());
        axios.post(domain + '/api/user/add_bank_details', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {

            if (response.data.error) {
                dispatch(addBankErr(response.data.message));
            } else {
                dispatch(addBankSucc(response.data));
                dispatch(callGetBankApi(body))
            }
        }).catch((error) => {
            dispatch(addBankErr(error.message));
        })
    }
}

export const addBankAction = {
    url: `/api/user/add_bank_details`,
    action: types.ADD_BANK,
    __proto__: JointActionDispatcher
}



//Documents

// get document

export const getDocumentsFetch = () => {
    return {
        type: types.GET_DOCUMENTS_FETCH
    }
}
export const getDocumentsSucc = (data) => {
    return {
        type: types.GET_DOCUMENTS_SUCC,
        payload: data
    }
}
export const getDocumentsErr = (error) => {
    return {
        type: types.GET_DOCUMENTS_ERR,
        payload: error

    }
}
export const callGetDocumentsApi = (body) => {
    return (dispatch) => {
        dispatch(getDocumentsFetch());
        axios.post(domain + '/api/user/get_documents', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            if (response.data.error) {
                dispatch(getDocumentsErr(response.data.message));
            } else {
                dispatch(getDocumentsSucc(response.data));
            }

        }).catch((error) => {
            dispatch(getDocumentsErr(error.message));
        })
    }
}

export const getDocumentAction = {
    url: `/api/user/get_documents`,
    action: types.GET_DOCUMENTS,
    __proto__: JointActionDispatcher
}
//action for add documents

export const addDocumentFetch = () => {
    return {
        type: types.ADD_DOCUMENT_FETCH
    }
}
export const addDocumentSucc = (data) => {
    return {
        type: types.ADD_DOCUMENT_SUCC,
        payload: data
    }
}
export const addDocumentErr = (error) => {
    return {
        type: types.ADD_DOCUMENT_ERR,
        payload: error

    }
}
export const callAddDocumentApi = (body) => {
    return (dispatch) => {
        dispatch(addDocumentFetch());
        axios.post(domain + '/api/user/add_document', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            if (response.data.error) {

            } else {
                dispatch(addDocumentSucc(response.data));
                dispatch(callGetDocumentsApi(body))
            }

        }).catch((error) => {
            dispatch(addDocumentErr(error.message));
        })
    }
}

export const addDocumentAction = {
    url: `/api/user/add_document`,
    action: types.ADD_DOCUMENT,
    __proto__: JointActionDispatcher
}

//update document

export const updateDocumentFetch = () => {
    return {
        type: types.UPDATE_DOCUMENT_FETCH
    }
}
export const updateDocumentSucc = (data) => {
    return {
        type: types.UPDATE_DOCUMENT_SUCC,
        payload: data
    }
}
export const updateDocumentErr = (error) => {
    return {
        type: types.UPDATE_DOCUMENT_ERR,
        payload: error

    }
}
export const callUpdateDocumentApi = (body) => {
    return (dispatch) => {

        dispatch(updateDocumentFetch());
        axios.post(domain + '/api/user/update_document', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(updateDocumentSucc(response.data));
        }).catch((error) => {
            dispatch(updateDocumentErr(error.message));
        })
    }
}











