import React from 'react';
import TaggedImage from './../Dashboard/TaggedImage'
import $ from 'jquery'
import './../../static/css/Marker.css'
import {  useLocation } from "react-router-dom";


const Markers = (props) => {
  const location=useLocation()
  const getLocation=location.pathname
  
  const taggedImagesArray=props.icon
  const Lengthofarray=taggedImagesArray.length

  const _handleClick = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible")
    $(`#${id}`).addClass("custom-modal-is-visible")

    if(getLocation==='/dashboard/Map'){ 
      $('.TI-outer').addClass('map-TI-outer').removeClass('TI-outer');
      $('.TI-inner4').addClass('hide').removeClass('TI-inner4');  
      $('.TI-inner3').addClass('TImap-inner3').removeClass('TI-inner3');  
    }
    else{
      console.log("no");
    }
}
const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
    $(".custom-modal").removeClass("custom-modal-is-visible")
   
}
  
    return (
      <>
        {Lengthofarray !== 0 ? (
          <div className="customMarker">
            <img src={taggedImagesArray[0].image} alt="none" onClick={()=>{_handleClick('Taggedimage')}}/>
            <span>{Lengthofarray}</span>
        </div>
        ):
        <div className="customMarker1">
            <div></div>
        </div>
        }
        <TaggedImage closeModal={_closeModal}/>
      </>
    );
  };
  export default Markers;