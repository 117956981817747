import React,{useState} from 'react'
import moment from 'moment';
import { getAttendance, updateAttendance } from '../../state/redux/attendance/action';
import { useDispatch } from 'react-redux';
import MarkAttendance from '../Dashboard/Modal/MarkAttendance';
import Pagination from './Pagination';

export default function GeneralAttendanceTable({textHeaders, dateHeaders, rows, _showModal, _closeModal, refreshPageContent,totalCount,currentPage,pageSize,setPage}) {
    const dispatch = useDispatch();

    const [user, setUser] = useState(null);
    const [date, setDate] = useState(null);
    const [status, setStatus] = useState(null);
    // const {leaves} = useSelector(attendanceListSelector);

    const handleCellClick = (_id, d,read,s) => {
        
        dispatch(
            getAttendance.call({
                firstDay: d.clone().startOf('month').format("MM-DD-YYYY"),
                lastDay: d.clone().endOf('month').format("MM-DD-YYYY"),
                searchBy:"empId",
                search:read
                // search:"101"

            }, (res_data) => {
                setStatus(s)
                setDate(d);
                setUser(res_data.data.filter(obj => obj._id == _id)[0]);
                _showModal('markAttendanceModal');
            })
        )
    }
    
    const markAttendance = (attendanceObj) => {
        
        attendanceObj.toast = true;
        dispatch(updateAttendance.call(attendanceObj, (res)=>{
            refreshPageContent();
        }))
    }

    const handlePageChange = (newPage) => {
        refreshPageContent(newPage)
        setPage(newPage);
    };
    
    return (
        <>
            <MarkAttendance closeModal={_closeModal} markAttendance={markAttendance} user={user} date={date} status={status}/>
            <table className="workforce-table">
                <thead>
                    <tr>        
                        {
                            textHeaders.map((head, i) => {
                                return <th rowSpan={2} key={i}>{head}</th>
                            })
                        }
                        {
                            dateHeaders.map((head, i) => {
                                return <th key={i}>{head.date}</th>
                            })
                        }
                    </tr>
                    <tr>
                        {
                            dateHeaders.map((head, i) => {
                                return <th key={i}>{head.day}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length > 0
                        ?
                            rows.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        {
                                            row.textCells.map((cell, j) => {
                                                return <td key={j}>{cell}</td>
                                            })
                                        }
                                        {
                                            row.dateCells.map((cell, j) => {
                                                return <td key={j} className={cell.className} onClick={()=> handleCellClick(cell.id, cell.date , row.textCells[1],cell.text)}>{cell.text}</td>
                                            })
                                        }
                                    </tr>
                                )
                            })
                        :
                            <tr>
                                <td colSpan={100}>No Records</td>
                            </tr>
                    }
                </tbody>
            </table>
            <Pagination onPageChange={handlePageChange} totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} />
        </>
    )
}
