import React, { useEffect, useState } from 'react';
import './../../../static/css/workforce.css';
import $ from 'jquery'
import AddHoliday from '../Modal/AddHoliday';
import { useDispatch, useSelector } from 'react-redux';
import { defaultHolidaysSelector, holidaysSelector } from '../../../state/redux/attendance/selectors';
import { addDefaultHoliday,add_Holiday, getDefaultHolidays, getHolidays, updateHolidays } from '../../../state/redux/attendance/action';
import moment from 'moment';

export default function Holidays() {
    const dispatch = useDispatch();
    const default_holidays = useSelector(defaultHolidaysSelector);
    const holidays = useSelector(holidaysSelector);
    const [holidaysInBox, setHolidaysInBox] = useState([]);

    const filteredHolidays = default_holidays.filter(
        obj => {
            
            for(let i=0;i<holidaysInBox.length;i++){
                
                if(obj.name == holidaysInBox[i].holidayName){
                    return false;
                }
            }
            return true;
        }
    )
    const topHoliday = filteredHolidays.length > 0 ? filteredHolidays[0] : "Select Holiday"

    const handleSelectHoliday = (value) => {
        let newBoxValues = [...holidaysInBox]
        const [addedName, addedDate] = value.split(";");
        newBoxValues.push({
            date: moment(addedDate).format("MM-DD-YYYY"),
            holidayName: addedName
           
        })
        setHolidaysInBox(newBoxValues)
        // setSelectedHoliday(e.target.value);
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    const addHoliday = (data) => {
        data.toast = true;
        dispatch(add_Holiday.call(data, ()=>{
            dispatch(getDefaultHolidays.call({}));
            // dispatch(getHolidays.call({holidayType: 'day'}))
            dispatch(getHolidays.call({holidayType: 'day'}, (res)=>{
                setHolidaysInBox(res.data.filter(h => h.hasOwnProperty("date")).map((h, i) => {
                    return {
                        date: moment(h.date).format('MM-DD-YYYY'),
                        holidayName: h.holidayName
                    }
                }))}))
        }))
    }


    const handleSave = () => {
        
        dispatch(updateHolidays.call({holidays: holidaysInBox, toast: true}, ()=>{
            dispatch(getHolidays.call({holidayType: 'day'}, (res)=>{
                setHolidaysInBox(res.data.filter(h => h.hasOwnProperty("date")).map((h, i) => {
                    return {
                        date: moment(h.date).format('MM-DD-YYYY'),
                        holidayName: h.holidayName
                    }
                }));
                // dispatch(getDefaultHolidays.call({}))
            }))
        }))
    }

    const removeHoliday = (i) => {
        const newHolidays = [...holidaysInBox];
        newHolidays.splice(i, 1);
        setHolidaysInBox(newHolidays);
    }

    const handleChange = (e) => {
        
        let newBoxValues = [...holidaysInBox]
        const [addedName, addedDate] = e.target.value.split(";")
        newBoxValues.push({
            date: moment(addedDate).format("MM-DD-YYYY"),
            holidayName: addedName
           
        })
        setHolidaysInBox(newBoxValues)
    }

    useEffect(()=>{
        dispatch(getHolidays.call({holidayType: 'day'}, (res)=>{
            setHolidaysInBox(res.data.filter(h => h.hasOwnProperty("date")).map((h, i) => {
                return {
                    date: moment(h.date).format('MM-DD-YYYY'),
                    holidayName: h.holidayName
                }
            }));
            dispatch(getDefaultHolidays.call({}))
        }))
    }, [])

    

  return (
    <>
        <div className='contentpanel'>
            <h4 className='title'>Holidays</h4>
            <hr/>
            <AddHoliday closeModal={_closeModal} addHoliday={addHoliday}/>
            <div className='cust-row'>
                <div className='cust-col-3'></div>
                <div className='cust-col-1'>
                    <h6 className='mt-2'>Holidays</h6>
                </div>
                {/*
                <div className='cust-col-3'>
                    <AddHoliday closeModal={_closeModal} addHoliday={addHoliday}/>
                    <div className='input-group'>
                        <select className='form-select' onChange={handleChange}>
                            {
                                default_holidays.filter(
                                    obj => {
                                        
                                        for(let i=0;i<holidaysInBox.length;i++){
                                            
                                            if(obj.name == holidaysInBox[i].holidayName){
                                                return false;
                                            }
                                        }
                                        return true;
                                    }
                                    ).map((holiday,i) => {
                                        
                                        return (
                                            <option key={i} value={holiday.name + ";" + holiday.date}>{holiday.name} - {moment(holiday.date).format(("DD-MM-YYYY"))}</option>
                                        )
                                })
                            }
                        </select>
                        <button className='btn btn-orange btn-sm' onClick={()=> _handleClick('addHolidayModal')}>Add</button>
                    </div>
                </div>*/}
                
                <div className='cust-col-3'>
                    <div className="dropdown ms-2"> 
                        
                        <a className="btn btn-outline-secondary form-control" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {/* {topHoliday.name + " - " + moment(topHoliday.date).format(("DD-MM-YYYY"))} */}
                            {`Select Holidays `}
                             <i className="fa fa-caret-down"></i>
                        </a>
                        <div className="dropdown-menu filter-menu p-1">
                            { filteredHolidays &&
                                filteredHolidays.map((holiday,i) => {
                                        
                                    return (
                                        <a class="dropdown-item" href="#" onClick={()=>handleSelectHoliday(holiday.name + ";" + holiday.date)}>
                                            {holiday.name} - {moment(holiday.date).format(("DD-MM-YYYY"))}
                                            
                                        </a>
                                    )
                                })
                            }
                        </div>  
                    </div>
                </div>
                <div className='cust-col-1 text-left mt-1'>
                    <button className='btn btn-orange btn-sm' onClick={()=> _handleClick('addHolidayModal')}>Add +</button>
                </div>
            </div>
            <div className='cust-row mt-2'>
                <div className='cust-col-1'></div>
                <div className='cust-col-8'>
                    <div className="card">
                        <div className="card-header">
                            <p className='fs-6 mt-2'>Selected Holidays</p>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                {
                                    holidaysInBox.map((holiday,i) => {
                                        return (
                                            <div className='col-md-3 col-sm-4' key={i}>
                                                <div className='alert alert-info alert-holiday alert-dismissible fade show'>
                                                    <strong>{holiday.holidayName}</strong><br/>{moment(holiday.date).format(("DD-MM-YYYY"))}
                                                    <button type="button" className="btn-close" onClick={()=>removeHoliday(i)}></button>
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            
            <div className='text-center mt-2'>
                <button className='btn btn-orange' onClick={()=> handleSave()}>Save Holidays</button>
            </div>
        </div>
    </>
    

  )
}
//alert alert-info alert-dismissible fade show
