import React, { useState } from 'react';

const ConfirmBooking = (props) => {

    const [vehicleStatus,setVehicleStatus]=useState(false)
    const [dlStatus,setDlStatus]=useState(false)

    const _handleInputChange = (type,value) =>{
        if(type==='vehicle'){
            if(value){
                setVehicleStatus(true)
            }
            else if(vehicleStatus===true){
                setVehicleStatus(false)
            }
        }
        else if(type==='driver'){
            if(value){
                setDlStatus(true)
            }
            else if(dlStatus===true){
                setDlStatus(false)
            }
        }
    }

    return(
        <section id="confirmBookingModal" className="custom-modal">
            <div className="custom-modal-header">
            <h1>Add Details Against Indent</h1>
            <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'70vh'}}>
                <div className="cust-row mt-3 mb-2">
                    <div className='cust-col-10'>
                        <label className='labelcc font-md'>Assign Vehicle Against Demand Order?</label>
                    </div>
                </div>
                <div className="cust-row mb-5">
                    <div className='cust-col-2 flex-algn-cent'>
                        <label className='labelcc'>Vehicle Number</label>
                    </div>
                    <div className='cust-col-2'>
                        <input className='form-control' placeholder='Vehicle Number' onChange={(evt)=>{_handleInputChange('vehicle',evt.target.value)}}/>
                    </div>
                    <div className='cust-col-2 flex-algn-cent'>
                        <label className='labelcc'>Loadable Capacity</label>
                    </div>
                    <div className='cust-col-3'>
                        <input className='form-control' placeholder='Loadable Capacity'/>
                    </div>
                    <div className='cust-col-1 flex-algn-cent'>
                        {vehicleStatus?<div class="popdv">
                            <i className='fa fa-info-circle ccIcon'></i>
                            <div class="btmplc">
                                <div className='dv_flex pd5 brd_rht brd_btm brd_tp brd_lft'>
                                    <div className='wdt100 text-center'>
                                        <span className='font-bold black_color'>Vehicle Number : <span className='grey_color'>(enter by user)</span></span>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt40 brd_rht text-left'>
                                        <span className='font-bold black_color font-sm'>Fitness Upto</span>
                                    </div>
                                    <div className='wdt40 brd_rht'>
                                        <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                    </div>
                                    <div className='wdt20'>
                                        <i className='fa fa-check green_color'></i>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt40 brd_rht text-left'>
                                        <span className='font-bold black_color font-sm'>PUC Valid Upto</span>
                                    </div>
                                    <div className='wdt40 brd_rht'>
                                        <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                    </div>
                                    <div className='wdt20'>
                                        <span className='font-bold red_color font-xsm'>Expired</span>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt40 brd_rht text-left'>
                                        <span className='font-bold black_color font-sm'>Insurance Validity</span>
                                    </div>
                                    <div className='wdt40 brd_rht'>
                                        <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                    </div>
                                    <div className='wdt20'>
                                        <i className='fa fa-check green_color'></i>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt50'>
                                        <button className='button bg-success'>
                                            <span className='btn_txt'>Accept</span>
                                        </button>
                                    </div>
                                    <div className='wdt50'>
                                        <button className='button bg-danger'>
                                            <span className='btn_txt'>Reject</span>
                                        </button>
                                    </div>
                                </div>
                                <i></i>
                            </div>
                        </div>     :""}                   
                        {/* <i className='fa fa-check-circle'></i> */}
                        <i className='fa fa-plus-circle ccIcon'></i>
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className='cust-col-10'>
                        <label className='labelcc font-md'>Assign Driver Against Demand Order?</label>
                    </div>
                </div>  
                <div className="cust-row mb-5">
                    <div className='cust-col-2 flex-algn-cent'>
                        <label className='labelcc'>DL Number</label>
                    </div>
                    <div className='cust-col-2'>
                        <input className='form-control' placeholder='Enter DL Number' onChange={(evt)=>{_handleInputChange('driver',evt.target.value)}}/>
                    </div>
                    <div className='cust-col-2 flex-algn-cent'>
                        <label className='labelcc'>Mobile</label>
                    </div>
                    <div className='cust-col-3'>
                        <input className='form-control' placeholder='Enter Mobile Number'/>
                    </div>
                    <div className='cust-col-1 flex-algn-cent'>
                        {/* <i className='fa fa-info-circle'></i> */}
                        {dlStatus?<div class="popdv">
                            <i className='fa fa-check-circle ccIcon green_color'></i>
                            <div class="btmplc top">
                                <div className='dv_flex pd5 brd_rht brd_btm brd_tp brd_lft'>
                                    <div className='wdt100 text-center'>
                                        <span className='font-bold black_color'>DL Number : <span className='grey_color'>(enter by user)</span></span>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt40 brd_rht text-left'>
                                        <span className='font-bold black_color font-sm'>Valid Upto</span>
                                    </div>
                                    <div className='wdt40 brd_rht'>
                                        <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                    </div>
                                    <div className='wdt20'>
                                        <i className='fa fa-check green_color'></i>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt40 brd_rht text-left'>
                                        <span className='font-bold black_color font-sm'>Issue Date</span>
                                    </div>
                                    <div className='wdt40 brd_rht'>
                                        <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                    </div>
                                    <div className='wdt20'>
                                        <i className='fa fa-check green_color'></i>
                                    </div>
                                </div>
                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                    <div className='wdt40 brd_rht text-left'>
                                        <span className='font-bold black_color font-sm'>Driver Name</span>
                                    </div>
                                    <div className='wdt40 brd_rht'>
                                        <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                    </div>
                                    <div className='wdt20'>
                                        <i className='fa fa-check green_color'></i>
                                    </div>
                                </div>
                                <i></i>
                            </div>
                        </div>  :""}                       
                        <i className='fa fa-plus-circle ccIcon'></i>
                    </div>
                </div>
                <div className="row-div mb-4">
                    <div className="share-div">
                        <button className="action_btn" onClick={props.confirmBooking}>Allocate Vehicle</button>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ConfirmBooking;