let namespace = "workforceDetail";

export default {
    LOGIN_INFO: `${namespace}/LOGIN_INFO`,
    GET_ALL_BANK: `${namespace}/GET_ALL_BANK`,
    GET_EXPENSE_TYPE: `${namespace}/GET_EXPENSE_TYPE`,
    ADD_EXPENSE_TYPE: `${namespace}/ADD_EXPENSE_TYPE`,
    GET_EXPENSE: `${namespace}/GET_EXPENSE`,
    ADD_EXPENSE: `${namespace}/ADD_EXPENSE`,
    UPDATE_EXPENSE: `${namespace}/UPDATE_EXPENSE`,
    DELETE_EXPENSE: `${namespace}/DELETE_EXPENSE`,
    GET_PAYMENT_METHOD: `${namespace}/GET_PAYMENT_METHOD`,
    ADD_PAYMENT_METHOD: `${namespace}/ADD_PAYMENT_METHOD`,
    GET_SALARY: `${namespace}/GET_SALARY`,
    GET_LEAVE_BALANCE: `${namespace}/GET_LEAVE_BALANCE`,
    GET_FILE: `${namespace}/GET_FILE`,
    UPDATE_DOCUMENT: `${namespace}/UPDATE_DOCUMENT`,
    DELETE_DOCUMENT: `${namespace}/DELETE_DOCUMENT`,
    UPDATE_BANK: `${namespace}/UPDATE_BANK`,
    DELETE_BANK: `${namespace}/DELETE_BANK`,
}