import React, {useState} from 'react';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import {useForm} from 'react-hook-form';


export default function AddUserType({closeModal, addUserType}) {

    const { register, handleSubmit, getValues, reset, formState:{errors} } = useForm();


    const handleAdd = (data) => {
        addUserType(data);
        reset();
        closeModal();
    }

    return (
        <form onSubmit={handleSubmit(handleAdd)}>
            <section id="addUserTypeModal" className="custom-modal">
                <div className="custom-modal-header">
                <h1>Add User Type</h1>
                <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'70vh'}}>
                    <div className="cust-row">
                        <div className='cust-col-10'>
                            <div className='table-responsive'>
                                <table className='bordered_table'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>User Type Name<span className='red_color'>*</span></h2>
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" {...register('user_type', { required : true })} />
                                                {errors.user_type &&<p className='error red_color'>User Type is required</p>}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                    
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Submit</div>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </section>

        </form>
        
    )
}
