import {React,useRef,useState, useEffect} from "react";
import history from '../../services/history';
import moment from 'moment';
import $ from 'jquery';
import './../../static/css/workforce.css'
import {Link} from 'react-router-dom'
import GeneralTab from "../Components/GeneralTab";
import { useSelector, useDispatch } from "react-redux";
import { userTypesSelector, workforceListSelector } from "../../state/redux/workforce/selectors";
import { getUsersAction, callDeleteUserApi, callGetUsersApi, callGetUserTypesApi, callUpdateUserApi, updateUserAction, deleteUserAction } from "../../state/redux/workforce/actions";
import UpdateUser from "./Modal/UpdateUser";
import Confirm from "./Modal/Confirm";
import {showToast} from './../../services/functions'
import {toast} from 'react-toastify'
import { confimToast } from "../../util/customToast";

const UserDetails = ()=>{
    const dispatch = useDispatch();
    const {users, total_users} = useSelector(workforceListSelector);

    const [userTab, setUserTab] = useState("User");
    const [updateUserObj, setUpdateUserObj] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
   
    const navigateAndShow = () => {
        history.push('/dashboard/workforce')
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setUpdateUserObj(null);
    }

    const handleUserChange =(userType)=>{
            setUserTab(userType);
    }

    const handleDeleteUser = (id) => {
        setDeleteUserId(id);
        _handleClick('deleteUserConfirmModal');
    }

    const confirmDeleteUser = () => {
        
        dispatch(deleteUserAction.call({userId: deleteUserId, toast: true}, ()=>{
            dispatch(getUsersAction.call({
                limit: pageLimit,
                page_no: page
            }))
        }))
        setDeleteUserId(null);
    }

    const handleUpdateUser = (obj) => {
        setUpdateUserObj(obj)
        _handleClick('updateUserModal')
    }
    const updateUser = (userId, data) => {
        dispatch(updateUserAction.call({
            ...data,
            userId: userId,
            userDocs: [],
            toast: true
        }, ()=> {
            dispatch(getUsersAction.call({
                limit: pageLimit,
                page_no: page
            }))
        }))
        // dispatch(callUpdateUserApi({
        //     ...data,
        //     userId: userId,
        //     userDocs: []
        // }))
    }

    const filterUsers = (data) => {
        data.toast = true;
        data.onlyError = true;
        dispatch(getUsersAction.call(data))
    }

    const userTableData = users.map((user,i)=>{
        let row=[];
        row.push((page-1)*pageLimit+i+1);
        row.push(user.readableId);
        row.push(<Link to={"/dashboard/workforce/user-detail/"+ user._id}>{user.name}</Link>);
        row.push(user.mobile?user.mobile:"-");
        row.push(moment(user.doj).format('DD/MM/YYYY'));
        row.push(moment(user.dob).format('DD/MM/YYYY'));
        row.push(user.department?user.department:"-");
        row.push(user.email?user.email:"-");
        row.push(user.balance ? (user.balance).toFixed(2) : 0);
        row.push(
            <>
                <button className='btn-outline-orange' onClick={() => handleDelete(user._id)}>
                    <i className='fa fa-trash'></i>
                </button>
                <button className='btn-outline-orange ms-2' onClick={()=> handleUpdateUser(user)}>
                    <i className='fa fa-edit'></i>
                </button>
            </>
        )
        return row;
    })

    const handleDelete = (id) => {
        confimToast({message:'Are you sure you want to delete this entry?', callback: ()=>{
            dispatch(deleteUserAction.call({userId: id, toast: true}, ()=>{
                dispatch(getUsersAction.call({
                    limit: pageLimit,
                    page_no: page
                }))
            }))
        }})
    };

    useEffect(()=>{
        dispatch(getUsersAction.call({
            limit: pageLimit,
            page_no: 1,
        }))
        setPage(1)
    }, [pageLimit])
    
    return(
        <>
            <div className="contentpanel">
                <div className="cust-container">   
                    <div className='cust-row'>
                        <div className='cust-col-6'>
                            <h1 className="title pt-3">Workforce</h1>
                        </div>
                        <div className='cust-col-2'></div>
                        <div className='cust-col-2 outline-box text-center pt-3 ms-1'>
                            <p className="fs-5">Total Users: <span className="text-orange fw-bold">{total_users} <i className="fa fa-user"></i></span></p>
                        </div>

                    </div>
                    <hr />
                    <UpdateUser closeModal={_closeModal} updateUser={updateUser} updateUserObj={updateUserObj}/>
                    <Confirm id="deleteUserConfirmModal" closeModal={_closeModal} confirm={confirmDeleteUser} />
                    <GeneralTab
                        userType={userTab} 
                        filterUsers={filterUsers}
                        headers={["S.no","Emp Id","Name"," Mobile Number","Date of joining","DOB","Department","Email","Total Balance","Actions"]}
                        data={userTableData}
                        total_users={total_users}
                        setPage={setPage}
                        page={page}
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                    />
                    
                </div>
            </div>    
     
        </>
    )
}

export default UserDetails;