import { useState } from 'react'
import history from '../../services/history'

const ContractDetail = () => {

    const [isData] = useState(false)

    const _handleChange = (type,value) => {

    }

    const _goBack = () =>{
        history.push("/dashboard/Contracts")
    }

    return(
        <div className="contentpanel">            
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Contract Detail
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                        </button>
                    </div>
                </div>
                <hr className='bgg'/>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='contract-tbl mb-3'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Contract Name :</th>
                                        <td>
                                            1234
                                            <span className='view_dc_btn'>View Uploaded Documents</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Company Name :</th>
                                        <td>Hindalco Industries Limited</td>
                                    </tr>
                                    <tr>
                                        <th>Plant Name :</th>
                                        <td>Renukot Plant</td>
                                    </tr>
                                    <tr>
                                        <th>Contract Date :</th>
                                        <td>31-Jan-2022</td>
                                    </tr>
                                    <tr>
                                        <th>Valid Upto :</th>
                                        <td>28-Feb-2022 <span className='grey_color font-md'>(Expires in 28 Days)</span></td>
                                    </tr>
                                    <tr>
                                        <th>Address :</th>
                                        <td>ABC Block, Gali No. 21, CC Avenue, Delhi - 222222</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='contract-tbl1 mb-3'>
                            <table className='wdt100'>
                                <tbody>
                                    <tr>
                                        <th rowSpan={2} className='wdt55'>Value Clauses</th>
                                        <th colSpan={3} className='wdt45 text-center'>Value</th>
                                    </tr>
                                    <tr>
                                        <td className='wdt15 text-center font-bold'>X</td>
                                        <td className='wdt15 text-center font-bold'>Y</td>
                                        <td className='wdt15 text-center font-bold'>Z</td>
                                    </tr>
                                    <tr>
                                        <td>Placement after indent request to be <b>X</b> hours</td>
                                        <td>
                                            {
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }
                                        </td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Penalty if not placed within time limit, will be <b>X</b> rupees every day with a max cap of <b>Y</b> rupees</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Day is taken as calendar day or 24 hours?</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Remuneration will be on basis of per <b>X</b></td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Change in rate for trip under than <b>X</b> km will change after diesel price changes by <b>Y</b></td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle with capacity <b>X</b> ton will have <b>Y</b> rupee rate per ton</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Payment terms which will be based on credit period of a <b>X</b> number of days from end of last trip</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>No rate fluctuation in case of weight being +/- <b>X</b> kg</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td><b>X</b>% of freight rate will increase increase of fuel fluctuation</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Fuel averaging will be every <b>X</b> days</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Billing dispute needs to be raised within <b>X</b> days</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td><b>Y</b> Per day penalty for late delivery will be <b>X</b> rupees</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Placement after indent request to be <b>X</b> hours if its more then that the penality will be <b>Y</b> rupees</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Transporter can run maximum <b>X</b> no or <b>Y</b>% of trips per month</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>The transporter shall be liable for any loss/damage and will pay <b>X</b>% of the penality</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Any charges not paid within <b>X</b> days of delivery will be subject to interest at <b>Y</b>% per month until paid.</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle must carry up-to-date fitness, road permit, insurance, original driving license and related documents/certificates</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Lorry Receipt issued by the contractor shall clearly state description, quantity in numbers/weight of the consignment and all the required information.</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>The Transporter agrees to not discuss its performance of services under this Agreement, with any third party</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Payment terms which will be based on credit period of a <b>X</b> number of days from end of last trip</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>A detention fee Rs <b>X</b> /- is assessed when the truck is held at the pickup or delivery location longer than the <b>Y</b> time.</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Contract term for the transporter will be depend on the start(<b>X</b>) and end date(<b>Y</b>) of the contract</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                    <tr>
                                        <td>Per <b>X</b> day/hours penalty for late indent</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                        <td>{
                                                isData?"(From HIL)":
                                                <input className='ccInput' onChange={(evt)=>{_handleChange('x',evt.target.value)}}/>
                                            }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='contract-tbl1 mb-3'>
                            <table className='wdt100'>
                                <tbody>
                                    <tr>
                                        <th className='wdt70'>Standard Clauses</th>
                                        <th className='wdt30'>Status</th>
                                    </tr>
                                    <tr>
                                        <td>Lorry Receipt issued by the contractor shall clearly state description, quantity in numbers/weight of the consignment and all the required information</td>
                                        <td>
                                            <div className='green_color flex-algn-cent'>
                                                <i className='fa fa-check-circle-o icncc'></i>
                                                (From Lynkit/Blockchain)
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>The Transporter agrees to not discuss its performance of services under this agreement, with any third party</td>
                                        <td>
                                            <div className='green_color flex-algn-cent'>
                                                <i className='fa fa-check-circle-o icncc'></i>
                                                (From Lynkit/Blockchain)
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle must carry up-to-date fitness, road permit, insurance, original driving license and related documents/certificates.</td>
                                        <td>
                                            <div className='green_color flex-algn-cent'>
                                                <i className='fa fa-check-circle-o icncc'></i>
                                                (From Lynkit/Blockchain)
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractDetail