import React, {useState} from 'react';
import FileUpload from './../FileUpload'
import { base64Convert } from '../../../services/functions';
import {useForm} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { expenseTypesSelector, paymentMethodsSelector } from '../../../state/redux/workforceDetail/selectors';
import { getExpenseType, getPaymentMethod } from '../../../state/redux/workforceDetail/actions';


export default function AddExpenseType({closeModal, addExpenseType, id}) {

    const dispatch = useDispatch();
    const expenseTypes = useSelector(expenseTypesSelector);

    const { register, handleSubmit, watch, reset, formState: {errors} } = useForm();

    const handleAddExpenseType = (data) => {
        addExpenseType({...data, expense_type: "user"});
        reset();
        closeModal();
    }


    return (
        <form onSubmit={handleSubmit(handleAddExpenseType)}>
            <section id={id?"addExpenseTypeModal"+id:"addExpenseTypeModal"} className="custom-modal">
                <div className="custom-modal-header">
                <h1>Add Expense Type</h1>
                <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'70vh'}}>
                    <div className="cust-row mb-2">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Expense Type<span className="red_color">*</span></label>
                            <input type="text" className='cust-input' placeholder='Enter Expense Type' {...register('expense_name', {required: true})}/>
                            {errors.expense_name &&<p className='error red_color'>Expense Type is required</p>}
                        </div>
                    </div>
                    
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'> Submit </div>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </section>

        </form>
        
    )
}


