import { useEffect, useState } from "react";
import { useUrlQuery } from "../../../services/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
	getUserTypes,
	getUsersAction,
} from "../../../state/redux/workforce/actions";
import {
	userTypesSelector,
	workforceListSelector,
} from "../../../state/redux/workforce/selectors";
import { getDepartments } from "../../../state/redux/department/actions";
import { departmentListSelector } from "../../../state/redux/department/selectors";
import { Checkbox, Dropdown, Input } from "../../../util/components";
import { Link } from "react-router-dom";
import {
	fieldMappingSelector,
	reportSelector,
} from "../../../state/redux/report/selectors";
import {
	getFieldMapping,
	getReport,
} from "../../../state/redux/report/actions";
import { saveAsExcel, showToast } from "../../../services/functions";

const DEFAULT_REPORT_TYPE = "combined";

export default function ReportDownload() {
	const dispatch = useDispatch();
	const [selectedFields, setSelectedFields] = useState([]);
	const [screen, setScreen] = useState("fieldMapping"); // fieldMapping | filterSelection | downloadReport
	const [filter, setFilter] = useState({
		reportType: DEFAULT_REPORT_TYPE,
	});

	const query = useUrlQuery();
	const reportType = query.get("type");
	const { fieldMapping } = useFieldMapping(reportTypeMap[reportType]);
	const { fetchReport } = useGetReport();

	const handleFieldSelect = (e) => {
		const { name, checked, value } = e.target;
		let nextSelected = [...selectedFields];
		if (name == "selectAll") {
			if (checked) {
				nextSelected = fieldMapping.map((_) => ({
					label: _.field_name,
					value: _.field_id,
				}));
			} else {
				nextSelected = [];
			}
		} else {
			if (checked) {
				nextSelected.push({ label: name, value });
			} else {
				nextSelected = nextSelected.filter((_) => _.value != value);
			}
		}
		setSelectedFields(nextSelected);
		// console.log(name, checked, value);
	};
	const handleScreenChange = (type = "fieldMapping") => {
		setScreen(type);
	};
	const handleFilterChange = (e, meta = null) => {
		const name = meta ? meta.name : e.target.name;
		const value = meta ? (Array.isArray(e) ? e : e.value) : e.target.value;
		// console.log({ name, value });
		setFilter((old) => ({ ...old, [name]: value }));
	};

	const handleGetReport = () => {
		const payload = makePayload();
		const errMsg = checkValidation(payload);
		if (errMsg) {
			showToast(errMsg, true);
		} else {
			fetchReport(payload);
		}
		// console.log(payload);
	};

	const handleExcelDownload = () => {
		const payload = makePayload("excel");
		const errMsg = checkValidation(payload);
		if (errMsg) {
			showToast(errMsg, true);
		} else {
			fetchReport(payload, excelCallback);
		}
		// console.log(payload);
	};

	const handleDataReset = () => {
		dispatch({ type: "report/GET_REPORT_RESET" });
	};
	// console.log(filter);
	let ui;
	switch (screen) {
		case "fieldMapping":
			ui = (
				<>
					<FeildMapping
						selectedFields={selectedFields}
						onFieldSelect={handleFieldSelect}
						onScreenChange={handleScreenChange}
					/>
				</>
			);
			break;
		case "filterSelection":
			ui = (
				<FilterSelection
					reportType={reportType}
					onScreenChange={handleScreenChange}
					onFilterChange={handleFilterChange}
					filter={filter}
				/>
			);
			break;
		case "downloadReport":
			ui = (
				<DownloadPreview
					reportType={reportType}
					onScreenChange={handleScreenChange}
					onFilterChange={handleFilterChange}
					onGetReport={handleGetReport}
					onExcelDownload={handleExcelDownload}
					filter={filter}
					onReset={handleDataReset}
				/>
			);
			break;
		default:
			ui = <></>;
			break;
	}
	return (
		<>
			<div className="contentpanel">
				<div className="cust-container">
					<div className="cust-row">
						<div className="cust-col-6">
							<h1 className="title">{reportType}</h1>
						</div>
						<div className="cust-col-2"></div>
						<div className="cust-col-2 d-flex f-a-cent f-j-end">
							<span onClick={handleDataReset}>
								<Link
									to="/dashboard/reports"
									className="btn-back"
								>
									Back
								</Link>
							</span>
						</div>
					</div>
					<hr className="mt-2 mb-3" />
					{ui}
				</div>
			</div>
		</>
	);
	function makePayload(download_type = "html") {
		return {
			type: reportTypeMap[reportType]?.type,
			driverId: filter.userName?.map((_) => _.value),
			driver_report_type:
				reportTypeMap[reportType]?.sub_type || filter.reportType,
			department:
				reportsUsingReportType.includes(reportType) &&
				filter.reportType == "combined"
					? undefined
					: filter.department?.map((_) => _.value),
			user_type:
				reportsUsingReportType.includes(reportType) &&
				filter.reportType == "combined"
					? undefined
					: filter.userType?.map((_) => _.value),
			enddate: convertDate(filter.enddate),
			selected_fields: selectedFields?.map((_) => _.value),
			startdate: convertDate(filter.startdate),
			gender: filter.gender,
			download_type,
		};
	}
	function excelCallback(resp) {
		const { data } = resp;
		if (data) {
			let byteCharacters = atob(data.toString("base64"));
			let byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);
			saveAsExcel(byteArray, reportType);
		}
	}
}

function FeildMapping({
	selectedFields = [],
	onFieldSelect = () => {},
	onScreenChange = () => {},
}) {
	const {
		data: fieldMapping,
		error,
		loading,
	} = useSelector((state) => state.report.fieldMapping);

	return (
		<>
			<div className="field-mapping-cont">
				<section>
					<div className="head">All Fields</div>
					<div className="body">
						<Checkbox
							id="selectAll"
							name="selectAll"
							checked={
								fieldMapping?.length
									? fieldMapping.length ==
									  selectedFields.length
									: false
							}
							onChange={onFieldSelect}
							label="Select All"
							value="All"
						/>

						{loading ? (
							<div className="loader-cont">
								<div className="loader" />
							</div>
						) : (
							fieldMapping?.map((field, index) => {
								const { field_name, field_id } = field;
								return (
									<Checkbox
										key={index}
										id={field_name}
										name={field_name}
										label={field_name}
										value={field_id}
										checked={
											!!selectedFields.find(
												(_) => _.value == field_id
											)
										}
										onChange={onFieldSelect}
									/>
								);
							})
						)}
					</div>
				</section>
				<div className="field-arrow"></div>
				<section>
					<div className="head">Selected Fields</div>
					<div className="body">
						{selectedFields.map(({ label, value }, index) => {
							return (
								<div key={index} className="field-item">
									<span>{label}</span>
									<i
										className="fa fa-times icon-cross"
										onClick={() =>
											onFieldSelect({
												target: {
													name: label,
													value: value,
													checked: false,
												},
											})
										}
									></i>
								</div>
							);
						})}
					</div>
				</section>
			</div>
			<div className="nav-cont">
				<button onClick={() => onScreenChange("filterSelection")}>
					Next
				</button>
			</div>
		</>
	);
}

function FilterSelection({
	reportType,
	onScreenChange,
	onFilterChange,
	filter,
}) {
	const { userOptions } = useUsers(reportType, reportsUsingUsers);
	const { departmentOptions } = useDepartments(reportType, reportsUsingDept);
	const { userTypeOptions } = useUserTypes();

	return (
		<>
			<div className="fitler-selection-cont">
				{shouldRender("reportType") ? (
					<Dropdown
						id="reportType"
						name="reportType"
						label="report type"
						options={reportTypeOptions}
						onChange={onFilterChange}
						value={reportTypeOptions.find(
							(_) => _.value == filter.reportType
						)}
					/>
				) : null}
				{shouldRender("userType") ? (
					<Dropdown
						id="userType"
						name="userType"
						label="user type"
						options={userTypeOptions}
						onChange={onFilterChange}
						value={getUserTypeValue_multi(filter.userType)}
						isMulti
					/>
				) : null}
				{shouldRender("gender") ? (
					<Dropdown
						id="gender"
						name="gender"
						label="gender"
						options={genderOptions}
						onChange={onFilterChange}
						value={genderOptions.find(
							(_) => _.value == filter.gender
						)}
					/>
				) : null}
				{shouldRender("department") ? (
					<Dropdown
						id="department"
						name="department"
						label="department"
						options={departmentOptions}
						onChange={onFilterChange}
						value={getDeptValue_multi(filter.department)}
						isMulti
					/>
				) : null}
				{shouldRender("userName") ? (
					<Dropdown
						id="userName"
						name="userName"
						label="user name"
						options={userOptions}
						onChange={onFilterChange}
						value={getUserNameValue_multi(filter.userName)}
						isMulti
					/>
				) : null}
			</div>
			<div className="nav-cont">
				<button onClick={() => onScreenChange("fieldMapping")}>
					Previous
				</button>
				<button onClick={() => onScreenChange("downloadReport")}>
					Next
				</button>
			</div>
		</>
	);
	function shouldRender(inputType) {
		switch (inputType) {
			case "reportType":
				return reportsUsingReportType.includes(reportType);
			case "userType":
				// prettier-ignore
				return reportsUsingUserType.includes(reportType) && (reportType== "Staff Ledger Report (Individual)" ? filter.reportType != "combined" : true);
			case "department":
				// prettier-ignore
				return reportsUsingDept.includes(reportType) && (reportType== "Staff Ledger Report (Individual)" ? filter.reportType != "combined" : true);
			case "gender":
				return reportsUsingGender.includes(reportType);
			case "userName":
				return reportsUsingUsers.includes(reportType);
			default:
				return false;
		}
	}
	function getUserTypeValue_multi(values) {
		return userTypeOptions?.filter(
			(op) => !!values?.find((v) => v.value == op.value)
		);
	}
	function getDeptValue_multi(values) {
		return departmentOptions?.filter(
			(op) => !!values?.find((v) => v.value == op.value)
		);
	}

	function getUserNameValue_multi(values) {
		return userOptions?.filter(
			(op) => !!values?.find((v) => v.value == op.value)
		);
	}
}

function DownloadPreview({
	reportType,
	onScreenChange,
	onFilterChange,
	onGetReport,
	onExcelDownload,
	filter,
	onReset,
}) {
	const { data, error, loading } = useSelector(
		(state) => state.report.report
	);

	return (
		<>
			<div className="fitler-selection-cont">
				<Input
					id="startdate"
					name="startdate"
					label="start date"
					type="date"
					value={filter.startdate}
					onChange={onFilterChange}
					required={reportType != "Staff Report"}
				/>
				<Input
					id="enddate"
					name="enddate"
					label="end date"
					type="date"
					value={filter.enddate}
					onChange={onFilterChange}
					required={reportType != "Staff Report"}
				/>
			</div>

			<div className="nav-cont">
				<button
					onClick={() => {
						onReset();
						onScreenChange("filterSelection");
					}}
				>
					Previous
				</button>
				{filter.reportType != "individual" ? (
					<button
						onClick={() => {
							!loading && onGetReport();
						}}
						disabled={loading}
					>
						Get Report
					</button>
				) : null}
				<button
					onClick={() => {
						!loading && onExcelDownload();
					}}
					className="btn-excel"
					disabled={loading}
				>
					Export To Excel
				</button>
			</div>

			{loading ? (
				<div className="loader-cont">
					<div className="loader" />
				</div>
			) : data && data.includes("<html>") ? (
				<div
					dangerouslySetInnerHTML={{
						__html: data?.replace(/\n/g, ""),
					}}
					className="html-cont"
				/>
			) : null}
		</>
	);
}

//hooks
function useFieldMapping(data) {
	const dispatch = useDispatch();
	const fieldMapping = useSelector(fieldMappingSelector);
	function getFieldMappings() {
		// call api
		const { type, sub_type } = data;
		dispatch(getFieldMapping.call({ type, driver_report_type: sub_type }));
	}

	useEffect(() => {
		getFieldMappings();
	}, []);

	return { fieldMapping };
}
function useGetReport() {
	const dispatch = useDispatch();

	function fetchReport(payload, cb = null) {
		dispatch(
			getReport.call({ ...payload, toast: true, onlyError: true }, cb)
		);
	}

	return { fetchReport };
}

function useUserTypes() {
	const dispatch = useDispatch();
	const userTypes = useSelector(userTypesSelector);
	useEffect(() => {
		dispatch(getUserTypes.call({}));
	}, []);
	const userTypeOptions = [
		{ label: "--Select-User-Type--", value: "-", isDisabled: true },
		...userTypes?.map((_) => ({ label: _.user_type, value: _.user_type })),
	];
	return { userTypeOptions };
}
function useUsers(type = "", toInclude = []) {
	const dispatch = useDispatch();
	const { users, total_users } = useSelector(workforceListSelector);
	function getUsers() {
		dispatch(
			getUsersAction.call({
				limit: "",
				page_no: "",
			})
		);
	}

	useEffect(() => {
		// fetch when function is called without type or without toInclude array
		// if it is called with type and toInclude, then type should be present in toInclude
		if (type == "" || !toInclude.length || toInclude.includes(type)) {
			getUsers();
		}
	}, []);
	const userOptions = [
		{ label: "--Select-User--", value: "-", isDisabled: true },
		...users.map((_) => {
			return { label: _.name, value: _._id };
		}),
	];
	// console.log({ users, total_users });
	return { userOptions };
}
function useDepartments(type = "", toInclude = []) {
	const dispatch = useDispatch();
	const { department: departments } = useSelector(departmentListSelector);
	function getDept() {
		dispatch(getDepartments.call({}));
	}
	useEffect(() => {
		// fetch when function is called without type or without toInclude array
		// if it is called with type and toInclude, then type should be present in toInclude
		if (type == "" || !toInclude.length || toInclude.includes(type)) {
			getDept();
		}
	}, []);

	const departmentOptions = [
		{ label: "--Select-Departments--", value: "-", isDisabled: true },
		...departments.map((_) => {
			return { label: _.department, value: _.department };
		}),
	];
	// console.log({ departments});
	return { departmentOptions };
}

//utils
function checkValidation(data) {
	// console.log(data);
	const {
		type,
		enddate,
		startdate,
		selected_fields,
		download_type,
		department,
		driverId,
		driver_report_type,
		gender,
		user_type,
	} = data;

	let msg = "";

	if (
		["attendance_report", "user_ledger_report", "leave_report"].includes(
			type
		)
	) {
		if (!startdate) {
			msg = "Please select start date";
		} else if (!enddate) {
			msg = "Please select end date";
		}
	}
	if (!selected_fields.length) {
		msg = "Please select at least 1 field";
	} else if (new Date(startdate).getTime() > new Date(enddate).getTime()) {
		msg = "Start date cannot be greater than end date";
	}
	return msg;
}

const genderOptions = [
	{ label: "--Select-Gender--", value: "", isDisabled: false },
	{ label: "Male", value: "male" },
	{ label: "Female", value: "female" },
];
const reportTypeOptions = [
	{ label: "--Select-Report-Type--", value: "", isDisabled: false },
	// { label: "Aggregate", value: "aggregated" },
	{ label: "Individual (combined)", value: "combined" },
	{ label: "Individual (separated)", value: "individual" },
];

// reports where the particular data is shown
const reportsUsingUserType = [
	"Attendance Report",
	"Attendance Stats Report",
	"Staff Report",
	"Staff Ledger Report (Individual)",
	"Staff Ledger Report (Aggregated)",
	"Leaves Report",
];
const reportsUsingGender = [
	"Attendance Report",
	"Attendance Stats Report",
	"Staff Report",
];
const reportsUsingDept = [...reportsUsingUserType];
const reportsUsingUsers = [
	"Staff Report",
	"Staff Ledger Report (Individual)",
	"Staff Ledger Report (Aggregated)",
	"Leaves Report",
];
const reportsUsingReportType = ["Staff Ledger Report (Individual)"];

const reportTypeMap = {
	"Attendance Report": { type: "attendance_report" },
	"Attendance Stats Report": { type: "attendance_stats_report" },
	"Staff Report": { type: "user_report" },
	"Staff Ledger Report (Individual)": { type: "user_ledger_report" },
	"Staff Ledger Report (Aggregated)": {
		type: "user_ledger_report",
		sub_type: "aggregated",
	},
	"Leaves Report": { type: "leave_report" },
};

function convertDate(dateStr) {
	if (!dateStr) return;
	const [yyyy, mm, dd] = dateStr.split("-");
	return `${mm}-${dd}-${yyyy}`;
}
