import { React, memo, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery';

// Redux, Actions and State
import { useSelector, useDispatch } from "react-redux";
import { userDetailSelector } from "../../state/redux/workforce/selectors";
import { callUpdateUserApi, callAddBankApi, callAddDocumentApi, callGetBankApi, callGetDocumentsApi, callGetUserDetailApi, callDeleteUserApi, getUserDetail, updateUserAction, deleteUserAction, getUsersAction, addDocumentAction, getDocumentAction, addBankAction, getBankAction, updateUserStatus } from "../../state/redux/workforce/actions";
import { getExpenseType, getExpense, addExpense, getLeaveBalance, getFile, deleteExpense, updateExpense, updateDocuments, deleteDocuments, updateBank, deleteBank } from '../../state/redux/workforceDetail/actions';
import { expenseTypesSelector, expensesSelector, leaveBalanceSelector } from '../../state/redux/workforceDetail/selectors';



// Modals
import AddBankDetail from './Modal/AddBankDetail';
import AddDocuments from './Modal/AddDocuments';
import AddCredit from './Modal/AddCredit';
import AddDebit from './Modal/AddDebit';
import AddSalary from './Modal/AddSalary';
import UpdateUser from './Modal/UpdateUser';
import Confirm from './Modal/Confirm';
import UpdateSalary from './Modal/UpdateSalary'
import ViewDocument from './Modal/ViewDocument'
// Logos
import KYCLogo from './../../static/images/icon/address/kyc.svg'
import BusinessLogo from './../../static/images/icon/address/business_id.svg'
import AddressLogo from './../../static/images/icon/address/address.svg'
import CompanyLogo from './../../static/images/icon/address/company.svg'
import ContactLogo from './../../static/images/icon/address/contact.svg'
import GeneralTable from '../Components/GeneralTable';
import Joining from './../../static/images/icon/address/datejoining2.svg'
import Email from './../../static/images/icon/address/email 2.svg'
import Gender from './../../static/images/icon/address/gender 2.svg'
import Role from './../../static/images/icon/address/Role Type 2.svg'
import Address from './../../static/images/icon/address/address2.svg'
// import EmailLogo from './../../static/images/icon/address/email .svg'
// import Joining from './../../static/images/icon/address/joiningDate'
import history from './../../services/history'

// Css
import './../../static/css/workforce.css'
//leave tracker table
import { attendanceListSelector, userLeavesSelector } from '../../state/redux/attendance/selectors';
import { callGetUserLeavesApi, getUserLeaves } from '../../state/redux/attendance/action';
import UpdateExpense from './Modal/UpdateExpense';
import UpdateDocuments from './Modal/UpdateDocuments';
import UpdateBankDetail from './Modal/UpdateBankDetails';
import { getLeaveType } from '../../state/redux/category/actions';
import { toast } from 'react-toastify'
import { confimToast, warnToast } from '../../util/customToast';
import { show_notif } from '../../services/functions';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const years = generateArrayOfYears();
function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 9
    let years = []

    for (var i = max; i >= min; i--) {
        years.push(i)
    }
    return years
}
const DEFAULT_PROFILE_URL = "https://cdn-icons-png.flaticon.com/512/3135/3135715.png";
const UserView = () => {
    const { _id } = useParams();
    const dispatch = useDispatch();

    const loggedUser = useSelector(state => state.workforceDetail.loggedUser);
    const { loading, user, banks, documents } = useSelector(userDetailSelector);
    const [profileImg, setProfileImg] = useState(DEFAULT_PROFILE_URL);
    const [ledgerPage, setLedgerPage] = useState(1);
    const [leavesPage, setLeavesPage] = useState(1);
    const [deleteExpenseId, setDeleteExpenseId] = useState(null);
    const [updateExpenseObj, setUpdateExpenseObj] = useState(null);
    const [deleteDocumentId, setDeleteDocumentId] = useState(null);
    const [updateDocumentObj, setUpdateDocumentObj] = useState(null)
    const [deleteBankId, setDeleteBankId] = useState(null)
    const [updateBankObj, setUpdateBankObj] = useState(null)
    const [pageLimit, setPageLimit] = useState(10);
    const [leavesEntries, setLeavesEntries] = useState(10);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [imageArrays, setImageArrays] =useState([])
    // leaves 
    const { data: leaves, count: totalLeaves } = useSelector(userLeavesSelector);
    // const leaveTrackerData = leaves.map((l,i)=>{
    //     let row=[]
    //     row.push(i+1);
    //     row.push(l.date);
    //     row.push("");
    //     row.push("");
    //     row.push(l.status);
    //     row.push("");
    //     return row;
    // })


    const { data: expenses, count: total_expenses } = useSelector(expensesSelector);
    const leaveBalance = useSelector(leaveBalanceSelector);
    const [activeAccordion, setActiveAccordion] = useState("ledger")

    /* Functions for Modal Open & Close */
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setUpdateDocumentObj(null);
    }

    /* Callback Functions for Modals to get form information */
    const addBank = (bankObj) => {
        dispatch(addBankAction.call({
            ...bankObj,
            userId: _id,
            documents: bankObj.documents.map(file => file),
            toast: true
        }, () => {
            dispatch(getBankAction.call({
                userId: _id
            }))
        }))
    }

    const addDocuments = (documentObj) => {
        documentObj.documents = documentObj.documents.map(file => file)
        dispatch(addDocumentAction.call({
            ...documentObj,
            userId: _id,  
            toast: true
        }, () => {
            dispatch(getDocumentAction.call({
                userId: _id
            }))
        }))
    }

    const _updateDocuments = (data) => {

        data.toast = true;
        dispatch(updateDocuments.call(data, () => {
            dispatch(callGetDocumentsApi({
                userId: _id
            }))
        }))
    }

    const handleUpdateDocument = (obj) => {
        setUpdateDocumentObj(obj);
        _handleClick('updateDocumentsModal');
    }

    const confirmDeleteDocuments = () => {
        dispatch(deleteDocuments.call({ documentId: deleteDocumentId, toast: true }, () => {
            dispatch(callGetDocumentsApi({
                userId: _id
            }))
        }))
    }

    const handleDeleteDocuments = (id) => {
        setDeleteDocumentId(id);
        _handleClick('deleteDocumentsConfirmModal')

    }

    const handleDeleteDocumentsToast = (id) => {
        confimToast(
            {
                message: 'Are you sure you want to delete this document record ?',
                callback: ()=>{
                    dispatch(deleteDocuments.call({ documentId: id, toast: true }, () => {
                        dispatch(callGetDocumentsApi({
                            userId: _id
                        }))
                    }))
                }
            }
        )
        // const Confirm = ({ closeToast, toastProps }) => {
        //     return (
        //         <div className="confirmation-toast">
        //             Are you sure you want to delete this document record ?
        //             <button
        //                 className="confirmation-toast-btn toast-confirm"
        //                 onClick={() => {
        //                     dispatch(deleteDocuments.call({ documentId: id, toast: true }, () => {
        //                         dispatch(callGetDocumentsApi({
        //                             userId: _id
        //                         }))
        //                     }))
        //                     closeToast();
        //                 }}
        //             >
        //                 Yes, delete it.
        //             </button>
        //             <button
        //                 onClick={closeToast}
        //                 className="confirmation-toast-btn toast-cancel"
        //             >
        //                 No, go back.
        //             </button>
        //         </div>
        //     );
        // };
        // toast.warn(Confirm);
    };
    ///

    const _updateBank = (data) => {
        data.toast = true;
        dispatch(updateBank.call(data, () => {
            dispatch(callGetBankApi({
                userId: _id
            }))
        }))
    }

    const handleUpdateBank = (obj) => {
        setUpdateBankObj(obj);
        _handleClick('updateBankDetailModal');
    }

    const confirmDeleteBank = () => {
        dispatch(deleteBank.call({ bank_details_id: deleteBankId, toast: true }, () => {
            dispatch(callGetBankApi({
                userId: _id
            }))
        }))
    }

    const handleDeleteBank = (id) => {
        setDeleteBankId(id);
        _handleClick('deleteBankConfirmModal')

    }

    const handleDeleteBankToast = (id) => {
        const Confirm = ({ closeToast, toastProps }) => {
            return (
                <div className="confirmation-toast">
                    Are you sure you want to delete this bank ?
                    <button
                        className="confirmation-toast-btn toast-confirm"
                        onClick={() => {
                            dispatch(deleteBank.call({ bank_details_id: id, toast: true }, () => {
                                dispatch(callGetBankApi({
                                    userId: _id
                                }))
                            }))
                            closeToast();
                        }}
                    >
                        Yes, delete it.
                    </button>
                    <button
                        onClick={closeToast}
                        className="confirmation-toast-btn toast-cancel"
                    >
                        No, go back.
                    </button>
                </div>
            );
        };
        toast.warn(Confirm);
    };


    const addCredit = (body) => {
        let data;
        dispatch(addExpense.call({
            ...body,
            driverDetails:{
                _id:user.driver_ref_id,
                name:user.name,
                emp_id:user.emp_id,
                mobile:user.mobile,
                email:user.email,
                license_no:user.license_no,
            },
            expense_type: "user",
            transaction_type: "credit",
            driverId: _id,
            toast: true,
        }, () => {
            dispatch(getExpense.call({
                "transaction_type": "all",
                "expense_type": "user",
                "limit": pageLimit,
                "page_no": ledgerPage,
                "searchBy": "driver",
                "search": _id,
                year: selectedYear,
                month: selectedMonth,
            }))
            refreshUserDetail();
        }))
    }

    const addDebit = (body) => {
        dispatch(addExpense.call({
            ...body,
            expense_type: "user",
            transaction_type: "debit",
            driverId: _id,
            driverDetails:{
                _id:user.driver_ref_id,
                name:user.name,
                emp_id:user.emp_id,
                mobile:user.mobile,
                email:user.email,
                license_no:user.license_no,
            },
            toast: true
        }, () => {
            dispatch(getExpense.call({
                "transaction_type": "all",
                "expense_type": "user",
                "limit": pageLimit,
                "page_no": ledgerPage,
                "searchBy": "driver",
                "search": _id,
                year: selectedYear,
                month: selectedMonth,
            }))
            refreshUserDetail();
        }))
    }

    const addSalary = (body) => {
        dispatch(addExpense.call({
            ...body,
            isSalary: true,
            toast: true
        }, () => {
            dispatch(getExpense.call({
                "transaction_type": "all",
                "expense_type": "user",
                "limit": pageLimit,
                "page_no": ledgerPage,
                "searchBy": "driver",
                "search": _id,
                year: selectedYear,
                month: selectedMonth,
            }))
            refreshUserDetail();
        }))
    }

    const confirmDeleteUser = () => {
        dispatch(callDeleteUserApi({ userId: user._id }, () => {
            history.push("/dashboard/workforce");
        }))
    }

    // const handleUpdateUser = (obj) => {
    //     setUpdateUserObj(obj)
    //     _handleClick('updateUserModal')
    // }

    const updateUser = (userId, data) => {
        dispatch(updateUserAction.call({
            ...data,
            userId: userId,
            userDocs: [],
            toast: true
        }, () => {
            dispatch(getUserDetail.call({ userId: userId }, (res) => {
                if (res) {
                    dispatch(getFile.call({
                        key: res?.data?.profile_pic
                    }, (image_data) => {
                        if (image_data) {
                            setProfileImg(image_data?.data || DEFAULT_PROFILE_URL);
                        }

                    }))
                }
            }))
        }))
        // dispatch(callUpdateUserApi({
        //     ...data,
        //     userId: userId,
        //     userDocs: []
        // }, callGetUserDetailApi))
    }
    const documentFunction = (item, key) => {
        if(key === 'doc'){
            let files = item?.documents
            setImageArrays(files)
        }
        else if(key === 'bank'){
            let files = item?.documents
            setImageArrays(files)
        }
        
    }

    const handleDeleteUser = (id) => {
        const Confirm = ({ closeToast, toastProps }) => {
            return (
                <div className="confirmation-toast">
                    Are you sure you want to delete {user.name}
                    <button
                        className="confirmation-toast-btn toast-confirm"
                        onClick={() => {
                            dispatch(deleteUserAction.call({ userId: user._id }, () => {
                                dispatch(getUsersAction.call({}, () => {
                                    history.push("/dashboard/workforce");
                                }))

                            }))
                            closeToast();
                        }}
                    >
                        Yes, delete it.
                    </button>
                    <button
                        onClick={closeToast}
                        className="confirmation-toast-btn toast-cancel"
                    >
                        No, go back.
                    </button>
                </div>
            );
        };
        toast.warn(Confirm);
    };

    const handleDeleteExpenseToast = (id) => {
        const Confirm = ({ closeToast, toastProps }) => {
            return (
                <div className="confirmation-toast">
                    Are you sure you want to delete this expense record
                    <button
                        className="confirmation-toast-btn toast-confirm"
                        onClick={() => {
                            dispatch(deleteExpense.call({ expense_id: id, toast: true }, (res_data) => {
                                dispatch(getExpense.call({
                                    "transaction_type": "all",
                                    "expense_type": "user",
                                    "limit": pageLimit,
                                    "page_no": ledgerPage,
                                    "searchBy": "driver",
                                    "search": _id,
                                    year: selectedYear,
                                    month: selectedMonth,
                                }))
                                refreshUserDetail();
                            }))
                            closeToast();
                        }}
                    >
                        Yes, delete it.
                    </button>
                    <button
                        onClick={closeToast}
                        className="confirmation-toast-btn toast-cancel"
                    >
                        No, go back.
                    </button>
                </div>
            );
        };
        toast.warn(Confirm);
    };


    const handleDeleteExpense = (id, modalId) => {
        setDeleteExpenseId(id);
        _handleClick(modalId);
    }

    const confirmDeleteExpense = () => {
        dispatch(deleteExpense.call({ expense_id: deleteExpenseId, toast: true }, (res_data) => {
            dispatch(getExpense.call({
                "transaction_type": "all",
                "expense_type": "user",
                "limit": pageLimit,
                "page_no": ledgerPage,
                "searchBy": "driver",
                "search": _id,  
                year: selectedYear,
                month: selectedMonth,
            }, () => {
                refreshUserDetail();
                setDeleteExpenseId(null);
            }))
        }))
    }

    const handleUpdateExpense = (expense) => {
        setUpdateExpenseObj({ ...expense })
        if (expense.isSalary) {
            _handleClick('updateSalaryModal')
        }
        else {
            _handleClick('updateExpenseModal')
        }
    }


    const _updateExpense = (expenseId, data) => {
        data.toast = true;
        dispatch(updateExpense.call(data, (res) => {
            dispatch(getExpense.call({
                "transaction_type": "all",
                "expense_type": "user",
                "limit": pageLimit,
                "page_no": ledgerPage,
                "searchBy": "driver",
                "search": _id,
                year: selectedYear,
                month: selectedMonth,
            }))
            refreshUserDetail();
        }))
        // dispatch(callUpdateUserApi({
        //     ...data,
        //     userId: userId,
        //     userDocs: []
        // }))
    }



    const expensesTableData = expenses.map((expense, i) => {
        let row = []
        row.push(i + 1);
        row.push(expense.expense_name);
        row.push(moment(expense.expense_date).format("DD/MM/YYYY"))
        row.push(expense.transaction_type == "credit" ? expense?.amount_paid.toFixed(2) : 0)
        row.push(expense.transaction_type == "debit" ? expense?.amount_paid.toFixed(2) : 0)
        row.push(
            <>
            <span style={{marginRight: "0px"}}>{expense.payment_mode}</span>  
            {expense.transaction_bank && expense.transaction_bank._id? <p style={{margin: "2px",textAlign:"center",fontWeight:"600"}}>{expense?.transaction_bank?.bankName +" - "+expense?.transaction_bank?.accountNumber}</p>:""}          
            </>
        )
        row.push(expense.notes ? expense.notes : "-");
        row.push(
            <>
                <button className='btn-outline-orange' onClick={() => handleDeleteExpenseToast(expense._id)}>
                    <i className='fa fa-trash'></i>
                </button>
                <button className='btn-outline-orange ms-2' onClick={() => handleUpdateExpense(expense)}>
                    <i className='fa fa-edit'></i>
                </button>
            </>
        )

        return row
    })
    const handleMonth = (val) => {
        let month = val.target.value;
        setSelectedMonth(month)

        dispatch(getExpense.call({
            "transaction_type": "all",
            "expense_type": "user",
            "limit": pageLimit,
            "page_no": ledgerPage,
            "searchBy": "driver",
            "month": +month,
            "year": selectedYear,
            "search": _id
        }))
    }
    const handleYear = (e) => {
     
        let year = e.target.value;
        setSelectedYear(year);
        dispatch(getExpense.call({
            "transaction_type": "all",
            "expense_type": "user",
            "limit": pageLimit,
            "page_no": ledgerPage,
            "searchBy": "driver",
            "month": selectedMonth,
            "year": year,
            "search": _id
        }))
    }

    const handleLedgerPage = (p) => {
        dispatch(getExpense.call({
            "transaction_type": "all",
            "expense_type": "user",
            "limit": pageLimit,
            "month": selectedMonth,
            "year": selectedYear,
            "page_no": p,
            "searchBy": "driver",
            "search": _id
        }))
        setLedgerPage(p);
    }
    const handleLeavesPage = (p) => {
        dispatch(getUserLeaves.call({
            limit: leavesEntries,
            page_no: p,
            userId: _id
        }))
        setLeavesPage(p);
    }

    /* Document on load APIs */

    useEffect(() => {
        if (activeAccordion == "all_documents") {
            dispatch(callGetDocumentsApi({
                userId: _id
            }))

        } else if (activeAccordion == "leave_tracker") {
            dispatch(getUserLeaves.call({
                limit: leavesEntries,
                page_no: leavesPage,
                userId: _id
            }));

            dispatch(getLeaveType.call({}, (res) => {
                if (res) {
              
                    for (let i = 0; i < res.data.length; i++) {
                        const leaveType = res.data[i].leaveType;
                        const leaveCategory = res.data[i].leaveCategory;
                       
                        if(leaveCategory != 'unpaid'){
                            // console.log(leaveType)
                            dispatch(getLeaveBalance.call({
                                userId: _id,
                                leaveType: leaveType
                            }))
                        }

                    }
                }
            }))

        } else if (activeAccordion == "bank_details") {
            dispatch(callGetBankApi({
                userId: _id
            }))

        } else if (activeAccordion == "ledger") {
            dispatch(getExpenseType.call({
                expense_type: "user"
            }))

            dispatch(callGetBankApi({
                userId: _id
            }))

            dispatch(getExpense.call({
                "transaction_type": "all",
                "expense_type": "user",
                "limit": pageLimit,
                "page_no": ledgerPage,
                "searchBy": "driver",
                "month": selectedMonth,
                "year": selectedYear,
                "search": _id
            }))

        }
    }, [activeAccordion, pageLimit])

    const refreshUserDetail = () => {
        dispatch(getUserDetail.call({ userId: _id }, (res_data) => {
            dispatch(getFile.call({
                key: res_data?.data?.profile_pic
            }, (image_data) => {
                if (image_data) {
                    setProfileImg(image_data?.data || DEFAULT_PROFILE_URL);
                }

            }))
        }))
    }

    // console.log({profileImg})
    useEffect(() => {
        refreshUserDetail();
    }, [])

    return (
        <>
            {
                user &&
                <div className='contentpanel'>
                    <div className='cust-container'>
                        <UpdateUser closeModal={_closeModal} updateUser={updateUser} updateUserObj={user} />
                        <Confirm id='deleteUserConfirmModal' closeModal={_closeModal} confirm={confirmDeleteUser} />
                        <div className='cust-row'>
                            <div className='cust-col-5'>
                                <h1 className='title pt-1'>User Management</h1>
                            </div>
                            <div className='cust-col-5 text-end'>
                                <StatusToggle />
                                {
                                    user.balance
                                        ?
                                        <button className='btn btn-orange'><i className="fa fa-money"></i> Balance {user.balance.toFixed(2)}</button>
                                        :
                                        <button className='btn btn-orange'><i className="fa fa-money"></i> Balance 0</button>

                                }
                                <button className='btn btn-outline-success ms-1' onClick={() => _handleClick('updateUserModal')}><i className="fa fa-pencil-square-o"></i> Update</button>
                                <button className='btn btn-outline-danger ms-1' onClick={() => handleDeleteUser(user._id)}><i className="fa fa-trash-o"></i> Delete</button>
                            </div>

                        </div>
                        <div className='cust-row'>
                            <div className='cust-col-10 subheading'>
                                <div className='cust-row'>
                                    <div className='cust-col-2'></div>
                                    <div className='cust-col-2'>
                                        <h5>{user.name ? user.name : "NA"}</h5>
                                    </div>
                                    <div className='cust-col-2'>
                                        <h5>{user.user_type ? user.user_type : "NA"}</h5>
                                    </div>
                                    <div className='cust-col-2'>
                                        <h5>{user.readableId ? user.readableId : "NA"}</h5>
                                    </div>
                                    <div className='cust-col-2'>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='cust-row'>
                            <div className='cust-col-10'>
                                <div className='detail_content'>
                                    <div className='cust-row'>
                                        <div className='cust-col-2 text-center'>
                                            <img src={profileImg} className="img-thumbnail p-2" />
                                        </div>
                                        <div className='cust-col-6 ps-3 pe-3'>
                                            <table className='book_tbl'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span className='tbl_tt'>
                                                                <img src={BusinessLogo} alt="" />
                                                                Date of Birth
                                                            </span>
                                                            <span className='tbl_txt'>{moment(user.dob).format('DD/MM/YYYY')}</span>
                                                        </td>
                                                        <td>
                                                            <span className='tbl_tt'>
                                                                <img src={ContactLogo} alt="" />
                                                                Mobile No
                                                            </span>
                                                            <span className='tbl_txt'>{user.mobile}</span>
                                                        </td>
                                                        <td className='wd25'>
                                                            <span className='tbl_tt'>
                                                                <img src={Email} alt="" />
                                                                Email
                                                            </span>
                                                            <span className='tbl_txt'>{user.email ? user.email : "NA"}</span>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className='tbl_tt'>
                                                                <img src={Gender} alt="" />
                                                                Gender
                                                            </span>
                                                            <span className='tbl_txt'>{user.gender ? user.gender : "NA"}</span>
                                                        </td>
                                                        <td>
                                                            <span className='tbl_tt'>
                                                                <img src={Joining} alt="" />
                                                                Date of Joining
                                                            </span>
                                                            <span className='tbl_txt'>{moment(user.doj).format('DD/MM/YYYY')}</span>
                                                        </td>
                                                        <td>
                                                            <span className='tbl_tt'>
                                                                <img src={Role} alt="" />
                                                                Role Type
                                                            </span>
                                                            <span className='tbl_txt'>{user.role_type ? user.role_type : "NA"}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className='tbl_tt'>
                                                                <img src={Address} alt="" />
                                                                Address
                                                            </span>
                                                            <span className='tbl_txt'>{user.address ? user.address : "NA"}</span>
                                                        </td>
                                                        {
                                                            loggedUser && loggedUser.permissions.viewSalary &&
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={KYCLogo} alt="" />
                                                                    Salary
                                                                </span>
                                                                <span className='tbl_txt'>{user.salary ? user.salary : "NA"}</span>
                                                            </td>
                                                        }

                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className='cust-col-2'>
                                            <div className={activeAccordion == 'all_documents' ? 'button bti bg-grey-out' : 'button bti bg-orange-out'} onClick={() => setActiveAccordion('all_documents')}>
                                                <div className='btn_icon'>
                                                    <i className='fa fa-file-text'></i>
                                                </div>
                                                <div className='btn_txt'>All Documents</div>
                                            </div>
                                            <div className={activeAccordion == 'leave_tracker' ? 'button bti bg-grey-out' : 'button bti bg-orange-out'} onClick={() => setActiveAccordion('leave_tracker')}>
                                                <div className='btn_icon'>
                                                    <i className='fa fa-calendar-check-o'></i>
                                                </div>
                                                <div className='btn_txt'>Leave Tracker</div>
                                            </div>
                                            <div className={activeAccordion == 'bank_details' ? 'button bti bg-grey-out' : 'button bti bg-orange-out'} onClick={() => setActiveAccordion('bank_details')}>
                                                <div className='btn_icon'>
                                                    <i className='fa fa-university'></i>
                                                </div>
                                                <div className='btn_txt'>Bank Details</div>
                                            </div>
                                            <div className={activeAccordion === 'ledger' ? 'button bti bg-grey-out' : 'button bti bg-orange-out'} onClick={() => setActiveAccordion('ledger')}>
                                                <div className='btn_icon'>
                                                    <i className='fa fa-money'></i>
                                                </div>
                                                <div className='btn_txt'>User Ledger</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <hr />

                        <div className='cust-row'>
                            <div className='cust-col-10 accordion-orange'>
                                <Accordion defaultActiveKey="0">
                                    {
                                        activeAccordion == 'leave_tracker' &&
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header >Leave Tracker</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='row'>
                                                    {
                                                        Object.keys(leaveBalance).map((key, i) => {
                                                            // console.log({leaveBalance})
                                                            return (
                                                                <div className='col-md-2 mt-2' key={i}>
                                                                    <div className='text-center'>
                                                                        <h6 className="text">{key}</h6>
                                                                    </div>
                                                                    <div className='left-orange-strip-box'>
                                                                        <p className="text text-orange">{leaveBalance[key]}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <br />
                                                <div className='cust-row'>
                                                    <div className="cust-col-2">

                                                        <div className="input-group">
                                                            <label className="input-group-text">
                                                                Show Entries
                                                            </label>
                                                            <select className="form-select" onChange={(e) => setLeavesEntries(e.target.value)}>
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={40}>40</option>
                                                                <option value={50}>50</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='cust-row'>
                                                    <table className='workforce-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Category</th>
                                                                <th>Type</th>
                                                                <th>From Date</th>
                                                                <th>To Date</th>

                                                                <th>Status</th>
                                                                <th>Reason</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                leaves.length > 0 ?

                                                                    leaves.map((l, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{i + 1}</td>
                                                                                <td>{l.leaveDetails ? l.leaveDetails.leaveType : ""}</td>
                                                                                <td>{l.leaveDetails ? (l.leaveDetails.leaveCategory || 'paid') : ""}</td>
                                                                                <td>{moment(l.date).format('DD/MM/YYYY')}</td>
                                                                                <td>{moment(l.date).format('DD/MM/YYYY')}</td>
                                                                                <td className={l.status}>{l.status}</td>
                                                                                <td>{l.leaveDetails ? l.leaveDetails.reason : ""}</td>
                                                                            </tr>
                                                                        )

                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={100}>No Records</td>
                                                                    </tr>

                                                            }

                                                        </tbody>

                                                    </table>
                                                </div>
                                                <div className="cust-row">
                                                    <div className="cust-col-5"></div>
                                                    <div className="cust-col-5">
                                                        <nav aria-label="Page navigation example" className="cust-pagination">
                                                            <ul className="pagination justify-content-end">
                                                                {
                                                                    Array(Math.ceil(totalLeaves / leavesEntries)).fill(0).map((_, i) => {
                                                                        return <li key={i} className={"page-item " + (i + 1 == leavesPage ? "active" : "")} onClick={() => handleLeavesPage(i + 1)}><a className="page-link">{i + 1}</a></li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }

                                    {
                                        activeAccordion == 'bank_details' &&
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Bank Details</Accordion.Header>
                                            <Accordion.Body>
                                                <AddBankDetail closeModal={_closeModal} addBank={addBank} />
                                                <UpdateBankDetail closeModal={_closeModal} updateBank={_updateBank} bankObj={updateBankObj} />
                                                <ViewDocument imageArrays={imageArrays} closeModal={_closeModal}  />
                                                <Confirm id="deleteBankConfirmModal" closeModal={_closeModal} confirm={confirmDeleteBank} />
                                                <div className='text-end'>
                                                    <button className='btn btn-sm btn-orange' onClick={() => _handleClick('addBankDetailModal')}>Add Bank <i className="fa fa-plus"></i></button>
                                                </div>
                                                <div className='cust-row mt-3' style={{display:'flex', flexWrap:'wrap'}}>
                                                    {
                                                        banks.map((bank, i) => {
                                                            return (
                                                                <div className='cust-col-3 p-2 bank-card ms-1' style={{margin:'5px'}}  key={i}>
                                                                    <table className='table'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <p><strong>Beneficiary Name : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank.beneficiaryName}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <p><strong>Bank Name : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank.bankName}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <p><strong>Account Number : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank.accountNo}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <p><strong>Account Type : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank.accountType}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <p><strong>Branch Name : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank.branchName}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <p><strong>IFSC Code : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank.ifscCode}
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{position:'relative'}}>
                                                                                <td>
                                                                                    <p><strong>Number of Files : </strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    {bank?.documents?.length}
                                                                                    {bank?.documents?.length !== 0 ?
                                                                                    <i onClick={() => {_handleClick('ViewDocument'); documentFunction(bank,'bank')}} style={{position:'absolute', right:'10px', top:'14px', cursor:'pointer'}} className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                    :''}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <button className='btn btn-sm btn-danger' onClick={() => handleDeleteBankToast(bank._id)}>Delete</button>
                                                                    <button className='btn btn-sm btn-success float-end' onClick={() => handleUpdateBank(bank)}>Update</button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                    {
                                        activeAccordion == 'all_documents' &&
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Document Details</Accordion.Header>
                                            <Accordion.Body>
                                                <AddDocuments closeModal={_closeModal} addDocuments={addDocuments} />
                                                <UpdateDocuments closeModal={_closeModal} updateDocuments={_updateDocuments} updateObj={updateDocumentObj} />
                                                <Confirm id="deleteDocumentsConfirmModal" closeModal={_closeModal} confirm={confirmDeleteDocuments} />
                                                <ViewDocument imageArrays={imageArrays} closeModal={_closeModal}  />
                                                <div className='text-end'>
                                                    <button className='btn btn-sm btn-orange' onClick={() => _handleClick('addDocumentsModal')}>Add Document <i className="fa fa-plus"></i></button>
                                                </div>

                                                <div className='cust-row ' style={{flexWrap:'wrap'}}>
                                                    {
                                                        documents.map((document, i) => {
                                                            return (
                                                                <div className='cust-col-3 p-1 bank-card ms-1' style={{margin:'8px'}} key={i}>
                                                                    <table className='table'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><strong>Documents Name</strong></td>
                                                                                <td>{document.document_name}</td>
                                                                            </tr>
                                                                            <tr style={{position:'relative'}}>
                                                                                <td><strong>Number of Files</strong></td>
                                                                                <td>{document.documents.length}</td>
                                                                                {document.documents.length !== 0 ?
                                                                                <i onClick={() => {_handleClick('ViewDocument'); documentFunction(document,'doc')}} style={{position:'absolute', right:'10px', top:'7px', cursor:'pointer'}} className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                :''}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {/* <button className='btn btn-sm btn-danger' onClick={()=> handleDeleteDocumentsToast(document._id)}>Delete</button> */}
                                                                    <button className='btn btn-sm btn-success float-end' onClick={() => handleUpdateDocument(document)}>Update</button>

                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                    {
                                        activeAccordion == 'ledger' &&
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>User Ledger</Accordion.Header>
                                            <Accordion.Body>
                                                <Confirm id="deleteExpenseConfirmModal" closeModal={_closeModal} confirm={confirmDeleteExpense} />
                                                <AddCredit closeModal={_closeModal} addCredit={addCredit} userId={_id} balance={user && user.balance ? user.balance : 0} />
                                                <AddDebit closeModal={_closeModal} addDebit={addDebit} />
                                                <AddSalary closeModal={_closeModal} userObj={user} userId={_id} balance={user && user.balance ? user.balance : 0} addSalary={addSalary} />
                                                <UpdateExpense closeModal={_closeModal} updateExpense={_updateExpense} expenseObj={updateExpenseObj} />
                                                <UpdateSalary closeModal={_closeModal} updateExpense={_updateExpense} expenseObj={updateExpenseObj} userObj={user} />
                                                <div className='cust-row '>
                                                    <div className='cust-col-2'>
                                                        <div className="input-group">
                                                            <label className="input-group-text">
                                                                Show Entries
                                                            </label>
                                                            <select className="form-select" onChange={(e) => setPageLimit(e.target.value)}>
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={40}>40</option>
                                                                <option value={50}>50</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='cust-col-2'>
                                                        {/* <div className='input-group'> */}
                                                        {/* <label className='input-group-text'>Select Month</label> */}
                                                        <select className='form-select' onChange={(e) => handleMonth(e)} value={selectedMonth}>
                                                            <option value="">Month</option>
                                                            {months.map((month, index) => {
                                                                return (
                                                                    <option value={index + 1}>{month}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {/* </div> */}
                                                    </div>
                                                    <div className='cust-col-2'>
                                                        {/* <div className='input-group'> */}
                                                        {/* <label className='input-group-text'>Select Month</label> */}
                                                        <select className='form-select' onChange={(e) => handleYear(e)} value={selectedYear}>
                                                            <option value="">Year</option>
                                                            {years.map((year) => {
                                                                return (
                                                                    //    year==currentYear ? <option value={year} selected>{year}</option>:<option value={year} selected>{year}</option>
                                                                    <option value={year}>{year}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {/* </div> */}
                                                    </div>
                                                    <div className='cust-col-4 text-end'>
                                                        <button className='btn btn-sm btn-orange' onClick={() => _handleClick('addSalaryModal')}>Add Salary <i className="fa fa-plus"></i></button>
                                                        <button className='btn btn-sm btn-orange ms-2' onClick={() => _handleClick('addCreditModal')}>Add Credit <i className="fa fa-plus"></i></button>
                                                        <button className='btn btn-sm btn-orange ms-2' onClick={() => _handleClick('addDebitModal')}>Add Debit <i className="fa fa-plus"></i></button>
                                                    </div>

                                                </div>
                                                <br />
                                                {/* <div className='cust-row'>
                                                    <div className="cust-col-2">

                                                        <div className="input-group">
                                                            <label className="input-group-text">
                                                                Show Entries
                                                            </label>
                                                            <select className="form-select" onChange={(e)=>setPageLimit(e.target.value)}>
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={40}>40</option>
                                                                <option value={50}>50</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div> */}
                                                <div className='cust-row'>
                                                    <div className='cust-col-10'>
                                                        <GeneralTable
                                                            headers={["Sno", "Type", "Date", "Debit Amount", "Credit Amount", "Payment Mode", "Remarks", "Actions"]}
                                                            rows={expensesTableData}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="cust-row">
                                                    <div className="cust-col-5"></div>
                                                    <div className="cust-col-5">
                                                        <nav aria-label="Page navigation example" className="cust-pagination">
                                                            <ul className="pagination justify-content-end">
                                                                {
                                                                    Array(Math.ceil(total_expenses / pageLimit)).fill(0).map((_, i) => {
                                                                        return <li key={i} className={"page-item " + (i + 1 == ledgerPage ? "active" : "")} onClick={() => handleLedgerPage(i + 1)}><a className="page-link">{i + 1}</a></li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                </Accordion>

                            </div>

                        </div>
                    </div>
                </div>

            }





        </>
    );
};

export default UserView;

//user name/type get from userdetail from _id

export const StatusToggle = memo(() => {
	const dispatch = useDispatch();
	const loggedUser = useSelector((state) => state.workforceDetail.loggedUser);
	const { _id: userId } = useSelector(
		(state) => state.workforce.userDetail.user
	);
	const userStatus = useSelector(
		(state) => state.workforce.userDetail.status
	);
	const handleToggle = () => {
		if (
			(userStatus === "Active" &&
				loggedUser?.permissions?.DeactivateUser) ||
			(userStatus === "InActive" && loggedUser?.permissions?.activeUser)
		) {
			show_notif(
				{
					message: "",
					title: "Are you sure you want to change the user's status?",
					type: "confirm",
					autohide: false,
				},
				(result) => {
					if (result.type === "confirm") {
						dispatch(
							updateUserStatus.call({
								userId,
								status:
									userStatus === "Active"
										? "InActive"
										: "Active",
							})
						);
					}
				}
			);
		} else {
			// console.log("cant cahnge status");
			show_notif(
				{
					message: "",
					title: "You don't have permission to change the user status",
					type: "confirm",
					autohide: false,
				},
				(result) => {
					if (result.type === "confirm") {
					}
				}
			);
			// warnToast({ message:""})
		}
	};

	return (
		<>
			{loggedUser &&
			(loggedUser.permissions?.activeUser ||
				loggedUser.permissions?.DeactivateUser) ? (
				<span className="statusToggle">
					<label className="switch">
						<input
							type="checkbox"
							onChange={handleToggle}
							checked={userStatus === "Active" ? true : false}
						/>
						<span className="slider round"></span>
					</label>
					<span className="status" aria-label='user status'>
						{userStatus|| "NA"}
					</span>
				</span>
			) : null}
		</>
	);
});

