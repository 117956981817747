import React,{Component} from 'react';
import $ from 'jquery'
import { selectInit } from './../../services/functions'
import history from './../../services/history'
import ConfirmBookingModal from './../Dashboard/Modal/ConfirmBooking'

class Booking extends Component {

    constructor(props){
        super(props)
        this.toogleItemView=this.toogleItemView.bind(this)
        this.confirmBooking=this.confirmBooking.bind(this)
        this.cancelBooking=this.cancelBooking.bind(this)
        this._handleClick=this._handleClick.bind(this)
        this._closeModal=this._closeModal.bind(this)
        this._viewDetail=this._viewDetail.bind(this)
        this.state={
            userType:localStorage.getItem("userType")==='userType2'?'2':"1",
            type:props.type
        }
    }

    componentDidMount(){
        setTimeout(() => {
            selectInit(this._onChange)
        }, 100);
    }

    static getDerivedStateFromProps(nextProps,prevState) {
        if (nextProps.type !== prevState.type) {
          return ({
            type: nextProps.type
          });
        }
        return null
    }

    toogleItemView(index){
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        } 
        else {
            $(".data-cont-item").eq(index).addClass("open")
        }
    }

    confirmBooking(){
        // window.$.fn.show_notification({ message: "confirmation is required for acceptance of booking", title: 'Are you sure!!', type: 'confirm', autohide: false},(result) => {
        //     if (result.type==="confirm") {
        //         this._closeModal()
        //     }
        // })
    }

    _handleClick(id){
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    
    _closeModal(){ 
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }    

    cancelBooking(index){
        // window.$.fn.show_notification({ message: "confirmation is required to cancel booking request", title: 'Are you sure!!', type: 'confirm', autohide: false,confirmVal:"Yes, Proceed",showInput:true,inputPlaceholder:"Reason for cancellation?"},(result)=>{
        //     if(result.type==='confirm'){
                
        //     }
        // })
    }

    _viewDetail(){
        history.push("/dashboard/booking/detail")
    }
    
    render(){
        return(
            <div className="contentpanel">
                <h1 className="title">Booking</h1>
                <hr/>
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Booking List</div>
                        </div>
                        <div className='cust-col-4'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="truck_no" id="searchType">
                                        <option value="booking_no">Booking No.</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..."/>
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                    <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="bookingStatus">
                                <option value="all">All Type</option>
                                <option value="received">Pending</option>
                                <option value="confirm">Confirm</option>
                                <option value="canceled">Canceled</option>
                            </select>
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item'>
                                    {this.state.userType==='2'?"":<div className='ttname'>Transporter : <span className='transportrName'>Ajay(ID : XXXX)</span></div>}
                                    <div className='sno_txt'>1.</div>
                                    <div className='data_item_content set_height'>
                                        <div className='wd_15'>
                                            <span>Booking No.</span>
                                            <span className='orange_color font-lg'>(Ekayan)</span>
                                            <span>(Date:Ekayan)</span>
                                        </div>
                                        <div>
                                            <span>From</span>
                                            <span className='flex_box_2'>(Ekayan)</span>
                                        </div>
                                        <div>
                                            <span>To</span>
                                            <span className='flex_box_2'>(Ekayan)</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Status</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Goods Type</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span className='flex_box_2'>Goods Description</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Goods Weight(KG)</span>
                                            <span>(Ekayan)</span>
                                        </div>                                        
                                    </div>
                                    <div className='data_item_content clps'>
                                        {this.state.type==='all' || this.state.type===''?
                                        <>
                                        <div className='wd_10'>
                                            <span>Number of Goods</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Need Vehicle by Date</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Desired Date of Delivery</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Vehicle Type</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Shipment Type</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Contract Name</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Contract Rate</span>
                                            <span>(Ekayan)</span>
                                        </div>
                                        </>
                                        :""} 
                                        {this.state.type==='truck-reporting'?
                                        <>
                                        <div className='wd_10'>
                                            <span>Driver Name</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>DL Number</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Vehicle Number</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Engine No.</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Chasis No.</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>GPS Status</span>
                                            <span>(Vendor API)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>GPS No.</span>
                                            <span>(Vendor API)</span>
                                        </div>
                                        </>
                                        :""} 
                                        {this.state.type==='inspection'?
                                        <>
                                        <div className='wd_100'>
                                            <span>Inspection</span>
                                            <span>
                                                <i className='fa fa-check-circle-o font-xl green_color mr-1'></i>
                                                <span className='color_green font-lg'>Pass</span>
                                            </span>
                                        </div>
                                        </>
                                        :""} 
                                        {this.state.type==='gatein'?
                                        <>
                                        <div className='wd_10'>
                                            <span>Gated In</span>
                                            <span>Yes</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Gated In Time</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_25'>
                                            <span>Gate In Allowed By</span>
                                            <span>(Lynkit)</span>
                                        </div>
                                        <div className='wd_25'>
                                            <span>Weightment</span>
                                            <span>Done</span>
                                        </div>
                                        <div className='wd_25'>
                                            <span>Loading Status</span>
                                            <span>Pending</span>
                                        </div>
                                        </>
                                        :""}                                        
                                        <div className='btn_conte'>
                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1'>View Documents</button>
                                            {this.state.userType==='2'?<button className='btn btn-shadow btn-sm btn-outline-success ml-1 mr-1' onClick={()=>{this._handleClick('confirmBookingModal')}}>Allocate Truck</button>:""}
                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1' onClick={()=>{this.cancelBooking(0)}}>Cancel Booking</button>
                                            {/* <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>Create Receipt</button> */}
                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1' onClick={()=>{this._viewDetail()}}>View Detail</button>
                                        </div>
                                    </div>
                                    <div className='collps_icon' onClick={()=>{this.toogleItemView(0)}}>
                                        <i className='fa fa-chevron-down'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmBookingModal closeModal={this._closeModal} confirmBooking={this.confirmBooking}/>
            </div>
        )
    }
}

export default Booking;