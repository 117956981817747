import React,{useState} from 'react';
import KYCLogo from './../../static/images/icon/address/kyc.svg'
import BusinessLogo from './../../static/images/icon/address/business_id.svg'
import AddressLogo from './../../static/images/icon/address/address.svg'
import CompanyLogo from './../../static/images/icon/address/company.svg'
import ContactLogo from './../../static/images/icon/address/contact.svg'
import history from './../../services/history';

const AddressBook=()=> {

    const [lat, setLat]=useState(28.5470088)
    const [lng,setLng]=useState(77.3560634)
  

    const openAddressDetails=()=>{
        history.push("/dashboard/address-book/details")  
    }
    const openAddAddress=()=>{
        history.push("/dashboard/address-book/add-address")
    }
    const openMap=()=>{
        history.push({
            pathname:'/dashboard/Map',
            state: {lat, lng}
        })
    }
    
        return(
            <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-8 flex-algn-cent'>
                        <h1 className="title">Address Book</h1>
                    </div>
                    {/* <div className='cust-col-2 flex-jc-end' onClick={openAddAddress}>
                        <button className='button bti bg-orange m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-plus font-lg'></i>
                            </div>
                            <div className='btn_txt font-lg'>Add Address</div>
                        </button>
                    </div> */}
                </div>
                <hr className='bgg'/>
                <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='book_content'>
                            <div className='book_content_item'>
                                <table className='book_tbl'>
                                    <tbody>
                                        <tr>
                                            <td>
                                            <span className='tbl_tt'>
                                                    <img src={ContactLogo} alt=""/>
                                                    Contact Details
                                                </span>
                                                <span className='tbl_txt'>Contact Person - XYX Mobile - 9540046458</span>                                                
                                            </td>
                                            <td>
                                                <span className='tbl_tt'>
                                                    <img src={AddressLogo} alt=""/>
                                                    Registered Address
                                                </span>
                                                <span className='tbl_txt'>12 Ward No 12, C Block New Delhi - 110020</span>
                                            </td>
                                            <td rowSpan={2} className='wd25'>
                                                <span className='tbl_tt'>
                                                    <img src={KYCLogo} alt=""/>
                                                    KYC Details
                                                </span>
                                                <span className='tbl_txt'>PAN - WEQRT4325G</span>
                                                <span className='tbl_txt'>GSTIN - 12WEQRT4325G1ZR</span>
                                            </td>
                                            <td rowSpan={2} className='actbt'>
                                                <div className='button bti bg-orange-out'>
                                                    <div className='btn_icon'>
                                                        <i className='fa fa-trash'></i>
                                                    </div>
                                                    <div className='btn_txt'>Delete</div>
                                                </div>
                                                <div className='button bti bg-orange-out'>
                                                    <div className='btn_icon'>
                                                        <i className='fa fa-edit'></i>
                                                    </div>
                                                    <div className='btn_txt'>Update</div>
                                                </div>  
                                                <div className='button bti bg-orange-out'>
                                                    <div className='btn_icon'>
                                                        <i className='fa fa-info'></i>
                                                    </div>
                                                    <div onClick={openAddressDetails} className='btn_txt'>Detail</div>
                                                </div>     
                                                <div className='button bti bg-orange-out'>
                                                    <div className='btn_icon'>
                                                        <i  className="fa fa-map-marker"></i>
                                                    </div>
                                                    <div onClick={openMap} className='btn_txt'>View on Map</div>
                                                </div>                                              
                                            </td>                                            
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className='tbl_tt'>
                                                    <img src={CompanyLogo} alt=""/>
                                                    Company Name
                                                </span>
                                                <span className='tbl_txt'>XYX Logistics Pvt. Ltd.</span>
                                            </td>
                                            <td>
                                            <span className='tbl_tt'>
                                                    <img src={CompanyLogo} alt=""/>
                                                    Total Trips Count
                                                </span>
                                                <span className='tbl_txt'>4</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        )
    }


export default AddressBook;