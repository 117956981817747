import React from 'react'

export default function GeneralCalendar({data}) {
  return (
    <div className='attendance-calender'>
        <table>
            <thead>
                <tr>
                    <th>S</th>
                    <th>M</th>
                    <th>T</th>
                    <th>W</th>
                    <th>T</th>
                    <th>F</th>
                    <th>S</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((row, i) => {
                        return (
                            <tr key={i}>
                                {
                                    row.map((col, j) => {
                                        return (
                                            <td key={j} className={col.className}>{col.text}</td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                
            </tbody>
        </table>
    </div>
  )
}
