import React from 'react'


export default function GeneralTable({headers, rows}) {
  return (
    <table className="workforce-table">
        <thead>
            <tr>
                {
                    headers.map((head, i) => {
                        return <th key={i}>{head}</th>
                    })
                }
            </tr>
        </thead>
        <tbody>
            {
                rows.length > 0 
                ?
                    rows.map((row, i) => {
                        return (
                            <tr key={i} data-testid={"test-row-"+row[0]}>
                                {
                                    row.map((cell, j) => {
                                        return <td key={j}>{cell}</td>
                                    })
                                }
                            </tr>
                        )
                    })
                :
                    <tr>
                        <td colSpan={100}>No Records</td>
                    </tr>
            }
        </tbody>
    </table>
  )
}
