import { useEffect } from 'react';
import {useForm} from 'react-hook-form';

const UpdateLeaveType = ({closeModal, updateLeaveType, leaveObj}) => {
    const { register, handleSubmit, getValues, setValue, reset,watch, formState:{ errors} } = useForm();
    const watchLeaveCategory = watch('leaveCategory');

    // console.log(leaveObj);
    const handleSubmitForm = (data) => {
        updateLeaveType(data)
        reset();
        closeModal();
    }
    useEffect(()=>{
        if(leaveObj){
            setValue('leave_type_id', leaveObj._id)
            setValue('leaveType', leaveObj.leaveType);
            setValue('leaveCategory', leaveObj.leaveCategory || 'paid');
            setValue('leaveCount', leaveObj.leaveCount);
        }
    }, [leaveObj])

    return(
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <section id="updateLeaveTypeModal" className="custom-modal custom-modal-small" style={{width:"60%"}}>
                <div className="custom-modal-header">
                <h1>Update Leave Type</h1>
                <span className="ct-close" onClick={closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'75vh'}}>
                    <div className="cust-row">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Leave Category Name<span className="red_color">*</span></label>
                            <input className="cust-input" type="text" placeholder=" Enter Leave Category" {...register('leaveType', { required : true , minLength:2 })} />
                            { errors.leaveType && <p className='error red_color'>Length of Leave Category Name should be minimum 2 </p>}
                        </div>
                        
                    </div>
                    <div className="cust-row">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Leave Type<span className="red_color">*</span></label>
                            <select	className="cust-input select2-single" type="text" {...register("leaveCategory", {required: true})} defaultValue={""}>
							    <option disabled value="">Leave Type</option>
							    <option  value="paid">Paid</option>
						        <option  value="unpaid">Unpaid</option>
                            </select>
                            { errors.leaveCategory && <p className='error red_color'>Leave Type is required</p>}
                        </div>
                    </div>
                    {watchLeaveCategory == 'paid' ?
                    <div className="cust-row mb-2">
                        <div className='cust-col-2'></div>
                        <div className="cust-col-6">
                            <label className="black_color font-bold text-left wdt100">Number Of Leaves<span className="red_color">*</span></label>
                            <input className="cust-input" type="number" placeholder="Enter Number of Leaves" {...register('leaveCount', { required : true , validate: val => val > 0})} />
                            { errors.leaveCount && <p className='error red_color'>Leave number should be greater than 0</p>}


                        </div>
                    </div>
                    : null}
                    <div className="cust-row">
                        <div className="cust-col-3">

                        </div>
                        <div className="cust-col-4">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'>Update</div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UpdateLeaveType