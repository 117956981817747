import React,{useEffect, useState} from 'react';
import { get_pdf_thumbnail,base64Convert } from './../../services/functions'
import $ from 'jquery'
import FileLogo from './../../static/images/icon/vehicle/upload-file.svg'



const ViewDocuments = (props) => {

    const [docModal, setDocModal]=useState(false)
    const [newArray, setNewArray]=useState([])
    const [filename, setfilename]=useState("")
    const [previewState, setPreviewState]=useState("")
    const [togglediv, settogglediv]=useState("")
    const [showdiv, setshowdiv]=useState("")
    const [imageState, setImageState]=useState("")

    useEffect(()=>{
        if(newArray.length !== 0){
            settogglediv("ToggleViewDoc-modal-body")
            setshowdiv("toggleuploadDiv")
        }
        else if(newArray.length === 0){
            settogglediv("ViewDoc-modal-body")
            setshowdiv("hide")
        }    
    },[newArray])


    const uploadDoc=async (e)=>{
        const file=e.target.files[0]
        const Type=file.type
       
        if (Type === "application/pdf"){
            get_pdf_thumbnail(file)
            .then((response)=>{
                let obj={response , fileType:file.type, name:file.name}       
                setNewArray([...newArray,obj])
            }).catch((e)=>{
                console.log("Error",e);
            })            
        }
        else if(Type === "image/png" || Type === "image/jpeg"){
            let base64=await base64Convert(file);   
            let obj={base64 , fileType:file.type, name:file.name}           
            setNewArray([...newArray,obj])
        }
        else{
            alert("Choose .pdf , .png or .jpeg file only.")
        }
    }
    const renderBody = () => {
        const content = [];
        newArray.map((item,index) => {
        content.push(
            <div key={content.length +1} className='uploadedDocRow'>

                <div className='sno'>
                    <span>{index+1}.</span>
                </div>
                <div  className='uploadedDoc-h1'>
                    <span>{item.name}</span>
                </div>
                <div className='uploadedDoc-h2'>
                    <div className='uploadedDocIcons'>
                        <i className="fa fa-eye" id="viewdoc-view" aria-hidden="true" onClick={(e)=>previewFunction(item,e)}></i>
                        <i className="fa fa-times" id="viewdoc-download" onClick={(e)=>removeFile(item,index)} aria-hidden="true"></i>
                    </div>
                </div>

            </div>
        ); 
      });
      return content;
    }
   
    const previewFunction=(item)=>{
        setDocModal(!docModal)
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
       const getImgbase64=item.base64
       const getpdfbase64=item.response
       if(item.fileType === "application/pdf"){
            setPreviewState(getpdfbase64)
            setfilename(item.fileName)
            setImageState("pdfimg")
       }
       else{
        setPreviewState(getImgbase64)
        setfilename(item.fileName)
        setImageState("imgview")
       }    
    }
    
    const closeViewImage=(id)=>{
        setDocModal(!docModal)
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const removeFile=(index)=>{
        let localArr= [...newArray]
        localArr.splice(index,1)
        setNewArray(localArr)
    }
    return(
        <>        
            <section id="viewDocumentsModal" className="custom-modal" style={{width:"45%"}}>
                <div className="custom-modal-header">
                <h1>Upload Documents</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
                </div>
            
                <div className={togglediv} >
                    <div className="cust-row">
                        <div className='cust-col-10'>
                        
                            <div className="documentOuter"> 
                                <div className="documentheading">
                                    <span>Upload Files</span>
                                </div>

                                <div className="documentupload">
                                    <div onChange={(e)=>uploadDoc(e)} className='uploadbtndiv'>
                                        <button>
                                        <img id="docUploadicon" src={FileLogo} alt=""/>
                                            <span>Upload your JPEG, PNG or PDF File. </span>
                                            <input className='viewdoc-uploadbtn' type="file"/>
                                        </button>
                                    </div>

                                </div>
                            </div> 
                        <div className={showdiv}>
                                <div className='uploadedDocDiv'>
                                    {renderBody()}
                                </div>
                                <div className='upload-doc-button'>
                                    <div className='btn_cont'>
                                        <button className='button bti bg-orange-out m-0'>
                                            <div className='btn_icon'>
                                                <i className='fa fa-upload'></i>
                                            </div>
                                            <div className='btn_txt font-lg'>Upload </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
    
            {docModal &&(        
                <div className='docPreview'>
            
                    <div className='docOverlay'></div>
                    <div className='doc-content'>
                        <span className='viewDoc-heading'>View Document</span>

                        <div className='doc-close' onClick={()=>{closeViewImage('viewDocumentsModal')}}>
                            <span><i  className="fa fa-times" id="docView-cross" aria-hidden="true"></i></span>
                        </div>
                        
                        <div className='doc-inner1'>
                        <div className='doc-img-div'>
                            <img className={imageState} src={previewState} alt="" />
                        </div>

                        <div className='doc-download-div'>
                            <a href={previewState} download>Download Image</a>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    )

}

export default ViewDocuments;