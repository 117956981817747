import React, { useEffect, useRef, useState } from "react";
import $ from 'jquery';
import './../../../static/css/workforce.css';
import { useSelector, useDispatch } from "react-redux";
import {  userLeavesSelector } from "../../../state/redux/attendance/selectors";
import { applyMultipleLeaves, getUserLeaves } from "../../../state/redux/attendance/action";
import moment from 'moment';
import { getUsersAction } from "../../../state/redux/workforce/actions";
import { useForm, Controller } from "react-hook-form";
import { getLeaveType } from "../../../state/redux/category/actions";
import { capitalize, secondsToDHMS } from "../../../services/functions";
import { getLeaveBalance } from "../../../state/redux/workforceDetail/actions";
import GenericSelect from "../../../util/GenericSelect";
import Pagination from "../../Components/Pagination";


const AllLeaves = ()=>{
    const dispatch = useDispatch();
    const {data:leaves, count:totalLeaves} = useSelector(userLeavesSelector);


    const [page, setPage] = useState(1);
    const [entries, setEntries] = useState(10);

    const searchByRef = useRef();
    const searchRef = useRef();
    

    const filterUserLeaves = (resetPage=false, search=false)=>{
        let body = {};
        body.limit = entries;
        body.page_no = resetPage?1:page;
        body.searchBy = searchByRef.current.value;
        body.search = searchRef.current.value;
        if(resetPage){
            setPage(1);
        }
        if(search){
            body.toast = true;
            body.onlyError = true;
        }
        dispatch(getUserLeaves.call(body))
    }
   
    useEffect(()=>{
        filterUserLeaves();
    },[page])

    useEffect(()=>{
        filterUserLeaves(true);
    },[entries])

	const handlePageChange = (newPage) => {
		dispatch(getUserLeaves.call({
			limit : entries,
			page_no : newPage,
			searchBy : searchByRef.current.value,
			search : searchRef.current.value
		}))
		setPage(newPage)
    };

    return(
        <>
        <div className='contentpanel'>
            <h5>All Leaves</h5>
            <hr/>
            <div className='cust-row'>
                <div className='cust-col-4'>
                    <div className="input-group">
                        <select className="form-select" ref={searchByRef} defaultValue="">
                            <option value="">Search by</option>
                            <option value="name">Name</option>
                            <option value="userType">User Type</option>
                        </select>
                        <input type="text" className="form-control" placeholder="Search value" ref={searchRef}/>
                        <button className="btn btn-orange" onClick={()=>filterUserLeaves(true, true)}>
                            <i className="fa fa-search"></i>
                            
                        </button>
                    </div>
                </div>
                <div className="cust-col-2">
                    <div className="input-group">
                        <label className="input-group-text">
                            Show Entries
                        </label>
                        <select className="form-select" value={entries} onChange={(e)=>setEntries(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </select>
                    </div>

                </div>
                <div className="cust-col-2">
                   <ApplyLeaves />
                </div>
            </div>
            <br/>
            <br/>
            <div className='cust-row'>
                <div className="cust-col-10">
                    <table className='workforce-table'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>User Type</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th>From Date</th>
                                <th>To Date</th>
                                <th>Status</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                leaves.length > 0 
                                ?
                                    leaves.map((l,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{l.user.name ? l.user.name:"-"}</td>
                                                <td>{l.user.user_type ? l.user.user_type:"-"}</td>
                                                <td>{l.leaveDetails && l.leaveDetails.leaveType ? l.leaveDetails.leaveType: "-"}</td>
                                                <td>{l.leaveDetails && l.leaveDetails.leaveCategory ? l.leaveDetails.leaveCategory: "paid"}</td>
                                                <td>{
													l.date ? moment(l.date).format('DD/MM/YYYY') : 
													l.leaveFrom ? 
														moment(l.leaveFrom).format('DD/MM/YYYY') : '-'}
												</td>
												<td>{
													l.date ? moment(l.date).format('DD/MM/YYYY') : 
													l.leaveTo ? 
														moment(l.leaveTo).format('DD/MM/YYYY') : '-'}
												</td>
                                                <td className={l.status}>{l.status ? capitalize(l.status):"-"}</td>
                                                <td>{l.leaveDetails ? l.leaveDetails.reason: "-"}</td>
                                            </tr>
                                        )
                                    })
                                :
                                    <tr>
                                        <td colSpan={100}>No Records</td>
                                    </tr>
                            }
                        </tbody>

                    </table>
                </div>

            </div>
            <div className="cust-row">
                <div className="cust-col-5"></div>
                <div className="cust-col-5">
                    <nav aria-label="Page navigation example" className="cust-pagination">
                        {/* <ul className="pagination justify-content-end">
                            {
                                Array(Math.ceil(totalLeaves/entries)).fill(0).map((_, i) => {
                                    return <li key={i} className={"page-item "+(i+1==page?"active":"")} onClick={()=>setPage(i+1)}><a className="page-link">{i+1}</a></li>
                                })
                            }
                        </ul> */}

						<Pagination onPageChange={handlePageChange} totalCount={totalLeaves} currentPage={page} pageSize={entries} />




                    </nav>
                </div>
            </div>
            


        </div>
        </>
    )
}


export default AllLeaves;


export const ApplyLeaves = () => {
    const dispatch = useDispatch()
	const [isModal, setIsModal] = useState(false);
	const handleModalClose = () => {
		setIsModal(false);
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#applyLeaveModal").removeClass("custom-modal-is-visible")
	};
	const handleModalOpen = () => {
		setIsModal(true);
		setTimeout(() => {
			$(".custom-modal-overlay").addClass("custom-modal-is-visible");
			$(`#applyLeaveModal`).addClass("custom-modal-is-visible");
		}, 200);
	};
    useEffect(()=>{
        dispatch(getUsersAction.call({}))
        dispatch(getLeaveType.call({}))
    },[])
	return (
		<>
			<button className='btn btn-orange btn-sm'onClick={handleModalOpen}>Apply Leaves</button>
			{isModal && <ApplyLeaveModal closeModal={handleModalClose} />}
		</>
	);
};


const ApplyLeaveModal = ({ closeModal }) => {
	const dispatch = useDispatch()
	const {
		control,
		register,
		handleSubmit,
		watch,
		reset,
		setValue,
		setError,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: {
			userName: "",
			leaveCategory: "",
			leaveType: "",
			leaveCount: "",
			leaveFrom: "",
			leaveTo: "",
			leaveReason: "",
		},
	});

	const users = useSelector((state) => state.workforce.workforceList.users);
	
	const userOptions = users?.map((user, index) => {
		// console.log(index==0 && user)
		return { label: `${capitalize(user?.name)} (${user?.readableId || ""}) `, value: user._id};
	});
	const leaveCategories = useSelector(
		(state) => state.category.categoryList.category
	);
	const leaveCategoryOptions = leaveCategories?.map((leave, index) => {
		return { label: capitalize(leave?.leaveType), value: leave.leaveType };
	});
	const leaveBalance = useSelector(state=> state.workforceDetail.leaveBalance)
	useEffect(()=>{
		if(!leaveBalance[watchLeaveCategory]) return 
		// console.log(leaveBalance, leaveBalance[watchLeaveCategory])
		setValue("leaveCount", leaveBalance[watchLeaveCategory]);

	},[leaveBalance])
	const watchUser= watch('userName')
	const watchLeaveCategory = watch("leaveType");
	const watchLeaveCount = watch("leaveCount");
	const watchLeaveFrom = watch("leaveFrom");
	const watchLeaveTo = watch("leaveTo");
	const watchLeaveType = watch('leaveCategory');

	// console.log({watchUser, watchLeaveCategory, watchLeaveCount, watchLeaveFrom, watchLeaveTo, watchLeaveType})
	// console.log({errors})
	const handleApplyLeave = (formData) => {
		const {leaveCategory,leaveCount,leaveFrom,leaveReason,leaveTo,leaveType,userName} = formData

		// console.log({formData})
		const body = {
			leaveDetails: {
				dayDuration: 'full',
				leaveCategory,
				leaveLeft: leaveCount,
				leaveType,
				reason: leaveReason
			},
			leaveFrom: moment(leaveFrom).format('MM-DD-YYYY'),
			leaveTo:moment(leaveTo).format('MM-DD-YYYY'),
			userId: userName,

			toast: true // for ui only
		}
		dispatch(applyMultipleLeaves.call(body, (res)=>{
			// console.log(res)
			const {error, message} = res || {};
			if(error){

			}else{
				dispatch(getUserLeaves.call({
					limit: 10,
					page_no: 1
				}))
				closeModal()
			}
		}))
		/**
		 * body.toast = true;
        dispatch(addUserAction.call(body, () => {
            history.push("/dashboard/workforce")
            dispatch(getUsersAction.call({
                limit: 5,
                page_no: 1,
            }))
        }))
		 */
	};

	function getSelectedCategory(){
		return leaveCategories.find(
			(_) => _.leaveType === watchLeaveCategory
		);
	}
	useEffect(() => {
		if (!watchLeaveCategory) return;
		const selectedLeaveCategory = getSelectedCategory()
		dispatch(getLeaveBalance.call({
			userId: watchUser,
			leaveType: watchLeaveCategory
		}))
		setValue("leaveCategory", selectedLeaveCategory?.leaveCategory || 'paid'); //this is not a bug, in backend , the type (paid-unpaid) is stored as leaveCateogory

	}, [watchLeaveCategory]);

	
	useEffect(() => {
		if(!watchLeaveFrom || !watchLeaveTo) return;

		// console.log({watchLeaveFrom, watchLeaveTo})
		const fromTime = new Date(watchLeaveFrom).getTime();
		const toTime = new Date(watchLeaveTo).getTime();
		const time_diff = toTime - fromTime;
		const {d}= secondsToDHMS(time_diff/1000);
		const day_diff = d + 1 // because we want to consider same day as 1 day. 
		// console.log({fromTime, toTime, day_diff})s
		
		const selectedLeaveCategory = getSelectedCategory();
		
if (watchLeaveType != "unpaid") {
	if (day_diff <= 0) {
		setError("leaveTo", {
			type: "inValidLeaveTo",
			message: "To Date cannot be less than From Date",
		});
		setValue("leaveCount", leaveBalance[watchLeaveCategory]);
	} else if (day_diff > leaveBalance[watchLeaveCategory]) {
		setError("leaveCount", {
			type: "inValidLeaveCount",
			message: `Insufficient Leaves: You have ${leaveBalance[watchLeaveCategory]} leave(s) available but you selected ${day_diff} leave(s)`,
		});
		setError("leaveTo", {});
		setValue("leaveCount", leaveBalance[watchLeaveCategory]);
	} else {
		setError("leaveCount", {});
		setError("leaveTo", {});
		setValue("leaveCount", leaveBalance[watchLeaveCategory] - day_diff);
	}
}
	
	}, [watchLeaveFrom, watchLeaveTo]);

	return (
		<section
			id="applyLeaveModal"
			data-testid="applyLeaveModal"
			className="custom-modal"
			style={{ width: "30%" }}
		>
			<div className="custom-modal-header">
				<h1>Apply Leave</h1>
				<span className="ct-close" onClick={closeModal}></span>
			</div>
			<div
				className="custom-modal-body"
				style={{ overflowX: "auto", maxHeight: "75vh" }}
			>
				<div
					className="cust-row"
					style={{ flexDirection: "column", gap: "1em" }}
				>
					<div className="cust-row">
						<label className="black_color font-bold text-left wdt100" htmlFor="userNameSelect">
							User Name<span className="red_color">*</span>
						</label>

						<div className="wdt100">
						<Controller
						name="userName"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
							<GenericSelect
							className="modal__form__select"
							field={field}
							options={userOptions}
							onChange={(value)=> field.onChange(value.value)}
							value = {userOptions.find(_=> _.value == field.value)}
							placeholder='User Name'
							/>
							);
						}}
						/>
						</div>
					</div>
					<div className="cust-row">
						<label className="black_color font-bold text-left wdt100" htmlFor="leaveCategorySelect">
							Leave Category <span className="red_color">*</span>
						</label>
						<div className="wdt100">
						<Controller
						name="leaveType"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
							<GenericSelect
							className="modal__form__select"
							field={field}
							options={leaveCategoryOptions}
							onChange={(value)=> field.onChange(value.value)}
							value = {leaveCategoryOptions.find(_=> _.value == field.value)}
							placeholder='Leave Category'
							/>
							);
						}}
						/>
							{/* <select
								className="cust-input select2-single"
								type="text"
								{...register("leaveType", {
									required: true,
								})}
								defaultValue={""}
								id='leaveCategorySelect'
							>
								<option disabled value="">
									Leave Category
								</option>
								{leaveCategoryOptions}
							</select> */}
						</div>
					</div>

					<div className="cust-row">
						<label className="black_color font-bold text-left wdt100" htmlFor="leaveTypeSelect">
							Leave Type
						</label>
						<div className="wdt100">
							<input
								className="cust-input"
								type="text"
								placeholder="Leave Type"
								{...register("leaveCategory", {
									required: true,
									// validate: isBefore,
								})}
								id="leaveTypeSelect"
								readOnly
							/>
						</div>
					</div>
					{typeof watchLeaveCount=='number' && watchLeaveCount >=0  && watchLeaveType != 'unpaid'? (
						<div className="cust-row">
							<label
								className="black_color font-bold text-left wdt100"
								htmlFor="leaveCountSelect"
							>
								Leaves Left
							</label>
							<div className="wdt100">
								<input
									className="cust-input"
									type="text"
									placeholder=""
									{...register("leaveCount")}
									id="leaveCountSelect"
									readOnly
								/>
								{errors.leaveCount && errors.leaveCount?.type && (
									<p className="error red_color mb-0 mt-1">
										{errors.leaveCount?.message}
									</p>
								)}
							</div>
						</div>
					) : null}
					<div className="cust-row">
						<label
							className="black_color font-bold text-left wdt100"
							htmlFor="leave_fromDate"
						>
							From Date <span className="red_color">*</span>
						</label>
						<div className="wdt100">
							<input
								className="cust-input"
								type="date"
								placeholder=""
								{...register("leaveFrom", {
									required: true,
								})}
								id="leave_fromDate"
							/>
							{/* {errors.leaveFrom && (
								<p className="error red_color">
									{errors.leaveFrom?.message}
								</p>
							)} */}
						</div>
					</div>

					<div className="cust-row">
						<label
							className="black_color font-bold text-left wdt100"
							htmlFor="leave_toDate"
						>
							To Date <span className="red_color">*</span>
						</label>
						<div className="wdt100">
							<input
								className="cust-input"
								type="date"
								placeholder=""
								{...register("leaveTo", {
									required: true,
								})}
								id="leave_toDate"
							/>
							{errors.leaveTo && errors.leaveTo?.type && (
									<p className="error red_color mb-0 mt-1">
										{errors.leaveTo?.message}
									</p>
								)}
						</div>
					</div>

					<div className="cust-row">
						<label
							className="black_color font-bold text-left wdt100"
							htmlFor="leaveReason"
						>
							Reason <span className="red_color">*</span>
						</label>
						<div className="wdt100">
							<input
								className="cust-input"
								type="text"
								placeholder="Enter reason for leave"
								{...register("leaveReason", {
									required: true,
									// validate: isBefore,
								})}
								id="leaveReason"
							/>
						</div>
					</div>
					<div
						className="cust-row"
						style={{ justifyContent: "center" }}
					>
						<button
							className="button bti bg-grey-out m-0"
							style={{ flexBasis: "14em" }}
							onClick={handleSubmit(handleApplyLeave)}
							aria-label="submit apply leave"
							// disabled={watchLeaveType == 'unpaid'}
							// title={watchLeaveType == 'unpaid' ? 'Cannot Apply Unpaid Leaves' : 'Click to Apply Leaves'}
						>
							<div className="btn_icon">
								<i className="fa fa-plus"></i>
							</div>
							<div className="btn_txt font-lg">Apply Leave</div>
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};


function getDays(date) {
    if (!date) return;
    return new Date(date).getDate();
}

// const validateLeaveDate = {
	// 	isInRange: (v) => {
	// 		const selectedDate = new Date(v).getDate();
	// 		const currentDate = new Date().getDate();
	// 		return (
	// 			currentDate - selectedDate < 0 ||
	// 			"Leave cannot be applied for past dates"
	// 		);
	// 	},
	// };