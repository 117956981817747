import { useEffect, useState } from 'react';
import { getFile } from '../../../state/redux/workforceDetail/actions';
import { useDispatch } from 'react-redux';

const ViewBankDocument = ({ closeModal, imageArrays, bankAction, setBankAction , bankdocumentsArray, bankIndex }) => {

    const [viewIndex, setViewIndex] = useState(0)
    const [viewArray, setViewAarray] = useState([])  

    useEffect(() => {
        if(bankdocumentsArray.length !== 0 && bankIndex !== null){
            setViewAarray((bankdocumentsArray || [])[bankIndex][viewIndex])
        }
    
    }, [  viewIndex , bankdocumentsArray,bankIndex])



    const paginateView = (item) => {
        if (item === 'prev') {
            if (viewIndex !== 0) {
                setViewIndex(viewIndex - 1)
            }
        }
        else if (item === 'next') {
            if (bankdocumentsArray[bankIndex]?.length !== viewIndex + 1) {
                setViewIndex(viewIndex + 1)
            }
            else {
            }
        }
    }

    const reset = () => {
            setViewIndex(0)
            setBankAction('')
       
    }

  


    return (

        <section id="ViewBankDocument" className="custom-modal" style={{ width: "60%" }}>
            <div className="custom-modal-header">
                <h1>View Document</h1>
                <span className="ct-close" onClick={() => { closeModal(); reset() }}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>

             
                    <div style={{ height: '350px', display: 'flex', justifyContent: 'space-between' }}>
                        <div className='arrowElem'>
                            <i className='fa fa-angle-left arrowP' onClick={() => paginateView('prev')} ></i>
                        </div>
                        <div className='imgElem'>

                            {viewArray?.includes("image") ?
                                <>
                                    <img className='imgClassP'
                                        src={viewArray}
                                        alt='' />
                                    <span style={{ position: 'absolute', bottom: '11px', color: '#000', fontWeight: '800', fontSize: '14px' }}>{`${viewIndex + 1}/${bankdocumentsArray && bankdocumentsArray[bankIndex]?.length}`}</span>
                                </>
                                :
                                <>
                                <object
                                    data={viewArray}
                                    type="application/pdf"
                                    width="100%"
                                    height='90%'
                                ></object>
                                <span style={{ position: 'absolute', bottom: '11px', color: '#000', fontWeight: '800', fontSize: '14px' }}>{`${viewIndex + 1}/${bankdocumentsArray && bankdocumentsArray[bankIndex]?.length}`}</span>
                                </>
                                }

                        </div>
                        <div className='arrowElem'>
                            <i className='fa fa-angle-right arrowP' onClick={() => paginateView('next')}></i>
                        </div>
                    </div>
               


            </div>
        </section>

    )
}

export default ViewBankDocument