export const attendanceListSelector = (state) => state.attendance.attendancelist;
export const expenseListSelector = (state) => state.attendance.expenselist;
export const expenseTypeListSelector = (state) => state.attendance.expensetypelist;

export const defaultHolidaysSelector = state => state.attendance.default_holidays;
export const holidaysSelector = state => state.attendance.holidays;
export const attendancesSelector = state => state.attendance.attendances;
export const statsSelector = state => state.attendance.attendance_stats;
export const settingsSelector = state => state.attendance.settings;

export const userLeavesSelector = state => state.attendance.leaves
