
import React, { useState } from 'react';
// import './../../static/css/driverdetails.css'
import './../../static/css/workforce.css'
import user from  './../../static/images/dummy-user.png'
import license from  './../../static/images/license.jpg'
import history from '../../services/history'

const DriverDetails = () => {

    const [imgURL, setImgURL] = useState()
    const [backImgURL, setBackImgURL] = useState()

    const uploadFrontImage = (e) => {
        const frontFile = e.target.files[0]
        const reader = new FileReader();

        reader.onloadend = () => {
            setImgURL(reader.result)
        }
        if (frontFile) {
            reader.readAsDataURL(frontFile);
            setImgURL(reader.result)
        }
        else {
            setImgURL("")
        }
    }

    const uploadBackImage = (e) => {
        const backFile = e.target.files[0]
        const reader = new FileReader();

        reader.onloadend = () => {
            setBackImgURL(reader.result)
        }
        if (backFile) {
            reader.readAsDataURL(backFile);
            setBackImgURL(reader.result)
        }
        else {
            setBackImgURL("")
        }
    }
    const _goBack = () =>{
        history.push("/dashboard/driver")
    }
    return (
        <>
          <div className="contentpanel">
            <div className='cust-row'>
                <div className='cust-col-6'>
                    <h1 className="title">Workforce</h1>
                </div>
                <div className='cust-col-2'></div>
                <div className='cust-col-2'>
                    <p>Total Trips:</p>
                </div>
                <div className='cust-col-2'>
                    <p>Total Drivers:</p>
                </div>

            </div>

                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Driver List</div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="" id="searchType">
                                        <option value="truck_no">Driver name</option>
                                        <option value="transporter_name">xyz</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <button className='form-control'>Drivers</button>
                        </div>
                        <div className='cust-col-2'>
                           <button className='form-control'>Users</button>
                        </div>
                       

                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary' onClick={() => { this._handleClick('addVehicleModal') }}>Add Driver</button>
                        </div>
                    </div>
                </div>
                <div className='cust-row'>
                    <table>
                        <tr>
                            <th>#</th>
                            <th>Driver Name</th>
                            <th>Mobile Number</th>
                            <th>DL number</th>
                            <th>Vehicle(s)</th>
                            <th>DOB</th>
                            <th>Total Balance</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>
                                <a href='#'>Rakesh Sharma</a>
                            </td>
                            <td>123</td>
                            <td>0000</td>
                            <td>mn9A2</td>
                            <td>0/0/0</td>
                            <td>0</td>
                            <td>Active</td>
                            <td>add/delete</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>
                                <a href='#'>Raju Mehta</a>
                            </td>
                            <td>123</td>
                            <td>0000</td>
                            <td>mn9A2</td>
                            <td>0/0/0</td>
                            <td>0</td>
                            <td>Active</td>
                            <td>add/delete</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>
                                <a href='#'>Kaju Sharma</a>
                            </td>
                            <td>123</td>
                            <td>0000</td>
                            <td>mn9A2</td>
                            <td>0/0/0</td>
                            <td>0</td>
                            <td>Active</td>
                            <td>add/delete</td>
                        </tr>
                    </table>
                </div>
            </div>  
        </>
    )


    // return (
        // <> 
        //     <div className="drivercontentpanel">
            
        //     <div className="cust-row flex-algn-cent">
        //             <div className='cust-col-4'>
        //                 <h1 className="title">
        //                     <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
        //                         <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
        //                     </button>
        //                     Vehicle Detail
        //                 </h1>
        //             </div>
        //             <div className='cust-col-6 flex-jc-end'>
        //                 <button className='button bg-orange-out mr-2 wd10'>
        //                     <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
        //                 </button>
        //                 {/* <button className='button bg-orange-out mr-2 wd10'>
        //                     <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
        //                 </button>
        //                 <button className='button bg-orange-out mr-2 wd10'>
        //                     <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
        //                 </button> */}
        //             </div>
        //         </div>
        //         <hr />
               

        //         <div className='parent_div'>
        //             <div className='basic_info'>
        //                 <div className='basic_info1'>
        //                     <span>Basic Details</span>
        //                     <form>
        //                         <label> Transporter's Name
        //                             <input type="text" value="Transporter" dis />
        //                         </label>
        //                         <label> Driver Name
        //                             <input type="text" value="Ramesh Kumar" dis />
        //                         </label>

        //                         <label> Date of Birth
        //                             <input type="text" value=" 22 - sep - 1990" dis />
        //                         </label>

        //                         <label> Contact Number
        //                             <input type="number" value="987654321" dis />
        //                         </label>

        //                         <label> Blood Group
        //                             <input type="text" value="O+" dis />
        //                         </label>

        //                         <label> Father/ Husband
        //                             <input type="text" value="Ramesh Kumar" dis />
        //                         </label>

        //                         <label> Address
        //                             <input type="text" value="W - 39 Okhla Phase -|| , Delhi" dis />
        //                         </label>
        //                     </form>
        //                 </div>
        //                 <div className='basic_info_img'>
        //                     <img src={user} alt="loading" />
                            
        //                 </div>
        //             </div>

        //             <div className='license_details'>
        //                 <div className='licence_data'>
        //                     <span>License Details</span>
        //                     <form>
        //                         <label> DL No.
        //                             <input type="text" value="123456789" />
        //                         </label>

        //                         <label> Date of Issue
        //                             <input type="text" value=" 22 - sep - 1990" />
        //                         </label>

        //                         <label> Date of Expiry
        //                             <input type="text" value="20 - sep - 2022" />
        //                         </label>
        //                     </form>
        //                 </div>

        //                 <div className='licence_img'>
        //                     <img src={license} alt="license" />
        //                 </div>


        //             </div>

        //             <div className='other_details'>

        //                 <div className='other_heading'>
        //                     <span>Other ID's Details</span>
        //                     <div className='others_1'>
        //                         <p>ID No.</p>
        //                         <p>Doc Type</p>
        //                     </div>

        //                     <div className='others_inputs'>
        //                         <input id="id_num" type="number" value="987654321" />

        //                         <select id="doc_type">
        //                             <option value="aadhar">Aadhar Card</option>
        //                             <option value="pan">PAN Card</option>
        //                             <option value="License">License</option>
        //                         </select>
        //                     </div>
        //                 </div>

        //                 <div className='upload'>

        //                     <div className='upload_front'>
        //                         <img id="id_img" src={imgURL} alt=""/>
        //                     </div>

        //                     <div className='upload_back'>
        //                         <img id="id_img" src={backImgURL} alt=""/>
        //                     </div>

        //                 </div>

        //                 <div className='upload_btn'>
        //                     <button>
        //                         <input className="hide_input" type="file" onChange={(e) => uploadFrontImage(e)} />
        //                         <p>Upload Front</p>
        //                     </button>

        //                     <button>
        //                         <input className="hide_input" type="file" onChange={(e) => uploadBackImage(e)} />
        //                         <p>Upload Back</p>
        //                     </button>
        //                 </div>

        //                 <div className='btn_div'>
        //                     <button>Save Details</button>
        //                 </div>
        //             </div>
        //         </div>


        //     </div>
        // </>
        // <>
        // </>
    
        // );
};

export default DriverDetails;

