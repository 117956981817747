import types from './types';

const initialState ={
    categoryList:{
        category:[],
        count: null,
        loading:false,
        error:null
   }
}

const categoryReducer = (state=initialState, action)=>{

    switch(action.type){
        case `${types.GET_LEAVE_TYPE}_FETCH`: return state
        case `${types.GET_LEAVE_TYPE}_SUCC`:
        return {
            ...state,
            categoryList: {
                ...state.categoryList,
                category: action.payload.data,
                count: action.payload.count,
                loading: false,
                error: null
            }
        }
        case `${types.GET_LEAVE_TYPE}_ERR`: return {
            ...state,
            categoryList: {
                ...state.categoryList,
                category: [],
                count: 0,
                loading: false,
                error: null
            }
        }
         

          default: return state
    }

}

export default categoryReducer;