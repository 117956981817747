import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import GeneralAttendanceTable from '../../Components/GeneralAttendanceTable';
import { getAttendance, getAttendanceStats, markAllAttendance } from '../../../state/redux/attendance/action';
import { statsSelector } from '../../../state/redux/attendance/selectors';
import { departmentListSelector } from '../../../state/redux/department/selectors';
import { getDepartments } from '../../../state/redux/department/actions';
import MarkAll from '../Modal/MarkAll';
import { userTypesSelector } from '../../../state/redux/workforce/selectors';
import { getUserTypes } from '../../../state/redux/workforce/actions';
import { toast } from 'react-toastify';
import HomePage from './Home';
import '../../../static/css/attendance.css'



const getCurrentWeek = (date_obj) => {
    const weekStart = date_obj.clone().startOf('week');
    const weekEnd = date_obj.clone().endOf('week');
    return {weekStart, weekEnd}
}

export default function DayWeekView2() {
    const dispatch = useDispatch();

    const stats = useSelector(statsSelector);
    const userTypes = useSelector(userTypesSelector);
    const {department} = useSelector(departmentListSelector);

    const [currentView, setCurrentView] = useState("week");
    const [currentDay, setCurrentDay] = useState(moment());
    const [currentWeek, setCurrentWeek] = useState(moment());
    const [dateHeaders, setDateHeaders] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [entries, setEntries] = useState(10);
    const [page, setPage] = useState(1);

    const searchByRef = useRef();
    const searchRef = useRef();
    const attTypeRef = useRef();
    const departmentRef = useRef();
    const userTypeRef = useRef();
    // console.log(`Current Day ${currentDay.format("YYYY-MM-DD")}`);
    // console.log(`Current Week ${currentWeek.format("YYYY-MM-DD")}`);

    const handleNavigationButton = (direction) => {
        if(currentView == "day"){
            setCurrentDay(currentDay.clone().add(direction * 1, 'days'))
        }else{
            setCurrentWeek(currentWeek.clone().add(direction * 7, 'days'))
        }
    }    

    const getNavigationText = () => {
        if(currentView == "day"){
            return currentDay.format("DD-MM-YYYY");
        }
        else{
            const {weekStart, weekEnd} = getCurrentWeek(currentWeek);
            return weekStart.format("DD-MM-YYYY") + " - " + weekEnd.format("DD-MM-YYYY")
        }
    }

    const getTableHeader = () => {
        let header = []
        if(currentView == "day"){
            header.push({
                date_obj: currentDay.clone(),
                date: currentDay.clone().format('DD-MM-YYYY'),
                day: currentDay.clone().format('dddd')
            })
        }else{
            const {weekStart, weekEnd} = getCurrentWeek(currentWeek);
            while(weekStart <= weekEnd){
                header.push({
                    date_obj: weekStart.clone(),
                    date: weekStart.clone().format('DD-MM-YYYY'),
                    day: weekStart.clone().format('ddd')
                })
                weekStart.add(1, 'days')
            }
        }
        return header
    }

    const getTableData = (data) => {
        // console.log(data);
        return data.map((user, i) => {
            // console.log("---------------- " + i + " ---------------");
            const textCells = [(page-1)*entries+i+1, user.readableId,user.name,user.mobile, user.user_type, user.department];
            const header = getTableHeader();
            let dateCells = header.map((head, j) => {
                return {
                    date: head.date_obj,
                    text: "-",
                    className: "unknown",
                    id: user._id
                }
            })
            let newDateCells = dateCells.map((cell) => {
                for(let j=user.attendance.length-1;j>=0;j--){
                    let temp = moment(moment(user.attendance[j].date).format("YYYY-MM-DD"));
                    // console.log(cell.date.format("YYYY-MM-DD"));
                    // console.log(temp.format("YYYY-MM-DD"));
                    let diff = cell.date.diff(temp, 'days')
                    
                    if(diff == 0){
                        // console.log("Found " + user.attendance[j].status);
                        return {
                            ...cell,
                            text: user.attendance[j].status,
                            className: user.attendance[j].status.toLowerCase().replace(/\s/g, ''),
                        }
                    }
                }
                // console.log("Not Found");
                return cell
            })
            // console.log(dateCells);
            return {
                textCells: textCells,
                dateCells: newDateCells
            }
        })
    }

    const refreshPageContent = (pageNo=page) => {
        // Prepare API parameters
        if(pageNo != page){
            setPage(1)
        }
        let body = {
            limit: entries,
            page_no: pageNo,
            searchBy: searchByRef.current.value,
            search: searchRef.current.value,
            attType: attTypeRef.current.value,
            department: departmentRef.current.value,
            userType: userTypeRef.current.value
        }
        if(currentView == "day"){
            body['firstDay'] = currentDay.clone().add(0, 'days').format("MM-DD-YYYY");
        }else{
            const {weekStart, weekEnd} = getCurrentWeek(currentWeek);
            body['firstDay'] = weekStart.clone().add(0, 'days').format("MM-DD-YYYY");
            body['lastDay'] = weekEnd.clone().add(0, 'days').format("MM-DD-YYYY");
        }
        
        dispatch(getAttendance.call(body, (res) => {
            if(res) {
                setTotalRecords(res.count);
                const newHeader = getTableHeader();
                setDateHeaders(newHeader)
                
                const newTableData = getTableData(res.data);
                setTableData(newTableData)
            }else{
                toast.error("No Records Found",{position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000});
                setTableData([]);
            }
        }))

        // Call Statistics API
        body.firstDay = moment(body.firstDay).clone().add(0, 'days').format("MM-DD-YYYY");
        
        dispatch(getAttendanceStats.call(body))
    }

    const clearFilter = () => {
        attTypeRef.current.value = ""
        departmentRef.current.value = ""
        userTypeRef.current.value = ""
        
        refreshPageContent();
    }

    const handlePage = (p) => {
        setPage(p);
    }
    const markAll = (data) =>{
        data.toast = true;
        dispatch(markAllAttendance.call(data, ()=>{
            refreshPageContent();
        }))
        _closeModal();
    }

    useEffect(()=>{
        dispatch(getDepartments.call({}));
        dispatch(getUserTypes.call({}))
    },[])

    useEffect(()=>{
        refreshPageContent();
    },[currentView, currentDay, currentWeek, page, entries])

    return (
        <div className='contentpanel'>
            <div className='cust-container'>
                <div className='cust-row'>
                    <div className='cust-col-3'>
                        <h4 className="mt-2">Attendance</h4>
                    </div>
                    
                    <div className='cust-col-3'>
                        <div className='cust-row'>
                            <div className='cust-col-10'>
                                <div className='input-group'>
                                    <a className='btn btn-orange' onClick={()=> handleNavigationButton(-1)}><i className="fa fa-arrow-left"></i></a>
                                    
                                    <input className='form-control text-center' type="text" disabled value={getNavigationText()}/>
                                    <a className='btn btn-orange' onClick={()=> handleNavigationButton(1)}><i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cust-col-4">
                        <div className="cust-row">
                            <div className="cust-col-6">
                                <div className="input-group ms-4">
                                    <a className={"btn " + (currentView == "week"?"btn-orange":"btn-outline-orange")} type="button" onClick={()=> setCurrentView("week")}>Week View</a>
                                    <a className={"btn " + (currentView == "day"?"btn-orange":"btn-outline-orange")} type="button" onClick={()=> setCurrentView("day")}>Day View</a>
                                </div>
                            </div>
                            <div className="cust-col-4 text-end">
                                <div className="dropdown ms-2">
                                    <a className="btn btn-secondary" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        Filters <i className="fa fa-filter"></i>
                                    </a>
                                    <div className="dropdown-menu filter-menu p-3">
                                        <div className="text-center">
                                            <select className="form-select mt-1" ref={userTypeRef}>
                                                <option value="">Select User Type </option>
                                                {
                                                    userTypes.map((userType, i) => <option key={i} value={userType.user_type}>{userType.user_type}</option>)
                                                }
                                            </select>
                                            <select className="form-select mt-1" ref={departmentRef}>
                                                <option value="">Department Type</option>
                                                {
                                                    department.map((dep, i) => {
                                                        return <option value={dep.department} key={i}>{dep.department}</option>
                                                    })
                                                }
                                            </select>
                                            <select className="form-select mt-1" ref={attTypeRef}>
                                                <option value="">Attendance Type</option>
                                                <option value="Present">Present</option>
                                                <option value="Absent">Absent</option>
                                                <option value="On Leave">On Leave</option>
                                            </select>
                                            <button className="btn btn-orange btn-sm mt-3" onClick={()=> refreshPageContent()}>Apply Filters</button>
                                            <br/>
                                            <button className="btn btn-warning btn-sm mt-1" onClick={()=> clearFilter()}>Clear Filters</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className='cust-row'>
                    <div className='cust-col-2 orange-strip-box'>
                        <h2 className="text-orange fw-bold">
                            {
                                stats.filter(obj => obj._id == 'users').length > 0
                                ?
                                    stats.filter(obj => obj._id == 'users')[0].count
                                :
                                    0
                            }
                        </h2>                            
                        <h5>Total Employee</h5>
                        
                    </div>
                    <div className='cust-col-2 orange-strip-box ms-1'>
                        <h2 className="text-orange fw-bold">
                            {
                                stats.filter(obj => obj._id == 'Present').length > 0
                                ?
                                    stats.filter(obj => obj._id == 'Present')[0].count
                                :
                                    0
                            }
                        </h2>
                        <h5>Present</h5>
                        
                    </div>
                    <div className='cust-col-2 orange-strip-box ms-1'>
                        <h2 className="text-orange fw-bold">
                            {
                                stats.filter(obj => obj._id == 'Absent').length > 0
                                ?
                                    stats.filter(obj => obj._id == 'Absent')[0].count
                                :
                                    0
                            }
                        </h2>
                        <h5>Absent</h5>
                        
                    </div>
                    <div className='cust-col-2 orange-strip-box ms-1'>
                        <h2 className="text-orange fw-bold">
                            {
                                stats.filter(obj => obj._id == 'On Leave').length > 0
                                ?
                                    stats.filter(obj => obj._id == 'On Leave')[0].count
                                :
                                    0
                            }
                        </h2>
                        <h5>On Leave</h5>
                        
                    </div>
                    <div className='cust-col-2 orange-strip-box ms-1'>
                        <h2 className="text-orange fw-bold">
                            {
                                stats.filter(obj => obj._id == 'Holiday').length > 0
                                ?
                                    stats.filter(obj => obj._id == 'Holiday')[0].count
                                :
                                    0
                            }
                        </h2>
                        <h5>Holiday</h5>
                    </div>

                </div>
                <br/>
                <div className='cust-row'>
                    <div className='cust-col-4'>
                        <div className="input-group">
                            <select className="form-select" ref={searchByRef} defaultValue="name">
                                
                                <option value="name">Name</option>
                                <option value="empId">Employee Id</option>
                                <option value="mobile">Mobile</option>
                            </select>
                            <input type="text" className="form-control" placeholder="Search value" defaultValue="" ref={searchRef}/>
                            <button className="btn btn-orange" onClick={()=> refreshPageContent(1)}>
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="cust-col-2">

                        <div className="input-group">
                            <label className="input-group-text">
                                Show Entries
                            </label>
                            <select className="form-select" onChange={(e)=>setEntries(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                        </div>

                    </div>
                    <div className='cust-col-2'></div>
                    <div className='cust-col-2 text-end'>
                        <MarkAll closeModal={_closeModal}  markAll={markAll}/>
                        <input type="checkbox" onClick={()=> _showModal('markAllModal')} /> Mark All Present
                    </div>
                </div>                
                <div className='cust-row'>
                    <div className={currentView =='day'?"cust-col-7":"cust-col-10"}>
                        <GeneralAttendanceTable
                            _showModal={_showModal}
                            _closeModal={_closeModal}
                            refreshPageContent={refreshPageContent}
                            textHeaders={["S.No","Emp Id" ,"Name", "Mobile","User Type", "Department"]}
                            dateHeaders={dateHeaders}
                            rows={tableData}  
                            totalCount={totalRecords} 
                            currentPage={page} 
                            pageSize={entries}  
                            setPage={setPage}
                        />
                    </div>                        
                </div>
                    
            </div>
        </div>
    )
}

const _showModal = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible")
    $(`#${id}`).addClass("custom-modal-is-visible")
}

const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
    $(".custom-modal").removeClass("custom-modal-is-visible")
}
