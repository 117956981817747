import React, { useEffect,useState } from 'react';
import $ from 'jquery'
import Markers from './Markers';
import { GoogleMap, LoadScript } from '@react-google-maps/api';


const Map = (props) => {
   
    const mapData = props.location.state
    const lat=mapData.lat
    const lng=mapData.lng
    const taggedimages=mapData.newarray || []

    const position = {
        lat: lat , lng: lng
    }  

    useEffect(() => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")

    }, [])
   
    return (
        <>
            <div className='mapOuter'>
                <LoadScript
                    googleMapsApiKey='AIzaSyAMbjwVD8stwnb3sKYaxCOefn6ranbU7bE'>

                    <GoogleMap id="mapDimensions"  zoom={15} center={position} >

                    {taggedimages.length > 1 ? (<Markers position={position} icon={taggedimages} />): <Markers position={position} icon={taggedimages} />}

                    </GoogleMap>

                </LoadScript>
            </div>
        </>

    );
};
export default Map;