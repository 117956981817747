import React, {useState} from 'react';



export default function Confirm({id, closeModal, confirm}) {

    const handleConfirm = () => {
        closeModal()
        confirm()
    }

    
    return (
        <section id={id} className="custom-modal custom-modal-small">
            <div className="custom-modal-header">
            <h1>Delete?</h1>
            <span className="ct-close" onClick={closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'70vh'}}>
                <div className="cust-row">
                    <div className='cust-col-2'></div>
                    <div className="cust-col-3">
                        <button className='button bti bg-success-out m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-check'></i>
                            </div>
                            <div className='btn_txt font-lg' onClick={handleConfirm}>Confirm</div>
                        </button>
                    </div>
                    <div className="cust-col-3">
                        <button className='button bti bg-danger-out m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-times'></i>
                            </div>
                            <div className='btn_txt font-lg' onClick={closeModal}>Cancel</div>
                        </button>
                    </div>
                </div>
                
            </div>
        </section>
        
    )
}


