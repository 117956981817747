import React from 'react';
import history from './../../services/history'

const AddAddress = () => {
    const OpenAddressBook=()=>{
        history.push("/dashboard/address-book")
    }
    return (
        <>
            <div className="contentpanel">
                <div className="cust-container">
                    <div className="cust-row flex-algn-cent">
                        <div className='cust-col-4'>
                            <h1 className="title">
                                <button className='button bg-orange mr-2 wd10 flex-inline'onClick={OpenAddressBook} >
                                    <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                                </button>
                                Add Address
                            </h1>
                        </div>

                    </div>
                    <hr className='bgg' />
                </div>

                <div className='addAddressBox'>
                    <div className='addaddressinner'>
                        <div className='addaddressform'>
                            <div className='addadresstop'>
                                <div className='addaddressleft'>

                                    <div className='addaddressd-L0'>
                                        <label>PAN</label>
                                        <input className='addaddress-input1' type="text" placeholder='PAN' />
                                        <button>
                                            <i className="fa fa-upload" id="addAddressUpload" aria-hidden="true"></i>
                                            <input className='addresInput' type="file"/>
                                        </button>
                                        
                                    </div>
                                    <div className='addaddressd-L1'>
                                        <label>Contact Person<span className='asterisk'>*</span> </label>
                                        <input className='addaddress-input' type="text" placeholder='Ajeet Jain' />
                                    </div>

                                    <div className='addaddressd-L1'>
                                        <label>Email</label>
                                        <input className='addaddress-input' type="email" placeholder='ajeet@Lynkit.in' />
                                    </div>
                                    <div className='addaddressd-L1'>
                                        <label>State<span className='asterisk'>*</span></label>
                                        <input className='addaddress-input' type="text" placeholder='New Delhi' />
                                    </div>
                                    <div className='addaddressd-L1'>
                                        <label>City<span className='asterisk'>*</span></label>
                                        <input className='addaddress-input' type="text" placeholder='Delhi' />
                                    </div>
                                </div>
                                <div className='addaddresscenter'></div>

                                <div className='addaddressright'>
                                    <div className='addaddressd-R1'>
                                        <label>Company</label>
                                        <input className='addaddress-input' type="text" placeholder='Lynkit solutions' />
                                    </div>
                                    <div className='addaddressd-R1'>
                                        <label>Mobile<span className='asterisk'>*</span></label>
                                        <input className='addaddress-input' type="text" placeholder='97427836381' />
                                    </div>
                                    <div className='addaddressd-R1'>
                                        <label>Address<span className='asterisk'>*</span></label>
                                        <input className='addaddress-input' type="text" placeholder=' 246, Block A, Okhla I, Okhla Estate,110020' />
                                    </div>
                                    <div className='addaddressd-L0'>
                                    <label>GSTIN</label>
                                        <input className='addaddress-input1' type="text" placeholder='H6SVHJKJVBJ7' />
                                        <button>
                                            <i className="fa fa-upload" id="addAddressUpload" aria-hidden="true"></i>
                                            <input className='addresInput' type="file"/>
                                        </button>
                                    </div>
                                    <div className='addaddressd-R1'>
                                        <label>Pincode<span className='asterisk'>*</span></label>
                                        <input className='addaddress-input' type="text" placeholder='202221' />
                                    </div>
                                </div>
                            </div>
                            <div className='addaddressbottom'>
                                <button>Add to Address Book  </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddAddress;

