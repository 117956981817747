import types from './types'
import axios from 'axios'
import JointActionDispatcher from '../../../services/jointActionDispatcher'

// const domain = 'http://d8gnu1rryevl2.cloudfront.net'
// const domain = 'https://attendance.lynkit.io'
// const domain = process.env.REACT_APP_HOSTNAME
// const domain = "https://beta.attendance.lynkit.io";
const domain = process.env.REACT_APP_APIHOST || "";
export const getCategoryFetch = () => {
    return {
        type: types.GET_CATEGORY_FETCH
    }
}

export const getCategorySucc = (data) => {
    return {
        type: types.GET_CATEGORY_SUCC,
        payload: data
    }
}

export const getCategoryErr = (error) => {
    return {
        type: types.GET_CATEGORY_ERR,
        payload: error
    }
}
export const callGetCategoryApi = (body) => {
    return (dispatch) => {
        dispatch(getCategoryFetch());
        axios.post(domain + '/api/settings/get_leaves', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(getCategorySucc(response.data));
        }).catch((error) => {
            dispatch(getCategoryErr(error.message))
        })
    }
}

// add category
export const addCategoryFetch = () => {
    return {
        type: types.ADD_CATEGORY_FETCH
    }
}

export const addCategorySucc = (data) => {
    return {
        type: types.ADD_CATEGORY_SUCC,
        payload: data
    }
}

export const addCategoryErr = (error) => {
    return {
        type: types.ADD_CATEGORY_ERR,
        payload: error
    }
}
export const callAddCategoryApi = (body) => {
    return (dispatch) => {
        dispatch(addCategoryFetch());
        axios.post(domain + '/api/settings/add_leave', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(addCategorySucc(response.data));
            dispatch(callGetCategoryApi({}));
        }).catch((error) => {
            dispatch(addCategoryErr(error.message))
        })
    }

}

export const getLeaveType = {
    url: `/api/settings/get_leaves`,
    action: types.GET_LEAVE_TYPE,
    __proto__: JointActionDispatcher
}

export const addLeaveType = {
    url: `/api/settings/add_leave`,
    action: types.ADD_LEAVE_TYPE,
    __proto__: JointActionDispatcher
}

export const updateLeaveType = {
    url: `/api/settings/update_leave`,
    action: types.UPDATE_LEAVE_TYPE,
    __proto__: JointActionDispatcher
}

export const deleteLeaveType = {
    url: `/api/settings/delete_leave`,
    action: types.DELETE_LEAVE_TYPE,
    __proto__: JointActionDispatcher
}