import types from './types';

const initialState = {
    attendances: {
        data: [],
        count: null
    },
    default_holidays: [],
    holidays: [],
    all_leaves: [],
    attendance_stats: [],
    settings: {},
    leaves: {
        data: [],
        count: null
    },
    attendancelist:{
        holidays: [],
        loading: false,
        error: null,
        settings:[],
        leaves:[],
        casualLeave:null,
        sickLeave:null,
        earnedLeave:null,
        attendanceStats:[]
    },
    //
    expenselist:{
        expense:[],
        loading:false,
        error:null

    },
    expensetypelist:{
        expenseType:[],
        paymentType:[],
        loading:false,
        error:null
    }
    
}

const attendanceReducer = (state=initialState,action)=>{
    switch(action.type){
        //get holiday
        case `${types.GET_DEFAULT_HOLIDAYS}_FETCH`: return state
        case `${types.GET_DEFAULT_HOLIDAYS}_SUCC`: return {
            ...state,
            default_holidays: action.payload.data
        }
        case `${types.GET_DEFAULT_HOLIDAYS}_ERR`: return state

        // Add default Holiday
        case `${types.ADD_DEFAULT_HOLIDAY}_FETCH`: return state
        case `${types.ADD_DEFAULT_HOLIDAY}_SUCC`: return state
        case `${types.ADD_DEFAULT_HOLIDAY}_ERR`: return state

        case `${types.GET_HOLIDAYS}_FETCH`: return state
        case `${types.GET_HOLIDAYS}_SUCC`: return {
            ...state,
            holidays: action.payload.data
        }
        case `${types.GET_HOLIDAYS}_ERR`: return {
            ...state,
            holidays: []
        }

        // Add Holiday
        case `${types.ADD_HOLIDAY}_FETCH`: return state
        case `${types.ADD_HOLIDAY}_SUCC`: return state
        case `${types.ADD_HOLIDAY}_ERR`: return state
        // settings
        case `${types.GET_SETTINGS}_FETCH`: return state
        case `${types.GET_SETTINGS}_SUCC`: return {
            ...state,
            settings: action.payload.data[0]
        }
        case `${types.GET_SETTINGS}_ERR`: return state
        // update settings
        case `${types.UPDATE_SETTINGS}_FETCH`: return state
        case `${types.UPDATE_SETTINGS}_SUCC`: return state
        case `${types.UPDATE_SETTINGS}_ERR`: return state

        // get attendance
        case types.GET_ATTENDANCE_FETCH: return {
            ...state,
            attendancelist: {
                ...state.attendancelist,
                loading: true,
                error: null
            }
        }
        case types.GET_ATTENDANCE_SUCC: return {
            ...state,
            attendancelist: {
                ...state.attendancelist,
                loading: false,
                error: null
            }
        }
        case types.GET_ATTENDANCE_ERR: return {
            ...state,
            attendanceList: {
                ...state.attendancelist,
                loading: false,
                error: action.payload.message
            }
        }
        // add attendance
        case types.ADD_ATTENDANCE_FETCH: return {
            ...state,
            attendancelist: {
                ...state.attendancelist,
                loading: true,
                error: null
            }
        }
        case types.ADD_ATTENDANCE_SUCC: return {
            ...state,
            attendancelist: {
                ...state.attendancelist,
                loading: false,
                error: null
            }
        }
        case types.ADD_ATTENDANCE_ERR: return {
            ...state,
            attendanceList: {
                ...state.attendancelist,
                loading: false,
                error: action.payload.message
            }
        }

        // expense add
        case types.ADD_EXPENSE_FETCH : return {
            ...state,
            loading:true,
            error:null
        }
        case types.ADD_EXPENSE_SUCC : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:null
            }
        }
        case types.ADD_EXPENSE_ERR : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:action.payload.message
            }
        }
        //expense get
        case types.GET_EXPENSE_FETCH : return {
            ...state,
            loading:true,
            error:null
        }
        case types.GET_EXPENSE_SUCC : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:null
            }
        }
        case types.GET_EXPENSE_ERR : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:action.payload.message
            }
        }
        // expense update
        case types.UPDATE_EXPENSE_FETCH : return {
            ...state,
            loading:true,
            error:null
        }
        case types.UPDATE_EXPENSE_SUCC : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:null
            }
        }
        case types.UPDATE_EXPENSE_ERR : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:action.payload.message
            }
        }
        // expense  DELETE
        case types.DELETE_EXPENSE_FETCH : return {
            ...state,
            loading:true,
            error:null
        }
        case types.DELETE_EXPENSE_SUCC : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:null
            }
        }
        case types.DELETE_EXPENSE_ERR : return {
            ...state,
            expenselist:{
                ...state.expenselist,
                loading:false,
                error:action.payload.message
            }
        }
        // expense type
        case types.GET_EXPENSE_TYPE_FETCH : return {
            ...state,
            loading:true,
            error:null
        }

        case types.GET_EXPENSE_TYPE_SUCC : return {
            ...state,
            expensetypelist:{
                ...state.expenseType,
                loading:false,
                error:null
            }
        }
        case types.GET_EXPENSE_TYPE_ERR : return {
            ...state,
            expensetypelist:{
                ...state.expenseType,
                loading:false,
                error:action.payload.message
            }
        }
        // get payment method
        case types.GET_PAYMENT_METHOD_FETCH :return {
            ...state,
            loading:true,
            error:null
        }
        case types.GET_PAYMENT_METHOD_SUCC :return{
            ...state,
            expensetypelist:{
                ...state.paymentType,
                loading:false,
                error:null
            }

        }
        case types.GET_PAYMENT_METHOD_ERR : return {
            ...state,
            expensetypelist:{
                ...state.paymentType,
                loading:false,
                error:action.payload.message
            }
        }

        // add payment method
        case types.ADD_PAYMENT_METHOD_FETCH :return {
            ...state,
            loading:true,
            error:null
        }
        case types.ADD_PAYMENT_METHOD_SUCC :return{
            ...state,
            expensetypelist:{
                ...state.paymentType,
                loading:false,
                error:null
            }

        }
        case types.ADD_PAYMENT_METHOD_ERR : return {
            ...state,
            expensetypelist:{
                ...state.paymentType,
                loading:false,
                error:action.payload.message
            }
        }
        // get user leaves
        case types.GET_USER_LEAVES_FETCH : return {
            ...state,
        }
        case types.GET_USER_LEAVES_SUCC : return {
            ...state,
            leaves: {
                data: action.payload.data,
                count: action.payload.count
            }
        }
         case types.GET_USER_LEAVES_ERR : return {
            ...state,
            leaves: {
                data: [],
                count: 0
            }
         }
        
         // attendance stats
        case `${types.GET_ATTENDANCE_STATS}_FETCH`: return state
        case `${types.GET_ATTENDANCE_STATS}_SUCC`: return {
            ...state,
            attendance_stats: action.payload.data
        }
        case `${types.GET_ATTENDANCE_STATS}_ERR`: return {
            ...state,
            attendance_stats: []
        }

         // add leaves
         case types.ADD_LEAVES_FETCH : return{
            ...state,
            loading:true,
            error:null
         }
         case types.ADD_LEAVES_SUCC : return {
            ...state,
            attendancelist: {
                ...state.attendancelist,
                leaves: action.payload.data,
                loading: false,
                error: null
            }

         }
         case types.ADD_LEAVES_ERR : return {
            ...state,
            attendancelist: {
                ...state.attendancelist,
                loading: false,
                error: action.payload.message
            }
         }

      
        default: return state

    }
    

}

export default attendanceReducer;