import React,{Component} from 'react';
import { selectInit } from './../../services/functions'
import $ from 'jquery'
import AnalysisModal from './../Dashboard/Modal/Analysis'
import TrackingModal from './../Dashboard/Modal/Tracking'
import ViewDocumentModal from './ViewDocuments'
import history from '../../services/history';

class Receipt extends Component {

    constructor(props){
        super(props)
        this.state={
            userType:localStorage.getItem("userType")==='userType2'?'2':"1",
            type:props.type
        }
    }

    componentDidMount(){
        setTimeout(() => {
            selectInit(this._onChange)
        }, 100)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.type !== prevState.type) {
            return ({
              type: nextProps.type
            });
        }
        return null
    }

    toogleView(index, check = true) {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
          $(".collapse-view").eq(index).removeClass("open"); 
          $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
          let height =
            Number($(".collapse-view").eq(index).children("table").height()) + 2;
          $(".collapse-view")
            .eq(index)
            .animate({ height }, 500, () => {
              $(".collapse-view").eq(index).addClass("open");
            });
        }
    }

    toogleItemView(index){
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
        } 
        else {
            $(".data-cont-item").eq(index).addClass("open")
        }
    }

    _onChange(id,value){
        
    }

    _handleClick(id){
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    
    _closeModal(){
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    _copyID(){    
        var inp =document.createElement('input');
        document.body.appendChild(inp)
        inp.value = this.formDetail.tracking_id
        inp.select();
        inp.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand('copy',false);
        inp.remove();
        $("#copyText").addClass('copied')
    }

    _goto(){
        history.push("/dashboard/goods-receipt/detail")
    }

    _createReceipt(){
        history.push("/dashboard/goods-receipt/create")
    }
    
    render(){
        return(
            <div className="contentpanel">
                <h1 className="title">Goods Receipt</h1>
                <hr/>
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'> 
                            <div className='tt2'>Receipt List</div>
                        </div>
                        <div className='cust-col-4'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="truck_no" id="searchType">
                                        <option value="truck_no">Receipt No.</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..."/>
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                    <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                <option value="all">All Type</option>
                                <option value="owned">Owned Vehicle</option>
                                <option value="hired">Hired Vehicle</option>
                            </select>
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary' onClick={()=>{this._createReceipt()}}>Create Receipt</button>
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item'>
                                    {this.state.userType==='2'?"":<div className='ttname'>Transporter : <span className='transportrName'>Ajay( ID: XXXXX)</span></div>}
                                    <div className='sno_txt'>1.</div>
                                    <div className='data_item_content set_height'>
                                        <div className='wd_15'>
                                            <span>Receipt No.</span>
                                            <span className='orange_color font-lg'>12345</span>
                                            <span>(Oct 12th 2021 11:35 AM)</span>
                                        </div>
                                        <div>
                                            <span>From</span>
                                            <span className='flex_box_2'>Coke, w-101 ,industrial area , Ahmedabad, Gujarat - 343454</span>
                                        </div>
                                        <div>
                                            <span>To</span>
                                            <span className='flex_box_2'>New Delhi Office, g-89 , govind puri, New Delhi, Delhi - 110099</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Delivery Type</span>
                                            <span>Door Delivery</span>
                                        </div>                                        
                                        <div className='wd_15'>
                                            <span>Goods Description</span>
                                            <span>Boxes</span>
                                        </div>
                                        <div>
                                            <span>Remark</span>
                                            <span>(HIL)</span>
                                        </div>
                                    </div>
                                    <div className='data_item_content set_height clps'>
                                        {
                                            this.state.type==='all' || this.state.type===''?
                                            <>
                                                <div className='wd_10'>
                                                    <span>Contract Type</span>
                                                    <span>Full Truck Load</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Actual Weight(KG)</span>
                                                    <span>1000</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Package Details</span>
                                                    <span>15(Box)</span>
                                                </div>
                                                <div>
                                                    <span>Consignor</span>
                                                    <span className='flex_box_2'>Dettol, vishnu garden , Ahmedabad, Gujarat - 110020(GSTIN-24AZSXK7676L1Z6)</span>
                                                </div>
                                                <div>
                                                    <span>Consignee</span>
                                                    <span className='flex_box_2'>RISHABH CONSIGNOR, Test Address of consignor, Bhagalpur, Bihar - 110092</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Invoice Number(s)</span>
                                                    <span>1001,1002</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Total Freight</span>
                                                    <span>100000</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Tracking ID</span>
                                                    <span>9988776655 <i className='fa fa-clone copyIcon'></i></span>
                                                </div>
                                            </>
                                            :""
                                        }
                                        {
                                            this.state.type==='weighment-out'?
                                            <>
                                                <div>
                                                    <span>Weightment</span>
                                                    <span>1 Ton</span>
                                                </div>
                                                <div>
                                                    <span>Material Loaded</span>
                                                    <span>3 Ton</span>
                                                </div>
                                                <div>
                                                    <span>Loaded On</span>
                                                    <span>07/02/2022 10:40 PM</span>
                                                </div>
                                                <div className='wd_40'>
                                                    <span>Loaded By</span>
                                                    <span>VK</span>
                                                </div>
                                            </>
                                            :""
                                        }
                                        {
                                            this.state.type==='logistics-gate-out'?
                                            <>
                                                <div>
                                                    <span>Weightment</span>
                                                    <span>1 Ton</span>
                                                </div>
                                                <div>
                                                    <span>Material Loaded</span>
                                                    <span>3 Ton</span>
                                                </div>
                                                <div>
                                                    <span>Loaded On</span>
                                                    <span>07/02/2022 10:40 PM</span>
                                                </div>
                                                <div>
                                                    <span>Loaded By</span>
                                                    <span>VK</span>
                                                </div>
                                                <div>
                                                    <span>Logistic Gate Out Time</span>
                                                    <span>08/02/2022 10:30 AM</span>
                                                </div>
                                            </>
                                            :""
                                        }
                                        {
                                            this.state.type==='material-gate-out'?
                                            <>
                                                <div className='wd_10'>
                                                    <span>Weightment</span>
                                                    <span>1 Ton</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Material Loaded</span>
                                                    <span>3 Ton</span>
                                                </div>
                                                <div>
                                                    <span>Loaded On</span>
                                                    <span>07/02/2022 10:40 PM</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Loaded By</span>
                                                    <span>VK</span>
                                                </div>
                                                <div>
                                                    <span>Logistic Gate Out Time</span>
                                                    <span>08/02/2022 10:30 AM</span>
                                                </div>
                                                <div>
                                                    <span>Material Gate Out Time</span>
                                                    <span>1 Ton</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Delivery Date(est.)</span>
                                                    <span>20/02/2022</span>
                                                </div>
                                            </>
                                            :""
                                        }
                                        <div className='btn_conte'>
                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1' onClick={()=>{this._handleClick('viewDocumentsModal')}}>View Documents</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'>Update</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Delete</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1'>Cancel</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={()=>{this._handleClick('trackingModal')}}>Tracking</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={()=>{this._handleClick('analysisModal')}}>Analytics</button>
                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1' onClick={()=>{this._goto()}}>View Detail</button>
                                        </div>
                                    </div>
                                    <div className='collps_icon' onClick={()=>{this.toogleItemView(0)}}>
                                        <i className='fa fa-chevron-down'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AnalysisModal closeModal={this._closeModal}/>
                <TrackingModal closeModal={this._closeModal}/>
                <ViewDocumentModal closeModal={this._closeModal}/>
            </div>
        )
    }
}

export default Receipt;