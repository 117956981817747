let namespace = "department";
// GET DEPARTMENT
const GET_DEPARTMENTS_FETCH = `${namespace}/GET_DEPARTMENTS_FETCH`;
const GET_DEPARTMENTS_SUCC = `${namespace}/GET_DEPARTMENTS_SUCC`;
const GET_DEPARTMENTS_ERR = `${namespace}/GET_DEPARTMENTS_ERR`;

// ADD DEPARTMENT
const ADD_DEPARTMENT_FETCH = `${namespace}/ADD_DEPARTMENT_FETCH`;
const ADD_DEPARTMENT_SUCC = `${namespace}/ADD_DEPARTMENT_SUCC`;
const ADD_DEPARTMENT_ERR = `${namespace}/ADD_DEPARTMENT_ERR`;


export default{
    // get department
    GET_DEPARTMENTS_FETCH,
    GET_DEPARTMENTS_SUCC,
    GET_DEPARTMENTS_ERR,

    // add department
    ADD_DEPARTMENT_FETCH,
    ADD_DEPARTMENT_SUCC,
    ADD_DEPARTMENT_ERR,
    GET_DEPARTMENTS: `${namespace}/GET_DEPARTMENTS`,
    ADD_DEPARTMENT: `${namespace}/ADD_DEPARTMENT`,
    UPDATE_DEPARTMENT: `${namespace}/UPDATE_DEPARTMENT`,
    DELETE_DEPARTMENT: `${namespace}/DELETE_DEPARTMENT`

}
