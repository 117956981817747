import React, {Component} from 'react';
import { Switch,Route,Redirect } from 'react-router-dom';
import Sidebar from "../../views/Dashboard/Sidebar"
import Header from "../../views/Dashboard/Header"
import CustomBreadcrumb from "./../../views/Dashboard/CustomBreadcrumb"
import DashboardView from "../../views/Dashboard/DashboardView"
import Booking from "../../views/Dashboard/Booking"
import Receipt from "../../views/Dashboard/Receipt"
import Vehicle from "../../views/Dashboard/Vehicle"
import AddressBook from "../../views/Dashboard/AddressBook"
import AddAddress from '../../views/Dashboard/AddAddress';
import Hubs from "../../views/Dashboard/Hubs"
import Driver from "../../views/Dashboard/Driver"
import DriverDetails from '../../views/Dashboard/DriverDetails'
// import UserProfile from '../../views/Dashboard/UserProfile'
import VehicleDetail from './../../views/Dashboard/VehicleDetail'
import BookingDetail from '../../views/Dashboard/BookingDetail';
import AddressBookDetails from './../../views/Dashboard/AddressBookDetails'
import DeliveryView from './../../views/Dashboard/Delivery'
import PodDetail from '../../views/Dashboard/PodDetail';
import Contracts from '../../views/Dashboard/Contracts';
import ReceiptDetail from '../../views/Dashboard/ReceiptDetail';
import CreateReceipt from '../../views/Dashboard/CreateReceipt';
import Analytics from './../../views/Dashboard/Analytics'
import Map from '../../views/Dashboard/Map';
import ContractDetailView from './../../views/Dashboard/ContractDetail'
import TransporterMasterView from './../../views/Dashboard/TransporterMaster'

import WorkforceList from '../../views/Dashboard/WorkforceList';
import WorkforceAdd from '../../views/Dashboard/WorkforceAdd';
import WorkforceDetail from '../../views/Dashboard/WorkforceDetail';
import AttendanceSetting from '../../views/Dashboard/AttendanceSetting';
import AttendanceWeekView from '../../views/Dashboard/AttendanceWeekView';
import AttendanceDayView from '../../views/Dashboard/AttendanceDayView';

import AttendanceDayWeekView from '../../views/Dashboard/Attendance/DayWeekView'
import AttendanceHome from '../../views/Dashboard/Attendance/Home'
import AttendanceHolidays from '../../views/Dashboard/Attendance/Holidays'
import SettingPanel from '../../views/Dashboard/Attendance/SettingPanel'
import AllLeaves from '../../views/Dashboard/Leaves/AllLeaves'
import Categories from '../../views/Dashboard/Leaves/Categories'
import DepartmentInfo from '../../views/Dashboard/Department/DepartmentInfo'
// import Practice from '../../views/Dashboard/Practice';
// import UserManagement from '../../views/Dashboard/UserManagement'
// import Practicecss from '../../views/Dashboard/Practicecss';
import history from './../../services/history'

import './../../static/css/dashboard.css'
import logo from './../../static/images/logo3.png'
import $ from 'jquery'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reports from '../../views/Dashboard/Reports/Reports';
import ReportDownload from '../../views/Dashboard/Reports/ReportDownload';


class Dashboard extends Component {
    constructor(props) {
      super(props)
      this._logout=this._logout.bind(this)
    }

    componentDidMount(){
      $("[data-toggle='dropdown']").on("click",function(e){
          e.stopPropagation(); 
          let target=$(this).data('target')
          $(target).parent().toggleClass('open')
      });

      setTimeout(() => {
        $(".custom-modal-overlay").height("100vh");
      }, 1000);

      $("[data-toggle!='dropdown']").on("click",function(){
          $(".btn-group,.media").removeClass('open')
      })

      $("#menuToggle").on("click",function(){
          $(".sidebar").toggleClass('open')
      }) 
    }

    _logout(){
      localStorage.removeItem('secretkey')
      history.push("/")
    }
    
    render() {
      return (
        <div>
          <Header handleClick={this._logout} image={logo}/>
          <div className="main-content">
            <Sidebar image={logo} {...this.props}/>    
            <div className="content-body">
                <CustomBreadcrumb {...this.props}/> 
                <Switch>
                  {/* <Route path={this.props.match.url+"/analytics"} exact={true} render={(props)=><Analytics {...props}/>}/> */}
                  {/* <Route path={this.props.match.url} exact={true} render={(props)=><DashboardView {...props}/>}/> */}
                  <Route path={`${this.props.match.url}/booking`} exact={true} render={(props)=><Booking {...props} type="all"/>}/>
                  <Route path={`${this.props.match.url}/booking/truck-reporting-pending`} exact={true} render={(props)=><Booking {...props} type="truck-reporting"/>}/>
                  <Route path={`${this.props.match.url}/booking/inspection-completed`} exact={true} render={(props)=><Booking {...props} type="inspection"/>}/>
                  <Route path={`${this.props.match.url}/booking/gated-in`} exact={true} render={(props)=><Booking {...props} type="gatein"/>}/>
                  <Route path={`${this.props.match.url}/booking/detail`} exact={true} render={(props)=><BookingDetail {...props}/>}/>
                  
                  <Route path={`${this.props.match.url}/goods-receipt`} exact={true} render={(props)=><Receipt {...props} type="all"/>}/>
                  <Route path={`${this.props.match.url}/goods-receipt/weighment-out`} exact={true} render={(props)=><Receipt {...props} type="weighment-out"/>}/>
                  <Route path={`${this.props.match.url}/goods-receipt/logistics-gate-out`} exact={true} render={(props)=><Receipt {...props} type="logistics-gate-out"/>}/>
                  <Route path={`${this.props.match.url}/goods-receipt/material-gate-out`} exact={true} render={(props)=><Receipt {...props} type="material-gate-out"/>}/>
                  
                  <Route path={`${this.props.match.url}/goods-receipt/detail`} exact={true} render={(props)=><ReceiptDetail {...props}/>}/>
                  <Route path={`${this.props.match.url}/goods-receipt/create`} exact={true} render={(props)=><CreateReceipt {...props}/>}/>
                                    
                  <Route path={`${this.props.match.url}/delivery`} exact={true} render={(props)=><DeliveryView {...props}/>}/>
                  <Route path={`${this.props.match.url}/delivery/detail`} exact={true} render={(props)=><PodDetail {...props}/>}/>
                  <Route path={`${this.props.match.url}/Map`} exact={true} render={(props)=><Map {...props}/>}/>
                  
                  <Route path={`${this.props.match.url}/address-book`} exact={true} render={(props)=><AddressBook {...props}/>}/>
                  <Route path={`${this.props.match.url}/address-book/add-address`} exact={true} render={(props)=><AddAddress {...props}/>}/>
                  <Route path={`${this.props.match.url}/address-book/details`} exact={true} render={(props)=><AddressBookDetails {...props}/>}/>
                  <Route path={`${this.props.match.url}/vehicle`} exact={true} render={(props)=><Vehicle {...props}/>}/>
                  <Route path={`${this.props.match.url}/vehicle/details`} exact={true} render={(props)=><VehicleDetail {...props}/>}/>
                  <Route path={`${this.props.match.url}/driver`} exact={true} render={(props)=><Driver {...props}/>}/>

                  <Route path={`${this.props.match.url}/driver/details`} exact={true} render={(props)=><DriverDetails {...props}/>}/>

                  <Route path={`${this.props.match.url}/hubs`} exact={true} render={(props)=><Hubs {...props}/>}/>
                  
                  <Route path={`${this.props.match.url}/transporter-master`} exact={true} render={(props)=><TransporterMasterView {...props}/>}/>
                  
                  <Route path={`${this.props.match.url}/Contracts`} exact={true} render={(props)=><Contracts {...props}/>}/>
                  <Route path={`${this.props.match.url}/Contracts/details`} exact={true} render={(props)=><ContractDetailView {...props}/>}/>
                  
                  {/* <Route path={`${this.props.match.url}/UserProfile`} exact={true} render={(props)=><UserProfile {...props}/>}/> */}
                  
                  <Route path={`${this.props.match.url}/workforce`} exact={true} render={(props)=><WorkforceList {...props}/>}/>
                  <Route path={`${this.props.match.url}/workforce/user-add`} exact={true} render={(props)=><WorkforceAdd {...props}/>}/>
                  <Route path={`${this.props.match.url}/workforce/user-detail/:_id`} exact={true} render={(props)=><WorkforceDetail {...props}/>}/>

                  <Route path={`${this.props.match.url}/attendance/view`} exact={true} render={(props)=><AttendanceHome {...props}/>}/>
                  <Route path={`${this.props.match.url}/attendance`} exact={true} render={(props)=><AttendanceDayWeekView {...props}/>}/>
                  <Route path={`${this.props.match.url}/holidays`} exact={true} render={(props)=><AttendanceHolidays {...props}/>}/>
                  <Route path={`${this.props.match.url}/settings`} exact={true} render={(props)=><SettingPanel {...props}/>}/>


                  <Route path={`${this.props.match.url}/leaves/all`} exact={true} render={(props)=><AllLeaves {...props}/>}/>
                  <Route path={`${this.props.match.url}/leaves/categories`} exact={true} render={(props)=><Categories {...props}/>}/>
                  <Route path={`${this.props.match.url}/departments`} exact={true} render={(props)=><DepartmentInfo {...props}/>}/>
                  
                  <Route path={`${this.props.match.url}/reports`} exact={true} render={(props)=><Reports {...props}/>}/>
                  <Route path={`${this.props.match.url}/reports/download`} exact={true} render={(props)=><ReportDownload {...props}/>}/>




                  {/* <Route path={`${this.props.match.url}/Practice`} exact={true} render={(props)=><Practice {...props}/>}/> */}
                  <Redirect to='/dashboard/workforce'/>
                </Switch>       
                <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} /> 
            </div>
          </div>          
        </div>
      )
    }
  }
  
  export default Dashboard;