import KYCLogo from './../../static/images/icon/address/kyc.svg'
import BusinessLogo from './../../static/images/icon/address/business_id.svg'
import AddressLogo from './../../static/images/icon/address/address.svg'
import CompanyLogo from './../../static/images/icon/address/company.svg'
import ContactLogo from './../../static/images/icon/address/contact.svg'
import history from './../../services/history';

const TransporterMaster = () => {

    const _viewContract = () =>{
        history.push("/dashboard/Contracts/details")
    }

    return(
        <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-8 flex-algn-cent'>
                        <h1 className="title">Transporter Master</h1>
                    </div>
                </div>
                <hr className='bgg'/>
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='book_content'>
                                <div className='book_content_item'>
                                    <table className='book_tbl'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className='tbl_tt'>
                                                        <img src={BusinessLogo} alt=""/>
                                                        Transporter
                                                    </span>
                                                    <span className='tbl_txt'>XYZ Logistics Pvt. Ltd.</span>  
                                                    <span className='tbl_txt'>ID - XXXXX</span>                                               
                                                </td>
                                                <td>
                                                    <span className='tbl_tt'>
                                                        <img src={ContactLogo} alt=""/>
                                                        Contact Details
                                                    </span>
                                                    <span className='tbl_txt'>Contact Person - XYX</span>                                                
                                                    <span className='tbl_txt'>Mobile - 9540046458</span>                                                
                                                </td>                                                
                                                <td className='wd25'>
                                                    <span className='tbl_tt'>
                                                        <img src={KYCLogo} alt=""/>
                                                        KYC Details
                                                    </span>
                                                    <span className='tbl_txt'>PAN - WEQRT4325G</span>
                                                    <span className='tbl_txt'>GSTIN - 12WEQRT4325G1ZR</span>
                                                </td>
                                                <td rowSpan={2} className='actbt'>
                                                    <div className='button bti bg-orange-out'>
                                                        <div className='btn_icon'>
                                                            <i className='fa fa-info'></i>
                                                        </div>
                                                        <div className='btn_txt'>Detail</div>
                                                    </div>                                              
                                                    <div className='button bti bg-orange-out' onClick={_viewContract}>
                                                        <div className='btn_icon'>
                                                            <i className='fa fa-eye'></i>
                                                        </div>
                                                        <div className='btn_txt'>Contract</div>
                                                    </div>                                              
                                                    <div className='button bti bg-grey-out'>
                                                        <div className='btn_icon'>
                                                            <i className='fa fa-close'></i>
                                                        </div>
                                                        <div className='btn_txt'>Blacklist</div>
                                                    </div>                                              
                                                </td>                                            
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className='tbl_tt'>
                                                        <img src={AddressLogo} alt=""/>
                                                        Registered Address
                                                    </span>
                                                    <span className='tbl_txt'>12 Ward No 12, C Block New Delhi - 110020</span>
                                                </td>
                                                <td>
                                                    <span className='tbl_tt'>
                                                        <img src={KYCLogo} alt=""/>
                                                        Registered Since
                                                    </span>
                                                    <span className='tbl_txt'>15 Nov 2021</span>
                                                </td>
                                                <td>
                                                    <span className='tbl_tt'>
                                                        <img src={CompanyLogo} alt=""/>
                                                        Total Trips Count
                                                    </span>
                                                    <span className='tbl_txt'>4</span>
                                                </td>
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default TransporterMaster