import { Link } from "react-router-dom";
import "./styles.css";
import {
	AttendanceIcon,
	LeavesIcon,
	WorkforceIcon,
} from "../../../services/assets";

export default function Reports() {
	return (
		<>
			<div className="contentpanel">
				<div className="cust-container">
					<ReportTypes types={reportTypes} />
				</div>
			</div>
		</>
	);
}

export function ReportTypes({ types }) {
	return (
		<div className="report-type-wrap">
			{types.map(({ name, icon }, index) => {
				return (
					<Link
						to={{
							pathname: "/dashboard/reports/download",
							search: `?type=${name}`,
						}}
						key={index}
						className="report-card"
					>
						<span className="report-txt">{name}</span>
						{icon}
					</Link>
				);
			})}
		</div>
	);
}

export const reportTypes = [
	{
		name: "Attendance Report",
		icon: <AttendanceIcon className="icon-report" />,
	},
	{
		name: "Attendance Stats Report",
		icon: <AttendanceIcon className="icon-report" />,
	},
	{
		name: "Leaves Report",
		icon: <LeavesIcon className="icon-report" />,
	},
	{
		name: "Staff Report",
		icon: <WorkforceIcon className="icon-report" />,
	},
	{
		name: "Staff Ledger Report (Individual)",
		icon: <i className="fa fa-money icon-report-fa"></i>,
	},
	{
		name: "Staff Ledger Report (Aggregated)",
		icon: <i className="fa fa-money icon-report-fa"></i>,
	},
];
