import { useEffect, useState } from 'react';
import { getFile } from '../../../state/redux/workforceDetail/actions';
import { useDispatch } from 'react-redux';
 
const AddBankDetail = ({ closeModal, imageArrays, action, setAction, documentsArray, ind }) => {

    const dispatch = useDispatch();
    const [profileImg, setProfileImg] = useState('');
    const [imageIndex, setImageIndex] = useState(0)
    const [viewIndex, setViewIndex] = useState(0)
    const [viewArray, setViewAarray] = useState([])


    useEffect(() => {
        if (action !== 'view') {
            if (imageArrays) {
                dispatch(getFile.call({
                    key: imageArrays && imageArrays[imageIndex]
                }, (image_data) => {
                    if (image_data) {
                        setProfileImg(image_data?.data || '');
                    }
                }))
            }
        }
        else {
            if(documentsArray.length !== 0 && ind !== null){
                setViewAarray((documentsArray || [])[ind][viewIndex])
            }

        }
    }, [imageArrays, imageIndex, action, viewIndex, documentsArray, ind])

    const paginate = (item) => {
        if (item === 'prev') {
            if (imageIndex !== 0) {
                setImageIndex(imageIndex - 1)
            }
        }
        else if (item === 'next') {
            if (imageArrays && imageArrays?.length !== imageIndex + 1) {
                setImageIndex(imageIndex + 1)
            }
            else {
            }
        }
    }


    const paginateView = (item) => {
        if (item === 'prev') {
            if (viewIndex !== 0) {
                setViewIndex(viewIndex - 1)
            }
        }
        else if (item === 'next') {
            if (documentsArray[ind]?.length !== viewIndex + 1) {
                setViewIndex(viewIndex + 1)
            }
            else {
            }
        }
    }

    const reset = () => {
        if (action === 'view') {
            setViewIndex(0)
            setAction('')
        }
        else {
            setImageIndex(0)
        }

    }

    useEffect(() => {

    }, [viewArray])




    return (

        <section id="ViewDocument" className="custom-modal" style={{ width: "60%" }}>
            <div className="custom-modal-header">
                <h1>View Document</h1>
                <span className="ct-close" onClick={() => { closeModal(); reset() }}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>

                {action !== 'view' ?
                    <div style={{ height: '350px', display: 'flex', justifyContent: 'space-between' }}>
                        <div className='arrowElem'>
                            <i className='fa fa-angle-left arrowP' onClick={() => paginate('prev')} ></i>
                        </div>
                        <div className='imgElem'>

                            {profileImg?.includes("image") ?
                                <>
                                    <img className='imgClassP'
                                        src={profileImg}
                                        alt='' />
                                    <span style={{ position: 'absolute', bottom: '11px', color: '#000', fontWeight: '800', fontSize: '14px' }}>{`${imageIndex + 1}/${imageArrays && imageArrays?.length}`}</span>
                                </>
                                :
                                <>
                                    <object
                                        data={profileImg}
                                        type="application/pdf"
                                        width="100%"
                                        height='90%'
                                    ></object>
                                    <span style={{ position: 'absolute', bottom: '11px', color: '#000', fontWeight: '800', fontSize: '14px' }}>{`${imageIndex + 1}/${imageArrays && imageArrays?.length}`}</span>
                                </>}

                        </div>
                        <div className='arrowElem'>
                            <i className='fa fa-angle-right arrowP' onClick={() => paginate('next')}></i>
                        </div>
                    </div>
                    :
                    <div style={{ height: '350px', display: 'flex', justifyContent: 'space-between' }}>
                        <div className='arrowElem'>
                            <i className='fa fa-angle-left arrowP' onClick={() => paginateView('prev')} ></i>
                        </div>
                        <div className='imgElem'>

                            {viewArray?.includes("image") ?
                                <>
                                    <img className='imgClassP'
                                        src={viewArray}
                                        alt='' />
                                    {/* <span style={{ position: 'absolute', bottom: '11px', color: '#000', fontWeight: '800', fontSize: '14px' }}>{`${viewIndex + 1}/${documentsArray && documentsArray[ind]?.length}`}</span> */}
                                </>
                                :
                                <>
                                    <object
                                        data={viewArray}
                                        type="application/pdf"
                                        width="100%"
                                        height='90%'
                                    ></object>
                                </>
                            }
                                    <span style={{ position: 'absolute', bottom: '11px', color: '#000', fontWeight: '800', fontSize: '14px' }}>{`${viewIndex + 1}/${documentsArray && documentsArray[ind]?.length}`}</span>
                        </div>
                        <div className='arrowElem'>
                            <i className='fa fa-angle-right arrowP' onClick={() => paginateView('next')}></i>
                        </div>
                    </div>
                }


            </div>
        </section>

    )
}

export default AddBankDetail

