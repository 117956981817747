import types from './types'
import axios from 'axios'
import JointActionDispatcher from '../../../services/jointActionDispatcher'

// action for get holiday

// const domain = 'http://d8gnu1rryevl2.cloudfront.net'
// const domain = 'https://attendance.lynkit.io'
// const domain = process.env.REACT_APP_HOSTNAME
// const domain = "https://beta.attendance.lynkit.io";
const domain = process.env.REACT_APP_APIHOST || "";

export const getHolidaysFetch = () => {
    return {
        type: types.GET_HOLIDAYS_FETCH
    }
}

export const getHolidaysSucc = (data) => {
    return {
        type: types.GET_HOLIDAYS_SUCC,
        payload: data
    }
}
export const getHolidaysErr = (error) => {
    return {
        type: types.GET_HOLIDAYS_ERR,
        payload: error
    }
}

export const callGetHolidaysApi = (body) => {
    return (dispatch) => {
        dispatch(getHolidaysFetch());
        axios.post(domain + '/api/settings/get_default_holidays', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(getHolidaysSucc(response.data));
        }).catch((error) => {
            dispatch(getHolidaysErr(error.message));

        })
    }
}

// action for add holiday
export const addHolidaysFetch = () => {
    return {
        type: types.ADD_HOLIDAYS_FETCH
    }
}

export const addHolidaysSucc = (data) => {
    return {
        type: types.ADD_HOLIDAYS_SUCC,
        payload: data
    }
}
export const addHolidaysErr = (error) => {
    return {
        type: types.ADD_HOLIDAYS_ERR,
        payload: error
    }
}

export const callAddHolidaysApi = (body) => {
    return (dispatch) => {
        dispatch(addHolidaysFetch());
        axios.post(domain + '/api/settings/add_default_holidays', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(addHolidaysSucc(response.data));
        }).catch((error) => {
            dispatch(addHolidaysErr(error.message));
        })
    }
}



// attendance
// get attendance

export const getAttendanceFetch = () => {
    return {
        type: types.GET_ATTENDANCE_FETCH
    }

}
export const getAttendanceSucc = (data) => {
    return {
        type: types.GET_ATTENDANCE_SUCC,
        payload: data

    }

}

export const getAttendanceErr = (error) => {
    return {
        type: types.GET_ATTENDANCE_ERR,
        payload: error
    }

}
export const callGetAttendanceApi = (body) => {
    return (dispatch) => {
        dispatch(getAttendanceFetch());
        axios.post(domain + '/api/attendance/get_attendance', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(getAttendanceSucc(response.data));
        }).catch((error) => {
            dispatch(getAttendanceErr(error.message));
        })

    }

}

// add attendance
export const addAttendanceFetch = () => {
    return {
        type: types.ADD_ATTENDANCE_FETCH
    }
}

export const addAttendanceSucc = (data) => {
    return {
        type: types.ADD_ATTENDANCE_SUCC,
        payload: data
    }

}
export const addAttendanceErr = (error) => {
    return {
        type: types.ADD_ATTENDANCE_ERR,
        payload: error
    }

}

export const callAddAttendanceApi = (body) => {
    return (dispatch) => {
        dispatch(addAttendanceFetch());
        axios.post(domain + '/api/attendance/add_attendance', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(addAttendanceSucc(response.data));
        }).catch((error) => {
            dispatch(addAttendanceErr(error.message));
        })

    }

}


//  get Expense

export const getExpenseFetch = () => {
    return {
        type: types.GET_EXPENSE_FETCH
    }
}

export const getExpenseSucc = (data) => {
    return {
        type: types.GET_EXPENSE_SUCC,
        payload: data
    }

}
export const getExpenseErr = (error) => {
    return {
        type: types.GET_EXPENSE_ERR,
        payload: error
    }

}

export const callGetExpenseApi = (body) => {
    return (dispatch) => {
        dispatch(getExpenseFetch());
        axios.post(domain + '/api/expense/get_expense', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(getExpenseSucc(response.data));
        }).catch((error) => {
            dispatch(getExpenseErr(error.message));
        })

    }

}
// add expense

export const addExpenseFetch = () => {
    return {
        type: types.ADD_EXPENSE_FETCH
    }
}

export const addExpenseSucc = (data) => {
    return {
        type: types.ADD_EXPENSE_SUCC,
        payload: data
    }

}
export const addExpenseErr = (error) => {
    return {
        type: types.ADD_EXPENSE_ERR,
        payload: error
    }

}

export const callAddExpenseApi = (body) => {
    return (dispatch) => {
        dispatch(addExpenseFetch());
        axios.post(domain + '/api/expense/add_expense', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(addExpenseSucc(response.data));
        }).catch((error) => {
            dispatch(addExpenseErr(error.message));
        })

    }

}

// update expense
export const updateExpenseFetch = () => {
    return {
        type: types.UPDATE_EXPENSE_FETCH
    }
}

export const updateExpenseSucc = (data) => {
    return {
        type: types.UPDATE_EXPENSE_SUCC,
        payload: data
    }

}
export const updateExpenseErr = (error) => {
    return {
        type: types.UPDATE_EXPENSE_ERR,
        payload: error
    }

}

export const callUpdateExpenseApi = (body) => {
    return (dispatch) => {
        dispatch(updateExpenseFetch());
        axios.post(domain + '/api/expense/update_expense', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(updateExpenseSucc(response.data));
        }).catch((error) => {
            dispatch(updateExpenseErr(error.message));
        })

    }

}
//delete expense

export const deleteExpenseFetch = () => {
    return {
        type: types.DELETE_EXPENSE_FETCH
    }
}

export const deleteExpenseSucc = (data) => {
    return {
        type: types.DELETE_EXPENSE_SUCC,
        payload: data
    }

}
export const deleteExpenseErr = (error) => {
    return {
        type: types.DELETE_EXPENSE_ERR,
        payload: error
    }
}

export const callDeleteExpenseApi = (body) => {
    return (dispatch) => {
        dispatch(deleteExpenseFetch());
        axios.post(domain + '/api/expense/delete_expense', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(deleteExpenseSucc(response.data));
        }).catch((error) => {
            dispatch(deleteExpenseErr(error.message));
        })

    }

}

// expense type
export const getExpenseTypeFetch = () => {
    return {
        type: types.GET_EXPENSE_TYPE_FETCH
    }
}

export const getExpenseTypeSucc = (data) => {
    return {
        type: types.GET_EXPENSE_TYPE_SUCC,
        payload: data
    }

}
export const getExpenseTypeErr = (error) => {
    return {
        type: types.GET_EXPENSE_TYPE_ERR,
        payload: error
    }

}

export const callGetExpenseTypeApi = (body) => {
    return (dispatch) => {
        dispatch(getExpenseTypeFetch());
        axios.post(domain + '/api/expense/get_expense_type', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(getExpenseTypeSucc(response.data));
        }).catch((error) => {
            dispatch(getExpenseTypeErr(error.message))

        })

    }

}

// get payment method

export const getPaymentMethodFetch = () => {
    return {
        type: types.GET_PAYMENT_METHOD_FETCH
    }

}
export const getPaymentMethodSucc = (data) => {
    return {
        type: types.GET_PAYMENT_METHOD_SUCC,
        payload: data
    }

}
export const getPaymentMethodErr = (error) => {
    return {
        type: types.GET_PAYMENT_METHOD_ERR,
        payload: error
    }

}
export const callGetPaymentMethodApi = (body) => {
    return (dispatch) => {
        dispatch(getPaymentMethodFetch());
        axios.post(domain + '/api/expense/get_payment_method', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(getPaymentMethodSucc(response.data));
        }).catch((error) => {
            dispatch(getPaymentMethodErr(error.message))

        })

    }

}

// add payment method

export const addPaymentMethodFetch = () => {
    return {
        type: types.ADD_PAYMENT_METHOD_FETCH
    }

}
export const addPaymentMethodSucc = (data) => {
    return {
        type: types.ADD_PAYMENT_METHOD_SUCC,
        payload: data
    }

}
export const addPaymentMethodErr = (error) => {
    return {
        type: types.ADD_PAYMENT_METHOD_ERR,
        payload: error
    }

}
export const callAddPaymentMethodApi = (body) => {
    return (dispatch) => {
        dispatch(addPaymentMethodFetch());
        axios.post(domain + '/api/expense/add_payment_method', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            // console.log(response.data);
            dispatch(addPaymentMethodSucc(response.data));
        }).catch((error) => {
            dispatch(addPaymentMethodErr(error.message))

        })

    }

}
// get user leaves
export const getUserLeavesFetch = () => {
    return {
        type: types.GET_USER_LEAVES_FETCH
    }
}

export const getUserLeavesSucc = (data) => {
    return {
        type: types.GET_USER_LEAVES_SUCC,
        payload: data
    }

}
export const getUserLeavesErr = (error) => {
    return {
        type: types.GET_USER_LEAVES_ERR,
        payload: error
    }
}

export const callGetUserLeavesApi = (body) => {
    return (dispatch) => {
        dispatch(getUserLeavesFetch());
        axios.post(domain + '/api/user/get_user_leaves', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(getUserLeavesErr(response.data.message))
            }
            else {
                dispatch(getUserLeavesSucc(response.data));
            }

        }).catch((error) => {
            dispatch(getUserLeavesErr(error.message))
        })

    }
}
// update leave

export const updateUserLeavesFetch = () => {
    return {
        type: types.UPDATE_USER_LEAVES_FETCH
    }
}

export const updateUserLeavesSucc = (data) => {
    return {
        type: types.UPDATE_USER_LEAVES_SUCC,
        payload: data
    }

}
export const updateUserLeavesErr = (error) => {
    return {
        type: types.UPDATE_USER_LEAVES_ERR,
        payload: error
    }
}

export const callUpdateUserLeavesApi = (body) => {
    return (dispatch) => {
        dispatch(updateUserLeavesFetch());
        axios.post(domain + '/api/user/update_leaves', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(updateUserLeavesSucc(response.data));
        }).catch((error) => {
            dispatch(updateUserLeavesErr(error.message))
        })

    }
}



// get casual leave balance
export const getCasualBalanceFetch = () => {
    return {
        type: types.GET_CASUAL_LEAVE_BALANCE_FETCH
    }
}
export const getCasualBalanceSucc = (data) => {
    return {
        type: types.GET_CASUAL_LEAVE_BALANCE_SUCC,
        payload: data
    }
}
export const getCasualBalanceErr = (error) => {
    return {
        type: types.GET_CASUAL_LEAVE_BALANCE_ERR,
        payload: error
    }
}
export const callGetCasualBalance = (body) => {
    return (dispatch) => {
        dispatch(getCasualBalanceFetch());
        axios.post(domain + '/api/user/get_leave_balance', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(getCasualBalanceSucc(response.data));
        }).catch((error) => {
            dispatch(getCasualBalanceErr(error.message));
        })
    }

}
// get sick leave balance

export const getSickLeaveBalanceFetch = () => {
    return {
        type: types.GET_SICK_LEAVE_BALANCE_FETCH
    }
}

export const getSickLeaveBalanceSucc = (data) => {
    return {
        type: types.GET_SICK_LEAVE_BALANCE_SUCC,
        payload: data
    }
}

export const getSickLeaveBalanceErr = (error) => {
    return {
        type: types.GET_SICK_LEAVE_BALANCE_ERR,
        payload: error.message
    }
}
export const callGetSickLeaveApi = (body) => {
    return (dispatch) => {
        dispatch(getSickLeaveBalanceFetch());
        axios.post(domain + '/api/user/get_leave_balance', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(getSickLeaveBalanceSucc(response.data));
        }).catch((error) => {
            dispatch(getSickLeaveBalanceErr(error.message));
        })
    }
}
// earned leave balance

export const getEarnedLeaveBalanceFetch = () => {
    return {
        type: types.GET_EARNED_LEAVE_BALANCE_FETCH
    }
}
export const getEarnedLeaveBalanceSucc = (data) => {
    return {
        type: types.GET_EARNED_LEAVE_BALANCE_SUCC,
        payload: data
    }
}
export const getEarnedLeaveBalanceErr = (error) => {
    return {
        type: types.GET_EARNED_LEAVE_BALANCE_ERR,
        payload: error
    }
}

export const callGetEarnedLeaveApi = (body) => {
    return (dispatch) => {
        dispatch(getEarnedLeaveBalanceFetch());
        axios.post(domain + '/api/user/get_leave_balance', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(getEarnedLeaveBalanceSucc(response.data));
        }).catch((error) => {
            dispatch(getEarnedLeaveBalanceErr(error.message));
        })
    }
}

// attendance stats
export const getAttendanceStatsFetch = () => {
    return {
        type: types.GET_ATTENDANCE_STATS_FETCH
    }
}
export const getAttendanceStatsSucc = (data) => {
    return {
        type: types.GET_ATTENDANCE_STATS_SUCC,
        payload: data
    }
}
export const getAttendanceStatsErr = (error) => {
    return {
        type: types.GET_ATTENDANCE_STATS_ERR,
        payload: error
    }
}
export const callGetAttendanceStatsApi = (body) => {
    return (dispatch) => {
        dispatch(getAttendanceStatsFetch());
        axios.post(domain + '/api/attendance/get_attendance_stats', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            dispatch(getAttendanceStatsSucc(response.data));
        }).catch((error) => {
            dispatch(getAttendanceStatsErr(error.message))
        })
    }
}

export const addLeavesFetch = () => {
    return {
        type: types.ADD_LEAVES_FETCH
    }
}

export const addLeavesSucc = (data) => {
    return {
        type: types.ADD_LEAVES_SUCC,
        payload: data
    }
}
export const addLeavesErr = (error) => {
    return {
        type: types.ADD_LEAVES_ERR,
        payload: error
    }
}

export const callAddLeavesApi = (body) => {
    return (dispatch) => {
        dispatch(addLeavesFetch());
        axios.post('', body, {
            headers: {
                'accesskey': localStorage.getItem('secretkey')
            }
        }).then((response) => {
            dispatch(addLeavesSucc(response.data))
        }).catch((error) => {
            dispatch(addLeavesErr(error.message))
        })

    }
}




export const getDefaultHolidays = {
    url: `/api/settings/get_default_holidays`,
    action: types.GET_DEFAULT_HOLIDAYS,
    __proto__: JointActionDispatcher
}

// export const addDefaultHoliday = {
//     url: `/api/settings/add_default_holiday`,
//     action: types.ADD_DEFAULT_HOLIDAY,
//     __proto__: JointActionDispatcher                                        //not using this instead using the addHoliday api instant fix
// }
export const add_Holiday = {
    url: `/api/settings/add_holiday`,
    action: types.ADD_DEFAULT_HOLIDAY,
    __proto__: JointActionDispatcher
}

export const getUserLeaves = {
    url: `/api/user/get_user_leaves`,
    action: types.GET_USER_LEAVES,
    __proto__: JointActionDispatcher
}

export const getAttendance = {
    url: `/api/attendance/get_attendance`,
    action: types.GET_ATTENDANCE,
    __proto__: JointActionDispatcher
}

export const updateAttendance = {
    url: `/api/attendance/update_attendance`,
    action: types.UPDATE_ATTENDANCE,
    __proto__: JointActionDispatcher
}

export const getAttendanceStats = {
    url: `/api/attendance/get_attendance_stats`,
    action: types.GET_ATTENDANCE_STATS,
    __proto__: JointActionDispatcher
}

export const getSettings = {
    url: `/api/settings/get_settings`,
    action: types.GET_SETTINGS,
    __proto__: JointActionDispatcher
}

export const updateSettings = {
    url: `/api/settings/update_settings`,
    action: types.UPDATE_SETTINGS,
    __proto__: JointActionDispatcher
}


export const getHolidays = {
    url: `/api/settings/get_holidays`,
    action: types.GET_HOLIDAYS,
    __proto__: JointActionDispatcher
}

export const updateHolidays = {
    url: `/api/settings/update_holidays`,
    action: types.UPDATE_HOLIDAYS,
    __proto__: JointActionDispatcher
}

export const markAllAttendance = {
    url: `/api/attendance/mark_all_attendance`,
    action: types.MARK_ALL,
    __proto__: JointActionDispatcher
}

export const applyMultipleLeaves = {
    url: `/api/user/mark_leaves`,
    action: types.APPLY_MULTIPLE_LEAVES,
    __proto__: JointActionDispatcher
}


