import React, { useEffect, useState } from 'react';
import FileUpload from './../FileUpload'
import $ from 'jquery';
import moment from 'moment';
import { base64Convert } from '../../../services/functions';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { expenseTypesSelector, paymentMethodsSelector, monthSalarySelector,allBankSelector } from '../../../state/redux/workforceDetail/selectors';
import { addExpenseType, addPaymentMethod, getExpenseType, getPaymentMethod, getSalary,getAllBanks } from '../../../state/redux/workforceDetail/actions';
import AddExpenseType from './AddExpenseType';
import AddPaymentMode from './AddPaymentMode';


export default function AddCredit({ closeModal, addCredit, userId, balance }) {

    const dispatch = useDispatch();

    const allBanks = useSelector(allBankSelector);
    const expenseTypes = useSelector(expenseTypesSelector);
    const paymentMethods = useSelector(paymentMethodsSelector);

    const [uploadFiles, setUploadFiles] = useState([])
    const [imagesUrl, setImagesUrl] = useState([])
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const selectedPaymentMode = watch('payment_mode', "");
    const selectedExpenseName = watch('expense_name', "");

    const monthSalary = useSelector(monthSalarySelector);

    const [month, setMonth] = useState("");
    const [includeBalance, setIncludeBalance] = useState(false);

    const [showTid, setShowTid] = useState(false);
    useEffect(() => {
        let s = false;
        for (let i = 0; i < paymentMethods.length; i++) {
            if (selectedPaymentMode == paymentMethods[i].payment_method) {
                s = paymentMethods[i].transaction_id
                break;
            }
        }
        setShowTid(s);
    }, [selectedPaymentMode])

    const handleFilesUpload = async (files) => {
        //    
        for (const file of files) {
            //  let imageURL=URL.createObjectURL(file)
            setImagesUrl((prev) => [...prev, URL.createObjectURL(file)])
        }

        const promises = Array.from(files).map(async file => {
            // console.log(file.name);
            const base64File = await base64Convert(file);
            return base64File
        })
        const base64Files = await Promise.all(promises);
        setUploadFiles([...uploadFiles, ...base64Files]);
    }

    const handleRemoveDocument = (i) => {
        let oldBase = [...uploadFiles]
        let oldDocuments = [...imagesUrl];
        oldDocuments.splice(i, 1);
        oldBase.splice(i, 1);
        setImagesUrl(oldDocuments);
        setUploadFiles(oldBase)
    }
    const emptyFields = () => {
        setUploadFiles([]);
        setImagesUrl([])
        reset();
        setShowTid(false);
    }
    const handleAddCredit = (data) => {
        if(data.bank){
            let obj=allBanks.find(el=>el._id==data.bank)
            delete data.bank
            data['from_bank']=obj
        }
        data.image = uploadFiles;
        data.closeModal = closeModal;                //passing the function here to as we are not getting the response so it will be called when response is received in the joint dispatch
        data.emptyFields = emptyFields;
        addCredit(data);
        // closeModal();
    }

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = (id) => {
        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(`#addExpenseTypeModal`).removeClass("custom-modal-is-visible")
        // setUpdateDocumentObj(null);
    }

    const _closeModal2 = () => {
        $(`#addPaymentModeModal`).removeClass("custom-modal-is-visible")
    }
    const _addExpenseType = (data) => {
        data.toast = true;
        dispatch(addExpenseType.call(data, () => {
            dispatch(getExpenseType.call({ expense_type: "user" }))
        }))
    }

    const _addPaymentMode = (data) => {
        data.toast = true;
        dispatch(addPaymentMethod.call(data, () => {
            dispatch(getPaymentMethod.call({}))
        }))
    }
    const exitModal = () => {
        closeModal()
        setUploadFiles([])
        setImagesUrl([])
    }


    useEffect(() => {
        if (month != '') {
            dispatch(getSalary.call({
                userId: userId,
                month: month
            }))
        }

    }, [month])

    useEffect(() => {
        // dispatch(getExpenseType.call({
        //     expense_type: "driver"
        // }))
        dispatch(getPaymentMethod.call({}))
        dispatch(getAllBanks.call({}))
    }, [])


    return (

        <section id="addCreditModal" className="custom-modal">
            <div className="custom-modal-header">
                <h1>Add Credit</h1>
                <span className="ct-close" onClick={exitModal}></span>
            </div>
            <AddExpenseType closeModal={_closeModal} addExpenseType={_addExpenseType} />
            <AddPaymentMode closeModal={_closeModal2} addPaymentMode={_addPaymentMode} />
            <form onSubmit={handleSubmit(handleAddCredit)}>
                <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Expense Type<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" type="password" {...register('expense_name', { required: true })}>



                                <option value="">Select Expense</option>
                                {
                                    expenseTypes.map((exp, i) => {
                                        return (
                                            <option key={i} value={exp.expense_name}>{exp.expense_name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.expense_name && <p className='error red_color'>Expense Type is required</p>}
                            <div className='text-start mt-1'>
                                <a className='btn btn-orange btn-sm' onClick={() => _handleClick('addExpenseTypeModal')}>Add +</a>
                            </div>
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Amount Paid<span className="red_color">*</span></label>
                            <input className="cust-input" type="number" step=".01" placeholder="Enter Amount" {...register('amount_paid', { required: true })} />
                            {errors.amount_paid && <p className='error red_color'>Amount Paid is required</p>}
                        </div>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Payment Date<span className="red_color">*</span></label>
                            <input className="cust-input" type="date" max={moment().format("YYYY-MM-DD")} placeholder="Payment Date" {...register('expense_date', { required: true })} />
                            {errors.expense_date && <p className='error red_color'>Payment Date is required</p>}
                        </div>
                    </div>
                    {
                        selectedExpenseName == "Salary" &&
                        <div className="cust-row mb-2">
                            <div className="cust-col-2">
                                <label className="black_color font-bold text-left wdt100">Month<span className="red_color">*</span></label>
                                <select className="cust-input select2-single" type="password" value={month} onChange={(e) => setMonth(e.target.value)}>
                                    <option value="">Select Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            <div className='cust-col-5'>
                                <table className='bordered_table'>
                                    <tbody>
                                        <tr>

                                            <th>
                                                <h2 className='tht'>Monthly Salary</h2>
                                            </th>
                                            <th>
                                                <h2 className='tht'>{monthSalary.salary}</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <h2 className='tht'>Present Days</h2>
                                            </th>
                                            <th>
                                                <h2 className='tht'>{monthSalary.present}</h2>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th >
                                                <h2 className='tht'>Payable Days</h2>
                                            </th>
                                            <th>
                                                <h2 className='tht'>{monthSalary.payableDays}</h2>
                                            </th>
                                        </tr>
                                        {
                                            balance > 0 &&
                                            <tr>
                                                <th >
                                                    <h2 className='tht'>Include Balance({balance})</h2>
                                                </th>
                                                <th>
                                                    <input type="checkbox" value={includeBalance} onChange={() => setIncludeBalance(!includeBalance)} />
                                                </th>
                                            </tr>

                                        }

                                        <tr>
                                            <th >
                                                <h2 className='tht'>
                                                    <strong>Outstanding</strong>
                                                </h2>
                                            </th>
                                            <th>
                                                <h2 className='tht'>
                                                    <strong className='text-success'>
                                                        {
                                                            includeBalance
                                                                ?
                                                                monthSalary.salary - balance
                                                                :
                                                                monthSalary.salary
                                                        }
                                                    </strong>
                                                </h2>
                                            </th>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    <div className="cust-row mb-2">
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Payment Mode<span className="red_color">*</span></label>
                            <select className="cust-input select2-single" type="password" {...register('payment_mode', { required: true })}>

                                <option value="">Select Mode</option>
                                {
                                    paymentMethods.map((method, i) => {
                                        return (
                                            <option value={method.payment_method} key={i}>{method.payment_method}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.payment_mode && <p className='error red_color'>Payment Mode is required</p>}
                            <div className='text-start mt-1'>
                                <a className='btn btn-orange btn-sm' onClick={() => _handleClick('addPaymentModeModal')}>Add +</a>
                            </div>


                        </div>
                        {
                            showTid &&
                            (
                                <div className="cust-col-5">
                                    <label className="black_color font-bold text-left wdt100">Transaction ID<span className="red_color">*</span></label>
                                    <input className="cust-input" type="text" placeholder="Transaction Id" {...register('transaction_id', { required: true })} />
                                </div>
                            )

                        }
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Remarks(Optional)</label>
                            <input className="cust-input" type="text" placeholder="Add Remarks" {...register('notes')} />
                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className="cust-col-5">
                            <label className="black_color font-bold text-left wdt100">Select Transaction Bank<span>(optional)</span></label>
                            <select data-testid="bank_dropdown_add_credit" className="cust-input select2-single" {...register('bank', { required: false })}>
                                <option value="">Select Bank</option>
                                {
                                    allBanks.map((bank, i) => {
                                        return (
                                            <option key={i} value={bank._id}>{bank.bankName +" - "+bank.accountNumber}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.bank && <p className='error red_color'>Bank is required</p>}
                        </div>
                    </div>

                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <label className="black_color font-bold text-left wdt100">Add Image(Optional)</label>
                            <div className='doc_container'>
                                <div className='doc_container_bdy'>
                                    <FileUpload handleFiles={handleFilesUpload} modalId="add-credit-documents" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        imagesUrl.length > 0 &&
                        <>
                            <br />
                            <h6>Selected Files</h6>
                            <hr />
                            <div className='row'>
                                {
                                    imagesUrl.map((file, i) => {
                                        return (
                                            <div className='col-md-6 col-sm-4 col-lg-3' key={i}>
                                                <div className='alert alert-info alert-holiday alert-dismissible fade show p-3' style={{ width: "115px", height: "110px" }}>
                                                    {file?.includes("image") ? (
                                                        <>
                                                            <img src={file} style={{ width: "100%", height: "100%" }} /> <button type="button" className="btn-close" style={{ padding: "0px" }} onClick={() => handleRemoveDocument(i)}></button>
                                                        </>
                                                    )
                                                        :
                                                        (
                                                            <>
                                                                <object
                                                                    data={file}
                                                                    type="application/pdf"
                                                                    width="100%"
                                                                    height='100%'
                                                                ></object>
                                                                <button type="button" className="btn-close" style={{ padding: "0px" }} onClick={() => handleRemoveDocument(i)}></button>
                                                            </>
                                                        )}
                                                </div>

                                            </div>
                                        )
                                    })
                                }



                            </div>
                            <hr />
                        </>
                    }
                    <div className="cust-row">
                        <div className="cust-col-4">

                        </div>
                        <div className="cust-col-2">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-plus'></i>
                                </div>
                                <div className='btn_txt font-lg'> Submit </div>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </section>

    )
}


